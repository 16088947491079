// @ts-nocheck
import React from 'react';
import { Button, Dialog, Text, PROPS } from 'new-ui';
import { getText } from '../../../i18n';

import { CHECKOUT } from '../../bi/constants/checkout';
import { SERVICETYPE, SERVICETYPERU } from '../../bi/constants/serviceType';
import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';

import { getEmployeeFullNameWithSimplicity } from '../../bi/utils/employees';
import parseJsonString from '../../bi/utils/parseJsonString';

import CartAir from './components/cartAir';
import CartTrain from './components/cartTrain';
import CartHotel from './components/cartHotel';
import TripAir from './components/tripAir';
import TripTrain from './components/tripTrain';
import TripHotel from './components/tripHotel';

import styles from './styles/index.module.css';

interface CartConflicts {
  Data: string,
  Employees: any[],
  Id: number,
  ServiceType: string,
}

interface TripConflicts {
  id: number,
  serviceType: string,
  jsonData: string,
}

interface ConflictsType {
  cartConflicts: CartConflicts[],
  tripConflicts: TripConflicts[],
  duplicateCartTrips: CartConflicts[],
  titleTrip: string,
}

interface DuplicateDialogTypeProps {
  conflicts: ConflictsType,
  onClose: () => void,
  onBack: () => void,
  show: boolean,
}

type FieldType = 'Cart' | 'Trip';

const LABELS = {
  BACK: getText('components:duplicateDialog.edit'),
  CANCEL: getText('components:duplicateDialog.cancel'),
};

const FIELD = {
  CART: 'Cart',
  TRIP: 'Trip',
  DUPLICATECARTS: 'DuplicateCarts',
};

const getCartItem = {
  [SERVICETYPE.AIR]: CartAir,
  [SERVICETYPE.TRAIN]: CartTrain,
  [SERVICETYPE.HOTEL]: CartHotel,
};

const getTripItem = {
  [SERVICETYPE.AIR]: TripAir,
  [SERVICETYPE.TRAIN]: TripTrain,
  [SERVICETYPE.HOTEL]: TripHotel,
};

const tripHeader = (tripConflicts: any[]) => (
  tripConflicts.length === 1 ? CHECKOUT.DUBLICATE.HEADER.TRIP : CHECKOUT.DUBLICATE.HEADER.TRIPS
);

const serviceTypeRu = (type: string) => {
  const preparedType = SERVICETYPERU[type];

  return preparedType[0].toUpperCase() + preparedType.slice(1);
};

class DuplicateDialog extends React.Component<DuplicateDialogTypeProps> {
  renderTravellers = ({ jsonData }) => {
    const data = parseJsonString(jsonData);

    if (!data) return null;

    const { VoucherTravellers } = data;

    const html = VoucherTravellers.map((traveller: any, index :number) => {
      const comma = index > 0 ? ', ' : '';

      return (
        <Text
          key={ `trip_traveller_${index}_${traveller.LastName}` }
          type={ PROPS.TEXT.TYPES.NORMAL_14 }
          color={ PROPS.TEXT.COLORS.GRAY }
        >
          {comma}{getEmployeeFullNameWithSimplicity(traveller)}
        </Text>
      );
    });

    return (
      <span>{html}</span>
    );
  };

  renderTripItem = (item: any) => {
    const { serviceType } = item;

    return getTripItem[serviceType](item, this.renderTravellers(item), serviceTypeRu(serviceType));
  };

  renderCartItem = (item) => {
    const { ServiceType, Employees } = item;
    const employees = Employees.map(({ Employee }) => getEmployeeFullNameWithSimplicity(Employee)).join(', ');

    return getCartItem[ServiceType](item, employees, serviceTypeRu(ServiceType));
  };

  renderItems = (items: any[], field: FieldType) => {
    const { conflicts: { titleTrip } } = this.props;

    const isCart = field !== FIELD.TRIP;
    const titleText = isCart ? CHECKOUT.DUBLICATE.TITLES[field] : titleTrip;

    const renderInfo = (item: any) => (
      isCart ? this.renderCartItem(item) : this.renderTripItem(item)
    );

    return (
      <div className={ styles.items }>
        <Text className={ styles.title } type={ PROPS.TEXT.TYPES.NORMAL_18 }>{ titleText }</Text>
        { items.map((i) => (
          <div className={ styles.item } key={ i.id || i.Id }>
            { renderInfo(i) }
          </div>
        )) }
      </div>
    );
  };

  render() {
    const {
      conflicts: {
        cartConflicts,
        tripConflicts,
        duplicateCartTrips,
      },
      onClose,
      onBack,
      show,
    } = this.props;

    const cartItemsHtml = cartConflicts.length > 0 && this.renderItems(cartConflicts, FIELD.CART);
    const tripItemsHtml = tripConflicts.length > 0 && this.renderItems(tripConflicts, FIELD.TRIP);
    const duplicateItemsHtml = duplicateCartTrips.length > 0 && this.renderItems(duplicateCartTrips, FIELD.DUPLICATECARTS);

    const headerText = tripItemsHtml && !cartItemsHtml ? tripHeader(tripConflicts) : CHECKOUT.DUBLICATE.HEADER.CART;
    const borderHtml = tripItemsHtml && cartItemsHtml && <div className={ styles.border } />;

    const headerClassName = headerText === CHECKOUT.DUBLICATE.HEADER.CART ? `${styles.header} ${styles.error}` : styles.header;

    return (
      <Dialog
        show={ show }
        onChange={ onClose }
        showClosing
        qaAttrWrapper={ QA_ATTRIBUTES.cart.duplicate.wrapper }
        qaAttr={ QA_ATTRIBUTES.cart.duplicate.close }
        outsideClickClosing={ false }
      >
        <div className={ styles.wrap }>
          <Text
            qaAttr={ QA_ATTRIBUTES.cart.duplicate.header }
            className={ headerClassName }
            type={ PROPS.TEXT.TYPES.BOLD_20 }
          >
            { headerText }
          </Text>
          { tripItemsHtml }
          { duplicateItemsHtml }
          { borderHtml }
          { cartItemsHtml }
          <div className={ styles.action }>
            <Button
              type={ PROPS.BUTTON.TYPES.PRIMARY }
              onClick={ onBack }
              qaAttr={ QA_ATTRIBUTES.cart.duplicate.buttons.back }
            >
              { LABELS.BACK }
            </Button>
            <Button
              className={ styles.cancel }
              type={ PROPS.BUTTON.TYPES.TEXTUAL_MEDIUM }
              onClick={ onClose }
              qaAttr={ QA_ATTRIBUTES.cart.duplicate.buttons.close }
            >
              { LABELS.CANCEL }
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default DuplicateDialog;
