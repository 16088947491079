// @ts-nocheck
const iconContentLayout = (ymaps) => {
  const myIconContentLayout = ymaps.templateLayoutFactory.createClass(
    `<div class="ymap-point-wrapper">
       <div class="ymap-point-count-wrapper">
         <div class="ymap-point-count-icon-container"></div>
       </div>
  </div>`,
    {
      build() {
        myIconContentLayout.superclass.build.call(this);

        const price = this.getData().properties.get('price');

        if (price) {
          const priceWrapper = this._parentElement.querySelector('.ymap-point-wrapper');

          const priceHtml = `<span class="ymap-point-price">${price}</span>`;
          const child = document.createElement('div');
          child.classList.add('ymap-point-price-wrapper');
          child.innerHTML = priceHtml;

          priceWrapper.appendChild(child);
        }
      },
      clear() {
        myIconContentLayout.superclass.clear.call(this);
      },
    },
  );

  return myIconContentLayout;
};

const iconMinContentLayout = (ymaps) => {
  const myIconContentLayout = ymaps.templateLayoutFactory.createClass(
    `<div class="ymap-point-animation">
         <div class="placemark"></div>
       </div>`,
    {
      build() {
        myIconContentLayout.superclass.build.call(this);
        this.wrapper = this.getParentElement().getElementsByClassName('ymap-point-animation')[0];

        this.getData().geoObject.events.add('propertieschange', () => {
          const active = this.getData().properties.get('active');

          if (active) {
            this.wrapper.classList.add('active');
          } else {
            this.wrapper.classList.remove('active');
          }
        });
      },
    },
  );

  return myIconContentLayout;
};

export { iconContentLayout, iconMinContentLayout };
