import React, { useState } from 'react';
import { Icon, StyledWrapper } from 'new-ui';

import { IMulty } from './types';

import styles from './styles/index.module.css';

const MultiPassangers = ({
  sum,
  children,
}: IMulty) => {
  const [show, setShow] = useState<boolean>(false);

  const renderDropdown = () => {
    if (!show) {
      return null;
    }

    return (
      <StyledWrapper className={ styles.dropdown }>
        { children }
      </StyledWrapper>
    );
  };

  const arrow = show ? 'arrowsUpGray' : 'arrowsDownGray';

  return (
    <div
      className={ styles.wrapper }
    >
      <div
        className={ styles.header }
        onClick={ () => setShow(prevState => !prevState) }
      >
        { sum }
        <Icon type={ arrow }/>
      </div>
      <div >
        { renderDropdown() }
      </div>
    </div>
  );
};

export default MultiPassangers;
