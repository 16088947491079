import { getText } from '../../../i18n';

import { ERROR_CODE } from './app';

const FIELDS = {
  PREPAYMENTS: 'PREPAYMENTS',
  EXPENSES: 'EXPENSES',
};

const DAILY_EXPENSES = {
  RU: 700,
  NOT_RU: 2500,
};

const PREPAYMENTS = {
  DAILY_EXPENSES: 0,
  PREPAYMENT: 1,
  CASH: 0,
  CARD: 1,
};

const EXPENSES_NAMES = [
  {
    value: null,
    label: '',
  },
];

const PREFILED_VALUE_TYPE = {
  SELECT_INPUT: 'input_or_select',
  SELECT_SEARCH: 'select_with_search',
  NONE: 'none',
};

const PREPAYMENTS_TYPE = {
  [PREPAYMENTS.DAILY_EXPENSES]: {
    RU: getText('constants:expenseReport.prepaymentsType.dailyExpenses.ru'),
    LABEL: getText('constants:expenseReport.prepaymentsType.dailyExpenses.ru'),
  },
  [PREPAYMENTS.PREPAYMENT]: {
    RU: getText('constants:expenseReport.prepaymentsType.prepayment.ru'),
    LABEL: getText('constants:expenseReport.prepaymentsType.prepayment.ru'),
  },
};

const PREPAYMENTS_METHOD = {
  [PREPAYMENTS.CASH]: {
    TYPE: 'Cash',
    RU: getText('constants:expenseReport.prepaymentsMethod.cash.ru'),
  },
  [PREPAYMENTS.CARD]: {
    TYPE: 'Card',
    RU: getText('constants:expenseReport.prepaymentsMethod.card.ru'),
  },
};

enum EXPENSES {
  CASH_RECEIPT = 0,
  SALES_RECEIPT = 1,
  BSO = 2,
  ENOVER_RECEIPT = 3,
}

const EXPENSES_NAME = {
  GSM: 0,
  TRAIN: 1,
  LIVE: 2,
  ANOTHER: 3,
};

const EXPENSES_TYPE = {
  [EXPENSES.CASH_RECEIPT]: {
    Type: 'CashReceipt',
    RU: getText('constants:expenseReport.expensesType.cashReceipt.ru'),
  },
  [EXPENSES.SALES_RECEIPT]: {
    Type: 'SalesReceipt',
    RU: getText('constants:expenseReport.expensesType.salesReceipt.ru'),
  },
  [EXPENSES.BSO]: {
    Type: 'BSO',
    RU: getText('constants:expenseReport.expensesType.bso.ru'),
    RU_1S: getText('constants:expenseReport.expensesType.bso.ru_1s'),
  },
};

const EXPENSE_VAT = {
  VAT_RATES: (v: string) => getText('expenseReports:report.expenseDialog.vatRates', { v }),
  WITHOUT_NDS: getText('expenseReports:report.expenseDialog.withoutNds'),
};

const EXPENSE_VAT_RATES = [
  { label: EXPENSE_VAT.VAT_RATES('20'), value: 1 },
  { label: EXPENSE_VAT.VAT_RATES('10'), value: 2 },
  { label: EXPENSE_VAT.VAT_RATES('20/120'), value: 3 },
  { label: EXPENSE_VAT.VAT_RATES('10/110'), value: 4 },
  { label: EXPENSE_VAT.VAT_RATES('0'), value: 5 },
  { label: EXPENSE_VAT.WITHOUT_NDS, value: 6 },
];

const EXPENSES_TYPES = [
  {
    value: EXPENSES.CASH_RECEIPT,
    label: getText('constants:expenseReport.expensesType.cashReceipt.ru'),
  },
  {
    value: EXPENSES.SALES_RECEIPT,
    label: getText('constants:expenseReport.expensesType.salesReceipt.ru'),
  },
  {
    value: EXPENSES.BSO,
    label: getText('constants:expenseReport.expensesType.bso.ru'),
  },
  {
    value: EXPENSES.ENOVER_RECEIPT,
    label: getText('constants:expenseReport.expensesType.bso.ru_1s'),
  },
];

enum Status1S {
  CREATED,
  WAITING_SEND_1C,
  DELIVERED,
  ERROR_SEND_1C,
  WAITING_APPROVE,
  APPROVE,
  DECLINED,
  CREATED_EVENT,
}

interface IStatus1SRu {
  [key: number]: string;
}

const STATUS_1S_RU: IStatus1SRu = {
  [Status1S.CREATED]: getText('constants:expenseReport.status1sRu.created'),
  [Status1S.WAITING_SEND_1C]: getText('constants:expenseReport.status1sRu.waitingSend1C'),
  [Status1S.DELIVERED]: getText('constants:expenseReport.status1sRu.delivered'),
  [Status1S.ERROR_SEND_1C]: getText('constants:expenseReport.status1sRu.errorSend1C'),
  [Status1S.WAITING_APPROVE]: getText('constants:expenseReport.status1sRu.waitingApprove'),
  [Status1S.APPROVE]: getText('constants:expenseReport.status1sRu.approve'),
  [Status1S.DECLINED]: getText('constants:expenseReport.status1sRu.declined'),
};

const DEFAULT_STATUS_FIELDS = [
  { label: STATUS_1S_RU[0], value: Status1S.CREATED },
  { label: STATUS_1S_RU[1], value: Status1S.WAITING_SEND_1C },
  { label: STATUS_1S_RU[2], value: Status1S.DELIVERED },
  { label: STATUS_1S_RU[3], value: Status1S.ERROR_SEND_1C },
  { label: STATUS_1S_RU[4], value: Status1S.WAITING_APPROVE },
  { label: STATUS_1S_RU[5], value: Status1S.APPROVE },
  { label: STATUS_1S_RU[6], value: Status1S.DECLINED },
];

const FIELDS_FILTERS = {
  EMPLOYEES: 'employees',
  COMPANIES: 'companies',
  MINE: 'mine',
  STATUS: 'status',
};

export enum AnalyticFilterExpense {
  STATUSES = 'statuses',
  EMPLOYEE = 'employee',
}

const STATUS_PAGE = {
  CREATE: 'Create',
  WAITING_APPROVE: 'WaitingApprove',
  APPROVE: 'Approve',
  NOT_APPROVE: 'NotApprove',
  APPROVER: 'Approver',
};

enum ApproveStatus {
  ACCEPTED,
  WAITING_1C,
  SEND_TO_1C,
  ERROR_SEND_1C,
  WAITING_APPROVE,
  APPROVED,
  DECLINED,
}

const DIALOGS = {
  cancelDialog: false,
  approvedDialog: false,
  approveDialog: false,
  approveSchemeDialog: false,
  declineDialog: false,
};

const DIALOGS_FIELD = {
  CANCEL: 'cancelDialog',
  APPROVED: 'approvedDialog',
  APPROVE: 'approveDialog',
  APPROVE_SCHEME: 'approveSchemeDialog',
  DECLINE: 'declineDialog',
};

const LOADINGS = {
  loading: true,
  loadingSaveButton: false,
  loadingDeleteButton: false,
  loadingSendButton: false,
  loadingDeclinedButton: false,
  addNewDocLoader: false,
  deleteItemDoc: false,
  downloadItemDoc: false,
  loadingDownloadArchiveDocs: false,
};

const LOADINGS_FIELD = {
  LOADING: 'loading',
  SAVE_BUTTON: 'loadingSaveButton',
  DELETE_BUTTON: 'loadingDeleteButton',
  SEND_BUTTON: 'loadingSendButton',
  DECLINED_BUTTON: 'loadingDeclinedButton',
  ADD_NEW_DOC_LOADER: 'addNewDocLoader',
  DELETE_ITEM_DOC: 'deleteItemDoc',
  DOWNLOAD_ITEM_DOC: 'downloadItemDoc',
  DOWNLOAD_ARCHIVE_DOCS: 'loadingDownloadArchiveDocs',
};

const APPROVER = {
  APPROVED: 'Approved',
  DECLINE: 'Declined',
};

const STEPS_RESOLUTIONS = {
  NORMAL: 'Normal',
  ERROR: 'Error',
  SELF_APPROVE: 'SelfApprove',
};

const VALIDATION_DOC_DOWNLOAD = {
  [ERROR_CODE.BAD_REQUEST]: getText('constants:expenseReport.validationDownloadDocument.errors.badRequest'),
  [ERROR_CODE.REQUEST_ENTITY_TOO_LARGE]: getText('constants:expenseReport.validationDownloadDocument.errors.tooLarge'),
  [ERROR_CODE.INTERNAL_SERVER_ERROR]: getText('constants:expenseReport.validationDownloadDocument.errors.serverError'),
};

export {
  FIELDS,
  DAILY_EXPENSES,
  PREPAYMENTS,
  PREPAYMENTS_TYPE,
  PREPAYMENTS_METHOD,
  EXPENSES,
  EXPENSES_TYPE,
  EXPENSE_VAT_RATES,
  EXPENSES_TYPES,
  STATUS_1S_RU,
  FIELDS_FILTERS,
  STATUS_PAGE,
  DIALOGS,
  DIALOGS_FIELD,
  DEFAULT_STATUS_FIELDS,
  APPROVER,
  LOADINGS,
  LOADINGS_FIELD,
  STEPS_RESOLUTIONS,
  VALIDATION_DOC_DOWNLOAD,
  ERROR_CODE,
  Status1S,
  ApproveStatus,
  EXPENSES_NAMES,
  EXPENSES_NAME,
  PREFILED_VALUE_TYPE,
};
