// @ts-nocheck
import React from 'react';
import { Text, PROPS } from 'new-ui';

import { QA_ATTRIBUTES } from '../../../bi/constants/attributesForTests';

import parseJsonString from '../../../bi/utils/parseJsonString';
import { getAirlineType } from '../../../bi/utils/airline';
import { formatRangeDateWithSimplicity } from '../../../bi/utils/formatDate';

import parseUnix from '../../../bi/utils/parseDateTime';

import styles from './styles/index.module.css';

type CartAirTypeProps = {
  item: any,
  employee: string,
  serviceType: string,
};

const getAirline = segment => segment[getAirlineType(segment)].ID;

const CartAir = (item, employees, serviceType): CartAirTypeProps => {
  const { Data, Id } = item;
  const data = parseJsonString(Data);

  if (!data) return null;

  const { Routes } = data;

  const routesHtml = Routes.map(({ Segments }, index) => {
    const { DepartureTime, DepartureAirport, DepartureCity } = Segments[0];
    const { ArrivalTime, ArrivalAirport, ArrivalCity } = Segments[Segments.length - 1];

    const date = formatRangeDateWithSimplicity(parseUnix(DepartureTime), parseUnix(ArrivalTime), { withTime: true });
    const segmentsOfFlightNumber = [];

    Segments.forEach((segment) => {
      if (segmentsOfFlightNumber.every(({ FlightNumber }) => FlightNumber !== segment.FlightNumber)) {
        segmentsOfFlightNumber.push(segment);
      }
    });

    const flightNumbers = segmentsOfFlightNumber.map(segment => `${getAirline(segment)} ${segment.FlightNumber}`).join(', ');

    return (
      <div key={ `cart_conflict_item_${Id}_${index}` }>
        <Text qaAttr={ QA_ATTRIBUTES.cart.duplicate.date }>{date}, {flightNumbers},</Text>
        <Text qaAttr={ QA_ATTRIBUTES.cart.duplicate.info } type={ PROPS.TEXT.TYPES.NORMAL_14_130 }>{serviceType} {DepartureAirport.Name} ({DepartureCity}) &ndash; {ArrivalAirport.Name} ({ArrivalCity}),</Text>
        <Text qaAttr={ QA_ATTRIBUTES.cart.duplicate.travellers } type={ PROPS.TEXT.TYPES.NORMAL_14 } color={ PROPS.TEXT.COLORS.GRAY }>{employees}</Text>
      </div>
    );
  });

  return (
    <div key={ `cart_conflict_${Id}` } className={ styles.wrapper }>
      { routesHtml }
    </div>
  );
};

export default CartAir;
