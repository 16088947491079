import React from 'react';
import { IconButton } from 'new-ui';

import { getText } from '../../../i18n';

import { EditableText, EnumEditableTextType } from '../EditableText';
import { ToDoItem } from './components/ToDoItem';

import guid from '../../bi/utils/guid';

import styles from './styles/index.module.css';

interface IToDoList {
  list: string[],
  onAddValue: (value: string) => void,
  onDeleteItem: (idx: number) => void,
  maxLengthList?: number,
  editWrapperClassName?: string,
  listWrapperClassName?: string,
  onBlur?: (isValid: boolean) => void,
  errorMsg?: string,
  onFocus?: () => void,
  disable?: boolean,
}

const LABELS = {
  ADD: getText('analytics:add'),
};

const ToDoList = ({
  list,
  onAddValue,
  onDeleteItem,
  maxLengthList,
  editWrapperClassName = '',
  listWrapperClassName = '',
  onBlur = () => {},
  errorMsg = '',
  onFocus = () => {},
  disable = false,
}: IToDoList) => {
  const renderToDoList = () => list && list.map((item, idx) => (
    <ToDoItem
      onDeleteItem={ onDeleteItem }
      idx={ idx }
      key={ `${idx}_${guid()}` }
      content={ item }
    />
  ));

  const renderAddButton = () => {
    if (maxLengthList && list.length === maxLengthList) return null;

    return (
      <div className={ styles.options_add }>
        <EditableText
          toggleModeOnChange={ false }
          renderPreview={ () => (
            <IconButton
              iconType={ 'plusRound' }
            >
              { LABELS.ADD }
            </IconButton>
          ) }
          placeholder={ '' }
          iconType={ 'plusRound' }
          onChange={ onAddValue }
          editType={ EnumEditableTextType.PHONE }
          editWrapperClassName={ editWrapperClassName }
          onBlur={ onBlur }
          errorMsg={ errorMsg }
          onFocus={ onFocus }
          disable={ disable }
        />
      </div>
    );
  };

  return (
    <div className={ listWrapperClassName }>
      { renderToDoList() }
      { renderAddButton() }
    </div>
  );
};

export { ToDoList };
