import { getText } from '../../../i18n';

import { PATH } from '../constants/airlineAdditionalServices';
import { ADDITIONAL_SERVICES_CONSTS } from '../constants/additionalServices';
import { PATTERN } from '../constants/dateFormats';
import ROUTES from '../constants/routes';
import { SegmentInterface } from '../types/airlineAdditionalServices';
import SERVICETYPE from '../constants/serviceType';
import { OrderItem } from '../types/order';
import { AirRoute } from '../types/airline';

import { getPassportChangeAirTrip } from './changeAirTrip';
import { getEmployeeFullNameWithFeatureFlags } from './employees';
import { formatDate, formatRangeDateWithSimplicity } from './formatDate';

const LABELS = {
  TRIP: getText('components:additionalServices.breadCrumbs.trip'),
  ADDITIONAL_SERVICE: getText('components:additionalServices.breadCrumbs.additionalService'),
  OTHER_CHANGES: getText('components:additionalServices.breadCrumbs.otherChanges'),
  ROUTE: getText('components:additionalServices.breadCrumbs.route'),
  WAITING_ROOM: getText('components:additionalServices.breadCrumbs.waitingRoom'),
  TERMINAL: getText('components:additionalServices.breadCrumbs.terminal'),
  DIRECTION: getText('components:additionalServices.breadCrumbs.direction'),
  RESULT: getText('components:additionalServices.breadCrumbs.result'),
  PAYMENT: getText('components:additionalServices.breadCrumbs.payment'),
  ORDER_FLIGHT_CERTIFICATE: getText('components:additionalServices.radioButtons.orderFlightCertificate'),
  CANCEL_TICKET_ADDITIONAL: {
    CANCEL: getText('components:tripDetailsDialog.cancelTicketAdditional.cancel'),
  },
};

const contentCrumbsChangeAdditional = (tripId: string, ticketId: string, changeTypeCrumbs: string) => {
  const crumbsResult = (startTripId: string, startTicketId: string, nameEnd: string) => [
    { name: LABELS.TRIP, link: ROUTES.ADDITIONAL_SERVICES.TRIP(startTripId) },
    { name: LABELS.ADDITIONAL_SERVICE, link: ROUTES.ADDITIONAL_SERVICES.MAIN(startTripId, startTicketId) },
    { name: nameEnd },
  ];

  switch (changeTypeCrumbs) {
    case PATH.WAITING_ROOM:
      return crumbsResult(tripId, ticketId, LABELS.WAITING_ROOM);

    case PATH.ROUTE_VIP:
    case PATH.ROUTE_ESCORT:
      return crumbsResult(tripId, ticketId, LABELS.ROUTE);

    case PATH.TERMINAL:
      return crumbsResult(tripId, ticketId, LABELS.TERMINAL);

    case PATH.DIRECTION:
      return crumbsResult(tripId, ticketId, LABELS.DIRECTION);

    case PATH.RESULT_VIP:
    case PATH.RESULT_ESCORT:
      return crumbsResult(tripId, ticketId, LABELS.RESULT);

    case PATH.INFO_ESCORT:
    case PATH.INFO_VIP:
      return crumbsResult(tripId, ticketId, LABELS.RESULT);

    case PATH.PAYMENT_ESCORT:
    case PATH.PAYMENT_VIP:
      return crumbsResult(tripId, ticketId, LABELS.PAYMENT);

    case PATH.FLIGHT_CERTIFICATE:
      return crumbsResult(tripId, ticketId, LABELS.ORDER_FLIGHT_CERTIFICATE);

    case PATH.OTHER_CHANGES:
      return crumbsResult(tripId, ticketId, LABELS.OTHER_CHANGES);

    default:
      return ([
        { name: LABELS.TRIP, link: ROUTES.ADDITIONAL_SERVICES.TRIP(tripId) },
        { name: LABELS.ADDITIONAL_SERVICE },
      ]);
  }
};

const prepareDataPerson = (ticketData: any, ticket: any) => {
  const { Type, Number } = ticketData.Travellers.ADT[0].Document;

  const fullName = getEmployeeFullNameWithFeatureFlags(ticket?.ActualVersion.Employees[0]);

  const validNumber = `${Number.substring(0, 2)} ${Number.substring(2, 4)} ${Number.substring(4)}`;

  return `${fullName}, ${getPassportChangeAirTrip(Type)} ${validNumber}`;
};

const prepareDataFlight = (route: any) => {
  const firstSegment = route.Segments.at(0);
  const lastSegment = route.Segments.at(-1);

  const departureData = formatDate(firstSegment?.DepartureDate, PATTERN.DAY_OF_MONTH_WITH_YEAR_AND_TIME);
  const arrivalData = formatDate(firstSegment?.ArrivalDate, PATTERN.DAY_OF_MONTH_WITH_YEAR_AND_TIME);

  return { firstSegment, lastSegment, departureData, arrivalData };
};

const prepareAirlineNames = (segments: SegmentInterface[], firstSegment: SegmentInterface) => {
  const segmentsLength = segments.length;

  const filterAirlines = segments.filter(segment =>
    segment.MarketingAirline.Code === firstSegment.MarketingAirline.Code,
  );
  const flightNumbers = segments
    .map(({ MarketingAirline: { Code }, FlightNumber }) => `${Code} ${FlightNumber}`)
    .join(', ');

  if (segmentsLength === 1 || (segmentsLength === 2 && filterAirlines.length === 2)) {
    return `${firstSegment.MarketingAirline.Name} (${flightNumbers})`;
  }

  return flightNumbers;
};

const combinedTripAndAdditionalServices = (
  { ActualVersion }: OrderItem,
  orderItems: OrderItem[],
) => {
  if (ActualVersion.ServiceType !== SERVICETYPE.AIR) {
    return null;
  }

  const itemJsonData = JSON.parse(ActualVersion.JsonData);
  const tickets = itemJsonData.Tickets;

  const nameEmployee = ActualVersion.Employees.map(
    ({ Surname, Name, Patronymic }) => {
      const fullName = `${Surname} ${Name} ${Patronymic}`;

      return fullName.trim();
    },
  );

  const nameTrip = itemJsonData.Routes.map(
    (route: AirRoute) =>
      `${route.Segments[0].DepartureCity} – ${
        route.Segments[route.Segments.length - 1].ArrivalCity
      }`,
  ).join(', ');

  const date = formatRangeDateWithSimplicity(
    ActualVersion.CheckinDate,
    ActualVersion.CheckoutDate,
    { withoutSpaceBetweenDates: true },
  );

  const matchedOrderItem = orderItems.find(orderItem => {
    const { ServiceType } = orderItem.ActualVersion;

    if (ServiceType !== SERVICETYPE.AIR_ADDITIONAL_SERVICE) {
      return null;
    }

    const orderItemJsonData = JSON.parse(orderItem.ActualVersion.JsonData);

    return tickets.includes(orderItemJsonData.TicketNumber);
  });

  if (matchedOrderItem) {
    const { Type } = JSON.parse(matchedOrderItem.ActualVersion.JsonData);

    const text = `<b>${LABELS.CANCEL_TICKET_ADDITIONAL.CANCEL} «${ADDITIONAL_SERVICES_CONSTS[Type].name}», ${date}</b>`;

    const tripInfo = `«${ADDITIONAL_SERVICES_CONSTS[Type].name}», ${nameTrip}, ${nameEmployee}, ${date}.`;

    return {
      text,
      tripInfo,
      newService: true,
    };
  }

  return null;
};

export {
  contentCrumbsChangeAdditional,
  prepareDataPerson,
  prepareDataFlight,
  prepareAirlineNames,
  combinedTripAndAdditionalServices,
};
