import React from 'react';
import { Select } from 'new-ui';

import { SortArrowOption } from './components/SortArrowOption';

import { AnalyticSortTrain } from '../../bi/types/train';

interface ISortSelectProps {
  items: ISortSelctItem[],
  value: string,
  onChange: (value: string) => void,
  qaAttr: string,
}

interface ISortSelctItem {
  label: string,
  type: number | null,
  value: string,
  analyticValue: AnalyticSortTrain,
}

const SortSelect = ({ items, value, onChange, qaAttr = '' }: ISortSelectProps) => (
  <Select
    theme='default-large'
    items={ items }
    value={ value }
    renderLabel={ item => <SortArrowOption item={ item } /> }
    renderItem={ item => <SortArrowOption item={ item } /> }
    onChange={ onChange }
    qaAttr={ qaAttr }
  />
);

export { SortSelect };
