// @ts-nocheck
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { PropTypes as MobxPropTypes } from 'mobx-react';
import { Text, PROPS } from 'new-ui';
import { getText } from '../../../../../i18n';

import { getEmployeeFullNameWithFeatureFlags } from '../../../../bi/utils/employees';

import styles from './styles/index.module.css';

const LABELS = {
  COST_CENTERS: getText('components:tripItem.tripItemEmployees.costCenters'),
};

const {
  TEXT: {
    COLORS: {
      GRAY,
    },
    TYPES: {
      SEMIBOLD_16,
      NORMAL_14,
    },
  },
} = PROPS;

class TripItemEmployees extends Component {
  static propTypes = {
    currentVersion: PropTypes.object.isRequired,
    companies: MobxPropTypes.observableArray,
    projects: PropTypes.array.isRequired,
    displayEmployee: PropTypes.array,
    cartEmployeeBirthday: PropTypes.bool,
  };

  renderName = string => <Text color={ GRAY }>{ string }</Text>;

  renderCompany = () => {
    const { currentVersion: { CompanyId, DepartmentId }, companies } = this.props;

    if (!CompanyId || !companies || (companies && companies.length === 1 && !DepartmentId)) {
      return null;
    }

    const currentCompany = companies.find(({ CompanyId: Id }) => Id === CompanyId);

    if (!currentCompany) {
      return null;
    }

    const { ShortCompanyName, CompanyName, Departments = [] } = currentCompany;

    if (DepartmentId) {
      const currentDepartment = Departments.find(({ Id }) => Id === DepartmentId);

      return currentDepartment ? this.renderName(`${ShortCompanyName || CompanyName} (${currentDepartment.Name})`) : null;
    }

    return this.renderName(ShortCompanyName || CompanyName);
  };

  renderProject = () => {
    const { currentVersion: { ProjectId }, projects } = this.props;

    if (!ProjectId) {
      return null;
    }

    const currentProject = projects.find(({ Id }) => Id === ProjectId);

    if (!currentProject) {
      return null;
    }

    return (
      <Text color={ GRAY } type={ NORMAL_14 }>
        { LABELS.COST_CENTERS } — {currentProject.Name}
      </Text>
    );
  };

  renderEmployeeData = (employee) => employee.map((info, index) => {
    if (index === 0) {
      return (
        <Text type='SEMIBOLD_16' key={ index }>
          { info }
        </Text>
      );
    }

    return (
      <Text type='NORMAL_14' color='gray' key={ index }>
        { info }
      </Text>
    );
  });

  renderEmployeeInRows = (employee) => (
    <div className={ styles.employeeContainer }>
      {this.renderEmployeeData(employee)}
    </div>
  );

  employeeDecorator = (employee, displayFF, showBirthday) => {
    const employeesData = getEmployeeFullNameWithFeatureFlags(employee, displayFF, showBirthday);

    if (Array.isArray(employeesData)) return this.renderEmployeeInRows(employeesData);

    return employeesData;
  };

  render() {
    const {
      currentVersion: { Employees },
      displayEmployee = [],
      cartEmployeeBirthday,
    } = this.props;
    const styleView = displayEmployee.length ? styles.itemFull : styles.item;

    return (
      <div className={ styles.wrapper }>
        { Employees.map((employee, index) => (
          <div key={ employee.Id } className={ styleView }>
            <div className={ styles.name }>
              <Text color={ GRAY } className={ styles.number }>{ index + 1 }.</Text>
              <Text type={ SEMIBOLD_16 } className={ styles.text }>
                { this.employeeDecorator(employee, displayEmployee, cartEmployeeBirthday) }
              </Text>
            </div>
            <div className={ styles.content }>
              { this.renderCompany() }
              { this.renderProject() }
            </div>
          </div>
        )) }
      </div>
    );
  }
}

export { TripItemEmployees };
