// @ts-nocheck
import React from 'react';
import { Text, PROPS } from 'new-ui';

import { QA_ATTRIBUTES } from '../../../bi/constants/attributesForTests';

import parseJsonString from '../../../bi/utils/parseJsonString';
import { formatHotelRangeDate } from '../../../bi/utils/hotel';

import styles from './styles/index.module.css';

interface CartHotelTypeProps {
  item: any,
  employees: string,
  serviceType: string,
}

const CartHotel = (item, employees, serviceType): CartHotelTypeProps => {
  const { Data, Id } = item;
  const data = parseJsonString(Data);

  if (!data) return null;

  const { hotel: { Name, City }, room: { Name: roomName }, Rate: { CustomCheckInDate, CustomCheckOutDate, CheckinDate, CheckoutDate } } = data;
  const date = formatHotelRangeDate(CustomCheckInDate, CustomCheckOutDate, CheckinDate, CheckoutDate);
  const room = roomName ? ` ${roomName}` : null;

  return (
    <div key={ `cart_conflict_${Id}` } className={ styles.wrapper }>
      <Text qaAttr={ QA_ATTRIBUTES.cart.duplicate.date }>{date},</Text>
      <Text qaAttr={ QA_ATTRIBUTES.cart.duplicate.info } type={ PROPS.TEXT.TYPES.NORMAL_14_130 }>{serviceType} {Name} ({City}),{room},</Text>
      <Text qaAttr={ QA_ATTRIBUTES.cart.duplicate.travellers } type={ PROPS.TEXT.TYPES.NORMAL_14 } color={ PROPS.TEXT.COLORS.GRAY }>{employees}</Text>
    </div>
  );
};

export default CartHotel;
