// @ts-nocheck
import React from 'react';
import { Dialog, Button } from 'new-ui';
import PropTypes from 'prop-types';
import { getText } from '../../../i18n';

import styles from './styles.module.css';

const LABELS = {
  CONFIRM: getText('trip:approvalRequestDialog.confirmButton'),
  TEXT: RequestId => getText('trip:approvalRequestDialog.text', { RequestId }),
};

const ApproveActionDialog = ({ show, RequestId, onClose, onSave, loading }) => {
  const buttonsHtml = (
    <div className={ styles.button_wrapper }>
      <Button onClick={ onSave } loading={ loading }>
        {LABELS.CONFIRM}
      </Button>
    </div>
  );

  const contentHtml = (
    <div className={ styles.wrapper }>
      <div className={ styles.text }>
        {LABELS.TEXT(RequestId)}
      </div>
      {buttonsHtml}
    </div>
  );

  return (
    <Dialog
      show={ show }
      onChange={ onClose }
      showClosing
      className={ styles.dialog }
    >
      {contentHtml}
    </Dialog>
  );
};

ApproveActionDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  RequestId: PropTypes.number.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ApproveActionDialog;
