// @ts-nocheck
import PropTypes from 'prop-types';
import React from 'react';
// import CONFIG from '../../../config';

class MixPanelMetric extends React.Component {
  static propTypes = {
    workspaceService: PropTypes.object.isRequired,
  };

  constructor(props) {
    super();

    const data = props.workspaceService.get();

    this.initializeMixPanelMetric(data);
  }

  initializeMixPanelMetric = (data) => {
    if (window.mixpanel && !data.IsDemo) {
      window.mixpanel.people.set({ Company: data.ShortCompanyName, User: data.Email });
      window.mixpanel.identify(data.CompanyId);
      window.mixpanel.isDemo = false;
    } else {
      window.mixpanel.isDemo = true;
    }
  };

  render() {
    return null;
  }
}

export default MixPanelMetric;
