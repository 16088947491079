import React from 'react';
import { Text } from 'new-ui';

import { Moment } from 'moment';
import { getText } from '../../../i18n';

import Timer from '../Timer';

import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';

import styles from './index.module.css';

const LABELS = {
  TIME_TO_RESERVATION_CANCEL: getText('note:item.timeToReservationCancel'),
};

interface LayoutTimerProps {
  bookDeadline: string,
  time: Moment,
  onTimeIsUp(): void
}

const LayoutTimer = ({ bookDeadline, time, onTimeIsUp }: LayoutTimerProps) => (
  <div className={ styles.green_header }>
    <div className={ styles.timer }>
      <Text
        type={ 'NORMAL_14' }
        color='white'
        qaAttr={ QA_ATTRIBUTES.note.airline.timer }
      >
        { LABELS.TIME_TO_RESERVATION_CANCEL }
      </Text>
      <div className={ styles.time }>
        <Timer
          deadLine={ bookDeadline }
          currentTime={ time || null }
          onTimeIsUp={ onTimeIsUp }
          color='white'
        />
      </div>
    </div>
    <div className={ styles.green_line }/>
  </div>
);

export default LayoutTimer;
