const compareLowerStrings = (a = '', b = '') => a.toLowerCase() === b.toLowerCase();

const firstCapitalLetter = (string: string | any[]) => string[0].toUpperCase() + string.slice(1);

const getBooleanFromStringBoolean = (stringBoolean: string) => stringBoolean === 'true';

export {
  compareLowerStrings,
  firstCapitalLetter,
  getBooleanFromStringBoolean,
};
