import { Moment } from 'moment';
import { getText, getTextArray } from '../../../i18n';

import { getMoment } from '../utils/formatDate';
import { getSmartIndexChartTitle } from '../utils/chartsAnalytics';

import SERVICETYPE from './serviceType';

const COMMON_CHARTS_ANALYTICS_DASH = '—';

const DEFAULT_CHARTS_ANALYTICS = {
  startDate: getMoment(),
  endDate: getMoment(),
  minDate: getMoment(),
  maxDate: getMoment(),
  selectedCompanies: [],
  companies: [],
};

const KEYS = {
  GENERAL: 'general',
  SMART_COMMON_FIRST: 'smart-common',
  SMART_AIRLINE_FIRST: 'smart-airline',
  SMART_TRAIN_FIRST: 'smart-train',
  SMART_HOTEL_FIRST: 'smart-hotel',
  SMART_TAXI_FIRST: 'smart-taxi',
  AIRLINE_SUMMARY: 'airline-summary',
  AIRLINE_DIRECTIONS: 'airline-directions',
  AIRLINE_COMPANIES: 'airline-company',
  AIRLINE_EARLINESS: 'airline-earliness',
  TRAIN_SUMMARY: 'train-summary',
  TRAIN_DIRECTIONS: 'train-directions',
  HOTEL_SUMMARY: 'hotel-summary',
  HOTEL_DIRECTIONS: 'hotel-directions',
  HOTEL_POPULAR: 'hotel-popular',
  TAXI_SUMMARY: 'taxi-summary',
  TAXI_DIRECTIONS: 'taxi-directions',
  AEROEXPRESS_SUMMARY: 'aeroexpress-summary',
  AEROEXPRESS_DIRECTIONS: 'aeroexpress-directions',
  TRANSFER_SUMMARY: 'transfer-summary',
  TRANSFER_DIRECTIONS: 'transfer-directions',
  MICE_SUMMARY: 'mice-summary',
  MICE_DIRECTIONS: 'mice-directions',
  STRUCTURE_SERVICE_TYPE: 'structure-serviceType',
  STRUCTURE_DEPARTMENTS: 'structure-departments',
  STRUCTURE_PROJECTS: 'structure-projects',
  STRUCTURE_VAT: 'structure-vat',
  STRUCTURE_CUSTOM_ANALYTICS: 'structure-customAnalytics',
};

const MENU_KEYS = {
  GENERAL: 'general',
  SMART: {
    COMMON: {
      FIRST: 'smart-common',
      SECOND: {
        AIRLINE: 'smart-common-airline-second',
        TRAIN: 'smart-common-train-second',
        HOTEL: 'smart-common-hotel-second',
        TAXI: 'smart-common-taxi-second',
        AEROEXPRESS: 'smart-common-aeroexpress-second',
        TRANSFER: 'smart-common-transfer-second',
        MICE: 'smart-common-mice-second',
      },
      THIRD: {
        AIRLINE: 'smart-common-airline-third',
        TRAIN: 'smart-common-train-third',
        HOTEL: 'smart-common-hotel-third',
        TAXI: 'smart-common-taxi-third',
        AEROEXPRESS: 'smart-common-aeroexpress-third',
        TRANSFER: 'smart-common-transfer-third',
        MICE: 'smart-common-mice-third',
      },
      FOURTH: {
        AIRLINE: 'smart-common-airline-fourth',
        TRAIN: 'smart-common-train-fourth',
        HOTEL: 'smart-common-hotel-fourth',
        TAXI: 'smart-common-taxi-fourth',
        AEROEXPRESS: 'smart-common-aeroexpress-fourth',
        TRANSFER: 'smart-common-transfer-fourth',
        MICE: 'smart-common-mice-fourth',
      },
    },
    AIRLINE: {
      FIRST: 'smart-airline',
      SECOND: 'smart-airline-second',
      THIRD: 'smart-airline-third',
    },
    TRAIN: {
      FIRST: 'smart-train',
      SECOND: 'smart-train-second',
      THIRD: 'smart-train-third',
    },
    HOTEL: {
      FIRST: 'smart-hotel',
      SECOND: 'smart-hotel-second',
      THIRD: 'smart-hotel-third',
    },
    TAXI: {
      FIRST: 'smart-taxi',
      SECOND: 'smart-taxi-second',
      THIRD: 'smart-taxi-third',
    },
    AEROEXPRESS: {
      FIRST: 'smart-aeroexpress',
      SECOND: 'smart-aeroexpress-second',
      THIRD: 'smart-aeroexpress-third',
    },
    TRANSFER: {
      FIRST: 'smart-transfer',
      SECOND: 'smart-transfer-second',
      THIRD: 'smart-transfer-third',
    },
    MICE: {
      FIRST: 'smart-mice',
      SECOND: 'smart-mice-second',
      THIRD: 'smart-mice-third',
    },
  },
  AIRLINE: {
    TITLE: 'airline',
    SUMMARY: 'airline-summary',
    SUMMARY_NESTED: 'airline-summary-nested',
    DIRECTIONS: 'airline-directions',
    DIRECTIONS_NESTED: 'airline-directions-nested',
    COMPANIES: 'airline-company',
    COMPANIES_NESTED: 'airline-company-nested',
    EARLINESS: 'airline-earliness',
    EARLINESS_NESTED: 'airline-earliness-nested',
  },
  TRAIN: {
    TITLE: 'train',
    SUMMARY: 'train-summary',
    SUMMARY_NESTED: 'train-summary-nested',
    DIRECTIONS: 'train-directions',
    DIRECTIONS_NESTED: 'train-directions-nested',
  },
  HOTEL: {
    TITLE: 'hotel',
    SUMMARY: 'hotel-summary',
    SUMMARY_NESTED: 'hotel-summary-nested',
    DIRECTIONS: 'hotel-directions',
    DIRECTIONS_NESTED: 'hotel-directions-nested',
    POPULAR: 'hotel-popular',
    POPULAR_NESTED: 'hotel-popular-nested',
  },
  TAXI: {
    TITLE: 'taxi',
    SUMMARY: 'taxi-summary',
    SUMMARY_NESTED: 'taxi-summary-nested',
    SUMMARY_NESTED_DOUBLE: 'taxi-summary-nested-double',
    DIRECTIONS: 'taxi-directions',
    DIRECTIONS_NESTED: 'taxi-directions-nested',
    DIRECTIONS_NESTED_DOUBLE: 'taxi-directions-nested-double',
    SMART_VOUCHER: 'smart-taxi-voucher',
    SMART_VOUCHER_COMMON: 'smart-common-taxi-voucher',
  },
  AEROEXPRESS: {
    TITLE: 'aeroexpress',
    SUMMARY: 'aeroexpress-summary',
    SUMMARY_NESTED: 'aeroexpress-summary-nested',
    DIRECTIONS: 'aeroexpress-directions',
    DIRECTIONS_NESTED: 'aeroexpress-directions-nested',
  },
  TRANSFER: {
    TITLE: 'transfer',
    SUMMARY: 'transfer-summary',
    SUMMARY_NESTED: 'transfer-summary-nested',
    DIRECTIONS: 'transfer-directions',
    DIRECTIONS_NESTED: 'transfer-directions-nested',
  },
  MICE: {
    TITLE: 'mice',
    SUMMARY: 'mice-summary',
    SUMMARY_NESTED: 'mice-summary-nested',
    DIRECTIONS: 'mice-directions',
    DIRECTIONS_NESTED: 'mice-directions-nested',
  },
  STRUCTURE: {
    TITLE: 'structure',
    SERVICE_TYPE: 'structure-serviceType',
    SERVICE_TYPE_NESTED: 'structure-serviceType-nested',
    DEPARTMENTS: 'structure-departments',
    DEPARTMENTS_NESTED: 'structure-departments-nested',
    DEPARTMENTS_NESTED_SUB: 'structure-departments-nested-sub',
    PROJECTS: 'structure-projects',
    PROJECTS_NESTED: 'structure-projects-nested',
    PROJECTS_NESTED_SUB: 'structure-projects-nested-sub',
    VAT: 'structure-vat',
    CUSTOM_ANALYTICS: 'structure-customAnalytics',
    CUSTOM_ANALYTICS_NESTED: 'structure-customAnalytics-nested',
    CUSTOM_ANALYTICS_NESTED_SUB: 'structure-customAnalytics-nested-sub',
  },
};

const TITLES = {
  GENERAL: getText('chartsAnalytics:menuItems.general.title'),
  SMART: getText('chartsAnalytics:menuItems.smart.title'),
  AIRLINE: {
    TITLE: getText('chartsAnalytics:menuItems.airline.title'),
    SUMMARY: getText('chartsAnalytics:menuItems.airline.summary'),
    SMART: getText('chartsAnalytics:menuItems.airline.smart'),
    DIRECTIONS: getText('chartsAnalytics:menuItems.airline.directions'),
    COMPANIES: getText('chartsAnalytics:menuItems.airline.companies'),
    EARLINESS: getText('chartsAnalytics:menuItems.airline.earliness'),
  },
  TRAIN: {
    TITLE: getText('chartsAnalytics:menuItems.train.title'),
    SUMMARY: getText('chartsAnalytics:menuItems.train.summary'),
    SMART: getText('chartsAnalytics:menuItems.train.smart'),
    DIRECTIONS: getText('chartsAnalytics:menuItems.train.directions'),
  },
  HOTEL: {
    TITLE: getText('chartsAnalytics:menuItems.hotel.title'),
    SUMMARY: getText('chartsAnalytics:menuItems.hotel.summary'),
    SMART: getText('chartsAnalytics:menuItems.hotel.smart'),
    DIRECTIONS: getText('chartsAnalytics:menuItems.hotel.directions'),
    POPULAR: getText('chartsAnalytics:menuItems.hotel.popular'),
  },
  TAXI: {
    TITLE: getText('chartsAnalytics:menuItems.taxi.title'),
    SUMMARY: getText('chartsAnalytics:menuItems.taxi.summary'),
    SMART: getText('chartsAnalytics:menuItems.taxi.smart'),
    DIRECTIONS: getText('chartsAnalytics:menuItems.taxi.directions'),
  },
  AEROEXPRESS: {
    TITLE: getText('chartsAnalytics:menuItems.aeroexpress.title'),
    SUMMARY: getText('chartsAnalytics:menuItems.aeroexpress.summary'),
    SMART: getText('chartsAnalytics:menuItems.aeroexpress.smart'),
    DIRECTIONS: getText('chartsAnalytics:menuItems.aeroexpress.directions'),
  },
  TRANSFER: {
    TITLE: getText('chartsAnalytics:menuItems.transfer.title'),
    SUMMARY: getText('chartsAnalytics:menuItems.transfer.summary'),
    SMART: getText('chartsAnalytics:menuItems.transfer.smart'),
    DIRECTIONS: getText('chartsAnalytics:menuItems.transfer.directions'),
  },
  MICE: {
    TITLE: getText('chartsAnalytics:menuItems.mice.title'),
    SUMMARY: getText('chartsAnalytics:menuItems.mice.summary'),
    SMART: getText('chartsAnalytics:menuItems.mice.smart'),
  },
  STRUCTURE: {
    TITLE: getText('chartsAnalytics:menuItems.structure.title'),
    SERVICE_TYPE: getText('chartsAnalytics:menuItems.structure.serviceType'),
    DEPARTMENTS: getText('chartsAnalytics:menuItems.structure.departments'),
    PROJECTS: getText('chartsAnalytics:menuItems.structure.projects'),
    VAT: getText('chartsAnalytics:menuItems.structure.vat'),
    CUSTOM_ANALYTICS: (text: string | undefined) => `${getText('chartsAnalytics:menuItems.structure.parameter')} "${text}"`,
  },
};

const MENU = (itemsStructureOfSpending = []) => [
  {
    title: TITLES.SMART,
    id: MENU_KEYS.SMART.COMMON.FIRST,
    items: [],
  },
  {
    title: TITLES.GENERAL,
    id: MENU_KEYS.GENERAL,
    items: [],
  },
  {
    title: TITLES.AIRLINE.TITLE,
    id: MENU_KEYS.AIRLINE.TITLE,
    items: [
      {
        title: TITLES.AIRLINE.SUMMARY,
        id: MENU_KEYS.AIRLINE.SUMMARY,
      },
      {
        title: TITLES.AIRLINE.SMART,
        id: MENU_KEYS.SMART.AIRLINE.FIRST,
      },
      {
        title: TITLES.AIRLINE.DIRECTIONS,
        id: MENU_KEYS.AIRLINE.DIRECTIONS,
      },
      {
        title: TITLES.AIRLINE.COMPANIES,
        id: MENU_KEYS.AIRLINE.COMPANIES,
      },
      {
        title: TITLES.AIRLINE.EARLINESS,
        id: MENU_KEYS.AIRLINE.EARLINESS,
      },
    ],
  },
  {
    title: TITLES.TRAIN.TITLE,
    id: MENU_KEYS.TRAIN.TITLE,
    items: [
      {
        title: TITLES.TRAIN.SUMMARY,
        id: MENU_KEYS.TRAIN.SUMMARY,
      },
      {
        title: TITLES.TRAIN.SMART,
        id: MENU_KEYS.SMART.TRAIN.FIRST,
      },
      {
        title: TITLES.TRAIN.DIRECTIONS,
        id: MENU_KEYS.TRAIN.DIRECTIONS,
      },
    ],
  },
  {
    title: TITLES.HOTEL.TITLE,
    id: MENU_KEYS.HOTEL.TITLE,
    items: [
      {
        title: TITLES.HOTEL.SUMMARY,
        id: MENU_KEYS.HOTEL.SUMMARY,
      },
      {
        title: TITLES.HOTEL.SMART,
        id: MENU_KEYS.SMART.HOTEL.FIRST,
      },
      {
        title: TITLES.HOTEL.DIRECTIONS,
        id: MENU_KEYS.HOTEL.DIRECTIONS,
      },
      {
        title: TITLES.HOTEL.POPULAR,
        id: MENU_KEYS.HOTEL.POPULAR,
      },
    ],
  },
  {
    title: TITLES.TAXI.TITLE,
    id: MENU_KEYS.TAXI.TITLE,
    items: [
      {
        title: TITLES.TAXI.SUMMARY,
        id: MENU_KEYS.TAXI.SUMMARY,
      },
      {
        title: TITLES.TAXI.SMART,
        id: MENU_KEYS.SMART.TAXI.FIRST,
      },
      {
        title: TITLES.TAXI.DIRECTIONS,
        id: MENU_KEYS.TAXI.DIRECTIONS,
      },
    ],
  },
  {
    title: TITLES.AEROEXPRESS.TITLE,
    id: MENU_KEYS.AEROEXPRESS.TITLE,
    items: [
      {
        title: TITLES.AEROEXPRESS.SUMMARY,
        id: MENU_KEYS.AEROEXPRESS.SUMMARY,
      },
      {
        title: TITLES.AEROEXPRESS.SMART,
        id: MENU_KEYS.SMART.AEROEXPRESS.FIRST,
      },
      {
        title: TITLES.AEROEXPRESS.DIRECTIONS,
        id: MENU_KEYS.AEROEXPRESS.DIRECTIONS,
      },
    ],
  },
  {
    title: TITLES.TRANSFER.TITLE,
    id: MENU_KEYS.TRANSFER.TITLE,
    items: [
      {
        title: TITLES.TRANSFER.SUMMARY,
        id: MENU_KEYS.TRANSFER.SUMMARY,
      },
      {
        title: TITLES.TRANSFER.SMART,
        id: MENU_KEYS.SMART.TRANSFER.FIRST,
      },
      {
        title: TITLES.TRANSFER.DIRECTIONS,
        id: MENU_KEYS.TRANSFER.DIRECTIONS,
      },
    ],
  },
  {
    title: TITLES.MICE.TITLE,
    id: MENU_KEYS.MICE.TITLE,
    items: [
      {
        title: TITLES.MICE.SUMMARY,
        id: MENU_KEYS.MICE.SUMMARY,
      },
      {
        title: TITLES.MICE.SMART,
        id: MENU_KEYS.SMART.MICE.FIRST,
      },
    ],
  },
  {
    title: TITLES.STRUCTURE.TITLE,
    id: MENU_KEYS.STRUCTURE.TITLE,
    items: [
      {
        title: TITLES.STRUCTURE.SERVICE_TYPE,
        id: MENU_KEYS.STRUCTURE.SERVICE_TYPE,
      },
      {
        title: TITLES.STRUCTURE.DEPARTMENTS,
        id: MENU_KEYS.STRUCTURE.DEPARTMENTS,
      },
      {
        title: TITLES.STRUCTURE.PROJECTS,
        id: MENU_KEYS.STRUCTURE.PROJECTS,
      },
      ...itemsStructureOfSpending,
    ],
  },
];

const DEFAULT_FILTERS = {
  employees: {
    list: [],
    selected: [],
    firstEmployees: [],
  },
  employeesAutocomplete: {
    list: [],
  },
  departments: {
    list: [],
    selected: [],
    firstDepartments: [],
  },
  departmentsAutocomplete: {
    list: [],
  },
  projects: {
    list: [],
    selected: [],
    firstProjects: [],
  },
  projectsAutocomplete: {
    list: [],
  },
  tags: {
    list: [],
    selected: [],
    firstTags: [],
  },
  tagsAutocomplete: {
    list: [],
  },
  analytics: {
    list: [],
    selected: [],
  },
};

const DEFAULT_CHART = {
  values: [],
  labels: [],
  stacked: false,
  legends: undefined,
  colors: ['#FD4E54', '#3DA3E8', '#FECC60', '#FF69B4', '#AAAAAA', '#8B4513', '#4682B4', '#228B22'],
};

const DEFAULT_AIRLINE_COLORS = [
  { label: 'S7', value: '#C4D42B' },
  { label: 'Аэрофлот', value: '#0A478B' },
  { label: 'Победа', value: '#1793DA' },
  { label: 'Уральские авиалинии', value: '#B81313' },
  { label: 'Ютэир', value: '#024B90' },
];

const DEFAULT_SUB_TABLE_INFO = {
  strings: [],
  columnsName: [],
};

const DEFAULT_PAGINATE = {
  currentPage: 1,
  itemsPerPage: 20,
  total: 0,
};

const DEFAULT_TABLE_INFO = {
  labelsHeader: [],
  content: [],
};

const TYPE = {
  SMART: 'smart',
  AIR: 'air',
  TRAIN: 'train',
  HOTEL: 'hotel',
  TAXI: 'taxi',
  TRANSFER: 'transfer',
  MICE: 'mice',
  AEROEXPRESS: 'aeroexpress',
  SPENDINGS: 'AllServices',
  DEPARTMENTS: 'Departmets',
  PROJECTS: 'Projects',
  CUSTOM_ANALYTICS: 'CustomAnalytics',
};

const CHARTS_ANALYTICS = (id: string | undefined) => `${MENU_KEYS.STRUCTURE.CUSTOM_ANALYTICS}/${id}`;

// @ts-ignore
const preparedTitle = (titl: string, startDate: Moment, endDate: Moment) => `${titl} ${getSmartIndexChartTitle(startDate, endDate)}`;

const TITLES_SMART_SUMMARY = {
  [SERVICETYPE.AIR]: getText('chartsAnalytics:smart.summary.titles.airline'),
  [SERVICETYPE.TRAIN]: getText('chartsAnalytics:smart.summary.titles.train'),
  [SERVICETYPE.HOTEL]: getText('chartsAnalytics:smart.summary.titles.hotel'),
  [SERVICETYPE.TAXI]: getText('chartsAnalytics:smart.summary.titles.taxi'),
  [SERVICETYPE.AEROEXPRESS]: getText('chartsAnalytics:smart.summary.titles.aeroexpress'),
  [SERVICETYPE.TRANSFER]: getText('chartsAnalytics:smart.summary.titles.aeroexpress'),
  [SERVICETYPE.MICE]: getText('chartsAnalytics:smart.summary.titles.mice'),
};

const HEADER_PROPS = (active: string | number, id: string, title: string | undefined, startDate: Moment, endDate: Moment) => ({
  [MENU_KEYS.GENERAL]: {
    show: false,
  },
  [MENU_KEYS.SMART.COMMON.FIRST]: {
    show: true,
    showOnlyTitle: true,
    title: preparedTitle(getText('chartsAnalytics:smart.title'), startDate, endDate),
    prev: MENU_KEYS.SMART.COMMON.FIRST,
    type: TYPE.SMART,
  },
  [MENU_KEYS.SMART.AIRLINE.FIRST]: {
    show: true,
    showOnlyTitle: true,
    title: preparedTitle(TITLES_SMART_SUMMARY[SERVICETYPE.AIR], startDate, endDate),
    prev: MENU_KEYS.SMART.AIRLINE.FIRST,
    type: TYPE.SMART,
  },
  [MENU_KEYS.SMART.TRAIN.FIRST]: {
    show: true,
    showOnlyTitle: true,
    title: preparedTitle(TITLES_SMART_SUMMARY[SERVICETYPE.TRAIN], startDate, endDate),
    prev: MENU_KEYS.SMART.TRAIN.FIRST,
    type: TYPE.SMART,
  },
  [MENU_KEYS.SMART.HOTEL.FIRST]: {
    show: true,
    showOnlyTitle: true,
    title: TITLES_SMART_SUMMARY[SERVICETYPE.HOTEL],
    prev: preparedTitle(MENU_KEYS.SMART.HOTEL.FIRST, startDate, endDate),
    type: TYPE.SMART,
  },
  [MENU_KEYS.SMART.TAXI.FIRST]: {
    show: true,
    showOnlyTitle: true,
    title: TITLES_SMART_SUMMARY[SERVICETYPE.TAXI],
    prev: preparedTitle(MENU_KEYS.SMART.TAXI.FIRST, startDate, endDate),
    type: TYPE.SMART,
  },
  [MENU_KEYS.SMART.AEROEXPRESS.FIRST]: {
    show: true,
    showOnlyTitle: true,
    title: TITLES_SMART_SUMMARY[SERVICETYPE.AEROEXPRESS_CHARTS],
    prev: preparedTitle(MENU_KEYS.SMART.AEROEXPRESS.FIRST, startDate, endDate),
    type: TYPE.SMART,
  },
  [MENU_KEYS.SMART.TRANSFER.FIRST]: {
    show: true,
    showOnlyTitle: true,
    title: TITLES_SMART_SUMMARY[SERVICETYPE.TRANSFER],
    prev: preparedTitle(MENU_KEYS.SMART.TRANSFER.FIRST, startDate, endDate),
    type: TYPE.SMART,
  },
  [MENU_KEYS.SMART.MICE.FIRST]: {
    show: true,
    showOnlyTitle: true,
    title: preparedTitle(TITLES_SMART_SUMMARY[SERVICETYPE.MICE], startDate, endDate),
    prev: preparedTitle(MENU_KEYS.SMART.MICE.FIRST, startDate, endDate),
    type: TYPE.SMART,
  },
  [MENU_KEYS.SMART.COMMON.SECOND.AIRLINE]: {
    show: true,
    showOnlyTitle: true,
    prevPath: MENU_KEYS.SMART.COMMON.FIRST,
    type: TYPE.SMART,
  },
  [MENU_KEYS.SMART.COMMON.SECOND.TRAIN]: {
    show: true,
    showOnlyTitle: true,
    prevPath: MENU_KEYS.SMART.COMMON.FIRST,
    type: TYPE.SMART,
  },
  [MENU_KEYS.TAXI.SMART_VOUCHER_COMMON]: {
    show: true,
    showOnlyTitle: true,
    prevPath: MENU_KEYS.SMART.COMMON.FOURTH.TAXI,
    type: TYPE.SMART,
  },
  [MENU_KEYS.SMART.COMMON.SECOND.HOTEL]: {
    show: true,
    showOnlyTitle: true,
    prevPath: MENU_KEYS.SMART.COMMON.FIRST,
    type: TYPE.SMART,
  },
  [MENU_KEYS.SMART.COMMON.SECOND.TAXI]: {
    show: true,
    showOnlyTitle: true,
    prevPath: MENU_KEYS.SMART.COMMON.FIRST,
    type: TYPE.SMART,
  },
  [MENU_KEYS.TAXI.SMART_VOUCHER]: {
    show: true,
    showOnlyTitle: true,
    prevPath: MENU_KEYS.SMART.TAXI.THIRD,
    type: TYPE.SMART,
  },
  [MENU_KEYS.SMART.COMMON.SECOND.AEROEXPRESS]: {
    show: true,
    showOnlyTitle: true,
    prevPath: MENU_KEYS.SMART.COMMON.FIRST,
    type: TYPE.SMART,
  },
  [MENU_KEYS.SMART.COMMON.SECOND.TRANSFER]: {
    show: true,
    showOnlyTitle: true,
    prevPath: MENU_KEYS.SMART.COMMON.FIRST,
    type: TYPE.SMART,
  },
  [MENU_KEYS.SMART.COMMON.SECOND.MICE]: {
    show: true,
    showOnlyTitle: true,
    prevPath: MENU_KEYS.SMART.COMMON.FIRST,
    type: TYPE.SMART,
  },
  [MENU_KEYS.SMART.COMMON.THIRD.AIRLINE]: {
    show: true,
    showOnlyTitle: true,
    prevPath: MENU_KEYS.SMART.COMMON.SECOND.AIRLINE,
    type: TYPE.SMART,
  },
  [MENU_KEYS.SMART.COMMON.THIRD.TRAIN]: {
    show: true,
    showOnlyTitle: true,
    prevPath: MENU_KEYS.SMART.COMMON.SECOND.TRAIN,
    type: TYPE.SMART,
  },
  [MENU_KEYS.SMART.COMMON.THIRD.HOTEL]: {
    show: true,
    showOnlyTitle: true,
    prevPath: MENU_KEYS.SMART.COMMON.SECOND.HOTEL,
    type: TYPE.SMART,
  },
  [MENU_KEYS.SMART.COMMON.THIRD.TAXI]: {
    show: true,
    showOnlyTitle: true,
    prevPath: MENU_KEYS.SMART.COMMON.SECOND.TAXI,
    type: TYPE.SMART,
  },
  [MENU_KEYS.SMART.COMMON.THIRD.AEROEXPRESS]: {
    show: true,
    showOnlyTitle: true,
    prevPath: MENU_KEYS.SMART.COMMON.SECOND.AEROEXPRESS,
    type: TYPE.SMART,
  },
  [MENU_KEYS.SMART.COMMON.THIRD.TRANSFER]: {
    show: true,
    showOnlyTitle: true,
    prevPath: MENU_KEYS.SMART.COMMON.SECOND.TRANSFER,
    type: TYPE.SMART,
  },
  [MENU_KEYS.SMART.COMMON.THIRD.MICE]: {
    show: true,
    showOnlyTitle: true,
    prevPath: MENU_KEYS.SMART.COMMON.SECOND.MICE,
    type: TYPE.SMART,
  },
  [MENU_KEYS.SMART.COMMON.FOURTH.AIRLINE]: {
    show: true,
    showOnlyTitle: true,
    prevPath: MENU_KEYS.SMART.COMMON.THIRD.AIRLINE,
    type: TYPE.SMART,
  },
  [MENU_KEYS.SMART.COMMON.FOURTH.TRAIN]: {
    show: true,
    showOnlyTitle: true,
    prevPath: MENU_KEYS.SMART.COMMON.THIRD.TRAIN,
    type: TYPE.SMART,
  },
  [MENU_KEYS.SMART.COMMON.FOURTH.HOTEL]: {
    show: true,
    showOnlyTitle: true,
    prevPath: MENU_KEYS.SMART.COMMON.THIRD.HOTEL,
    type: TYPE.SMART,
  },
  [MENU_KEYS.SMART.COMMON.FOURTH.TAXI]: {
    show: true,
    showOnlyTitle: true,
    prevPath: MENU_KEYS.SMART.COMMON.THIRD.TAXI,
    type: TYPE.SMART,
  },
  [MENU_KEYS.SMART.COMMON.FOURTH.AEROEXPRESS]: {
    show: true,
    showOnlyTitle: true,
    prevPath: MENU_KEYS.SMART.COMMON.THIRD.AEROEXPRESS,
    type: TYPE.SMART,
  },
  [MENU_KEYS.SMART.COMMON.FOURTH.TRANSFER]: {
    show: true,
    showOnlyTitle: true,
    prevPath: MENU_KEYS.SMART.COMMON.THIRD.TRANSFER,
    type: TYPE.SMART,
  },
  [MENU_KEYS.SMART.COMMON.FOURTH.MICE]: {
    show: true,
    showOnlyTitle: true,
    prevPath: MENU_KEYS.SMART.COMMON.THIRD.MICE,
    type: TYPE.SMART,
  },
  [MENU_KEYS.SMART.AIRLINE.SECOND]: {
    show: true,
    showOnlyTitle: true,
    prevPath: MENU_KEYS.SMART.AIRLINE.FIRST,
    type: TYPE.SMART,
  },
  [MENU_KEYS.SMART.HOTEL.SECOND]: {
    show: true,
    showOnlyTitle: true,
    prevPath: MENU_KEYS.SMART.HOTEL.FIRST,
    type: TYPE.SMART,
  },
  [MENU_KEYS.SMART.TRAIN.SECOND]: {
    show: true,
    showOnlyTitle: true,
    prevPath: MENU_KEYS.SMART.TRAIN.FIRST,
    type: TYPE.SMART,
  },
  [MENU_KEYS.SMART.TAXI.SECOND]: {
    show: true,
    showOnlyTitle: true,
    prevPath: MENU_KEYS.SMART.TAXI.FIRST,
    type: TYPE.SMART,
  },
  [MENU_KEYS.SMART.AEROEXPRESS.SECOND]: {
    show: true,
    showOnlyTitle: true,
    prevPath: MENU_KEYS.SMART.AEROEXPRESS.FIRST,
    type: TYPE.SMART,
  },
  [MENU_KEYS.SMART.TRANSFER.SECOND]: {
    show: true,
    showOnlyTitle: true,
    prevPath: MENU_KEYS.SMART.TRANSFER.FIRST,
    type: TYPE.SMART,
  },
  [MENU_KEYS.SMART.MICE.SECOND]: {
    show: true,
    showOnlyTitle: true,
    prevPath: MENU_KEYS.SMART.MICE.FIRST,
    type: TYPE.SMART,
  },
  [MENU_KEYS.SMART.AIRLINE.THIRD]: {
    show: true,
    showOnlyTitle: true,
    prevPath: MENU_KEYS.SMART.AIRLINE.SECOND,
    type: TYPE.SMART,
  },
  [MENU_KEYS.SMART.TRAIN.THIRD]: {
    show: true,
    showOnlyTitle: true,
    prevPath: MENU_KEYS.SMART.TRAIN.SECOND,
    type: TYPE.SMART,
  },
  [MENU_KEYS.SMART.HOTEL.THIRD]: {
    show: true,
    showOnlyTitle: true,
    prevPath: MENU_KEYS.SMART.HOTEL.SECOND,
    type: TYPE.SMART,
  },
  [MENU_KEYS.SMART.TAXI.THIRD]: {
    show: true,
    showOnlyTitle: true,
    prevPath: MENU_KEYS.SMART.TAXI.SECOND,
    type: TYPE.SMART,
  },
  [MENU_KEYS.SMART.AEROEXPRESS.THIRD]: {
    show: true,
    showOnlyTitle: true,
    prevPath: MENU_KEYS.SMART.AEROEXPRESS.SECOND,
    type: TYPE.SMART,
  },
  [MENU_KEYS.SMART.TRANSFER.THIRD]: {
    show: true,
    showOnlyTitle: true,
    prevPath: MENU_KEYS.SMART.TRANSFER.SECOND,
    type: TYPE.SMART,
  },
  [MENU_KEYS.SMART.MICE.THIRD]: {
    show: true,
    showOnlyTitle: true,
    prevPath: MENU_KEYS.SMART.MICE.SECOND,
    type: TYPE.SMART,
  },
  [MENU_KEYS.AIRLINE.SUMMARY]: {
    show: true,
    title: getText('chartsAnalytics:airline.tickets'),
    prev: MENU_KEYS.AIRLINE.SUMMARY_NESTED,
    type: TYPE.AIR,
  },
  [MENU_KEYS.AIRLINE.SUMMARY_NESTED]: {
    show: true,
    prevPath: MENU_KEYS.AIRLINE.SUMMARY,
    type: TYPE.AIR,
  },
  [MENU_KEYS.AIRLINE.DIRECTIONS]: {
    show: true,
    title: getText('chartsAnalytics:airline.directions'),
    prev: MENU_KEYS.AIRLINE.DIRECTIONS_NESTED,
    type: TYPE.AIR,
  },
  [MENU_KEYS.AIRLINE.DIRECTIONS_NESTED]: {
    show: true,
    prevPath: MENU_KEYS.AIRLINE.DIRECTIONS,
    type: TYPE.AIR,
  },
  [MENU_KEYS.AIRLINE.COMPANIES]: {
    show: true,
    title: getText('chartsAnalytics:airline.companies'),
    prev: MENU_KEYS.AIRLINE.COMPANIES_NESTED,
    type: TYPE.AIR,
  },
  [MENU_KEYS.AIRLINE.COMPANIES_NESTED]: {
    show: true,
    prevPath: MENU_KEYS.AIRLINE.COMPANIES,
    type: TYPE.AIR,
  },
  [MENU_KEYS.AIRLINE.EARLINESS]: {
    show: true,
    title: getText('chartsAnalytics:airline.earliness'),
    prev: MENU_KEYS.AIRLINE.EARLINESS_NESTED,
    type: TYPE.AIR,
  },
  [MENU_KEYS.AIRLINE.EARLINESS_NESTED]: {
    show: true,
    prevPath: MENU_KEYS.AIRLINE.EARLINESS,
    type: TYPE.AIR,
  },
  [MENU_KEYS.TRAIN.SUMMARY]: {
    show: true,
    title: getText('chartsAnalytics:train.tickets'),
    prev: MENU_KEYS.TRAIN.SUMMARY_NESTED,
    type: TYPE.TRAIN,
  },
  [MENU_KEYS.TRAIN.SUMMARY_NESTED]: {
    show: true,
    prevPath: MENU_KEYS.TRAIN.SUMMARY,
    type: TYPE.TRAIN,
  },
  [MENU_KEYS.TRAIN.DIRECTIONS]: {
    show: true,
    title: getText('chartsAnalytics:train.directions'),
    prev: MENU_KEYS.TRAIN.DIRECTIONS_NESTED,
    type: TYPE.TRAIN,
  },
  [MENU_KEYS.TRAIN.DIRECTIONS_NESTED]: {
    show: true,
    prevPath: MENU_KEYS.TRAIN.DIRECTIONS,
    type: TYPE.TRAIN,
  },
  [MENU_KEYS.HOTEL.SUMMARY]: {
    show: true,
    title: getText('chartsAnalytics:hotel.hotels'),
    prev: MENU_KEYS.HOTEL.SUMMARY_NESTED,
    type: TYPE.HOTEL,
  },
  [MENU_KEYS.HOTEL.SUMMARY_NESTED]: {
    show: true,
    prevPath: MENU_KEYS.HOTEL.SUMMARY,
    type: TYPE.HOTEL,
  },
  [MENU_KEYS.HOTEL.DIRECTIONS]: {
    show: true,
    title: getText('chartsAnalytics:hotel.directions'),
    prev: MENU_KEYS.HOTEL.DIRECTIONS_NESTED,
    type: TYPE.HOTEL,
  },
  [MENU_KEYS.HOTEL.DIRECTIONS_NESTED]: {
    show: true,
    prevPath: MENU_KEYS.HOTEL.DIRECTIONS,
    type: TYPE.HOTEL,
  },
  [MENU_KEYS.HOTEL.POPULAR]: {
    show: true,
    title: getText('chartsAnalytics:hotel.popular'),
    prev: MENU_KEYS.HOTEL.POPULAR_NESTED,
    type: TYPE.HOTEL,
  },
  [MENU_KEYS.HOTEL.POPULAR_NESTED]: {
    show: true,
    prevPath: MENU_KEYS.HOTEL.POPULAR,
    type: TYPE.HOTEL,
  },
  [MENU_KEYS.TAXI.SUMMARY]: {
    show: true,
    title: getText('chartsAnalytics:taxi.tax'),
    prev: MENU_KEYS.TAXI.SUMMARY_NESTED,
    type: TYPE.TAXI,
  },
  [MENU_KEYS.TAXI.SUMMARY_NESTED]: {
    show: true,
    prevPath: MENU_KEYS.TAXI.SUMMARY,
    type: TYPE.TAXI,
  },
  [MENU_KEYS.TAXI.SUMMARY_NESTED_DOUBLE]: {
    show: true,
    prevPath: MENU_KEYS.TAXI.SUMMARY_NESTED,
    type: TYPE.TAXI,
  },
  [MENU_KEYS.TAXI.DIRECTIONS]: {
    show: true,
    title: getText('chartsAnalytics:taxi.directions'),
    prev: MENU_KEYS.TAXI.DIRECTIONS_NESTED,
    type: TYPE.TAXI,
  },
  [MENU_KEYS.TAXI.DIRECTIONS_NESTED]: {
    show: true,
    prevPath: MENU_KEYS.TAXI.DIRECTIONS,
    type: TYPE.TAXI,
  },
  [MENU_KEYS.TAXI.DIRECTIONS_NESTED_DOUBLE]: {
    show: true,
    prevPath: MENU_KEYS.TAXI.DIRECTIONS_NESTED,
    type: TYPE.TAXI,
  },
  [MENU_KEYS.AEROEXPRESS.SUMMARY]: {
    show: true,
    title: getText('chartsAnalytics:aeroexpress.tickets'),
    prev: MENU_KEYS.AEROEXPRESS.SUMMARY_NESTED,
    type: TYPE.AEROEXPRESS,
  },
  [MENU_KEYS.AEROEXPRESS.SUMMARY_NESTED]: {
    show: true,
    prevPath: MENU_KEYS.AEROEXPRESS.SUMMARY,
    type: TYPE.AEROEXPRESS,
  },
  [MENU_KEYS.AEROEXPRESS.DIRECTIONS]: {
    show: true,
    title: getText('chartsAnalytics:aeroexpress.directions'),
    prev: MENU_KEYS.AEROEXPRESS.DIRECTIONS_NESTED,
    type: TYPE.AEROEXPRESS,
  },
  [MENU_KEYS.AEROEXPRESS.DIRECTIONS_NESTED]: {
    show: true,
    prevPath: MENU_KEYS.AEROEXPRESS.DIRECTIONS,
    type: TYPE.AEROEXPRESS,
  },
  [MENU_KEYS.TRANSFER.SUMMARY]: {
    show: true,
    title: getText('chartsAnalytics:transfer.tickets'),
    prev: MENU_KEYS.TRANSFER.SUMMARY_NESTED,
    type: TYPE.TRANSFER,
  },
  [MENU_KEYS.MICE.SUMMARY]: {
    show: true,
    title: getText('chartsAnalytics:mice.tickets'),
    prev: MENU_KEYS.MICE.SUMMARY_NESTED,
    type: TYPE.MICE,
  },
  [MENU_KEYS.MICE.SUMMARY_NESTED]: {
    show: true,
    prevPath: MENU_KEYS.MICE.SUMMARY,
    type: TYPE.MICE,
  },
  [MENU_KEYS.TRANSFER.SUMMARY_NESTED]: {
    show: true,
    prevPath: MENU_KEYS.TRANSFER.SUMMARY,
    type: TYPE.TRANSFER,
  },
  [MENU_KEYS.MICE.SUMMARY_NESTED]: {
    show: true,
    prevPath: MENU_KEYS.MICE.SUMMARY,
    type: TYPE.MICE,
  },
  [MENU_KEYS.MICE.SUMMARY_NESTED]: {
    show: true,
    prevPath: MENU_KEYS.MICE.SUMMARY,
    type: TYPE.MICE,
  },
  [MENU_KEYS.TRANSFER.DIRECTIONS]: {
    show: true,
    title: getText('chartsAnalytics:transfer.directions'),
    prev: MENU_KEYS.TRANSFER.DIRECTIONS_NESTED,
    type: TYPE.TRANSFER,
  },
  [MENU_KEYS.TRANSFER.DIRECTIONS_NESTED]: {
    show: true,
    prevPath: MENU_KEYS.TRANSFER.DIRECTIONS,
    type: TYPE.TRANSFER,
  },
  [MENU_KEYS.STRUCTURE.SERVICE_TYPE]: {
    show: true,
    title: `${TITLES.STRUCTURE.TITLE} — ${TITLES.STRUCTURE.SERVICE_TYPE}`,
    prev: MENU_KEYS.STRUCTURE.SERVICE_TYPE_NESTED,
    type: TYPE.SPENDINGS,
  },
  [MENU_KEYS.STRUCTURE.SERVICE_TYPE_NESTED]: {
    show: true,
    prevPath: MENU_KEYS.STRUCTURE.SERVICE_TYPE,
    type: TYPE.SPENDINGS,
  },
  [MENU_KEYS.STRUCTURE.DEPARTMENTS]: {
    show: true,
    title: `${TITLES.STRUCTURE.TITLE} — ${TITLES.STRUCTURE.DEPARTMENTS}`,
    prev: MENU_KEYS.STRUCTURE.DEPARTMENTS_NESTED,
    type: TYPE.DEPARTMENTS,
  },
  [MENU_KEYS.STRUCTURE.DEPARTMENTS_NESTED]: {
    show: true,
    prevPath: MENU_KEYS.STRUCTURE.DEPARTMENTS,
    type: TYPE.SPENDINGS,
  },
  [MENU_KEYS.STRUCTURE.DEPARTMENTS_NESTED_SUB]: {
    show: true,
    prevPath: MENU_KEYS.STRUCTURE.DEPARTMENTS_NESTED,
    type: TYPE.DEPARTMENTS,
  },
  [MENU_KEYS.STRUCTURE.PROJECTS]: {
    show: true,
    title: `${TITLES.STRUCTURE.TITLE} — ${TITLES.STRUCTURE.PROJECTS}`,
    prev: MENU_KEYS.STRUCTURE.PROJECTS_NESTED,
    type: TYPE.PROJECTS,
  },
  [MENU_KEYS.STRUCTURE.PROJECTS_NESTED]: {
    show: true,
    prevPath: MENU_KEYS.STRUCTURE.PROJECTS,
    type: TYPE.SPENDINGS,
  },
  [MENU_KEYS.STRUCTURE.PROJECTS_NESTED_SUB]: {
    show: true,
    prevPath: MENU_KEYS.STRUCTURE.PROJECTS_NESTED,
    type: TYPE.PROJECTS,
  },
  [CHARTS_ANALYTICS(id)]: {
    show: true,
    title: `${TITLES.STRUCTURE.CUSTOM_ANALYTICS(title)}`,
    prev: MENU_KEYS.STRUCTURE.CUSTOM_ANALYTICS,
    type: TYPE.CUSTOM_ANALYTICS,
  },
  [MENU_KEYS.STRUCTURE.CUSTOM_ANALYTICS_NESTED]: {
    show: true,
    prevPath: CHARTS_ANALYTICS(id),
    type: TYPE.SPENDINGS,
  },
  [MENU_KEYS.STRUCTURE.CUSTOM_ANALYTICS_NESTED_SUB]: {
    show: true,
    prevPath: MENU_KEYS.STRUCTURE.CUSTOM_ANALYTICS_NESTED,
    type: TYPE.CUSTOM_ANALYTICS,
  },
})[active];

const GENERAL_COMMON_SUMMARY = {
  EMPLOYEES: 'employees',
  AMOUNT: 'amount',
  AMOUNT_WITH_REFUNDS: 'amountWithRefunds',
  BOOKINGS: 'bookings',
  CHANGES: 'changes',
  REFUNDS: 'refunds',
};

const GENERAL_COMMON_SUMMARY_RU_OR_ENG = {
  [GENERAL_COMMON_SUMMARY.EMPLOYEES]: getTextArray('chartsAnalytics:menuItems.general.summary.employees'),
  [GENERAL_COMMON_SUMMARY.AMOUNT]: getText('chartsAnalytics:menuItems.general.summary.amount'),
  [GENERAL_COMMON_SUMMARY.AMOUNT_WITH_REFUNDS]: getText('chartsAnalytics:menuItems.general.summary.amountWithRefunds'),
  [GENERAL_COMMON_SUMMARY.BOOKINGS]: getTextArray('chartsAnalytics:menuItems.general.summary.bookings'),
  [GENERAL_COMMON_SUMMARY.CHANGES]: getTextArray('chartsAnalytics:menuItems.general.summary.changes'),
  [GENERAL_COMMON_SUMMARY.REFUNDS]: getTextArray('chartsAnalytics:menuItems.general.summary.refunds'),
};

const FILTERS_FIELDS = {
  EMPLOYEES: 'employees',
  DEPARTMENTS: 'departments',
  PROJECTS: 'projects',
  ANALYTICS: 'analytics',
  TAGS: 'tags',
};

const STRUCTURE_TYPE = {
  AIR: 'Air',
  HOTEL: 'Hotel',
  TRAIN: 'Train',
  AEROEXPRESS: 'Aeroexpress',
  TRANSFER: 'Transfer',
  MICE: 'MICE',
  TAXI_VOUCHER: 'TaxiVoucher',
  TAXI_OPERATION: 'TaxiOperation',
  OTHER: 'Undefined',
};

const STRUCTURE_TYPE_FOR_BACKEND = {
  ALL_SERVICE: 'AllServices',
  DEPARTMENTS: 'Departmets',
  PROJECTS: 'Projects',
  TAGS: 'Tags',
  CUSTOM_ANALYTICS: 'CustomAnalytics',
  OTHER: 'OtherServices',
};

const STRUCTURE_TYPE_LOWER = {
  AIR: 'air',
  HOTEL: 'hotel',
  TRAIN: 'train',
  TAXI: 'taxi',
  TAXI_VOUCHER: 'taxivoucher',
  TAXI_OPERATION: 'taxioperation',
  AEROEXPRESS: 'aeroexpress',
  TRANSFER: 'transfer',
  MICE: 'mice',
  OTHER: 'undefined',
};

const STRUCTURE_TYPE_RU = {
  AIR: 'Авиабилеты',
  HOTEL: 'Гостиницы',
  TRAIN: 'Ж/Д билеты',
  TAXI: 'Такси',
  TAXI_OPERATION: 'Поездки такси',
  OTHER: 'Другие',
};

const ANALYTICS_UPDATE_TYPE = (type: string) => ({
  [STRUCTURE_TYPE_RU.AIR]: STRUCTURE_TYPE.AIR,
  [STRUCTURE_TYPE_RU.HOTEL]: STRUCTURE_TYPE.HOTEL,
  [STRUCTURE_TYPE_RU.TRAIN]: STRUCTURE_TYPE.TRAIN,
  [STRUCTURE_TYPE_RU.TAXI_OPERATION]: STRUCTURE_TYPE_LOWER.TAXI_OPERATION,
  [STRUCTURE_TYPE_RU.OTHER]: STRUCTURE_TYPE.OTHER,
})[type];

const TYPE_SMART = {
  GENERAL: 'General',
  AIR: 'Air',
  TRAIN: 'Train',
  HOTEL: 'Hotel',
  TAXI: 'Taxi',
  AEROEXPRESS: 'Aeroexpress',
};

const TYPE_SMART_SERVICE_TYPE = {
  SUMMARY: 'Summary',
  TIMELINESS: 'Timeliness',
  RETURNS: 'Returns',
  TRAVEL_POLICIES_USING: 'TravelPoliciesUsing',
  CONTRACT_3D_USING: 'Contract3DUsing',
  TRAVELLER_BONUS_USING: 'TravellerBonusUsing',
  SMART_HOTEL_USING: 'SmartHotelUsing',
};

const SMART_SECTIONS_TITLE = {
  [TYPE_SMART_SERVICE_TYPE.SUMMARY]: getText('chartsAnalytics:smart.summary.subTitles.summary'),
  [SERVICETYPE.AIR]: getText('chartsAnalytics:smart.summary.subTitles.airline'),
  [SERVICETYPE.HOTEL]: getText('chartsAnalytics:smart.summary.subTitles.hotel'),
  [SERVICETYPE.TRAIN]: getText('chartsAnalytics:smart.summary.subTitles.train'),
  [SERVICETYPE.TAXI]: getText('chartsAnalytics:smart.summary.subTitles.taxi'),
  [SERVICETYPE.AEROEXPRESS]: getText('chartsAnalytics:smart.summary.subTitles.aeroexpress'),
  [SERVICETYPE.TRANSFER]: getText('chartsAnalytics:smart.summary.subTitles.transfer'),
  [SERVICETYPE.MICE]: getText('chartsAnalytics:smart.summary.subTitles.mice'),
};

const BORDER_COLOR_TYPE = {
  GREEN: 'border_item_green',
  YELLOW: 'border_item_yellow',
  RED: 'border_item_red',
  GRAY: 'border_item_grey',
};

export {
  DEFAULT_CHARTS_ANALYTICS,
  DEFAULT_FILTERS,
  DEFAULT_CHART,
  DEFAULT_TABLE_INFO,
  MENU,
  MENU_KEYS,
  KEYS,
  TITLES,
  DEFAULT_PAGINATE,
  DEFAULT_SUB_TABLE_INFO,
  DEFAULT_AIRLINE_COLORS,
  CHARTS_ANALYTICS,
  HEADER_PROPS,
  GENERAL_COMMON_SUMMARY,
  GENERAL_COMMON_SUMMARY_RU_OR_ENG,
  FILTERS_FIELDS,
  STRUCTURE_TYPE,
  STRUCTURE_TYPE_RU,
  ANALYTICS_UPDATE_TYPE,
  STRUCTURE_TYPE_FOR_BACKEND,
  COMMON_CHARTS_ANALYTICS_DASH,
  TYPE_SMART,
  TYPE_SMART_SERVICE_TYPE,
  TITLES_SMART_SUMMARY,
  SMART_SECTIONS_TITLE,
  TYPE,
  BORDER_COLOR_TYPE,
  STRUCTURE_TYPE_LOWER,
};
