import React, { FC } from 'react';
import { IconButton, Text } from 'new-ui';

import { getText } from '../../../i18n';

import { svgColor } from '../../bi/utils/env';

const LABELS = {
  DEFAULT: getText('components:toNote.default'),
};

interface ToNoteProps {
  label?: string,
  className?: string,
  disabled?: boolean,
  onClick(e: React.MouseEvent): void,
  qaAttr?: string,
}

const ToNote: FC<ToNoteProps> = ({
  label = LABELS.DEFAULT,
  onClick = () => {},
  disabled = false,
  className = '',
  qaAttr = '',
}) => {
  const content = (
    <IconButton
      disabled={ disabled }
      iconType='menuNote'
      iconColor={ svgColor }
      onClick={ onClick }
      qaAttr={ qaAttr }
    >
      <Text
        type='NORMAL_14'
      >
        { label }
      </Text>
    </IconButton>
  );

  return (
    <div className={ className }>
      { content }
    </div>
  );
};

export { ToNote };
