// @ts-nocheck
import React from 'react';
import { Tooltip, PROPS, Text, IconButton } from 'new-ui';

import styles from './styles/index.module.css';

type HelpIconProps = {
  text: string,
  onClick?: () => void,
};

const HelpIcon = ({ text, onClick = () => {} }: HelpIconProps) => (
  <Tooltip
    show={ !!text }
    renderContent={ () => (
      <div className={ styles.tooltip }>
        <Text type={ PROPS.TEXT.TYPES.NORMAL_14_130 } color={ PROPS.TEXT.COLORS.WHITE }>{ text }</Text>
      </div>
    ) }
  >
    <IconButton iconType={ PROPS.ICON.TYPES.QUESTION } onClick={ onClick } />
  </Tooltip>
);

export default HelpIcon;
