import React from 'react';
import { Text, RadioButton, Price } from 'new-ui';

import { getText } from '../../../i18n';

import { useServices } from '../../bi/context/services';

import { calculationAmount } from '../../bi/utils/cloudPayments';

import { PaymentMethodTypes, PAYMENT_METHODS } from '../../bi/types/paymentMethod';

import wallet_icon from './images/wallet.png';

import styles from './index.module.css';

const LABELS = {
  PAYMENT_METHOD_LABEL: getText('payment:paymentMethod'),
  COMMISSION_INFO: getText('payment:commissionInfo'),
  TRANSFER_FEE: getText('payment:commissionForCurrentPurchase'),
  SERVICES: getText('payment:services'),
  AMOUNT_TO_PAY: getText('payment:amountToPay'),
  METHODS: {
    ORGANIZATION_ACCOUNT: getText('payment:method.organizationAccount'),
    EMPLOYEE_CARD: getText('payment:method.employeeCard'),
    EMPLOYEE_PERSONAL_ACCOUNT: getText('payment:method.employeePersonalAccount'),
  },
  CHECKBOX: {
    ONE: getText('checkout:finish.checkbox.one'),
    TWO: getText('checkout:finish.checkbox.two'),
  },

};

interface PaymentMethodProps {
  children: React.ReactElement,
  price: string,
  onSelectPaymentMethod: (value: string) => void,
  paymentMethod: PaymentMethodTypes,
}

const PaymentMethod = (
  {
    children,
    price,
    onSelectPaymentMethod,
    paymentMethod = PAYMENT_METHODS.ORGANIZATION_ACCOUNT,
  }: PaymentMethodProps) => {
  const {
    featureFlagsService: { getFeatureFlags },
  } = useServices(['FeatureFlags']);

  if (!getFeatureFlags().Acqruiring) {
    return null;
  }

  const calculatedFee = calculationAmount(String(price), true).transactionFee;
  const paymentMethodIsEmployeeCart = paymentMethod === PAYMENT_METHODS.EMPLOYEE_CARD;
  const finallyPrice = paymentMethodIsEmployeeCart ? calculatedFee + price : price;

  const renderAccountSelection = () => {
    const infoCommissionHtml = paymentMethodIsEmployeeCart ? (
      <div className={ styles.info_commission }>
        <Text type={ 'NORMAL_14' }>
          { LABELS.COMMISSION_INFO }
        </Text>
      </div>
    ) : null;

    const transferCommissionHtml = paymentMethodIsEmployeeCart ? (
      <div className={ styles.flex_row }>
        <Text type={ 'NORMAL_14' }>{LABELS.TRANSFER_FEE}</Text>
        <Price
          value={ calculatedFee }
          type={ 'NORMAL_14' }
          typeCurrency={ 'NORMAL_14' }
        />
      </div>
    ) : null;

    const servicesPrice = paymentMethodIsEmployeeCart ? (
      <div className={ styles.flex_row }>
        <Text type={ 'NORMAL_14' }> {LABELS.SERVICES} </Text>
        <Price
          value={ price }
          type={ 'NORMAL_14' }
          typeCurrency={ 'NORMAL_14' }
        />
      </div>
    ) : null;

    const infoPaymentHtml = (
      <div className={ styles.prices_info }>
        { servicesPrice }
        { transferCommissionHtml }
        <div className={ styles.flex_row }>
          <Text type={ 'bold_16' }>{LABELS.AMOUNT_TO_PAY}</Text>
          <Price
            value={ finallyPrice }
            type={ 'bold_16' }
            typeCurrency={ 'bold_16' }
          />
        </div>
      </div>
    );

    return (
      <div className={ styles.payments_method }>
        <RadioButton
          value={ PAYMENT_METHODS.ORGANIZATION_ACCOUNT }
          checked={ paymentMethod === PAYMENT_METHODS.ORGANIZATION_ACCOUNT }
          alternativeDesign={ false }
          onChange={ () => onSelectPaymentMethod(PAYMENT_METHODS.ORGANIZATION_ACCOUNT) }
        >
          { LABELS.METHODS.ORGANIZATION_ACCOUNT }
        </RadioButton>
        <RadioButton
          value={ PAYMENT_METHODS.EMPLOYEE_CARD }
          checked={ paymentMethod === PAYMENT_METHODS.EMPLOYEE_CARD }
          alternativeDesign={ false }
          onChange={ () => onSelectPaymentMethod(PAYMENT_METHODS.EMPLOYEE_CARD) }
        >
          { LABELS.METHODS.EMPLOYEE_CARD }
        </RadioButton>
        <RadioButton
          value={ PAYMENT_METHODS.EMPLOYEE_PERSONAL_ACCOUNT }
          checked={ paymentMethod === PAYMENT_METHODS.EMPLOYEE_PERSONAL_ACCOUNT }
          alternativeDesign={ false }
          onChange={ () => onSelectPaymentMethod(PAYMENT_METHODS.EMPLOYEE_PERSONAL_ACCOUNT) }
        >
          { LABELS.METHODS.EMPLOYEE_PERSONAL_ACCOUNT }
        </RadioButton>
        { infoCommissionHtml }
        <div className={ styles.info_payment }>{ infoPaymentHtml } </div>
      </div>
    );
  };

  return (
    <div className={ styles.payments_method_container }>
      <div className={ styles.header }>
        <img src={ wallet_icon } alt='wallet_icon' />
        <Text type={ 'bold_20' }>{ LABELS.PAYMENT_METHOD_LABEL } </Text>
      </div>
      <div className={ styles.payments_info }>
        { renderAccountSelection() }
      </div>
      <div className={ styles.final_step }>
        { children }
      </div>
    </div>
  );
};

export { PaymentMethod };
