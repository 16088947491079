// @ts-nocheck
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { PropTypes as MobxPropTypes } from 'mobx-react';
import { PROPS, Text, ItemPanel } from 'new-ui';
import { getText } from '../../../i18n';

import HotelItem from '../../../App/components/HotelItem';
import TransferItem from '../../../App/components/TransferItem';
import TrainItem from '../../../App/components/TrainItem';
import AeroexpressItem from '../../../App/components/AeroexpressItem';
import AirlineItem from '../../../App/components/AirlineItem';
import { TripItemEmployees } from '../../../App/components/TripItem/components/TripItemEmployees';

import TripDocuments from '../../cart/TripDocuments';

import { SERVICETYPE } from '../../../App/bi/constants/serviceType';
import { TRIPSTATUS } from '../../../App/bi/constants/trips';
import { ADDITIONAL_SERVICES, AIRLINE_PROVIDERS } from '../../../App/bi/constants/airline';
import { QA_ATTRIBUTES } from '../../../App/bi/constants/attributesForTests';

import parseJsonString from '../../../App/bi/utils/parseJsonString';
import { formatDate, textualMonthPattern } from '../../../App/bi/utils/formatDate';
import { getAirlineType, getVoidUntilEndOfDayString } from '../../../App/bi/utils/airline';

import FlightCertificateItem from '../../../App/components/FlightCertificateItem';
import ItemLayout from '../../../App/components/ItemLayout';
import AnalyticsSelect from '../../../App/components/AnalyticsSelect';
import { TaxiVoucherItem } from '../../../App/components/TaxiVoucheriItem';
import { AdditionalServiceItem } from '../../../App/components/AdditionalServiceItem';

import styles from './styles/index.module.css';

const LABELS = {
  TICKET: getText('trip:documents.ticket'),
  SEAT: getText('trip:documents.place'),
  FREE_CANCELLATION: getText('trip:documents.freeCancellationIsAvailable'),
  CHOSEN_PLACE: getText('trip:documents.chosenPlace'),
  CHOSEN_PLACES: getText('trip:documents.chosenPlaces'),
  CHOICE_PLACE: getText('trip:documents.choicePlace'),
};

class BookingTripItem extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    status: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    companies: MobxPropTypes.observableArray,
    projects: PropTypes.array.isRequired,
    onSendDocument: PropTypes.func,
    onPrintDocument: PropTypes.func,
    onDownloadVoucherLocale: PropTypes.func,
    onDownloadVoucher: PropTypes.func,
    downloadServiceForm: PropTypes.func,
    sendServiceForm: PropTypes.func,
    flightCertificateStatus: PropTypes.string,
    customAnalytics: PropTypes.array,
    accountSettingsService: PropTypes.object.isRequired,
    qaAttrPrice: PropTypes.string,
    prohibitionVouchers: PropTypes.array,
    qaAttrAdditionService: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.object.isRequired,
    ]),
  };

  static defaultProps = {
    status: '',
    onSendDocument: () => {},
    onPrintDocument: () => {},
    onCancelService: () => {},
    onEditService: () => {},
    onDownloadVoucher: () => {},
    downloadServiceForm: () => {},
    sendServiceForm: () => {},
    flightCertificateStatus: '',
    prohibitionVouchers: [],
    customAnalytics: [],
    qaAttrPrice: '',
    qaAttrAdditionService: null,
  };

  getFreeSeatsData = ({ Segments = [], AdditionalServices = [] }) => {
    const freeSeatsGroupedBySegmentId = Segments.reduce((acc, { ID }) => {
      const seats = AdditionalServices.filter(
        ({ SegmentId, Type, Service, EmdNumber }) =>
          Type === ADDITIONAL_SERVICES.Seat && (Service.Price === 0 || !EmdNumber) && SegmentId === ID,
      );

      if (!seats.length) {
        return acc;
      }

      return [...acc, seats];
    }, []);

    if (!freeSeatsGroupedBySegmentId.length) {
      return {
        caption: null,
        data: null,
      };
    }

    const hasOneSegmentWithOneSeat = freeSeatsGroupedBySegmentId.length === 1 && freeSeatsGroupedBySegmentId[0].length === 1;

    const label = hasOneSegmentWithOneSeat ? LABELS.CHOSEN_PLACE : LABELS.CHOSEN_PLACES;

    const freeSeatsAsString = freeSeatsGroupedBySegmentId
      .map(seatsOfSegment => seatsOfSegment.map(({ Service: { Number } }) => Number).join(', '))
      .join(' \\ ');

    return {
      caption: label,
      data: freeSeatsAsString,
    };
  };

  renderServiceItem = (type, itemData) => {
    switch (type) {
      case SERVICETYPE.AIR: {
        const pnr = itemData.metaData ? itemData.metaData.PNR : null;

        return itemData.Routes.map((route, ind) => (
          <div key={ ind } className={ styles.segment }>
            <AirlineItem
              route={ route }
              pnr={ pnr }
              meta={ itemData }
              showSegments
              fromTrip
              getFreeSeatsData={ () =>
                this.getFreeSeatsData({
                  Segments: route.Segments,
                  AdditionalServices: itemData.AdditionalServices,
                })
              }
              qaAttr={ QA_ATTRIBUTES.cart.checkout.successPay.air }
            />
          </div>
        ));
      }
      case SERVICETYPE.HOTEL: {
        const item = {
          checkin: itemData.CheckinDate,
          checkout: itemData.CheckoutDate,
          room: {
            Name: itemData.Room.Category,
            Amenities: {
              CancellationInfo: itemData.Room.FreeCancellation,
            },
          },
          hotel: {
            Name: itemData.Hotel.Name,
            City: itemData.Hotel.City,
            Address: itemData.Hotel.Address,
            Stars: itemData.Hotel.Stars,
          },
          raw: itemData,
        };

        return <HotelItem item={ item } qaAttrDate={ QA_ATTRIBUTES.cart.checkout.successPay.hotel.date } fromTrip />;
      }
      case SERVICETYPE.TRANSFER: {
        return <TransferItem item={ itemData } />;
      }
      case SERVICETYPE.TRAIN: {
        return <TrainItem item={ itemData } />;
      }
      case SERVICETYPE.AIR_ADDITIONAL_SERVICE: {
        return <AdditionalServiceItem item={ itemData } />;
      }
      case SERVICETYPE.FLIGHT_CERTIFICATE: {
        return <FlightCertificateItem meta={ itemData } qaAttr={ QA_ATTRIBUTES.cart.checkout.successPay.flightCertificate } />;
      }
      case SERVICETYPE.AEROEXPRESS: {
        const {
          Trip: { TariffType, DepartureDate, BackwardDate, Airport, Direction },
        } = itemData;
        const mappedItemData = {
          ...itemData,
          Trip: {
            tariff_type: TariffType,
            departure_date: DepartureDate,
            backward_date: BackwardDate,
            airport: Airport,
            direction: Direction,
          },
        };

        return <AeroexpressItem item={ mappedItemData } />;
      }
      case SERVICETYPE.TAXI_VOUCHER: {
        return <TaxiVoucherItem item={ itemData.ReserveData } />;
      }

      default:
        return null;
    }
  };

  renderPaidSeats({ AdditionalServices = [], Routes, TicketsExtended, ProviderName = '' }) {
    const {
      item: {
        ActualVersion,
        ActualVersion: { ServiceType, Employees },
        Id,
        CompanyId,
      },
      companies,
      projects,
      downloadServiceForm,
      onDownloadVoucherLocale,
      sendServiceForm,
      flightCertificateStatus,
      qaAttrAdditionService,
      prohibitionVouchers,
      accountSettingsService: {
        getHowEmployeeDisplay,
        getCartEmployeeBirthday,
      },
    } = this.props;

    if (ServiceType !== SERVICETYPE.AIR) {
      return null;
    }

    return AdditionalServices.filter(
      ({ Type, Service: { Price }, EmdNumber }) => Type === ADDITIONAL_SERVICES.Seat && EmdNumber && Price > 0,
    ).map(({ Service, EmdNumber, Id: serviceId, TravellerId, SegmentId }) => {
      const currentSegment = Routes.flatMap(({ Segments }) => Segments).find(({ ID }) => ID === SegmentId);
      const airlineType = getAirlineType(currentSegment);

      const ticketNumber = TicketsExtended.length && TicketsExtended[0].Num ? `${LABELS.TICKET} #${TicketsExtended[0].Num}, ` : '';

      const { Code: airlineId } = currentSegment[airlineType];
      const displayEmployeeAccountSettings = getHowEmployeeDisplay();
      const cartEmployeeBirthday = getCartEmployeeBirthday();

      const {
        FlightNumber,
        DepartureAirport: { CityCode: DepartureCityCode, Code: DepartureCode },
        ArrivalAirport: { CityCode: ArrivalCityCode, Code: ArrivalCode },
        DepartureCity,
        ArrivalCity,
        DepartureDate,
      } = currentSegment;

      const departureDate = formatDate(DepartureDate, textualMonthPattern);

      const isS7 = ProviderName === AIRLINE_PROVIDERS.S7;
      const isProhibitionVoucher = prohibitionVouchers.some(id => id === CompanyId);

      const downloadableContent = !isS7 && status !== TRIPSTATUS.CANCELLED && status !== TRIPSTATUS.AWAITINGTICKETING && (
        <TripDocuments
          isServiceForm
          id={ Id }
          isProhibitionVoucher={ isProhibitionVoucher }
          additionalServiceId={ serviceId }
          item={ ActualVersion }
          downloadServiceForm={ downloadServiceForm }
          sendServiceForm={ sendServiceForm }
          flightCertificateStatus={ flightCertificateStatus }
          onDownloadVoucherLocale={ onDownloadVoucherLocale }
        />
      );

      const itemEmployees = {
        ...ActualVersion,
        Employees: Employees.filter(({ Id: travellerId }) => travellerId === TravellerId),
      };

      return (
        <ItemPanel key={ `${SegmentId}_${serviceId}` } renderHeader={ () => {} }>
          <div className={ styles.wrapper }>
            <ItemLayout
              isSeat
              serviceType={ SERVICETYPE.AIR }
              price={ Service.Price }
              qaAttrPrice={ qaAttrAdditionService?.price || '' }
            >
              <Text
                type={ PROPS.TEXT.TYPES.BOLD_18 }
                qaAttr={ qaAttrAdditionService?.seatTitle || '' }
              >
                {LABELS.CHOICE_PLACE}
              </Text>
              <Text className={ styles.seat_info } type={ PROPS.TEXT.TYPES.NORMAL_14 }>
                {ticketNumber}
                {airlineId} {FlightNumber}, {DepartureCity} ({DepartureCityCode || DepartureCode}) – {ArrivalCity} (
                {ArrivalCityCode || ArrivalCode}), {departureDate}
              </Text>
              <Text
                className={ styles.seat_info }
                type={ PROPS.TEXT.TYPES.NORMAL_14 }
                qaAttr={ qaAttrAdditionService?.seatNumber || '' }
              >
                {LABELS.SEAT} {Service.Number}
              </Text>
              <Text className={ styles.seat_info } type={ PROPS.TEXT.TYPES.SEMIBOLD_16 }>
                {EmdNumber}
              </Text>
            </ItemLayout>
            <div className={ styles.additional }>
              <div className={ styles.top }>
                <TripItemEmployees
                  currentVersion={ itemEmployees }
                  companies={ companies }
                  projects={ projects }
                  displayEmployee={ displayEmployeeAccountSettings }
                  cartEmployeeBirthday={ cartEmployeeBirthday }
                />
                {downloadableContent}
              </div>
              {this.renderCustomAnalytics()}
            </div>
          </div>
        </ItemPanel>
      );
    });
  }

  renderHeader = ({ TicketsExtended = [] }) => {
    const {
      item: {
        ActualVersion: { ServiceType },
      },
    } = this.props;

    if (ServiceType !== SERVICETYPE.AIR || !TicketsExtended.length) {
      return null;
    }

    const { VoidTimeLimitUTC = null } = TicketsExtended[0];

    if (!VoidTimeLimitUTC) {
      return null;
    }

    const voidTimeLimit = getVoidUntilEndOfDayString(VoidTimeLimitUTC);

    const freeCancellationText = `${LABELS.FREE_CANCELLATION} ${voidTimeLimit}`;

    if (!voidTimeLimit) {
      return null;
    }

    return (
      <div className={ styles.header }>
        <Text type={ PROPS.TEXT.TYPES.NORMAL_16_100 }>{ freeCancellationText }</Text>
      </div>
    );
  };

  renderCustomAnalytics = () => {
    const {
      customAnalytics,
      item: { UserAnalytics = [] },
    } = this.props;

    const analyticsList = customAnalytics.reduce((acc, analytics) => {
      const value = analytics.Values.find(({ Id }) => UserAnalytics.includes(Id));

      if (!value) {
        return acc;
      }

      return [
        ...acc,
        <div key={ analytics.Id } className={ styles.custom_analytics_item }>
          <AnalyticsSelect
            readonly
            onSet={ () => {} }
            onUnset={ () => {} }
            analytics={ analytics }
            value={ value }
            selectedClassName={ styles.custom_analytics_item_select }
          />
        </div>,
      ];
    }, []);

    return <div className={ styles.custom_analytics }>{analyticsList}</div>;
  };

  renderTransferViewers = () => {
    const {
      item,
      item: {
        ActualVersion: { ServiceType },
      },
    } = this.props;

    if (ServiceType !== SERVICETYPE.TRANSFER) return null;

    const viewers = JSON.parse(item.ActualVersion.JsonData).Viewers;

    if (!viewers) return null;

    const viewersListHtml = viewers.map((number, idx) => (
      <div key={ `${styles.viewer}_${idx}` } className={ styles.viewer }>
        {number}
      </div>
    ));

    return (
      <div className={ styles.viewers }>{ viewersListHtml }</div>
    );
  };

  render() {
    const {
      item: { ActualVersion, AgentFee, Id },
      status,
      onSendDocument,
      onPrintDocument,
      onDownloadVoucher,
      companies,
      projects,
      flightCertificateStatus,
      qaAttrPrice,
      prohibitionVouchers,
      accountSettingsService: {
        getHowEmployeeDisplay,
        getCartEmployeeBirthday,
      },
      onDownloadVoucherLocale,
    } = this.props;

    const type = ActualVersion.ServiceType;
    const itemData = parseJsonString(ActualVersion.JsonData);
    const isProhibitionVoucher = prohibitionVouchers.some(id => id === ActualVersion.CompanyId);

    if (!itemData) {
      return null;
    }

    const downloadableContent = status !== TRIPSTATUS.CANCELLED && status !== TRIPSTATUS.AWAITINGTICKETING && (
      <TripDocuments
        id={ Id }
        item={ ActualVersion }
        isProhibitionVoucher={ isProhibitionVoucher }
        onPrintDocument={ onPrintDocument }
        onSendDocument={ onSendDocument }
        onDownloadVoucher={ onDownloadVoucher }
        flightCertificateStatus={ flightCertificateStatus }
        onDownloadVoucherLocale={ onDownloadVoucherLocale }
      />
    );

    const { PriceDetails = null, Name, ProviderName, ReferralLink: referralLink = null, AdditionalServices: additionalServices = [] } = itemData;

    const checkAvailabilityAdditionalServices =
      ProviderName === AIRLINE_PROVIDERS.S7 &&
      (!PriceDetails || !PriceDetails.Total) &&
      Name ?
        null :
        downloadableContent;

    const additionalServicesPrice = additionalServices.reduce((acc, service) => {
      if (service.Type !== ADDITIONAL_SERVICES.Seat || !service.EmdNumber) {
        return acc;
      }

      return acc + service.Service.Price;
    }, 0);

    const customAnalytics = this.renderCustomAnalytics();
    const displayEmployeeAccountSettings = getHowEmployeeDisplay();
    const cartEmployeeBirthday = getCartEmployeeBirthday();

    return (
      <>
        <ItemPanel renderHeader={ () => this.renderHeader(itemData) }>
          <div className={ styles.wrapper }>
            <ItemLayout
              serviceType={ type }
              price={ ActualVersion.Price - additionalServicesPrice }
              qaAttrPrice={ qaAttrPrice }
              isOnlyReadingAdditionalFee
              feeSmartagent={ AgentFee }
              referralLink={ referralLink }
              versionStatus={ ActualVersion.Status }
              status={ status }
            >
              { this.renderServiceItem(type, itemData) }
            </ItemLayout>
            <div className={ styles.additional }>
              <div className={ styles.top }>
                <div className={ styles.employees }>
                  <TripItemEmployees
                    currentVersion={ ActualVersion }
                    companies={ companies }
                    projects={ projects }
                    displayEmployee={ displayEmployeeAccountSettings }
                    cartEmployeeBirthday={ cartEmployeeBirthday }
                  />
                </div>
                { checkAvailabilityAdditionalServices }
              </div>
              { customAnalytics }
              { this.renderTransferViewers() }
            </div>
          </div>
        </ItemPanel>
        { this.renderPaidSeats(itemData) }
      </>
    );
  }
}

export default BookingTripItem;
