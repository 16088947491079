import React, { FC } from 'react';
import { RadioButton } from 'new-ui';

import { getText } from '../../../i18n';

import { TRAVELPOLICYFILTER } from '../../bi/constants/travelPolicy';

import { isSmartAgent } from '../../bi/utils/env';

import { ITravelPolicyListItem } from '../../bi/types/travelPolicy';

import styles from './styles/index.module.css';

const LABELS = {
  NOT_APPLIED: getText('components:filterTravelPolicy.notApplied'),
};

const prepareList = (list: string[], readOnly: boolean, selected: string) => (readOnly ? list.filter(i => i === selected) : list);

const getName = (item: string, travelPolicyList: ITravelPolicyListItem[]) => {
  const foundedTp = travelPolicyList.find(tp => tp.Id === item);

  return foundedTp && foundedTp.Name;
};

interface FilterTravelPolicyProps {
  list: string[],
  travelPolicyList: ITravelPolicyListItem[],
  readOnly: boolean,
  selected: string,
  onChangeCheckbox(item: any): void,
  qaAttrCheckbox?: string,
}

interface FilterList {
  name: string,
  item: string,
}

const FilterTravelPolicy: FC<FilterTravelPolicyProps> = ({
  list,
  travelPolicyList,
  readOnly,
  selected,
  onChangeCheckbox,
  qaAttrCheckbox = '',
}) => {
  const preparedList = prepareList(list, readOnly, selected);

  const handleChangeCheckbox = (hash: string) => (readOnly ? () => {} : onChangeCheckbox(hash));

  const renderList = () => {
    const notApplied: FilterList = {
      name: LABELS.NOT_APPLIED,
      item: TRAVELPOLICYFILTER.NOTAPPLIED,
    };
    const listNames: string[] = [];
    const radioClassNames = [styles.radio];

    const filterList = preparedList.reduce<FilterList[]>((acc, item) => {
      const newResult = [...acc];
      const name = getName(item, travelPolicyList);

      if (readOnly) {
        radioClassNames.push(styles.readonly);
      }

      if (name && item !== TRAVELPOLICYFILTER.NOTAPPLIED) {
        newResult.push({ name, item });
        listNames.push(name);
      }

      return newResult;
    }, []);

    const sortedNamesList = [notApplied.name, ...listNames.sort()];

    filterList.push(notApplied);

    return sortedNamesList.map((radio, ind) => {
      const hash = filterList.find(({ name }) => name === radio);

      if (!hash) return null;

      return (
        <RadioButton
          key={ radio }
          value={ radio }
          checked={ hash.item === selected }
          onChange={ () => handleChangeCheckbox(hash.item) }
          className={ radioClassNames.join(' ') }
          qaAttr={ `${qaAttrCheckbox}-${ind}` }
          alternativeDesign={ isSmartAgent }
        >
          { radio }
        </RadioButton>
      );
    });
  };

  return (
    <>{ renderList() }</>
  );
};

export { FilterTravelPolicy };
