import React from 'react';

import FeatureFlags from '../../../bi/services/featureFlags';

import { AirlineMainMenu } from './components/MainMenu';
import { AirlineSubMenu } from './components/SubMenu';

import { MainAnalytic } from '../../../bi/utils/analytics';
import { FIELDS_SEARCH_MENU } from '../../../bi/constants/airline';

interface IAirlineSearchMenuProps {
  airlineService: any;
  requestsService: any;
  onSearch(): void;
  onSearchSubMenu(): void;
  handleReturnToSearch?(): void;
  subMenu?: boolean
  qaAttrs: null;
  qaAttrsSubMenu: null;
  featureFlagsService: FeatureFlags,
}

const AirlineSearchMenu = ({
  subMenu = false,
  handleReturnToSearch = () => {},
  airlineService,
  requestsService,
  onSearchSubMenu,
  onSearch,
  featureFlagsService: {
    getNewAirlineSearch,
  },
  qaAttrs = null,
  qaAttrsSubMenu = null,
}: IAirlineSearchMenuProps) => {
  const handleChangeAdult = (value: number) => airlineService.setSearchValue(FIELDS_SEARCH_MENU.ADULT, value);

  const handleChangeFlightClass = (value: string) => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.AIRLINE.SEARCH_AIR_CLASS_CHOSEN, {
      type: value,
    });

    return airlineService.setSearchValue(FIELDS_SEARCH_MENU.FLIGHT_CLASS, value);
  };

  const renderSubMenu = () => (
    <AirlineSubMenu
      requestsService={ requestsService }
      airlineService={ airlineService }
      onSearch={ onSearchSubMenu }
      handleReturnToSearch={ handleReturnToSearch }
      handleChangeAdult={ handleChangeAdult }
      handleChangeFlightClass={ handleChangeFlightClass }
      qaAttrs={ qaAttrsSubMenu }
    />
  );

  const renderPanelMenu = () => (
    <AirlineMainMenu
      airlineService={ airlineService }
      handleChangeAdult={ handleChangeAdult }
      handleChangeFlightClass={ handleChangeFlightClass }
      getNewAirlineSearch={ getNewAirlineSearch() }
      onSearch={ onSearch }
      qaAttrs={ qaAttrs }
    />
  );

  return subMenu
    ? renderSubMenu()
    : renderPanelMenu();
};

export default AirlineSearchMenu;
