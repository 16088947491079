// @ts-nocheck
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { PROPS, Text } from 'new-ui';
import { getText } from '../../../i18n';

import CONFIG from '../../../config';

import { isSmartAgent } from '../../bi/utils/env';

import styles from './styles/inetercom.module.css';

const LABELS = {
  TITLE_ONE: getText('components:intercom.fake.title.one'),
  TITLE_TWO: getText('components:intercom.fake.title.two'),
  TITLE_TWO_SMARTAGENT: getText('components:intercom.fake.title.twoSmartagent'),
  DESCRIPTION: getText('components:intercom.fake.description'),
  IF_CLIENT_ONE: getText('components:intercom.fake.ifClient.one'),
  IF_CLIENT_TWO: getText('components:intercom.fake.ifClient.two'),
  IF_CLIENT_THREE: getText('components:intercom.fake.ifClient.three'),
  TEL: telNumber => getText('components:intercom.fake.tel', { telNumber }),
};

class Intercom extends Component {
  static propTypes = {
    appId: PropTypes.string.isRequired,
    workspaceService: PropTypes.object.isRequired,
    appService: PropTypes.object.isRequired,
    chatService: PropTypes.object.isRequired,
  };

  state = {
    fakeOpen: false,
  };

  constructor(props) {
    super();

    this.unsubscribeChatFn = props.chatService.subscribe(this.updateState);
  }

  componentDidMount() {
    this.initializeIntercom();
  }

  componentWillUnmount() {
    this.props.chatService.closeSession();

    this.unsubscribeChatFn();
  }

  updateState = () => {
    const store = this.props.chatService.get();

    this.setState({
      fakeOpen: store.show,
    });
  };

  initializeIntercom = () => {
    const { workspaceService, chatService } = this.props;
    const { Email } = workspaceService.get();

    if (CONFIG.INTERCOM.SWITCHON && (chatService.hasDisabledUser(Email) || !workspaceService.canUseIntercom)) {
      chatService.createFakeIntercomObj();
    } else if (CONFIG.INTERCOM.SWITCHON) {
      const ic = window.Intercom;
      const appId = this.props.appId;

      if (!ic) {
        // eslint-disable-next-line
        (function (w, d, id, s, x) {
          function i() {
            // eslint-disable-next-line
            i.c(arguments);
          }

          i.q = [];
          // eslint-disable-next-line
          i.c = function (args) {
            i.q.push(args);
          };
          // eslint-disable-next-line
          w.Intercom = i;
          // eslint-disable-next-line
          s = d.createElement('script');
          // eslint-disable-next-line
          s.src = `https://widget.intercom.io/widget/${id}`;
          // eslint-disable-next-line
          x = d.getElementsByTagName('script')[0];
          x.parentNode.insertBefore(s, x);
        }(window, document, appId));
      }
    }
  };

  handleClose = () => {
    this.props.chatService.chat.hide();
  };

  handleLogin = () => {
    this.props.appService.showLogin();
  };

  renderFakeWindow = () => {
    const telNumber = this.props.workspaceService.getPhoneForIntercom();
    const titleTwo = isSmartAgent ? LABELS.TITLE_TWO_SMARTAGENT : LABELS.TITLE_TWO;

    return (
      <div className={ styles.wrap }>
        <div className={ styles.header }>
          <div className={ styles.title }>
            <div className={ styles.main }>{LABELS.TITLE_ONE}</div>
            <div className={ styles.sub }>{ titleTwo } </div>
          </div>
          <div
            className={ styles.cross }
            onClick={ this.handleClose }
          >
            <i className='material-icons'>clear</i>
          </div>
        </div>
        <div className={ styles.body }>
          <Text type={ PROPS.TEXT.TYPES.NORMAL_16_130 } className={ styles.text }>
            {LABELS.DESCRIPTION}
          </Text>
          <Text type={ PROPS.TEXT.TYPES.NORMAL_16_130 } className={ styles.text }>
            {LABELS.IF_CLIENT_ONE}
            {' '}
            <a className={ styles.link } onClick={ this.handleLogin }>{LABELS.IF_CLIENT_TWO}</a>
            {' '}
            {LABELS.IF_CLIENT_THREE}</Text>
          <Text type={ PROPS.TEXT.TYPES.NORMAL_16_130 } className={ styles.text }>
            {LABELS.TEL(telNumber)}
          </Text>
        </div>
      </div>
    );
  };

  render() {
    const { fakeOpen } = this.state;

    if (fakeOpen) return this.renderFakeWindow();

    return false;
  }
}

export default Intercom;
