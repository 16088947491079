import moment from 'moment';
import { getText } from '../../../i18n';

const LABELS = {
  AT: getText('constants:dateFormats.at'),
  MIDNIGHT: getText('constants:dateFormats.midnight'),
  MIDNIGHT_WITHOUT_MINUTE: getText('constants:dateFormats.midnightWithoutMinute'),
};

type DateformatsType = { [n: string]: string };

const DATEFORMATS: DateformatsType = {
  DATE: 'DD.MM.YYYY',
  DAY_MONTH: 'DD.MM',
  TIME: 'HH:mm',
  DAY_TYPE: 'ddd',
};

type UnitOfTimeBaseType = { [n: string]: moment.unitOfTime.DurationConstructor };

const UNIT_OF_TIME_BASE: UnitOfTimeBaseType = {
  HOUR: 'h',
};

type PatternType = { [n: string]: moment.unitOfTime.DurationConstructor | string };

const PATTERN: PatternType = {
  SHORT_FULL_YEAR: 'DD.MM.YY',
  LONG_FULL_YEAR: 'DD.MM.YYYY',
  YEARMONTHDAY: 'YYYY-MM-DD',
  YEAR_MONTH_DAY_MERGED: 'YYYYMMDD',
  YEAR_MONTH_DAY_TIME: 'YYYY-MM-DDTHH:mm:ss',
  YEAR_MONTH_DAY_TIME_WITHOUT_SECONDS: 'YYYY-MM-DDTHH:mm',
  DATE_TIME_WITHOUT_SECONDS: 'DD.MM.YYYY HH:mm',
  DAY_MONTH_TIME_WITHOUT_SECONDS: 'DD.MM HH:mm',
  DATEWITHWEEKDAY: 'DD.MM.YYYY, dd',
  DAY_OF_MONTH_WITH_YEAR_AND_TIME: 'D MMMM YYYY HH:mm',
  DAY_OF_MONTH_WITH_YEAR_AND_TIME_COMMA_SEPARATE: 'D MMMM YYYY, HH:mm',
  DAY_OF_MONTH_WITH_YEAR: 'D MMMM YYYY',
  DATE_WITH_YEAR: 'DD MMMM YYYY',
  DATE_TIME_WITH_YEAR: `D MMMM YYYY [${LABELS.AT}] HH:mm`,
  DAY_OF_MONTH_WITH_TIME: `D MMMM [${LABELS.AT}] HH:mm`,
  DAY_OF_MONTH: 'D MMMM',
  DAY_OF_MONTH_TIME: 'D MMMM HH:mm',
  DAY_OF_MONTH_SHORT: 'D MMM',
  DAY_OF_MONTH_LONG: 'DD MMMM',
  DATE_VOUCHER_FROM: `DD.MM.YYYY [${LABELS.MIDNIGHT}]`,
  DATE_VOUCHER_TO: `DD.MM.YYYY [${LABELS.MIDNIGHT_WITHOUT_MINUTE}]`,
  WEEK: 'dd',
  FULLDATEWITHTIME: 'DD.MM.YYYY HH:mm',
  HOUR: 'h',
  FULL_TIME_WITH_DATE: 'HH:mm DD.MM.YYYY',
  DAY_WITH_DIGITALMONTH: 'DD/MM',
  LOCAL_DAY_PATTERN: 'DD.MM.YYYY, dd, HH:mm',
  LOCAL_TIME_PATTERN: 'HH:mm',
  ACTION_IN_TIME_PATTERN: `dd[,] DD.MM.YYYY [${LABELS.AT}] HH:mm`,
  ACTION_IN_DATE_TIME_PATTERN: `DD.MM.YYYY[,] dd [${LABELS.AT}] HH:mm`,
  HOURSE_AND_MINUTES: 'LT',
  ISO_DATETIME_FORMAT_WITH_TIMEZONE: 'YYYY-MM-DDTHH:mm:sszzZ',
};

const FIELDS_DATE = {
  START_DATE: 'startDate',
  END_DATE: 'endDate',
};

enum EnumDateLimits {
  YEAR = 'year',
  DAY = 'day',
  MONTH = 'month',
  HOUR = 'hour',
  MINUTE = 'minute',
  SECOND = 'second',
  MILLISECOND = 'millisecond',
}

export {
  DATEFORMATS,
  PATTERN,
  FIELDS_DATE,
  UNIT_OF_TIME_BASE,
  EnumDateLimits,
};
