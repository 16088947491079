// @ts-nocheck
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import { getText } from '../../../i18n';

import Panel from '../Panel';
import { statuses, TYPES } from '../../../constants/Request';

import ROUTES from '../../bi/constants/routes';

import styles from './styles/request.module.css';

const LABELS = {
  REQUEST_NUMBER: getText('components:requestItem.requestNumber'),
  SENT: getText('components:requestItem.sent'),
};

class RequestsItem extends Component {
  static propTypes = {
    request: PropTypes.object.isRequired,
    formatService: PropTypes.object.isRequired,
  };

  renderTypes() {
    // ItemTypes приходят уникальными с сервера
    return this.props.request.ItemTypes.map((type) => {
      switch (type) {
        case TYPES.AIR: {
          return (<span key={ type } className={ `${styles.service} smartway-airplane` } />);
        }
        case TYPES.HOTEL: {
          return (<span key={ type } className={ `${styles.service} smartway-hotel` } />);
        }
        case TYPES.TRANSFER: {
          return (<span key={ type } className={ `${styles.service} smartway-transfer` } />);
        }
        case TYPES.TRAIN: {
          return (<span key={ type } className={ `${styles.service} smartway-train` } />);
        }
      }

      return '';
    });
  }

  render() {
    const {
      CreatedDate,
      Id,
      Status,
      Comment,
      EmployeesNames,
    } = this.props.request;

    const { formatService } = this.props;

    const createdDate = formatService.date(CreatedDate, 'DD.MM.YYYY HH:MM');
    const employees = EmployeesNames.map((employee, idx) => (
      <span key={ idx }>{ `${employee}${idx + 1 !== EmployeesNames.length ? ', ' : ''}` }</span>
    ));

    return (
      <NavLink to={ `${ROUTES.REQUESTS}/${Id}` }>
        <div className={ `${styles.wrap} ${styles.request_link}` }>
          <Panel shadow='light'>
            <div className={ styles.row } >
              <div className={ `${styles['column-75']} ${styles.info}` }>
                <div className={ styles.name }>{LABELS.REQUEST_NUMBER} { Id }</div>
                <div className={ styles.employees }>{ employees }</div>
                <div>{ Comment }</div>
                <div className={ styles['created-date'] }>{LABELS.SENT} { createdDate }</div>
              </div>
              <div className={ `${styles['column-25']} ${styles.icons}` }>
                <div className={ styles.types }>{ this.renderTypes() }</div>
                <div>
                  { statuses.get(Status) }
                  <span className={ `${styles['status-label']} ${styles[Status.toLowerCase()]}` } />
                </div>
              </div>
            </div>
          </Panel>
        </div>
      </NavLink>
    );
  }
}

export default RequestsItem;
