import { observable, action, makeObservable } from 'mobx';

import { ITrain } from './types';

export interface ITrainStore {
  loading: boolean,
  loadingCarDetails: boolean,
  train: ITrain | null,
  car: any | null,
}

class Store {
  constructor() {
    makeObservable(this);
  }

  @observable loading = true;
  @observable loadingCarDetails = true;
  @observable train: any = null;
  @observable car = null;

  @action
  setLoading = (value: boolean) => {
    this.loading = value;
  };

  @action
  setLoadingCarDetails = (value: boolean) => {
    this.loadingCarDetails = value;
  };

  @action
  setTrainInfo = (train: any) => {
    this.train = train;
  };

  @action
  setPlaceDetails = (car: any) => {
    this.car = car;
    this.setLoadingCarDetails(false);
  };

  @action
  setTrainFavorite = (value: any) => {
    this.train = {
      ...this.train,
      FavoriteId: value,
    };
  };
}

const TrainStore = new Store();

export { TrainStore };
