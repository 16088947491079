// @ts-nocheck
import React from 'react';
import { PROPS, Text } from 'new-ui';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

const { TEXT: { TYPES: { NORMAL_12 } } } = PROPS;

const AirlineSearchMenuSuggestItem = ({ city, name, code }) => (
  <div className={ styles.suggest }>
    <div className={ styles.city }>
      <Text>{ city }</Text>
      <Text>{ code }</Text>
    </div>
    <Text type={ NORMAL_12 }>{ name }</Text>
  </div>
);

AirlineSearchMenuSuggestItem.propTypes = {
  city: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
};

export { AirlineSearchMenuSuggestItem };
