const QA_ATTRIBUTES = {
  smartdesk: {
    startSearch: 'start-search-text',
    searchPanel: 'smartdesk-search-panel',
    search: {
      air: 'smartdesk-search-air',
      train: 'smartdesk-search-train',
      hotel: 'smartdesk-search-hotel',
      taxi: 'smartdesk-search-taxi',
      aeroexpress: 'smartdesk-search-aeroexpress',
      transfer: 'smartdesk-search-transfer',
      vip: 'smartdesk-search-vip',
      conference: 'smartdesk-search-conference',
      group: 'smartdesk-search-group',
      event: 'smartdesk-search-event',
    },
    popup: {
      travelPolicy: {
        submitButton: 'search-travel-policy-popup-submit',
        cancelButton: 'search-travel-policy-popup-cancel',
        title: 'search-travel-policy-popup-title',
        text: 'search-travel-policy-popup-text',
        closeButton: 'search-travel-policy-popup-close',
      },
      addingOrder: {
        submitButton: 'adding-order-popup-submit',
        cancelButton: 'adding-order-popup-cancel',
        title: 'adding-order-popup-title',
        text: 'adding-order-popup-text',
        closeButton: 'adding-order-popup-close',
      },
      pprDiscount: {
        submitButton: 'ppr-discount-popup-submit',
        cancelButton: 'ppr-discount-popup-cancel',
        title: 'ppr-discount-popup-title',
        text: 'ppr-discount-popup-text',
        closeButton: 'ppr-discount-popup-close',
      },
      smartagent: {
        submitButton: 'smartagent-popup-submit',
        cancelButton: 'smartagent-popup-cancel',
        title: 'smartagent-popup-title',
        text: 'smartagent-popup-text',
        closeButton: 'smartagent-popup-close',
      },
    },
  },
  header: {
    logout: 'logout-icon',
    cart: 'header-cart',
    cartCounter: 'header-cart-counter',
    settings: 'header-settings',
    favorites: 'header-favorites',
    smartdesk: 'header-smartdesk',
    search: 'header-search',
    favoriteIcons: {
      default: 'header-favorite-icon-default',
      yellow: 'header-favorite-icon-yellow',
    },
    trips: {
      general: 'header-trips-general',
    },
    expenseReport: 'header-expense-report',
    note: 'header-note',
    noteCounter: 'header-note-counter',
    approval: {
      general: 'header-approval-general',
      trips: 'header-approval-trips',
      preTravel: 'header-approval-pre-travel',
      expenseReport: 'header-approval-expense-report',
    },
    balance: {
      available: 'header-balance-available',
    },
    application: {
      general: 'header-application-general',
      requests1c: 'header-application-requests-1c',
      preTravelRequest: 'header-application-pre-travel-request',
    },
  },
  sidePanel: {
    close: 'close-side-panel',
  },
  hotels: {
    search: {
      title: 'hotel-search-title',
      suggest: 'hotel-search-suggest',
      suggestLoading: 'hotel-search-suggest-loading',
      suggestResult: 'hotel-search-suggest-result',
      checkIn: 'hotel-search-check-in',
      checkInNextMonth: 'hotel-search-check-in-next-month',
      checkOut: 'hotel-search-check-out',
      checkOutNextMonth: 'hotel-search-check-out-next-month',
      searchButton: 'hotel-search-button',
      travelers: 'hotel-search-travelers',
      travelersCount: 'hotel-search-travelers-count',
      earlyIn: 'hotel-search-early-in',
      lateOut: 'hotel-search-late-out',
      youSearch: 'hotel-search-you-search',
      fromHistory: {
        wrapper: 'hotel-search-from-history-wrapper',
        item: {
          date: 'hotel-search-from-history-item-date',
          name: 'hotel-search-from-history-item-name',
        },
      },
      bestHotels: 'hotel-search-best-hotels',
      checkRooms: 'hotel-search-check-rooms',
      tag: 'hotel-search-tag',
    },
    result: {
      skeleton: 'hotels-result-skeleton',
      region: {
        list: 'hotels-result-region-list',
        buttonList: 'hotels-result-region-button-list',
      },
      search: {
        icon: 'hotel-result-search-icon',
        suggest: 'hotel-result-search-suggest',
        searchButton: 'hotel-result-search-button',
        guests: 'hotel-result-search-guests',
        placing: 'hotel-result-search-placing',
        checkIn: 'hotel-result-search-check-in',
        checkInNextMonth: 'hotel-result-search-check-in-next-month',
        checkOut: 'hotel-result-search-check-out',
        checkOutNextMonth: 'hotel-result-search-check-out-next-month',
        checkInDirection: 'hotel-result-search-check-in-direction',
        checkOutDirection: 'hotel-result-search-check-out-direction',
        checkInHours: 'hotel-result-search-check-in-hours',
        checkOutHours: 'hotel-result-search-check-out-hours',
        chooseRoom: 'hotel-result-search-choose-room',
      },
      filters: {
        distance: 'hotel-result-filters-distance',
        priceMin: 'hotel-result-filters-price-min',
        priceMax: 'hotel-result-filters-price-max',
        breakfast: 'hotel-result-filters-breakfast',
        refundability: 'hotel-result-filters-refundability',
        online: 'hotel-result-filters-online',
        recommended: 'hotel-result-filters-recommended',
        favorites: 'hotel-result-filters-favorites',
        input: 'hotel-result-filters-input',
        stars: 'hotel-result-filters-stars',
        rating: 'hotel-result-filters-rating',
        types: 'hotel-result-filters-types',
        travelPolicy: 'hotel-result-travel-policy',
      },
      suggest: 'hotel-result-suggest',
      hotelIcon: 'hotel-result-icon',
      title: 'hotel-result-title',
      stars: 'hotel-result-stars',
      favorite: 'hotel-result-favorite',
      price: 'hotel-result-price',
      daysAmount: 'hotel-result-days-amount',
      chooseRoom: 'hotel-result-choose-room',
      rating: 'hotel-result-rating',
      distance: 'hotel-result-distance',
      loading: 'hotel-result-loading',
      firstHotel: 'hotel-result-first-hotel',
      notificationFavorite: 'hotel-result-notification-favorite',
      notificationRemoveFavorite: 'hotel-result-notification-remove-favorite',
    },
    current: {
      title: 'hotel-current-title',
      stars: 'hotel-current-stars',
      reviews: 'hotel-current-reviews',
      favorites: 'hotel-current-favorites',
      rating: 'hotel-current-rating',
      more: 'hotel-current-more',
      importantInfoMore: 'hotel-current-important-info-more',
      showOnMap: 'hotel-current-show-map',
      closeReviews: 'hotel-current-close-reviews',
      reviewsTitle: 'hotel-current-reviews-title',
      map: 'hotel-current-map',
      closeMap: 'hotel-current-close-map',
      availableRooms: 'hotel-current-available_rooms',
      earlyIn: 'hotel-current-early-in',
      lateOut: 'hotel-current-late-out',
      online: 'hotel-current-online',
      breakfast: 'hotel-current-breakfast',
      hasCancellation: 'hotel-current-has-cancellation',
      room: {
        title: 'hotel-current-room-title',
        cancellation: 'hotel-current-room-cancellation',
        breakfast: 'hotel-current-room-breakfast',
        note: 'hotel-current-room-note',
        price: 'hotel-current-room-price',
        tariff: 'hotel-current-room-tariff',
        select: 'hotel-current-room-select',
        cart: 'hotel-current-room-cart',
        request: 'hotel-current-room-send-request',
        wrapper: 'hotel-current-room-wrapper',
        container: 'hotel-current-room-container',
        buttonCartNotification: 'search-button-cart-notification',
        notificationNote: 'hotel-current-room-notification-note',
      },
    },
    noResult: {
      notFoundHotels: 'hotel-no-result-not-found-hotels',
    },
    hotelSearchDialog: {
      title: 'hotel-form-title',
      description: 'hotel-form-description',
      suggest: 'hotel-form-suggest',
      checkIn: 'hotel-form-check-in',
      checkOut: 'hotel-form-check-out',
      guests: 'hotel-form-guests',
      radioButtonBreakfast: 'hotel-form-radio-button-breakfast',
      radioButtonWithoutBreakfast: 'hotel-form-radio-button-no-breakfast',
      radioButtonCancellation: 'hotel-form-radio-button-cancellation',
      radioButtonWithoutCancellation: 'hotel-form-radio-button-no-cancellation',
      employees: 'hotel-form-employees',
      comment: 'hotel-form-comment',
      sendRequest: 'hotel-form-send-request',
    },
  },
  vipHall: {
    result: {
      price: 'vip-hall-price',
      orderButton: 'vip-hall-order-button',
    },
  },
  settings: {
    title: 'settings-title',
    search: 'settings-search',
    companies: 'settings-companies',
    employees: 'settings-employees',
    addEmployee: 'settings-add-employee',
    menuItem: {
      item: 'settings-menu-item',
      itemTitle: 'settings-menu-item-title',
      sub: 'settings-menu-item-sub',
    },
    departments: {
      add: 'settings-departments-add',
      notification: 'settings-departments-notification',
      create: {
        name: 'settings-departments-create-name',
        leaderChoose: 'settings-departments-create-leader-choose',
        leaderInput: 'settings-departments-create-leader-input',
        leaderFirstElement: 'settings-departments-create-leader-first-element',
        addButton: 'settings-departments-create-add-button',
        employeeChoose: 'settings-departments-create-employee-choose',
        employeeInput: 'settings-departments-create-employee-input',
        employeeSelect: 'settings-departments-create-employee-select',
        employeeFirst: 'settings-departments-create-employee-first',
        saveDepartmentButton: 'settings-departments-create-save-department-button',
      },
      list: {
        firstItem: {
          item: 'settings-departments-list-first-item-item',
        },
      },
    },
    analytics: {
      costCenter: 'settings-analytics-cost-center',
      addAnalyticButton: 'settings-analytics-add-analytic-button',
      title: 'settings-analytics-title',
      centerCreate: {
        mustBuy: 'settings-analytics-center-create-must-buy',
        addButton: 'settings-analytics-center-create-add-button',
        addWindow: 'settings-analytics-center-create-add-window',
        inputName: 'settings-analytics-center-create-input-name',
        inputLeader: 'settings-analytics-center-create-input-leader',
        firstLeader: 'settings-analytics-center-create-first-leader',
        saveButton: 'settings-analytics-center-create-save-button',
      },
      analyticCreate: {
        inputName: 'settings-analytics-analytic-create-input-name',
        everyBuy: 'settings-analytics-analytic-create-every-buy',
        allowAdd: 'settings-analytics-analytic-create-allow-add',
        allTrip: 'settings-analytics-analytic-create-all-trip',
        addValue: 'settings-analytics-analytic-create-add-value',
        inputValue: 'settings-analytics-analytic-create-input-value',
        saveValue: 'settings-analytics-analytic-create-save-value',
        buttonSave: 'settings-analytics-analytic-create-button-save',
      },
    },
    travelPolicy: {
      warning: {
        header: 'setting-travel-policy-warning-header',
        dialog: 'setting-travel-policy-warning-dialog',
        addButton: 'setting-travel-policy-warning-add-button',
      },
      addRules: {
        air: 'setting-travel-policy-add-rules-air',
        train: 'setting-travel-policy-add-rules-train',
        hotel: 'setting-travel-policy-add-rules-hotel',
        taxi: 'setting-travel-policy-add-rules-taxi',
        transfer: 'setting-travel-policy-add-rules-transfer',
        viphall: 'setting-travel-policy-add-rules-vip-hall',
        checkbox: 'setting-travel-policy-add-rules-checkbox',
        rules: {
          onePriceRule: 'setting-travel-policy-add-rules-rules-one-prise-rule',
          input: 'setting-travel-policy-add-rules-rules-input',
          notGreaterPriceRule: 'setting-travel-policy-add-rules-rules-not-greater-price-rule',
          daysCountRule: 'setting-travel-policy-add-rules-rules-days-count-rule',
          classRule: 'setting-travel-policy-add-rules-rules-class-rule',
        },
      },
      titles: {
        air: 'setting-travel-policy-titles-air',
        train: 'setting-travel-policy-titles-train',
        hotel: 'setting-travel-policy-titles-hotel',
        taxi: 'setting-travel-policy-titles-taxi',
        transfer: 'setting-travel-policy-titles-transfer',
        viphall: 'setting-travel-policy-titles-vip-hall',
      },
      addPolicyButton: 'settings-travel-policy-add-policy-button',
      firstItem: 'settings-travel-policy-first-item',
      lastItem: 'settings-travel-policy-last-item',
      betweenItem: 'settings-travel-policy-between-item',
      item: {
        wrapper: 'settings-travel-policy-item-wrapper',
        title: 'settings-travel-policy-item-title',
        delete: 'settings-travel-policy-item-delete',
      },
      notification: 'settings-travel-policy-notification',
      createPage: {
        title: 'settings-travel-policy-create-page-title',
        inputName: 'settings-travel-policy-create-page-input-name',
        aviaButton: 'settings-travel-policy-create-page-avia-button',
        saveButton: 'settings-travel-policy-create-page-save-button',
      },
      uniqueTravelPolicy: {
        header: 'setting-unique-travel-policy-header',
        subText: 'setting-unique-travel-policy-sub-text',
        back: 'setting-unique-travel-policy-back',
        edit: 'setting-unique-travel-policy-edit',
        save: 'setting-unique-travel-policy-save',
      },
      employeeDialog: {
        dialog: 'setting-travel-policy-employeeDialog',
        selectEmployees: 'setting-travel-policy-employeeDialog-select-employees',
        selectAllEmployees: 'setting-travel-policy-employeeDialog-select-all-employees',
        cancel: 'setting-travel-policy-employeeDialog-cancel',
        save: 'setting-travel-policy-employeeDialog-save',
      },
    },
  },
  employee: {
    back: 'employee-back',
    submit: 'employee-submit',
    submitDisabled: 'employee-submit-disabled',
    cancel: 'employee-cancel',
    first: 'employee-first',
    notifications: {
      success: 'employee-notification-success',
      edited: 'employee-notifications-edited',
      archive: 'employee-notification-archive',
      archiveRestored: 'employee-notification-archive-restored',
      createPassword: 'employee-notification-create-password',
    },
    navigation: {
      general: 'employee-navigation-general',
      documents: 'employee-navigation-documents',
      access: 'employee-navigation-access',
      signature: 'employee-navigation-signature',
      bonus: 'employee-navigation-bonus',
    },
    toArchive: 'employee-link-to-archive',
    fromArchive: 'employee-link-from-archive',
    general: {
      general: 'employee-general',
      title: 'employee-general-title',
      surname: 'employee-general-surname',
      name: 'employee-general-name',
      patronymic: 'employee-general-patronymic',
      birthday: 'employee-general-birthday',
      citizenship: 'employee-general-citizenship',
      citizenshipFirstEl: 'employee-general-citizenship-first-el',
      man: 'employee-general-man',
      female: 'employee-general-female',
      prefix: 'employee-general-prefix',
      phone: 'employee-general-phone',
      email: 'employee-general-email',
      emailError: 'employee-general-email-error',
      department: 'employee-general-department',
      organization: 'employee-general-organization',
    },
    documents: {
      title: 'employee-documents-title',
      passport: 'employee-documents-passport',
      deletePassport: 'employee-documents-delete',
      addForeignPassport: 'employee-documents-add-foreign-passport',
      addDomesticPassport: 'employee-documents-add-domestic-passport',
      passportRu: {
        surname: 'employee-documents-passport-ru-surname',
        name: 'employee-documents-passport-ru-name',
        patronymic: 'employee-documents-passport-ru-patronymic',
        seriesAndNumber: 'employee-documents-passport-ru-series-and-number',
        seriesAndNumberError: 'employee-documents-passport-ru-series-and-number-error',
        showHideTranslited: 'employee-documents-passport-ru-show-hide-translited',
        showTranslitedFullName: 'employee-documents-passport-ru-translited-full-name',
        translited: {
          lastname: 'employee-documents-passport-ru-translited-lastname',
          firstname: 'employee-documents-passport-ru-translited-firstname',
          patronymic: 'employee-documents-passport-ru-translited-patronymic',
        },
      },
      birthCertificate: {
        surname: 'employee-documents-birth-certificate-surname',
        name: 'employee-documents-birth-certificate-name',
        patronymic: 'employee-documents-birth-certificate-patronymic',
        number: 'employee-documents-birth-certificate-number',
      },
      access: {
        title: 'employee-documents-access-title',
        titleTP: 'employee-documents-access-title-tp',
        suggestTP: 'employee-documents-access-suggest-tp',
        settingsOfAccessAndNotifications: 'employee-documents-access-settings-of-access-and-notifications',
        getAccess: 'employee-documents-get-access',
        edit: 'employee-documents-access-edit',
        userCan: 'employee-documents-access-user-can',
        userCant: 'employee-documents-access-user-cant',
        modals: {
          buttonNext: 'employee-documents-access-modals-button-next',
          main: {
            title: 'employee-documents-access-modals-main-title',
            selfBuy: 'employee-documents-access-modals-main-self-buy',
            forAnother: 'employee-documents-access-modals-main-for-another',
            approve: 'employee-documents-access-modals-main-approve',
            finance: 'employee-documents-access-modals-main-finance',
            approveExpenseReports: 'employee-documents-access-modals-main-approve-expense-reports',
            buttons: {
              cancel: 'employee-documents-access-modals-main-button-cancel',
            },
          },
          admin: {
            title: 'employee-documents-access-modals-admin-title',
            personalData: 'employee-documents-access-modals-admin-personal-data',
            giveAccess: 'employee-documents-access-modals-admin-give-access',
            anotherTrips: 'employee-documents-access-modals-admin-another-trips',
            viewMice: 'employee-documents-access-modals-admin-view-mice',
            viewExpenseReports: 'employee-documents-access-modals-admin-view-expense-reports',
            tp: 'employee-documents-access-modals-admin-tp',
            buttons: {
              cancel: 'employee-documents-access-modals-admin-button-cancel',
            },
          },
          edit: {
            radioButtons: {
              edit: 'employee-documents-access-modals-edit-radiobutton-next',
              close: 'employee-documents-access-modals-edit-radiobutton-close',
            },
            buttons: {
              cancel: 'employee-documents-access-modals-edit-button-cancel',
            },
          },
          create: {
            wrapper: 'employee-documents-access-modals-create-wrapper',
            radioButtons: {
              manually: 'employee-documents-access-modals-create-radiobutton-manually',
              copy: 'employee-documents-access-modals-create-radiobutton-copy',
            },
            selectEmployee: 'employee-documents-access-modals-create-select-employee',
            selectEmployeeInput: 'employee-documents-access-modals-create-select-employee-input',
            buttons: {
              cancel: 'employee-documents-access-modals-create-button-cancel',
            },
          },
          limit: {
            title: 'employee-documents-access-modals-limit-title',
            radioButtons: {
              unlimited: 'employee-documents-access-modals-limit-radiobutton-unlimited',
              buyAfterApprove: 'employee-documents-access-modals-limit-radiobutton-buy-after-approve',
              afterApprove: 'employee-documents-access-modals-limit-radiobutton-after-approve',
              tp: 'employee-documents-access-modals-limit-radiobutton-tp',
              approvalScheme: 'employee-documents-access-modals-limit-radiobutton-approval-scheme',
            },
            buttons: {
              cancel: 'employee-documents-access-modals-limit-button-cancel',
            },
          },
          createExpenseReports: {
            title: 'employee-documents-access-modals-create-expense-reports-title',
            radioButtons: {
              unlimited: 'employee-documents-access-modals-create-expense-reports-radiobutton-unlimited',
              createERAfterApprove: 'employee-documents-access-modals-create-expense-reports-radiobutton-create-after-approve',
              afterApprove: 'employee-documents-access-modals-create-expense-reports-radiobutton-after-approve',
              approvalERScheme: 'employee-documents-access-modals-create-expense-reports-radiobutton-approval-scheme',
            },
            buttons: {
              cancel: 'employee-documents-access-modals-limit-button-cancel',
            },
          },
          notification: {
            title: 'employee-documents-access-modals-notification-title',
            vouchers: 'employee-documents-access-modals-notification-vouchers',
            vouchersSelect: 'employee-documents-access-modals-notification-vouchers-select',
            invoices: 'employee-documents-access-modals-notification-invoices',
            invoicesSelect: 'employee-documents-access-modals-notification-invoices-select',
            closeDocuments: 'employee-documents-access-modals-notification-close-documents',
            buttons: {
              cancel: 'employee-documents-access-modals-notification-button-cancel',
            },
          },
          copy: {
            title: 'employee-documents-access-modals-copy-title',
            buttonDone: 'employee-documents-access-modals-copy-button-done',
          },
        },
      },
      additionally: {
        title: 'employee-documents-additionally-title',
        bonusCart: 'employee-documents-additionally-bonus-cart',
        employeeNumber: 'employee-documents-additionally-employee-number',
        tagsLink: 'employee-documents-additionally-tags-link',
        costCenterLink: 'employee-documents-additionally-cost-center-link',
        costCenterFirstEl: 'employee-documents-additionally-cost-center-first-el',
        fpkBonusCart: 'employee-documents-additionally-fpk-bonus-cart',
        bonusCartAirline: 'employee-documents-additionally-bonus-cart-airline',
        numberOfBonus: 'employee-documents-additionally-number-of-bonus',
        addCart: 'employee-documents-additionally-add-cart',
        deleteBonus: 'employee-documents-additionally-delete-bonus',
        projectSelect: 'employee-documents-additionally-project-select',
        newLink: {
          search: 'employee-documents-additionally-new-link-search',
          add: 'employee-documents-additionally-new-link-add',
        },
        newLinkModal: {
          title: 'employee-documents-additionally-new-link-modal-title',
          search: 'employee-documents-additionally-new-link-modal-search',
          create: 'employee-documents-additionally-new-link-modal-create',
          cancel: 'employee-documents-additionally-new-link-modal-cancel',
        },
      },
    },
  },
  approvalRequest: {
    titleNew: 'approval-request-title-new',
    city: {
      input: 'approval-request-city-input',
      suggestFirst: 'approval-request-city-suggest-first',
    },
    date: {
      from: 'approval-request-date-from',
      to: 'approval-request-date-to',
    },
    purposeSelect: 'approval-request-purpose-select',
    budget: 'approval-request-budget',
    comment: 'approval-request-comment',
    selectEmployee: {
      button: 'approval-request-select-employee-button',
      loading: 'approval-request-select-employee-loading',
      input: 'approval-request-select-employee-input',
      firstEl: 'approval-request-select-employee-first-el',
    },
    department: {
      choose: 'approval-request-department-choose',
    },
    company: {
      choose: 'approval-request-company-choose',
    },
    button: {
      enabled: 'approval-request-button-enabled',
      disabled: 'approval-request-button-disabled',
    },
    trips: {
      title: 'approval-request-trips-title',
      create: 'approval-request-trips-create',
      createNew: 'approval-request-trips-create-new',
    },
  },
  cart: {
    loading: 'cart-loading',
    empty: 'cart-empty',
    title: 'cart-title',
    addEmployee: 'cart-add-employee',
    selectEmployee: 'cart-select-employee',
    selectEmployeeLoading: 'cart-select-employee-loading',
    selectEmployeeLoadingSuggest: 'cart-select-employee-loading-suggest',
    inputEmployee: 'cart-input-employee',
    removeSelectedEmployee: 'cart-remove-selected-employee',
    employeeSuggestFirstEl: 'cart-employee-suggest-first-el',
    itemWrapper: 'cart-item-wrapper',
    itemsWrapper: 'cart-items-wrapper',
    travelPolicyReasonField: 'cart-travel-policy-reason-field',
    travelPolicyReasonSave: 'cart-travel-policy-reason-save',
    customAnalytics: {
      title: 'cart-custom-analytics-title',
      select: 'cart-custom-analytics-select',
      selectMandatory: 'cart-custom-analytics-select-mandatory',
      suggestFirstEl: 'cart-custom-analytics-first-el',
      add: 'cart-custom-analytics-add',
      delete: 'cart-custom-analytics-delete',
      loading: 'cart-custom-analytics-loading',
      dialog: {
        title: 'cart-custom-analytics-dialog-title',
        input: 'cart-custom-analytics-dialog-input',
        buttons: {
          save: 'cart-custom-analytics-dialog-button-save',
          cancel: 'cart-custom-analytics-dialog-button-cancel',
        },
      },
    },
    tags: 'cart-tags',
    price: 'cart-price',
    paymentButton: {
      enabled: 'cart-payment-button-enabled',
      disabled: 'cart-payment-button-disabled',
      loading: 'cart-payment-button-loading',
    },
    buttonTooltip: 'cart-button-tooltip',
    booking: {
      inProgress: 'cart-booking-in-progress',
      priceChangedOk: 'cart-booking-price-changed-ok',
      continueButton: 'cart-booking-continue-button',
    },
    addHotel: 'cart-add-hotel',
    paidSeats: {
      wrapper: 'cart-paid-seats-wrapper',
      price: 'cart-paid-seats-price',
      seat: 'cart-paid-seats-seat',
      title: 'cart-paid-seats-title',
      delete: 'cart-paid-seats-delete',
      employee: 'cart-paid-seats-employee',
      employeeLoading: 'cart-paid-seats-employee-loading',
      errors: {
        noForeignPassport: 'cart-paid-seats-no-passport',
        noDomesticPassport: 'cart-paid-seats-no-passport',
        foulForeignPassport: 'cart-paid-seats-foul-foreign-passport',
      },
    },
    duplicate: {
      wrapper: 'cart-duplicate-wrapper',
      header: 'cart-duplicate-header',
      close: 'cart-duplicate-close',
      date: 'cart-duplicate-date',
      info: 'cart-duplicate-info',
      travellers: 'cart-duplicate-travellers',
      buttons: {
        back: 'cart-duplicate-button-back',
        close: 'cart-duplicate-button-close',
      },
    },
    company: {
      text: 'cart-company-text',
      select: 'cart-company-select',
      selectLabel: 'cart-company-select-label',
      selectLoading: 'cart-company-select-loading',
      selected: 'cart-company-selected',
      choose: 'cart-company-choose',
    },
    project: {
      select: 'cart-project-select',
      selected: 'cart-project-selected',
      delete: 'cart-project-delete',
      loading: 'cart-project-loading',
      loadingDelete: 'cart-project-loading-delete',
      firstEl: 'cart-project-first-el',
      input: 'cart-project-input',
      mandatory: 'cart-project-mandatory',
    },
    department: {
      text: 'cart-department-text',
      select: 'cart-department-select',
      selectLabel: 'cart-department-select-label',
      selectLoading: 'cart-department-select-loading',
    },
    passport: {
      text: 'cart-passport-text',
      select: 'cart-passport-select',
    },
    editName: {
      name: 'cart-edit-name-title',
      input: 'cart-edit-name-input',
      success: 'cart-edit-name-success',
      close: 'cart-edit-name-close',
    },
    deleteAll: {
      title: 'cart-delete-all-title',
      modal: {
        wrapper: 'cart-delete-all-modal-wrapper',
        close: 'cart-delete-all-modal-close',
        success: 'cart-delete-all-modal-success',
        cancel: 'cart-delete-all-modal-cancel',
      },
    },
    aeroexpress: {
      icon: 'cart-aeroexpress-icon',
      date: 'cart-aeroexpress-date',
      note: 'cart-note',
      delete: 'cart-delete',
      price: 'cart-price-item',
    },
    transfer: {
      dialog: {
        title: 'cart-transfer-dialog-title',
        button: 'cart-transfer-dialog-button',
      },
      dateDeparture: 'cart-transfer-date-departure',
      dateCarDelivery: 'cart-transfer-date-car-delivery',
      timeDeparture: 'cart-transfer-time-departure',
      arrivalFlightNumber: 'cart-transfer-arrival-flight-number',
    },
    train: {
      note: 'cart-note',
      delete: 'cart-delete',
      price: 'cart-price-item',
      icon: 'cart-train-icon',
      from: 'cart-train-from',
      to: 'cart-train-to',
      dateFrom: 'cart-train-date-from',
      dateTo: 'cart-train-date-to',
      cancellation: 'cart-train-cancellation',
      number: 'cart-train-number',
    },
    hotel: {
      note: 'cart-note',
      delete: 'cart-delete',
      price: 'cart-price-item',
      icon: 'cart-note-icon',
      title: 'cart-hotel-title',
      date: 'cart-hotel-date',
      details: 'cart-hotel-details',
      detailsBreakfast: 'cart-hotel-details-breakfast',
      cancellation: 'cart-hotel-cancellation',
      noReturnTitle: 'cart-hotel-no-return-title',
      noReturnRight: 'cart-hotel-no-return-right',
      noReturnCorrect: 'cart-hotel-no-return-correct',
    },
    air: {
      note: 'cart-note',
      delete: 'cart-delete',
      price: 'cart-price-item',
      icon: 'cart-air-icon',
      certificateCheckbox: 'cart-air-certificate-checkbox',
      from: 'cart-air-from',
      to: 'cart-air-to',
      fromTerminal: 'cart-air-from-terminal',
      toTerminal: 'cart-air-to-terminal',
      timeFrom: 'cart-air-time-from',
      timeTo: 'cart-air-time-to',
      dateFrom: 'cart-air-date-from',
      dateTo: 'cart-air-date-to',
      chooseSeatWrapper: 'cart-air-choose-seat-wrapper',
      chooseSeat: 'cart-air-choose-seat',
      seats: {
        cancel: 'cart-air-seats-cancel',
        chooseSeat: 'cart-air-seats-choose-seat',
        flightInfo: 'cart-air-seats-flight-info',
        date: 'cart-air-seats-date',
        row: 'cart-air-seats-row',
        unselected: 'cart-air-seats-unselected',
        employeeWrapper: 'cart-air-seats-employee-wrapper',
        employee: 'cart-air-seats-employee',
        price: 'cart-air-seats-price',
        buttons: {
          chooseSeat: 'cart-air-seats-button-choose-seat',
          next: 'cart-air-seats-button-next',
        },
        notification: 'cart-air-seats-notification',
      },
    },
    checkout: {
      title: 'cart-checkout-title',
      back: 'cart-checkout-back',
      price: 'cart-checkout-price',
      itemPrice: 'cart-checkout-item-price',
      editName: {
        name: 'cart-checkout-edit-name-title',
        input: 'cart-checkout-edit-name-input',
        success: 'cart-checkout-edit-name-success',
        close: 'cart-checkout-edit-name-close',
      },
      finish: {
        price: 'cart-checkout-finish-price',
        acceptRules: 'cart-checkout-finish-accept-rules',
        link: 'cart-checkout-finish-accept-rules-link',
        buy: 'cart-checkout-finish-buy',
      },
      successPay: {
        title: 'cart-checkout-success-pay-title',
        addToCalendar: 'cart-checkout-success-pay-add-to-calendar',
        titleNeedConfirmation: 'cart-checkout-success-pay-title-need-confirmation',
        price: 'cart-checkout-success-pay-price',
        smartdesk: 'cart-checkout-success-pay-smartdesk',
        tripDetails: 'cart-checkout-success-pay-trip-details',
        air: {
          from: 'cart-checkout-success-pay-air-from',
          timeFrom: 'cart-checkout-success-pay-air-time-from',
          dateFrom: 'cart-checkout-success-pay-air-date-from',
          to: 'cart-checkout-success-pay-air-to',
          timeTo: 'cart-checkout-success-pay-air-time-to',
          dateTo: 'cart-checkout-success-pay-air-date-to',
        },
        hotel: {
          date: 'cart-checkout-success-pay-hotel-date',
        },
        additionalService: {
          seatTitle: 'cart-checkout-success-pay-additional-service-seat-title',
          seatNumber: 'cart-checkout-success-pay-additional-service-seat-number',
          price: 'cart-checkout-success-pay-additional-service',
        },
        flightCertificate: 'cart-checkout-success-pay-flight-certificate',
      },
    },
    approval: {
      scheme: {
        request: {
          warning: 'cart-approval-scheme-request-warning',
          createNew: 'cart-approval-scheme-request-create-new',
          item: 'cart-approval-scheme-request-item',
        },
        buttonApprove: 'cart-approval-scheme-button-approve',
        buttonDisabled: 'cart-approval-scheme-button-disabled',
        buttonLoading: 'cart-approval-scheme-button-loading',
        dialog: {
          approver: 'cart-approval-scheme-dialog-approver',
          approversAll: 'cart-approval-scheme-dialog-approvers-all',
          buttonApprove: 'cart-approval-scheme-dialog-button-approve',
          hintContent: 'cart-approval-scheme-dialog-hint-content',
          aprroverCheckbox: 'cart-approval-scheme-dialog-approver-checkbox',
        },
      },
      header: 'cart-approval-header',
      item: 'cart-approval-item',
      errorDialog: 'cart-approval-error-dialog',
      purchaseButton: 'cart-approval-purchase-button',
      cancel: {
        button: 'cart-approval-cancel-button',
        dialog: {
          header: 'cart-approval-cancel-dialog-header',
          button: 'cart-approval-cancel-dialog-button',
        },
      },
      dialog: {
        selectApprover: 'cart-approval-dialog-select-approver',
        approver: 'cart-approval-dialog-approver',
        approversList: 'cart-approval-dialog-approvers-list',
        buttonApprove: 'cart-approval-dialog-button-approve',
      },
    },
  },
  search: {
    airline: {
      menu: {
        icon: 'search-airline-menu-icon',
        searchButton: 'search-airline-menu-search-button',
        dates: {
          from: 'search-airline-menu-dates-from',
          to: 'search-airline-menu-dates-to',
        },
        cities: {
          from: 'search-airline-menu-cities-from',
          to: 'search-airline-menu-cities-to',
          swap: 'search-airline-menu-cities-swap',
        },
        passengers: 'search-airline-menu-passengers',
        flyClass: 'search-airline-menu-fly-class',
      },
      filters: {
        tp: 'search-airline-filters-tp',
        priceMin: 'search-airline-filters-price-min',
        priceMax: 'search-airline-filters-price-max',
        baggage: 'search-airline-filters-baggage',
        refundable: {
          withCharge: 'search-airline-filters-refundable-with-charge',
          withoutCharge: 'search-airline-filters-refundable-without-charge',
        },
        flightNumber: 'search-airline-filters-flight-number',
        directCount: 'search-airline-filters-direct-count',
        fewAirlineCompanies: 'search-filters-airline-few-airline-companies',
        airlineCompany: 'search-filters-airline-company',
      },
      sortSelect: 'search-filters-airline-sort-select',
      directCount: 'search-filters-airline-direct-count',
      baggage: 'search-filters-airline-baggage',
      refundable: 'search-filters-airline-refundable',
      items: 'search-airline-items',
      itemWrapper: 'search-airline-item',
      aircompanyIncludes: 'search-airline-aircompany-includes',
      item: {
        note: 'search-airline-item-note',
        toFavorite: 'search-airline-item-to-favorite',
        departureTime: 'search-airline-item-departure-time',
        departureDate: 'search-airline-item-departure-date',
        arrivalTime: 'search-airline-item-arrival-time',
        arrivalDate: 'search-airline-item-arrival-date',
        departureCity: 'search-airline-item-departure-city',
        arrivalCity: 'search-airline-item-arrival-city',
        name: 'search-airline-item-name',
        routeDuration: 'search-airline-item-route-duration',
        transfer: 'search-airline-item-transfer',
        showSegments: 'search-airline-item-show-segments',
        fareItems: 'search-airline-item-fare-items',
        notificationNote: 'search-airline-item-notification-note',
        notificationFavorite: 'search-airline-item-notification-favorite',
        notificationRemoveFavorite: 'search-airline-item-notification-remove-favorite',
        price: 'search-airline-item-price',
        travellersAmount: 'search-airline-item-travellers-amount',
        icon: 'search-airline-item-icon',
      },
      loadingText: 'search-airline-loading-text',
      buttonCartNotification: 'search-button-cart-notification',
      buttonCart: 'search-airline-button-cart',
      favorite: 'search-airline-favorite',
      text: 'search-airline-text',
      panel: {
        dates: {
          from: 'search-airline-panel-date-from',
          to: 'search-airline-panel-date-to',
        },
        cities: {
          from: 'search-airline-panel-city-from',
          to: 'search-airline-panel-city-to',
          swap: 'search-airline-panel-city-swap',
          loadingFrom: 'search-airline-panel-city-from-loading',
          loadingTo: 'search-airline-panel-city-to-loading',
        },
        deleteRoute: 'search-airline-delete-route',
        addRoute: 'search-airline-add-route',
        searchButton: 'search-airline-panel-search-button',
        passengers: 'search-airline-panel-passengers',
        flyClass: 'search-airline-panel-fly-class',
        direct: 'search-airline-panel-direct',
        complex: 'search-airline-panel-complex',
      },
      history: {
        text: 'search-airline-history-text',
        wrapper: 'search-airline-history-wrapper',
      },
      bestAirlines: 'search-airline-best-airlines',
      loadingAirlines: 'search-airline-loading-skeleton',
      noResult: 'search-airline-no-result',
      paginateNext: 'search-airline-paginate-next',
    },
    train: {
      items: 'search-train-items',
      item: {
        itemWrapper: 'search-train-item',
        buttonCart: 'search-train-item-button-cart',
        time: {
          from: 'search-train-item-time-from',
          to: 'search-train-item-time-to',
        },
        duration: 'search-train-item-time-duration',
        station: {
          from: 'search-train-item-station-from',
          to: 'search-train-item-station-to',
        },
        dates: {
          from: 'search-train-item-date-from',
          to: 'search-train-item-date-to',
        },
        price: 'search-train-item-price',
        transfer: 'search-train-item-transfer',
        favorite: 'search-train-item-favorite',
        number: 'search-train-item-number',
      },
      transfer: {
        panel: {
          wrapper: 'search-train-transfer-panel-wrapper',
          button: 'search-train-transfer-panel-button',
        },
        item: {
          first: 'search-train-transfer-item-first',
          buttonCart: 'search-train-transfer-item-button-cart',
          time: {
            from: 'search-train-transfer-item-time-from',
            to: 'search-train-transfer-item-time-to',
          },
          duration: 'search-train-transfer-item-time-duration',
          station: {
            from: 'search-train-transfer-item-station-from',
            to: 'search-train-transfer-item-station-to',
          },
          date: {
            from: 'search-train-transfer-item-date-from',
            to: 'search-train-transfer-item-date-to',
          },
          price: 'search-train-transfer-item-price',
          numberTransfers: 'search-train-transfer-item-number-transfers',
          favorite: 'search-train-transfer-item-favorite',
          number: 'search-train-transfer-item-number',
        },
      },
      text: 'search-train-text',
      sortSelect: 'search-train-sort-select',
      filters: {
        tp: 'search-train-filters-tp',
        priceMin: 'search-train-filters-price-min',
        priceMax: 'search-train-filters-price-max',
        number: 'search-train-filters-number',
        isSapsan: 'search-train-filters-sapasan',
        type: 'search-train-filters-type',
      },
      ticket: {
        favorite: 'search-train-ticket-favorite',
      },
      panel: {
        dates: {
          from: 'search-train-panel-date-from',
          to: 'search-train-panel-date-to',
        },
        cities: {
          from: 'search-train-panel-city-from',
          to: 'search-train-panel-city-to',
          swap: 'search-train-panel-city-swap',
          loadingFrom: 'search-train-panel-city-from-loading',
          loadingTo: 'search-train-panel-city-to-loading',
        },
        searchButton: 'search-train-panel-search-button',
        passengers: 'search-train-panel-passengers',
      },
      menu: {
        icon: 'search-train-menu-icon',
        searchButton: 'search-train-menu-search-button',
        dates: {
          from: 'search-train-menu-dates-from',
          to: 'search-train-menu-dates-to',
        },
        cities: {
          from: 'search-train-menu-cities-from',
          to: 'search-train-menu-cities-to',
          swap: 'search-train-menu-cities-swap',
        },
        passengers: 'search-train-menu-passengers',
      },
      history: {
        text: 'search-train-history-text',
        wrapper: 'search-train-history-wrapper',
        loading: 'search-train-history-loading',
      },
      loading: 'search-train-loading',
      bestTrain: 'search-train-best-trains',
      tags: {
        number: 'searh-train-tag-number',
        isSapsan: 'searh-train-tag-sapsan',
      },
      notification: {
        addFavorite: 'search-train-notification-favorite-add',
        removeFavorite: 'search-train-notification-remove-favorite',
      },
    },
    aeroexpress: {
      buttonCart: 'search-aeroexpress-button-cart',
      text: 'search-aeroexpress-text',
      panel: {
        dates: {
          from: 'search-aeroexpress-panel-date-from',
          to: 'search-aeroexpress-panel-date-to',
        },
        route: 'search-aeroexpress-route',
        passengers: 'search-aeroexpress-panel-passengers',
        class: 'search-aeroexpress-panel-class',
        searchButton: 'search-aeroexpress-panel-search-button',
        firstResult: 'search-aeroexpress-panel-first-result',
      },
      result: {
        title: 'search-aeroexpress-result-title',
        price: 'search-aeroexpress-result-price',
        count: 'search-aeroexpress-result-count',
        button: 'search-aeroexpress-result-button',
        tariff: 'search-aeroexpress-result-tariff',
        close: 'search-aeroexpress-result-close',
      },
      popup: {
        aeroexpress: {
          submitButton: 'search-aeroexpress-popup-submit',
          cancelButton: 'search-aeroexpress-popup-cancel',
          title: 'search-aeroexpress-popup-title',
          text: 'search-aeroexpress-popup-text',
          closeButton: 'search-aeroexpress-popup-close',
          notification: 'search-aeroexpress-popup-notification',
        },
      },
      buttonCartNotification: 'search-button-cart-notification',
      loading: 'search-aeroexpress-loading',
    },
    vip: {
      results: 'search-vip-results',
      goBack: 'search-vip-go-back',
      popup: {
        submitButton: 'search-vip-popup-submit',
        cancelButton: 'search-vip-popup-cancel',
        title: 'search-vip-popup-title',
        text: 'search-vip-popup-text',
        closeButton: 'search-vip-popup-close',
      },
    },
  },
  train: {
    result: {
      backLink: 'train-result-back-link',
      name: 'train-result-name',
      direction: 'train-result-direction',
      dates: {
        from: 'train-result-date-from',
        to: 'train-result-date-to',
      },
      item: {
        price: 'train-result-item-price',
        choosePlace: 'train-result-item-choose-place',
        seatDefault: 'train-result-item-seat-default',
      },
      notifications: {
        favorite: 'train-result-notification-favorite',
        note: 'train-result-notification-note',
      },
      favorite: 'train-result-favorite',
      loading: 'train-result-loading',
      empty: 'train-result-empty',
    },
    current: {
      name: 'train-current-name',
      favorite: 'train-current-favorite',
      direction: 'train-current-direction',
      dates: {
        from: 'train-current-date-from',
        to: 'train-current-date-to',
      },
      backLink: 'train-current-backlink',
      loadingBack: 'train-current-loading-back',
      note: 'train-current-note',
      buttonCart: 'train-current-button-cart',
      availablePlace: 'train-current-available-place',
      notifications: {
        favorite: 'train-current-notification-favorite',
        note: 'train-current-notification-note',
        buy: 'search-button-cart-notification',
      },
      chosenSeats: 'train-current-chosen-seats',
      price: 'train-current-price',
      savedTicket: {
        title: 'train-current-saved-ticket-title',
        chooseAnother: 'train-current-saved-ticket-choose-another',
        number: 'train-current-saved-ticket-number',
        place: 'train-current-saved-ticket-place',
        price: 'train-current-saved-ticket-price',
      },
      seatSelection: {
        input: 'train-current-seat-selection-input',
        firstFreeSeat: 'train-current-seat-selection-first-free-seat',
      },
    },
  },
  note: {
    notification: {
      moveToCart: 'note-notification-move-to-cart',
      delete: 'note-notification-delete',
    },
    empty: {
      text: 'note-empty-text',
      searchPanel: 'note-empty-search-panel',
    },
    header: 'note-header',
    filter: 'note-filter',
    search: 'note-search',
    airline: {
      from: 'note-airline-from',
      to: 'note-airline-to',
      name: 'note-airline-name',
      timer: 'note-airline-timer',
    },
    train: {
      from: 'note-train-from',
      to: 'note-train-to',
      name: 'note-train-name',
    },
    hotel: {
      name: 'note-hotel-name',
    },
    lockPrice: 'note-lock-price',
    booking: {
      title: 'note-booking-title',
      buttonBook: 'note-booking-button-book',
      employee: {
        select: 'note-booking-employee-select',
        input: 'note-booking-employee-input',
        firstEl: 'note-booking-employee-first-el',
        loadingEmployee: 'note-booking-employee-loading-employee',
        loadingSuggest: 'note-booking-employee-loading-suggest',
      },
      dialog: {
        successMessage: 'note-booking-dialog-success-message',
        buttonToNote: 'note-booking-dialog-button-to-note',
      },
    },
    deleteSelected: 'note-delete-selected',
    toCartSelected: 'note-to-cart-selected',
    sendToEmail: 'note-send-to-email',
    selectAll: 'note-select-all',
    item: {
      delete: 'note-item-delete',
      toCart: 'note-item-to-cart',
      checkbox: 'note-item-checkbox',
      price: 'note-item-price',
      loader: 'note-item-loader',
    },
    dialogSendToEmail: {
      title: 'note-dialog-send-to-email-title',
      input: 'note-dialog-send-to-email-input',
      sendButton: 'note-dialog-send-to-email-send-button',
      notification: 'note-dialog-send-to-email-notification',
    },
  },
  favorites: {
    notification: 'favorites-notification',
    empty: 'favorites-empty',
    searchPanel: 'favorites-search-panel',
    header: 'favorites-header',
    filter: 'favorites-filter',
    search: 'favorites-search',
    wrapIcon: 'favorites-wrap-icon',
    airline: {
      from: 'favorites-airline-from',
      to: 'favorites-airline-to',
      name: 'favorites-airline-name',
    },
    train: {
      from: 'favorites-train-from',
      to: 'favorites-train-to',
      name: 'favorites-train-name',
    },
    hotel: {
      name: 'favorites-hotel-name',
    },
    chooseDates: 'favorites-choose-dates',
    delete: 'favorites-delete',
    deleteDialog: {
      wrapper: 'favorites-delete-dialog-wrapper',
      close: 'favorites-delete-dialog-close',
      buttons: {
        confirm: 'favorites-delete-dialog-confirm',
        close: 'favorites-delete-dialog-close',
      },
    },
    chooseDateDialog: {
      checkInNextMonth: 'favorites-choose-date-dialog-check-in-next-month',
      air: {
        wrapper: 'favorites-choose-date-dialog-wrapper',
        header: 'favorites-choose-date-dialog-header',
        date: 'favorites-choose-date-dialog-date',
        passengers: 'favorites-choose-date-dialog-passengers',
        buttons: {
          startSearch: 'favorites-choose-date-dialog-buttons-start-search',
          cancel: 'favorites-choose-date-dialog-buttons-cancel',
        },
      },
      train: {
        wrapper: 'favorites-choose-date-dialog-wrapper',
        header: 'favorites-choose-date-dialog-header',
        date: 'favorites-choose-date-dialog-date',
        passengers: 'favorites-choose-date-dialog-passengers',
        buttons: {
          startSearch: 'favorites-choose-date-dialog-buttons-start-search',
          cancel: 'favorites-choose-date-dialog-buttons-cancel',
        },
      },
      hotel: {
        wrapper: 'favorites-choose-date-dialog-wrapper',
        header: 'favorites-choose-date-dialog-header',
        dateFrom: 'favorites-choose-date-dialog-date-from',
        dateTo: 'favorites-choose-date-dialog-date-to',
        guests: 'favorites-choose-date-dialog-guests',
        placing: 'favorites-choose-date-dialog-train-placing',
        buttons: {
          startSearch: 'favorites-choose-date-dialog-buttons-start-search',
          cancel: 'favorites-choose-date-dialog-buttons-cancel',
        },
      },
    },
  },
  changeTrip: {
    air: {
      select: {
        choiceType: 'change-trip-air-select-choice-type',
        nextStage: 'change-trip-air-select-next-stage',
        backButton: 'change-trip-air-select-back-button',
        title: 'change-trip-air-select-title',
        loading: 'change-trip-air-select-loading',
      },
      headerInfoFlight: {
        ticketNumber: 'change-trip-air-header-info-ticket-number',
        personData: 'change-trip-air-header-info-person-data',
        ticketData: 'change-trip-air-header-info-ticket-data',
      },
      formOther: {
        title: 'change-trip-air-form-other-comment-content',
        commentContent: 'change-trip-air-form-other-comment-content',
        sendRequest: 'change-trip-air-form-other-send-request-chat',
        backButton: 'change-trip-air-select-back-button',
      },
      searchMenu: {
        searchButton: 'change-trip-air-search-button',
        addRoute: 'change-trip-air-search-add-route',
        routeDate: {
          activeCheckbox: 'change-trip-air-search-date-route-checkbox',
          info: 'change-trip-air-search-date-route-checkbox',
          dateFrom: 'change-trip-air-search-date-route-date-from',
        },
        panelDirection: {
          dates: {
            from: 'change-trip-air-search-panel-direction-date-from',
            to: 'change-trip-air-search-panel-direction-date-to',
          },
          cities: {
            from: 'change-trip-air-search-panel-direction-city-from',
            to: 'change-trip-air-search-panel-direction-city-to',
            swap: 'change-trip-air-search-panel-direction-city-swap',
          },
          deleteRoute: 'search-airline-delete-route',
          passengers: 'change-trip-air-search-panel-direction-passengers',
          flyClass: 'change-trip-air-search-panel-direction-fly-class',
          direct: 'change-trip-air-search-panel-direction-direct',
          complex: 'change-trip-air-search-panel-direction-complex',
        },
      },
      byDateDirectionSearch: {
        pageLoading: 'change-trip-air-by-date-direction-search-loading-page',
        searchLoading: 'change-trip-air-by-date-direction-search-loading-search',
      },
      byDateDirectionResult: {
        pageLoading: 'change-trip-air-by-date-direction-result-loading-page',
        searchLoading: 'change-trip-air-by-date-direction-result-loading-search',
        header: {
          infoTicket: 'change-trip-air-by-date-direction-result-header-info-ticket',
          buttonChangeTicket: 'change-trip-air-by-date-direction-result-header-button-change-ticket',
        },
        notFound: 'change-trip-air-by-date-direction-result-not-found',
        notFoundParamsSearch: 'change-trip-air-by-date-direction-result-not-found-params-search',
        notFoundParamsFilter: 'change-trip-air-by-date-direction-result-not-found-params-filter',
        resetParamsFilter: 'change-trip-air-by-date-direction-result-reset-params-filter',
        itemWrapper: 'change-trip-air-by-date-direction-result-item',
        item: {
          note: 'change-trip-air-by-date-direction-result-item-note',
          toFavorite: 'change-trip-air-by-date-direction-result-item-to-favorite',
          departureTime: 'change-trip-air-by-date-direction-result-item-departure-time',
          departureDate: 'change-trip-air-by-date-direction-result-item-departure-date',
          arrivalTime: 'change-trip-air-by-date-direction-result-item-arrival-time',
          arrivalDate: 'change-trip-air-by-date-direction-result-item-arrival-date',
          departureCity: 'change-trip-air-by-date-direction-result-item-departure-city',
          arrivalCity: 'change-trip-air-by-date-direction-result-item-arrival-city',
          name: 'change-trip-air-by-date-direction-result-item-name',
          routeDuration: 'change-trip-air-by-date-direction-result-item-route-duration',
          transfer: 'change-trip-air-by-date-direction-result-item-transfer',
          showSegments: 'change-trip-air-by-date-direction-result-item-show-segments',
          fareItems: 'change-trip-air-by-date-direction-result-item-fare-items',
          notificationNote: 'change-trip-air-by-date-direction-result-item-notification-note',
          notificationFavorite: 'change-trip-air-by-date-direction-result-item-notification-favorite',
          price: 'change-trip-air-by-date-direction-result-item-price',
          travellersAmount: 'change-trip-air-by-date-direction-result-item-travellers-amount',
          icon: 'change-trip-air-by-date-direction-result-item-icon',
        },
        sortSelect: 'change-trip-air-by-date-direction-result-sort-select',
        filters: {
          tp: 'change-trip-air-by-date-direction-result-filters-tp',
          priceMin: 'change-trip-air-by-date-direction-result-filters-price-min',
          priceMax: 'change-trip-air-by-date-direction-result-filters-price-max',
          baggage: 'change-trip-air-by-date-direction-result-filters-baggage',
          refundable: {
            withCharge: 'change-trip-air-by-date-direction-result-filters-refundable-with-charge',
            withoutCharge: 'change-trip-air-by-date-direction-result-filters-refundable-without-charge',
          },
          flightNumber: 'change-trip-air-by-date-direction-result-filters-flight-number',
          directCount: 'change-trip-air-by-date-direction-result-filters-direct-count',
          fewAirlineCompanies: 'change-trip-air-by-date-direction-result-airline-few-airline-companies',
          airlineCompany: 'change-trip-air-by-date-direction-result-airline-company',
        },
        openOtherRequest: 'change-trip-air-by-date-direction-result-other-request',
        dialogOtherRequest: 'change-trip-air-by-date-direction-result-dialog-other-request',
        approve: {
          title: 'change-trip-air-by-date-direction-result-approve-title',
          sendRequest: 'change-trip-air-by-date-direction-result-approve-send-request-chat',
          back: 'change-trip-air-by-date-direction-result-approve-back',
          icon: 'change-trip-air-by-date-direction-result-approve-icon',
          oldTicket: {
            title: 'change-trip-air-by-date-direction-result-approve-old-ticket-title',
            icon: 'change-trip-air-by-date-direction-result-approve-old-ticket-icon',
            showFare: 'change-trip-air-by-date-direction-result-approve-old-ticket-show-fare',
            data: {
              from: 'change-trip-air-by-date-direction-result-approve-old-ticket-from',
              timeFrom: 'change-trip-air-by-date-direction-result-approve-old-ticket-time-from',
              dateFrom: 'change-trip-air-by-date-direction-result-approve-old-ticket-date-from',
              to: 'change-trip-air-by-date-direction-result-approve-old-ticket-to',
              timeTo: 'change-trip-air-by-date-direction-result-approve-old-ticket-time-to',
              dateTo: 'change-trip-air-by-date-direction-result-approve-old-ticket-date-to',
            },
          },
          newTicket: {
            title: 'change-trip-air-by-date-direction-result-approve-new-ticket-title',
            icon: 'change-trip-air-by-date-direction-result-approve-new-ticket-icon',
            showFare: 'change-trip-air-by-date-direction-result-approve-new-ticket-show-fare',
            data: {
              from: 'change-trip-air-by-date-direction-result-approve-new-ticket-from',
              timeFrom: 'change-trip-air-by-date-direction-result-approve-new-ticket-time-from',
              dateFrom: 'change-trip-air-by-date-direction-result-approve-new-ticket-date-from',
              to: 'change-trip-air-by-date-direction-result-approve-new-ticket-to',
              timeTo: 'change-trip-air-by-date-direction-result-approve-new-ticket-time-to',
              dateTo: 'change-trip-air-by-date-direction-result-approve-new-ticket-date-to',
            },
          },
        },
      },
      byOther: {
        loading: 'change-trip-air-by-other-loading',
      },
      byPassport: {
        checkbox: 'change-trip-air-by-passport-checkbox',
        nextButton: 'change-trip-air-by-passport-nextButton',
        selectionText: 'change-trip-air-by-passport-nextButton',
        loading: 'change-trip-air-by-passport-loading',
        warning: 'change-trip-air-by-passport-warning',
        sendRequest: 'change-trip-air-by-passport-send-request-chat',
        back: 'change-trip-air-by-passport-back',
        notifications: {
          success: 'change-trip-air-by-passport-notifications-success',
          error: 'change-trip-air-by-passport-notifications-error',
        },
        editMode: 'change-trip-air-by-passport-edit-mode',
        approvalEdit: 'change-trip-air-by-passport-approval -edit',
        cancelEdit: 'change-trip-air-by-passport-cancel-edit',
        saveEdit: 'change-trip-air-by-passport-save-edit',
      },
    },
  },
  expenseReport: {
    title: 'expense-report-title',
    buttonCreate: 'expense-report-button-create',
    dialogTitle: 'expense-report-dialog',
    inputEmployee: 'expense-report-input-employee',
    selectEmployee: 'expense-report-select-employee',
    emptyTrips: 'expense-report-empty-trips',
    buttonDialogCreate: 'expense-report-button-dialog-create',
    create: {
      notification: 'expense-report-create-notification',
      prepayments: 'expense-report-create-prepayments',
    },
    firstItem: 'expense-report-first-item',
    item: {
      createReport: 'expense-report-title-create',
      editReport: 'expense-report-title-edit',
      download: {
        pdf: 'expense-report-button-download-pdf',
        xlsx: 'expense-report-button-download-xlsx',
      },
      sendDialog: {
        title: 'expense-report-send-to-mail-dialog-title',
        close: 'expense-report-send-to-mail-dialog-close',
        buttonSend: 'expense-report-send-to-mail-dialog-button-send',
        buttonClose: 'expense-report-send-to-mail-dialog-button-close',
        email: 'expense-report-send-to-mail-dialog-add-comment',
      },
      buttons: {
        sendToEmail: 'expense-report-send-to-email',
        back: 'expense-report-back',
        delete: 'expense-report-delete-report',
        save: 'expense-report-save',
        create: 'expense-report-create',
        sendToApprove: 'expense-report-send-to-approve',
        cancel: 'expense-report-cancel',
        downloadArchive: 'expense-report-download-archive',
      },
      prepayment: {
        close: 'expense-report-prepayment-dialog-close',
        title: 'expense-report-prepayment-dialog-title',
        sum: 'expense-report-prepayment-dialog-add-sum',
        radio: {
          cash: 'expense-report-prepayment-dialog-add-radio-cash',
          card: 'expense-report-prepayment-dialog-add-radio-card',
        },
        save: 'expense-report-prepayment-dialog-save',
        cancel: 'expense-report-prepayment-dialog-cancel',
      },
      expense: {
        close: 'expense-report-expense-dialog-close',
        title: 'expense-report-expense-dialog-title',
        name: 'expense-report-expense-dialog-name',
        type: {
          select: 'expense-report-expense-dialog-select-type',
          selectLabel: 'expense-report-expense-dialog-select-type-label',
          selectLoading: 'expense-report-expense-dialog-select-type-loading',
        },
        sum: 'expense-report-expense-dialog-add-sum',
        vatRates: {
          select: 'expense-report-expense-dialog-select-vat-rates',
          selectLabel: 'expense-report-expense-dialog-select-vat-rates-label',
          selectLoading: 'expense-report-expense-dialog-select-vat-rates-loading',
        },
        numberDoc: 'expense-report-expense-dialog-add-number-doc',
        dateDoc: {
          date: 'expense-report-expense-dialog-doc-date',
          top: 'expense-report-expense-dialog-doc-date-top',
          nextMonth: 'expense-report-expense-dialog-doc-date-next-month',
        },
        save: 'expense-report-expense-dialog-save',
        cancel: 'expense-report-expense-dialog-cancel',
      },
      document: {
        close: 'expense-report-document-dialog-close',
        title: 'expense-report-document-dialog-title',
      },
      anotherExpense: 'expense-report-another-expense',
      price: 'expense-report-another-price',
      deleteDialog: {
        title: 'expense-report-delete-dialog-title',
        close: 'expense-report-delete-dialog-close',
        delete: 'expense-report-delete-dialog-delete',
      },
      cancelSendDialog: {
        title: 'expense-report-cancel-send-dialog-title',
        close: 'expense-report-cancel-send-dialog-close',
        cancel: 'expense-report-cancel-send-dialog-cancel',
        return: 'expense-report-cancel-send-dialog-return',
      },
    },
    approver: {
      comment: 'approval-expense-report-dialog-comment',
      approve: 'approval-expense-report-dialog-approve',
      decline: 'approval-expense-report-dialog-decline',
      close: 'approval-expense-report-dialog-close',
    },
    sendToApproveDialogs: {
      scheme: {
        title: 'expense-report-send-to-approve-scheme-dialog-title',
        close: 'expense-report-send-to-approve-scheme-dialog-close',
        add: 'expense-report-send-to-approve-scheme-dialog-add-approver',
        comment: 'expense-report-send-to-approve-scheme-dialog-add-comment',
        send: 'expense-report-send-to-approve-scheme-dialog-button-send',
      },
      usual: {
        title: 'expense-report-send-to-approve-dialog-title',
        close: 'expense-report-send-to-approve-dialog-close',
        comment: 'expense-report-send-to-approve--dialog-add-comment',
        add: 'expense-report-send-to-approve-dialog-add-approver',
        send: 'expense-report-send-to-approve-dialog-button-send',
      },
    },
  },
  approvalSchemes: {
    expenseReport: {
      addSchema: 'settings-approval-scheme-expense-report',
      scheme: 'approval-scheme-expense-report-first',
      buttons: {
        edit: 'approval-scheme-expense-report-button-edit',
        remove: 'approval-scheme-expense-report-button-remove',
      },
      dialog: {
        buttons: {
          remove: 'approval-scheme-expense-report-dialog-button-remove',
          cancel: 'approval-scheme-expense-report-dialog-button-cancel',
        },
      },
      title: 'approval-scheme-expense-report-title',
      subTitle: 'approval-scheme-expense-report-sub-title',
      back: 'approval-scheme-expense-report-back',
      howWorks: 'approval-scheme-expense-report-how-works',
      schemeName: 'approval-scheme-expense-report-name',
      addStep: 'approval-scheme-expense-report-add-step',
    },
    trips: {
      buttonAdd: 'approval-schemes-trips-button-add',
      questionnaire: {
        wrapper: 'approval-schemes-trips-questionnaire-wrapper',
        whoBuys: 'approval-schemes-trips-questionnaire-who-buys',
        travelApproval: 'approval-schemes-trips-questionnaire-travel-approval',
        buttonContinue: 'approval-schemes-trips-questionnaire-button-continue',
      },
      inputSchemeName: 'approval-schemes-trips-input-scheme-name',
      buttonSave: 'approval-schemes-trips-button-save',
      role: 'approval-schemes-trips-role',
      typeDisruptedTp: 'approval-schemes-trips-type-disrupted-tp',
      moveToStep: 'approval-schemes-trips-move-to-step',
      addStep: 'approval-schemes-trips-add-step',
      error: {
        save: 'approval-schemes-trips-error-save',
        edit: 'approval-schemes-trips-error-edit',
      },
      item: {
        last: 'approval-schemes-trips-item-last',
        title: 'approval-schemes-trips-item-title',
        edit: 'approval-schemes-trips-item-edit',
        remove: 'approval-schemes-trips-item-remove',
      },
      preTravel: {
        moveToStep: 'approval-schemes-trips-pre-travel-move-to-step',
      },
    },
  },
  taxi: {
    title: 'taxi-title',
    voucher: {
      dates: {
        dateFrom: 'taxi-voucher-dates-date-from',
        dateTo: 'taxi-voucher-dates-date-to',
      },
      actions: {
        addToCart: 'taxi-voucher-actions-add-to-cart',
      },
    },
    notification: 'taxi-notification',
  },
  transfer: {
    title: 'transfer-title',
    search: {
      from: {
        suggest: 'transfer-search-from-suggest',
        input: 'transfer-search-from-input',
      },
      to: {
        suggest: 'transfer-search-to-suggest',
        input: 'transfer-search-to-input',
      },
      button: 'transfer-search-button',
    },
    result: {
      loading: 'transfer-result-loading',
      item: {
        first: 'transfer-result-item-first',
        toCart: 'transfer-result-item-to-cart',
        notification: 'transfer-result-item-notification',
      },
    },
  },
  trips: {
    title: 'trips-title',
    empty: 'trips-empty',
    firstItem: 'trips-first-item',
    trip: {
      header: 'trips-trip-header',
      addToTrip: 'trips-trip-add-to-trip',
      addToTripDialog: {
        wrapper: 'trips-trip-add-to-trip-dialog-wrapper',
        searchAirline: 'trips-trip-add-to-trip-dialog-search-airline',
        searchTrain: 'trips-trip-add-to-trip-dialog-search-train',
        searchHotel: 'trips-trip-add-to-trip-dialog-search-hotel',
        searchTaxi: 'trips-trip-add-to-trip-dialog-search-taxi',
        searchAeroexpress: 'trips-trip-add-to-trip-dialog-search-aeroexpress',
        searchTransfer: 'trips-trip-add-to-trip-dialog-search-transfer',
        searchVip: 'trips-trip-add-to-trip-dialog-search-vip',
        searchConference: 'trips-trip-add-to-trip-dialog-search-conference',
        searchEvent: 'trips-trip-add-to-trip-dialog-search-event',
        searchGroup: 'trips-trip-add-to-trip-dialog-search-group',
      },
      item: 'trips-trip-item',
      returnTicket: {
        dropdown: 'trips-trip-return-ticket-dropdown',
        button: 'trips-trip-return-ticket-button',
      },
      cancelOrder: 'trips-trip-cancel-order',
      cancelDialog: {
        wrapper: 'trips-trip-cancel-dialog-wrapper',
        checkboxAgree: 'trips-trip-cancel-dialog-checkbox-agree',
        penalty: 'trips-trip-cancel-dialog-penalty',
        freeCancellation: 'trips-trip-cancel-dialog-free-cancellation',
        button: {
          sendRequest: 'trips-trip-cancel-dialog-button-send-request',
          returnTicket: 'trips-trip-cancel-dialog-button-return-ticket',
          confirmReturn: 'trips-trip-cancel-dialog-button-confirm-return',
          disabled: 'trips-trip-cancel-dialog-button-disabled',
        },
      },
      status: {
        booked: 'trips-trip-status-booked',
        cancelled: 'trips-trip-status-cancelled',
        awaitingTicketing: 'trips-trip-status-awaiting-ticketing',
      },
      price: 'trips-trip-price',
    },
  },
  approve: {
    headerTrips: 'approve-header-trips',
    emptyTrips: 'approve-empty-trips',
    firstItem: 'approve-first-item',
    cart: {
      header: 'approve-cart-header',
      sender: {
        name: 'approve-cart-sender-name',
        email: 'approve-cart-sender-email',
      },
      buttonApprove: 'approve-cart-button-approve',
      dialog: {
        window: 'approve-cart-dialog-window',
        buttonConfirm: 'approve-cart-dialog-button-confirm',
      },
      notification: 'approve-cart-notification',
      buttonPurchase: 'approve-cart-button-purchase',
    },
  },
};

export { QA_ATTRIBUTES };
