// @ts-nocheck
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LinkButton, Select, PROPS, Text } from 'new-ui';

import { getText } from '../../../../../../i18n';

import { SERVICETYPE } from '../../../../../bi/constants/serviceType';
import { formatDate, momentObject } from '../../../../../bi/utils/formatDate';
import { isSmartAgent } from '../../../../../bi/utils/env';

import { SearchSubMenu } from '../../../SearchSubMenu';
import { AirlineSearchRoute } from '../AirlineSearchRoute';

import styles from './styles.module.css';

const LABELS = {
  ADD_FLIGHT: getText('components:menu.air.main.addFlight'),
  SEARCH: getText('common:search'),
  EDIT: getText('common:edit'),
  TRAVELLERS: getText('components:menu.air.main.passengers'),
  CLASS: getText('components:menu.air.main.class'),
  DIRECT: getText('components:menu.air.main.direct'),
  COMPLEX: getText('components:menu.air.main.complex'),
};

class AirlineSubMenu extends Component {
  static propTypes = {
    airlineService: PropTypes.object.isRequired,
    requestsService: PropTypes.object.isRequired,
    handleChangeAdult: PropTypes.func.isRequired,
    handleChangeFlightClass: PropTypes.func.isRequired,
    handleReturnToSearch: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    qaAttrs: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.object.isRequired,
    ]),
  };

  static defaultProps = {
    qaAttrs: null,
  };

  state = {
    travellersOptions: this.props.airlineService.travellersOptions,
    classOptions: this.props.airlineService.classOptions,
    ...this.props.airlineService.getSearch(),
  };

  componentDidMount() {
    const { airlineService, requestsService } = this.props;
    const { request, requestItem } = requestsService.get();

    if (request && requestItem && requestItem?.SearchOptions?.Routes) {
      const { SearchOptions: { Routes } } = requestItem;

      const routes = this.state.routes.map((route, index) => {
        const { DepartureAirport, ArrivalAirport, DepartureDate } = Routes[index];

        return ({
          ...route,
          from: { ...route.from, label: `${DepartureAirport.City} (${DepartureAirport.Code})` },
          to: { ...route.from, label: `${ArrivalAirport.City} (${ArrivalAirport.Code})` },
          date: momentObject(DepartureDate),
        });
      });

      airlineService.searchSetRoutes(routes);

      this.setState({
        ...this.state,
        routes,
      });
    }

    this.unsubscribeFn = airlineService.subscribeSearch(this.updateState);
  }

  componentWillUnmount() {
    this.unsubscribeFn();
  }

  updateState = (state) => {
    this.setState({
      ...state,
    });
  };

  renderComplex = () => {
    const {
      routes,
      isValid,
    } = this.state;

    const { onSearch, handleReturnToSearch } = this.props;

    const routesHtml = routes.map((route, ind) => {
      const { from, to, date } = route;

      return (
        <div key={ `airline_readonly_${ind}` } className={ styles.route }>
          <Text color={ PROPS.TEXT.COLORS.WHITE } type={ PROPS.TEXT.TYPES.NORMAL_14 }>{ `${from.label}` } &rarr; { `${to.label}` }</Text>
          <Text
            type={ PROPS.TEXT.TYPES.NORMAL_12 }
            color={ PROPS.TEXT.COLORS.BLUE }
            className={ styles.text }
          >
            { `${formatDate(date)}` }
          </Text>
        </div>
      );
    });

    return (
      <SearchSubMenu
        serviceType={ SERVICETYPE.AIR }
        disabled={ !isValid }
        buttonLabel={ LABELS.SEARCH }
        searchButton={ false }
        onClick={ onSearch }
      >
        <div className={ styles.submenu }>
          <div className={ styles.readonly }>
            { routesHtml }
          </div>
          <div className={ styles.action }>
            <LinkButton
              theme={ PROPS.LINK_BUTTON.THEMES.LARGE_WHITE }
              onClick={ handleReturnToSearch }
            >
              { LABELS.EDIT }
            </LinkButton>
          </div>
        </div>
      </SearchSubMenu>
    );
  };

  render() {
    const {
      travellersOptions,
      classOptions,
      routes,
      travellers,
      flightClass,
      isComplex,
      isValid,
    } = this.state;
    const {
      airlineService,
      handleChangeAdult,
      handleChangeFlightClass,
    } = this.props;

    if (isComplex) return this.renderComplex();

    const { onSearch, qaAttrs } = this.props;

    const routesHtml = routes.map((route, ind) => (
      <AirlineSearchRoute
        key={ `airline__row__${ind}` }
        ind={ ind }
        theme={ PROPS.SUGGEST.THEME.DARK }
        route={ route }
        isComplex={ isComplex }
        airlineService={ airlineService }
        qaAttrs={ qaAttrs }
      />
    ));

    return (
      <SearchSubMenu
        serviceType={ SERVICETYPE.AIR }
        disabled={ !isValid }
        buttonLabel={ LABELS.SEARCH }
        onClick={ onSearch }
        qaAttrIcon={ qaAttrs?.icon || '' }
        qaAttrSearchButton={ qaAttrs?.searchButton || '' }
      >
        <div className={ styles.submenu }>
          { routesHtml }
          <div className={ `${styles.select} ${styles.passengers}` }>
            <Select
              withLabel
              className={ styles.wrapper }
              placeholder={ LABELS.TRAVELLERS }
              theme={ PROPS.SELECT.THEMES.DARK }
              value={ travellers }
              items={ travellersOptions }
              onChange={ handleChangeAdult }
              alternativeDesign={ isSmartAgent }
              qaAttr={ qaAttrs?.passengers || '' }
            />
          </div>
          <div className={ styles.select }>
            <Select
              withLabel
              className={ styles.wrapper }
              placeholder={ LABELS.CLASS }
              theme={ PROPS.SELECT.THEMES.DARK }
              value={ flightClass }
              items={ classOptions }
              onChange={ handleChangeFlightClass }
              alternativeDesign={ isSmartAgent }
              qaAttrLabel={ qaAttrs?.flyClass || '' }
            />
          </div>
        </div>
      </SearchSubMenu>
    );
  }
}

export { AirlineSubMenu };
