// @ts-nocheck
import PropTypes from 'prop-types';
import React from 'react';

class GoogleAnalytic extends React.Component {
  static propTypes = {
    workspaceService: PropTypes.object.isRequired,
  };

  constructor(props) {
    super();

    const data = props.workspaceService.get();

    this.initializeGoogleAnalytic(data);
  }

  initializeGoogleAnalytic = (data) => {
    const companyId = data.CompanyId.toString();

    if (typeof (window.ga) !== 'undefined') {
      window.ga('set', 'userId', companyId);
      window.ga('send', 'pageview');
    }
  };

  render() {
    return null;
  }
}

export default GoogleAnalytic;
