// @ts-nocheck
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Dialog, Input, Dropdown, Text, Button, PROPS } from 'new-ui';

import { getText } from '../../../i18n';

import FORMATS from '../../bi/constants/formats';
import { DOTREGEXP, FLOATREGEXP, THREEDIGITS } from '../../bi/constants/regExp';

import styles from './styles/index.module.css';

const DELAY = 250;

const LABELS = {
  sumInvoice: getText('components:downloadDepositDialog.sumInvoice'),
  sum: getText('components:downloadDepositDialog.sum'),
  invoicePdf: getText('components:downloadDepositDialog.invoicePdf'),
  invoiceXlsx: getText('components:downloadDepositDialog.invoiceXlsx'),
  undo: getText('common:undo'),
};

class DownloadDepositDialog extends Component {
  static propTypes = {
    onDownloadDeposit: PropTypes.func.isRequired,
    defaultValue: PropTypes.string,
    autoSelect: PropTypes.bool,
    onClose: PropTypes.func,
    show: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    defaultValue: '',
    autoSelect: false,
    onClose: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      value: props.defaultValue || '',
    };

    this.inputRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const unEqual = prevProps.show !== this.props.show;

    if (this.inputRef && unEqual) {
      setTimeout(() => {
        this.inputRef.current.focus();
      }, DELAY);
    }
  }

  handleChangeInput = (value) => {
    const valueWithoutSpaces = value.split(' ').join('').toString();

    if (!(FLOATREGEXP.test(valueWithoutSpaces) || value === '')) return;

    const valueWithComma = valueWithoutSpaces.replace(DOTREGEXP, ',');
    const valueWithSpaces = valueWithComma.replace(THREEDIGITS, '$1 ');
    this.setState({ value: valueWithSpaces });
  };

  render() {
    const { onDownloadDeposit, autoSelect, onClose, show } = this.props;
    const { value } = this.state;

    return (
      <Dialog
        show={ show }
        showClosing
        onChange={ onClose }
      >
        <div className={ styles.wrapper }>
          <Text type={ PROPS.TEXT.TYPES.BOLD_20 } className={ styles.title }>
            { LABELS.sumInvoice }
          </Text>
          <Input
            value={ value }
            placeholder={ LABELS.sum }
            onChange={ this.handleChangeInput }
            autoSelect={ autoSelect }
            ref={ this.inputRef }
          />
          <div className={ styles.download }>
            <Dropdown
              onClick={ () => onDownloadDeposit(value, FORMATS.PDF) }
              theme={ PROPS.DROPDOWN.THEME.PRIMARY }
              renderLabel={ () => (
                <Text
                  className={ styles.dropdown_title }
                  type={ PROPS.TEXT.TYPES.NORMAL_14 }
                >
                  { LABELS.invoicePdf }
                </Text>
              ) }
            >
              <div className={ styles.content }>
                <Button
                  type={ PROPS.BUTTON.TYPES.TEXTUAL }
                  onClick={ () => onDownloadDeposit(value, FORMATS.XLSX) }
                  className={ styles.btn }
                >
                  { LABELS.invoiceXlsx }
                </Button>
              </div>
            </Dropdown>
            <Button
              className={ styles.cancel }
              type={ PROPS.BUTTON.TYPES.TEXTUAL }
              onClick={ onClose }
            >
              { LABELS.undo }
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}

export { DownloadDepositDialog };
