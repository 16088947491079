// @ts-nocheck
import { observable, action, makeObservable } from 'mobx';

export interface ITravelPoliciesListStore {
  list: any[],
  loading: boolean,
  showDialog: boolean,
}

class Store {
  constructor() {
    makeObservable(this);
  }

  @observable list = [];
  @observable loading = false;
  @observable showDialog = false;

  @action
  setLoading = (value) => {
    this.loading = value;
  };

  @action
  setList = (list) => {
    const preparedList = list.map(policy => {
      const {
        AirlineRule: originalAirlineRule,
        HotelRule: originalHotelRule,
        TrainRule: originalTrainRule,
        TaxiVoucherRule: originalTaxiVoucherRule,
        TransferVoucherRule: originalTransferVoucherRule,
        VipHallRule: originalVipHallRule,
      } = policy;

      const [TaxiVoucherRule] = [
        originalTaxiVoucherRule,
      ].map(({ DayOff = false, prohibitBook = false, Class, ...rest }) => ({
        DayOff: {
          IsEnabled: DayOff,
          ProhibitIsEnabled: prohibitBook,
        },
        prohibitBook: {
          IsEnabled: prohibitBook,
        },
        Class: {
          IsEnabled: Class.IsEnabled,
          Value: Class.Value,
          ProhibitIsEnabled: prohibitBook,
        },
        ...rest,
      }));

      const [TransferVoucherRule] = [
        originalTransferVoucherRule,
      ].map(({ DayOff = false, ...rest }) => ({
        DayOff: {
          IsEnabled: DayOff,
        },
        ...rest,
      }));

      const [VipHallRule] = [
        originalVipHallRule,
      ].map(({ AllowBook = false, ...rest }) => ({
        AllowBook: {
          IsEnabled: AllowBook,
        },
        ...rest,
      }));

      const [AirlineRule, HotelRule, TrainRule] = [
        originalAirlineRule,
        originalHotelRule,
        originalTrainRule,
      ].map(({ DayOff = false, Exceptions, ...rest }) => ({
        DayOff: {
          IsEnabled: DayOff,
        },
        Exceptions: Exceptions.map(({ DayOff: dayOff = false, ...restFields }) => ({
          DayOff: {
            IsEnabled: dayOff,
          },
          ...restFields,
        })),
        ...rest,
      }));

      return {
        ...policy,
        AirlineRule,
        HotelRule,
        TrainRule,
        TaxiVoucherRule,
        TransferVoucherRule,
        VipHallRule,
      };
    });

    this.list = preparedList;

    return preparedList;
  };

  @action
  setDialog = (value) => {
    this.showDialog = value;
  };
}

const TravelPoliciesListStore = new Store();

export { TravelPoliciesListStore };
