import { observable, action, makeObservable } from 'mobx';

import type { Trips, CalendarTrips, INotepadReservedBE } from './types';

class Store {
  constructor() {
    makeObservable(this);
  }

  @observable trips: Trips = [];
  @observable tripsInCalendar: CalendarTrips = [];
  @observable loading = true;
  @observable isSearchSmartdesk = false;
  @observable notepad = {
    air: 0,
    hotel: 0,
  };
  @observable isNotepad = false;
  @observable isTrips = false;

  @action
  setLoading = (value: boolean): void => {
    this.loading = value;
  };

  @action
  setTrips = (trips: Trips): void => {
    this.trips = trips;
  };

  @action
  setTripsInCalendar = (trips: CalendarTrips): void => {
    this.tripsInCalendar = trips;
  };

  @action
  setSearchSmartdesk = (value: boolean): void => {
    this.isSearchSmartdesk = value;
  };

  @action
  getReservationNotepad = ({ Air, Hotel }: INotepadReservedBE) => {
    this.notepad.air = Air;
    this.notepad.hotel = Hotel;
  };

  @action
  setIsNotepad = (value: boolean) => {
    this.isNotepad = value;
  };

  @action
  setIsTrips = (value: boolean) => {
    this.isTrips = value;
  };
}

const SmartdeskStore = new Store();

export { SmartdeskStore, Store as SmartdeskStoreType };
