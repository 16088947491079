// @ts-nocheck
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import {
  Text,
  Tooltip,
  LinkButton,
  Checkbox,
  Button,
  Input,
  Select,
  Icon,
  PROPS,
} from 'new-ui';

import { getText } from '../../../i18n';

import ROUTES from '../../bi/constants/routes';
import {
  THREEDIGITS,
  ONLY_VALID_NUMBERS,
} from '../../bi/constants/regExp';

import { checkCloudPaymentsInstance, calculationAmount } from '../../bi/utils/cloudPayments';
import MoneyFormat from '../../bi/utils/money';
import { isSmartAgent } from '../../bi/utils/env';

import { ExternalLink } from '../ExternalLink';

import styles from './styles/index.module.css';

const DEFAULTPRICE = '20 000';

const LABELS = {
  faq: getText('components:cloudPayments.approveForm.faq'),
  apply: getText('components:cloudPayments.approveForm.apply'),
  inn: getText('components:cloudPayments.approveForm.inn'),
  yourEmail: getText('components:cloudPayments.approveForm.youEmail'),
  receipt: getText('components:cloudPayments.approveForm.receipt'),
  conditionOffer: getText('components:cloudPayments.approveForm.conditionOffer'),
  offer: getText('components:cloudPayments.approveForm.offer'),
  warning: getText('components:cloudPayments.approveForm.warning'),
  warningFull: getText('components:cloudPayments.approveForm.warningFull'),
  pay: getText('components:cloudPayments.pay'),
  amount: getText('components:cloudPayments.amount'),
  selectAmount: getText('components:cloudPayments.selectAmount'),
  amountOnAccount: getText('components:cloudPayments.amountOnAccount'),
  transactionFee: getText('components:cloudPayments.transactionFee'),
  totalAmount: getText('components:cloudPayments.totalAmount'),
  selectCompany: getText('components:cloudPayments.selectCompany'),
  selectedCompany: getText('components:cloudPayments.selectedCompany'),
  prev: getText('components:cloudPayments.prev'),
  undo: getText('common:undo'),
  cartPay: getText('components:cloudPayments.label'),
  cartPayUnavailable: {
    first: getText('components:cloudPayments.notAvailable.first'),
    second: getText('components:cloudPayments.notAvailable.second'),
  },
  descriptions: {
    one: getText('components:cloudPayments.approveForm.description.one'),
    two: getText('components:cloudPayments.approveForm.description.two'),
    twoSmartAgent: getText('components:cloudPayments.approveForm.description.twoSmartAgent'),
    three: getText('components:cloudPayments.approveForm.description.three'),
    four: getText('components:cloudPayments.approveForm.description.four'),
  },
  error: getText('components:cloudPayments.errorInput'),
};

const {
  SELECT: { THEMES: { DEFAULT_LARGE } },
  BUTTON: { TYPES: { PRIMARY, TEXTUAL_MEDIUM } },
  TEXT: {
    TYPES: {
      SEMIBOLD_14,
      SEMIBOLD_18,
      NORMAL_12,
      NORMAL_14_130,
      NORMAL_16,
      NORMAL_16_140,
      BOLD_20,
    },
    COLORS: {
      WHITE,
    },
  },
  ICON: {
    TYPES: {
      QUESTION,
    },
  },
} = PROPS;

const MIN_THRESHOLD = 250;
const DELAY = 250;
const THOUSANDS_SEPARATOR = '$1 ';
const ZERO = '0';

// companyName, companyEmail, currentCompany & companyInn should be merged into one single property - company
class CloudPaymentsDialog extends Component {
  static propTypes = {
    onCloseDialog: PropTypes.func.isRequired,
    onChangeCompaniesSelect: PropTypes.func.isRequired,
    onChangeApproveForm: PropTypes.func.isRequired,
    companyEmail: PropTypes.string.isRequired,
    companyName: PropTypes.string.isRequired,
    companyInn: PropTypes.string.isRequired,
    currentCompany: PropTypes.number.isRequired,
    companiesItems: PropTypes.array.isRequired,
    paymentService: PropTypes.object.isRequired,
    showCompaniesSelect: PropTypes.bool,
    showApproveForm: PropTypes.bool.isRequired,
    showPayment: PropTypes.bool.isRequired,
    allCompanies: PropTypes.array.isRequired,
    autoFocusInput: PropTypes.bool,
  };

  static defaultProps = {
    autoFocusInput: false,
  };

  constructor(props) {
    super(props);
    const { currentCompany } = props;

    this.state = {
      value: DEFAULTPRICE,
      selectedCompany: currentCompany,
    };

    this.inputRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const unEqual = prevProps.autoFocusInput !== this.props.autoFocusInput;

    if (this.inputRef && this.inputRef.current && unEqual) {
      setTimeout(() => {
        this.inputRef.current.focus();
        this.inputRef.current.select();
      }, DELAY);
    }
  }

  isChargeTransactionFee = () => {
    const { allCompanies } = this.props;

    return allCompanies.length > 1
      ? allCompanies.find(companies => companies.CompanyId === this.state.selectedCompany).DebitingOfCommission || false
      : allCompanies[0].DebitingOfCommission;
  };

  handleChangeInput = (value) => {
    const valueWithoutSpaces = value.split(' ').join('').toString();

    if (!(ONLY_VALID_NUMBERS.test(valueWithoutSpaces) || value === '')) return;

    const valueWithSpaces = valueWithoutSpaces.replace(THREEDIGITS, THOUSANDS_SEPARATOR);
    this.setState({ value: valueWithSpaces });
  };

  handleOpenCloudPaymentsWidget = () => {
    const {
      paymentService,
      onCloseDialog,
      companyEmail,
      companyName,
      companyInn,
      allCompanies,
    } = this.props;
    const { selectedCompany, value } = this.state;

    const preparedCompanyName = allCompanies.length ? allCompanies.find(({ CompanyId }) => CompanyId === selectedCompany).CompanyName : companyName;
    const preparedCompanyInn = allCompanies.length ? allCompanies.find(({ CompanyId }) => CompanyId === selectedCompany).CompanyInn : companyInn;

    onCloseDialog();

    const {
      amountOnAccount,
      transactionFee,
      totalAmount,
    } = calculationAmount(value, this.isChargeTransactionFee());
    const amount = this.isChargeTransactionFee() ? totalAmount : amountOnAccount;
    const transactionPercent = this.isChargeTransactionFee() ? transactionFee : 0;

    paymentService.startCloudPayments(
      amount,
      transactionPercent,
      amountOnAccount,
      this.isChargeTransactionFee(),
      companyEmail,
      preparedCompanyName,
      preparedCompanyInn,
      selectedCompany,
    );
  };

  handleChangeCompany = (v) => this.setState({ selectedCompany: v });

  renderCompaniesSelect = () => {
    const {
      onCloseDialog,
      companiesItems,
      showCompaniesSelect,
      onChangeCompaniesSelect,
    } = this.props;

    return (showCompaniesSelect && companiesItems.length > 1) ? (
      <div className={ styles.companies }>
        <Text className={ styles.label }>
          { LABELS.selectCompany }
        </Text>
        <Select
          items={ companiesItems }
          value={ this.state.selectedCompany }
          onChange={ this.handleChangeCompany }
          excludeValue
          theme={ DEFAULT_LARGE }
        />
        <div className={ styles.actions }>
          <Button
            theme={ PRIMARY }
            onClick={ onChangeCompaniesSelect }
          >
            { LABELS.pay }
          </Button>
          <Button
            className={ styles.cancel }
            type={ TEXTUAL_MEDIUM }
            onClick={ onCloseDialog }
          >
            { LABELS.undo }
          </Button>
        </div>
      </div>
    ) : null;
  };

  renderForLowerFace = () => (isSmartAgent ? null : (
    <li>
      <Text type={ NORMAL_16_140 }>
        { LABELS.descriptions.one }
      </Text>
    </li>
  ));

  renderPayEmployeeDiscription = () => (isSmartAgent ? null : (
    <li>
      <Text type={ NORMAL_16_140 }>
        { LABELS.descriptions.three }
      </Text>
    </li>
  ));

  renderOfferLink = () => (isSmartAgent ? (
    <ExternalLink
      link={ ROUTES.OFFER_SMARTAGENT }
      text={ LABELS.offer }
    />
  ) : (
    <ExternalLink
      link={ ROUTES.OFFER }
      text={ LABELS.offer }
    />
  ));

  renderButtonBack = () => {
    const {
      allCompanies,
      onCloseDialog,
      onChangeCompaniesSelect,
    } = this.props;

    return (allCompanies.length > 1
      ? (
        <Button
          className={ styles.cancel }
          type={ TEXTUAL_MEDIUM }
          onClick={ onChangeCompaniesSelect }
        >
          { LABELS.prev }
        </Button>
      )
      : (
        <Button
          className={ styles.cancel }
          type={ TEXTUAL_MEDIUM }
          onClick={ onCloseDialog }
        >
          { LABELS.undo }
        </Button>
      )
    );
  };

  renderTransactionFeeText = () => {
    if (isSmartAgent) {
      return (
        <Text type={ NORMAL_16_140 }>
          { LABELS.descriptions.twoSmartAgent }
        </Text>
      );
    }

    return (
      <Text type={ NORMAL_16_140 }>
        { LABELS.descriptions.two }
      </Text>
    );
  };

  renderApproveForm = () => {
    const {
      companyEmail,
      showApproveForm,
      showPayment,
      onChangeApproveForm,
    } = this.props;

    if (!showApproveForm) return null;

    const itemsCommission = this.isChargeTransactionFee() && (
      <>
        <li>
          { this.renderTransactionFeeText() }
        </li>
        <li>
          <Text className={ styles.inline } type={ NORMAL_16_140 }>
            { LABELS.conditionOffer }
          </Text>
          { ' ' }
          <Text className={ styles.inline } type={ NORMAL_16 }>
            { this.renderOfferLink() }
          </Text>
          .
        </li>
      </>
    );

    const checkTypeAccountForButtonBack = isSmartAgent ? null : this.renderButtonBack();

    const infoHtml = (
      <div
        className={ styles.open }
      >
        <div className={ styles['info-wrapper'] }>
          <Text type={ SEMIBOLD_18 }>
            { LABELS.faq }
          </Text>
          <ol className={ styles['info-list'] }>
            { this.renderForLowerFace() }
            { itemsCommission }
            { this.renderPayEmployeeDiscription()}
            <li>
              <Text type={ NORMAL_16_140 }>
                { LABELS.descriptions.four }
              </Text>
            </li>
            <li>
              <Text className={ styles.inline } type={ NORMAL_16_140 }>
                { LABELS.receipt }
              </Text>
              { ' ' }
              <Tooltip
                className={ styles['inline-block'] }
                renderContent={ () => (
                  <Text
                    type={ SEMIBOLD_14 }
                    className={ styles['email-tooltip'] }
                  >
                    { companyEmail }
                  </Text>
                ) }
              >
                <LinkButton onClick={ () => {} }>
                  <Text type={ NORMAL_16 }>
                    { LABELS.yourEmail }
                  </Text>
                </LinkButton>
                .
              </Tooltip>
              { ' ' }
              <Text className={ styles.inline } type={ NORMAL_16_140 }>
                { LABELS.inn }
              </Text>
            </li>
          </ol>
          <div className={ styles.checkbox }>
            <Checkbox
              value={ showPayment }
              onChange={ onChangeApproveForm }
            >
              { LABELS.apply }
            </Checkbox>
          </div>
          <div className={ styles.actions }>
            { checkTypeAccountForButtonBack }
          </div>
        </div>
      </div>
    );

    return (
      <div className={ styles['approve-form'] }>
        { showApproveForm && infoHtml }
      </div>
    );
  };

  renderWarningTooltip = () => {
    const { value } = this.state;
    const { percent } = calculationAmount(value, this.isChargeTransactionFee());

    const tooltipClass = percent < MIN_THRESHOLD ? styles.wrapper_tooltip : styles.wrapper_tooltip_none;

    if (isSmartAgent) {
      return null;
    }

    const renderTooltipContent = () => (
      <div className={ styles.tooltip }>
        <Text
          className={ styles.tooltip_content }
          type={ NORMAL_14_130 }
          color={ WHITE }
        >
          { LABELS.warningFull }
        </Text>
      </div>
    );

    return (
      <div className={ tooltipClass }>
        <Text
          className={ styles.text }
          type={ NORMAL_12 }
        >
          {LABELS.warning}
        </Text>
        <Tooltip
          className={ styles.tooltip }
          renderContent={ () => renderTooltipContent() }
        >
          <Icon type={ QUESTION } />
        </Tooltip>
      </div>
    );
  };

  renderAmount = () => {
    const {
      amountOnAccount,
      transactionFee,
      totalAmount,
    } = calculationAmount(this.state.value, this.isChargeTransactionFee());

    const amountOnAccountFormat = MoneyFormat.moneyWithDecimal(amountOnAccount, true);
    const transactionFeeFormat = MoneyFormat.moneyWithDecimal(transactionFee, true);
    const totalAmountFormat = MoneyFormat.moneyWithDecimal(totalAmount, true);

    return this.isChargeTransactionFee() && (
      <div className={ styles.companies }>
        <Text className={ styles.label } type={ NORMAL_16 }>
          { LABELS.amountOnAccount }: { amountOnAccountFormat }
        </Text>
        <Text className={ styles.label } type={ NORMAL_16 }>
          { LABELS.transactionFee }: { transactionFeeFormat }
          { this.renderWarningTooltip() }
        </Text>
        <Text className={ styles.label } type={ SEMIBOLD_18 }>
          { LABELS.totalAmount }: { totalAmountFormat }
        </Text>
      </div>
    );
  };

  renderCheckSelectCompany = () => {
    const {
      allCompanies,
    } = this.props;

    const { selectedCompany } = this.state;

    const searchCompany = allCompanies.find(companies => companies.CompanyId === selectedCompany) || '';

    const companyName = allCompanies.length > 1
      ? searchCompany.ShortCompanyName || searchCompany.CompanyName
      : allCompanies[0].ShortCompanyName || allCompanies[0].CompanyName;

    return (
      <div className={ styles.companies }>
        <Text className={ styles.label }>
          {`${LABELS.selectedCompany}:`}
        </Text>
        <Text type={ SEMIBOLD_18 }>
          { companyName }
        </Text>
      </div>
    );
  };

  renderSelectCompany = () => (isSmartAgent ? null : this.renderCheckSelectCompany());

  renderPaymentForm = () => {
    const {
      showPayment,
      onChangeApproveForm,
    } = this.props;
    const { value } = this.state;
    const isStartsWithZero = value.startsWith(ZERO);
    const error = isStartsWithZero ? LABELS.error : '';
    const isButtonDisabled = isStartsWithZero || !value;

    if (!showPayment) return null;

    return (
      <div
        className={ styles.open }
      >
        <div className={ styles['amount-form'] }>
          <Text className={ styles.label }>
            { LABELS.selectAmount }
          </Text>
          <Input
            value={ value }
            placeholder={ LABELS.amount }
            onChange={ this.handleChangeInput }
            ref={ this.inputRef }
            className={ styles.label }
            error={ error }
          />
          {this.renderAmount() }
          {this.renderSelectCompany()}
          <div className={ styles.actions }>
            <Button
              disabled={ isButtonDisabled }
              theme={ PROPS.BUTTON.TYPES.PRIMARY }
              onClick={ this.handleOpenCloudPaymentsWidget }
            >
              { LABELS.pay }
            </Button>
            <Button
              className={ styles.cancel }
              type={ PROPS.BUTTON.TYPES.TEXTUAL_MEDIUM }
              onClick={ onChangeApproveForm }
            >
              { LABELS.prev }
            </Button>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { showPayment, showCompaniesSelect } = this.props;
    const wrapperClassNames = [styles.wrapper];
    const renderCompanies = isSmartAgent ? null : this.renderCompaniesSelect();

    if (showCompaniesSelect || showPayment) {
      wrapperClassNames.push(styles.payment);
    }

    return (
      <div className={ wrapperClassNames.join(' ') }>
        {
          checkCloudPaymentsInstance() ? (
            <>
              <Text type={ BOLD_20 }>
                { LABELS.cartPay }
              </Text>
              { renderCompanies }
              { this.renderApproveForm() }
              { this.renderPaymentForm() }
            </>
          ) : (
            <>
              <Text type={ NORMAL_16_140 }>
                { LABELS.cartPayUnavailable.first }
              </Text>
              <Text type={ NORMAL_16_140 }>
                { LABELS.cartPayUnavailable.second }
              </Text>
            </>
          )
        }
      </div>
    );
  }
}

export { CloudPaymentsDialog };
