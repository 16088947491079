import React from 'react';

import { Dialog, Button, Text } from 'new-ui';

import { getText } from '../../../i18n';

import {
  getFlightRoute,
  getDatesTrip,
  getEmployeeList,
} from '../../bi/utils/airline';

import { ISourcesEmployees } from '../../bi/services/checkout/types';
import { RouteInterface } from '../../bi/utils/changeAirTrip/types';

import styles from './index.module.css';

const LABELS = {
  TRIP_INFO: (
    route: string,
    dates: string,
    name: string,
    pnr: string,
  ) => getText('note:dialog.tripInfo', { route, dates, name, pnr }),
  REMOVE_INFO: getText('note:dialog.removeInfo'),
  ON_REMOVE: getText('note:dialog.onRemove'),
  ON_CLOSE: getText('note:dialog.onClose'),
  DIALOG_TITLE: getText('note:dialog.dialogTitle'),
};

interface IItem {
  id: number;
  PNR: string,
  routes: RouteInterface[],
  allowedEmployees: ISourcesEmployees[]
}

interface IRemoveItemDialog {
  items: IItem[],
  showRemoveItemDialog: boolean,
  onClose: () => void,
  onDelete: () => void,
}

const RemoveItemDialog: React.FC<IRemoveItemDialog> = ({
  items,
  showRemoveItemDialog,
  onClose,
  onDelete,
}) => {
  const renderItems = () => items.map((item) => (
    <Text key={ item.id } className={ styles.text }>
      { LABELS.TRIP_INFO(
        getFlightRoute(item.routes),
        getDatesTrip(item.routes),
        getEmployeeList(item.allowedEmployees),
        item.PNR,
      )}
    </Text>
  ));

  return (
    <Dialog
      show={ showRemoveItemDialog }
      onChange={ onClose }
      showClosing
    >
      <div className={ styles['dialog-content'] }>
        <Text
          type='bold_20_130'
          className={ styles.title }
        >
          { LABELS.DIALOG_TITLE }
        </Text>
        <div className={ styles['item-info'] }>
          { renderItems() }
        </div>
        <Text className={ styles.text }>{ LABELS.REMOVE_INFO }</Text>

        <div className={ styles.actions }>
          <Button
            type='primary'
            onClick={ onDelete }
          >
            { LABELS.ON_REMOVE }
          </Button>
          <Button
            className={ styles.cancel }
            type='textual-medium'
            onClick={ onClose }
          >
            { LABELS.ON_CLOSE }
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export { RemoveItemDialog };
