// @ts-nocheck
import PropTypes from 'prop-types';
import React from 'react';
import { Text, PROPS } from 'new-ui';
import { formatRangeDateWithSimplicity, textualMonthPatternWithoutZero } from '../../bi/utils/formatDate';
import { getText } from '../../../i18n';

import styles from './styles/index.module.css';

const LABELS = {
  FLIGHT_CERTIFICATE_NUMBER: getText('components:flightCertificateItem.number'),
  FLIGHT_CERTIFICATE_TITLE: getText('components:flightCertificateItem.title'),
};

const FlightCertificateItem = ({ meta: { FlightData: { Airlines, CheckinDate, CheckoutDate, Direction, Tickets } }, qaAttr }) => {
  const airlines = Airlines.join(', ');
  const number = `${LABELS.FLIGHT_CERTIFICATE_NUMBER}${Tickets}`;
  const date = formatRangeDateWithSimplicity(CheckinDate, CheckoutDate, { monthPattern: textualMonthPatternWithoutZero, withoutSpaceBetweenDates: true });

  return (
    <div className={ styles.wrap } data-qa={ qaAttr }>
      <div>
        <Text type={ PROPS.TEXT.TYPES.SEMIBOLD_16 } className={ styles.reference_texet }>{ LABELS.FLIGHT_CERTIFICATE_TITLE }</Text>
        <Text type={ PROPS.TEXT.TYPES.NORMAL_14 }>{ `${number}, ${airlines}, ${Direction}, ${date}`}</Text>
      </div>
    </div>
  );
};

export default FlightCertificateItem;

FlightCertificateItem.propTypes = {
  meta: PropTypes.object.isRequired,
  qaAttr: PropTypes.string,
};
