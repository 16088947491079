// @ts-nocheck
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Datepicker, Checkbox, Text, PROPS } from 'new-ui';

import { getText } from '../../../../../i18n';

import { currentDate } from '../../../../bi/utils/formatDate';

import { FIELDS_CHANGE } from '../../../../bi/constants/changeAirTrip';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import styles from './styles/index.module.css';

const {
  DATEPICKER: {
    INPUT_THEMES: { OPEN },
  },
  TEXT: {
    COLORS: { GRAY },
  },
} = PROPS;

const LABELS = {
  DEPARTURE_DATE: getText('components:changeAirTrip.departureDate'),
};

const {
  changeTrip: {
    air: {
      searchMenu: {
        routeDate: {
          activeCheckbox,
          info,
          dateFrom,
        },
      },
    },
  },
} = QA_ATTRIBUTES;

const SearchDateRoute = ({
  airlineService,
  index,
  route,
  visibleCheckbox,
  defaultRoute,
}) => {
  const firstSegment = defaultRoute.Segments[0];
  const lastSegment = defaultRoute.Segments[defaultRoute.Segments.length - 1];

  const [disabled, setDisabled] = useState(!visibleCheckbox);
  const [date, setDate] = useState(route.date);

  const handelUpdateDate = (value) => {
    setDate(value);
    airlineService.setSearchValue(FIELDS_CHANGE.DATE, { key: index, value });
  };

  const CheckboxVisible = visibleCheckbox && (
    <Checkbox
      qaAttr={ activeCheckbox }
      className={ styles.checkbox }
      onChange={ setDisabled }
      value={ disabled }
    />
  );

  return (
    <div className={ styles.wrapper }>
      { CheckboxVisible }
      <div className={ styles.flight_info }>
        <Text qaAttr={ info } className={ styles.duration } color={ GRAY }>
          { `${firstSegment.DepartureCity} (${firstSegment.DepartureAirport.Code}) - ` }
          { `${lastSegment.ArrivalCity} (${lastSegment.ArrivalAirport.Code})` }
        </Text>
      </div>
      <div className={ styles.date }>
        <Datepicker
          qaAttr={ dateFrom }
          wrapperClassName={ styles.wrapper_data }
          inputClassName={ styles.input }
          inputTheme={ OPEN }
          withLabel
          placeholder={ LABELS.DEPARTURE_DATE }
          disabled={ !disabled }
          value={ date }
          min={ currentDate() }
          onChange={ value => handelUpdateDate(value) }
        />
      </div>
    </div>
  );
};

SearchDateRoute.propTypes = {
  airlineService: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  route: PropTypes.object.isRequired,
  visibleCheckbox: PropTypes.bool.isRequired,
  defaultRoute: PropTypes.object.isRequired,
};

export { SearchDateRoute };
