import { action, makeObservable, observable } from 'mobx';
import { Moment } from 'moment';

import { filterArrayByIndex, replaceObjectByIndex } from '../../../utils/travelApproval';

import {
  APPROVAL_STATUS_PAGE,
  DEFAULT_PURPOSE,
} from '../../../constants/travelApproval';
import {
  DestinationType,
  EmployeeProjectsType,
  PurposeSchema,
  PurposeType,
  RequestGetApproverListType,
  TravelApprovalEmployeeType,
  TravelApprovalSchema,
  TravelApprovalTemplateSchema,
  TravelApprovalTemplateType,
  TravelApprovalType,
} from '../consts';
import { EmployeeType } from '../../employee/consts';

import { AnalyticsType } from '../../customAnalytics/types';

const editableStatus = (currentStatusPage: string) => !!(currentStatusPage === APPROVAL_STATUS_PAGE.CREATE || APPROVAL_STATUS_PAGE.APPROVER);

export class TravelApprovalStore {
  constructor() {
    makeObservable(this);
  }

  @observable travelApproval: TravelApprovalType = TravelApprovalSchema.getDefault();
  @observable travelApprovalForm = {};
  @observable approvalPopUp: any[] = [];
  @observable loading = true;
  @observable loadingSendButton = false;
  @observable showApproveDialog = false;
  @observable showDeleteRequestDialog = false;
  @observable purposes = DEFAULT_PURPOSE;
  @observable employeesWithRights: EmployeeProjectsType[] = [];
  @observable admin: EmployeeType | null = null;
  @observable projectId = [];
  @observable statusPage = APPROVAL_STATUS_PAGE.CREATE;
  @observable applicationSchemeTemplate: TravelApprovalTemplateType = TravelApprovalTemplateSchema.getDefault();
  @observable scheme: RequestGetApproverListType | null = null;
  @observable projectsEmployees: EmployeeProjectsType[] = [];
  @observable projectEditable: Record<`${number}`, boolean> = {};
  @observable customAnalyticsIds = [];
  @observable userCustomAnalytics: AnalyticsType[] = [];

  @action
  setLoading = (value: boolean) => {
    this.loading = value;
  };

  @action
  setScheme = (value: RequestGetApproverListType) => {
    this.scheme = value;
  };

  @action
  setAdmin = (value: EmployeeType) => {
    this.admin = value;
  };

  @action
  setApprovalPopUp = (data: any[]) => {
    this.approvalPopUp = data;
  };

  @action
  setTravelApproval = (item: TravelApprovalType) => {
    const { Destinations: DList } = item;
    const Destinations = DList?.length ? DList : [PurposeSchema.getDefault()];
    this.travelApproval = { ...item, Destinations };
  };

  @action
  setUsersCustomAnalytics = (userCustomAnalytics: AnalyticsType[]) => {
    this.userCustomAnalytics = userCustomAnalytics;
  };

  @action
  setCustomAnalytic = (customAnalytic: AnalyticsType) => {
    const id = customAnalytic.Id;
    const customAnalyticIds = this.travelApproval.Analytics;

    this.travelApproval = {
      ...this.travelApproval,
      Analytics: [...customAnalyticIds, id],
    };
  };

  @action
  unSetCustomAnalytic = (customAnalyticId: number) => {
    const selectedAnalyticIds = this.userCustomAnalytics
      .find(({ Id }) => customAnalyticId === Id)
      ?.Values.map(({ Id }) => Id);
    const customAnalyticIds = this.travelApproval.Analytics;
    const removedAnalytic = customAnalyticIds.filter((analyticValueId) => !selectedAnalyticIds?.includes(analyticValueId));

    this.travelApproval = {
      ...this.travelApproval,
      Analytics: removedAnalytic,
    };
  };

  @action
  setStatusPage = (status: string) => {
    this.statusPage = status;
  };

  @action
  setSuggestCityValue = (suggest: DestinationType, index: number) => {
    const { Destinations } = this.travelApproval;
    const newDestinations = replaceObjectByIndex(Destinations, index, suggest);

    this.travelApproval = {
      ...this.travelApproval,
      Destinations: [...newDestinations],
    };
  };

  @action
  addIntermediateCity = () => {
    this.travelApproval = {
      ...this.travelApproval,
      Destinations: [...this.travelApproval.Destinations, PurposeSchema.getDefault()],
    };
  };

  @action
  deleteIntermediateCity = (deletedIntermediateCityIndex: number) => {
    const { Destinations } = this.travelApproval;
    const newDestinations = filterArrayByIndex(Destinations, deletedIntermediateCityIndex);

    this.travelApproval = {
      ...this.travelApproval,
      Destinations: [...newDestinations],
    };
  };

  @action
  setTripTags = (updatedTripTags: number[]) => {
    this.travelApproval = {
      ...this.travelApproval,
      Tags: updatedTripTags,
    };
  };

  @action
  setRemoveSuggestValue = (_suggest: object, index: number) => {
    const { Destinations } = this.travelApproval;
    const newDestinations = replaceObjectByIndex(Destinations, index, PurposeSchema.getDefault());

    this.travelApproval = {
      ...this.travelApproval,
      Destinations: [...newDestinations],
    };
  };

  @action
  setStartDate = (value: Moment) => {
    if (!value) {
      this.travelApproval = {
        ...this.travelApproval,
        StartDate: value,
      };

      return;
    }

    this.travelApproval = value.isAfter(this.travelApproval.EndDate) ?
      this.travelApproval = {
        ...this.travelApproval,
        StartDate: value.toISOString(),
        EndDate: value.add(1, 'd').toISOString(),
      } :
      this.travelApproval = {
        ...this.travelApproval,
        StartDate: value.toISOString(),
      };
  };

  @action
  setEndDate = (value: string) => {
    this.travelApproval = {
      ...this.travelApproval,
      EndDate: value,
    };
  };

  @action
  setBudget = (value: number) => {
    this.travelApproval = {
      ...this.travelApproval,
      Budget: value,
    };
  };

  @action
  setUsers = (value: EmployeeProjectsType[]) => {
    this.employeesWithRights = value;
  };

  @action
  setPurpose = (value: PurposeType) => {
    this.travelApproval = {
      ...this.travelApproval,
      Purpose: value,
    };
  };

  @action
  setComment = (value: string) => {
    this.travelApproval = {
      ...this.travelApproval,
      Comment: value,
    };
  };

  @action
  setEmployee = (item: TravelApprovalEmployeeType, index: number) => {
    const newEmployee = this.travelApproval.Employees;
    const currentProjectEditable = this.projectEditable;

    newEmployee[index] = item;
    const { Id, ProjectId } = item;
    this.travelApproval = {
      ...this.travelApproval,
      Employees: newEmployee,
    };

    this.projectEditable = {
      ...currentProjectEditable,
      [`${Id}`]: !ProjectId && editableStatus(this.statusPage),
    };
  };

  @action
  setTravelApprovalProject = (ProjectId: number | null, employeeId: number) => {
    const updatedEmployees = this.travelApproval.Employees.map(item => {
      if (item.Id === employeeId) {
        return {
          ...item,
          ProjectId,
        };
      }

      return item;
    });

    this.travelApproval = {
      ...this.travelApproval,
      Employees: updatedEmployees,
    };
  };

  @action
  setProjectsEmployees = (projectsEmployees: EmployeeProjectsType[]) => {
    this.projectEditable = projectsEmployees.reduce((projectEmployees, { Id, ProjectId }) => (
      { ...projectEmployees, [`${Id}`]: !ProjectId && editableStatus(this.statusPage) }
    ), {});
    this.projectsEmployees = projectsEmployees;
  };

  @action
  deleteEmployee = (item: TravelApprovalEmployeeType) => {
    const employees = this.travelApproval.Employees.filter(({ Id }) => Id !== item.Id);
    this.travelApproval = {
      ...this.travelApproval,
      Employees: employees,
    };
  };

  @action
  addEmployeeCompany = (employeeId: number, companyId: string | number) => {
    const splitter = typeof companyId === 'string' ? companyId.split('--') : null;
    const updatedEmployees = this.travelApproval.Employees.map(item => {
      if (item.Id === employeeId) {
        return {
          ...item,
          CompanyId: splitter ? Number(splitter[0]) : companyId as number,
          DepartmentId: splitter ? Number(splitter[1]) : undefined,
        };
      }

      return item;
    });
    this.travelApproval = {
      ...this.travelApproval,
      Employees: updatedEmployees,
    };
  };

  @action
  addMoreEmployee = () => {
    const newEmployee = this.travelApproval.Employees.concat({} as EmployeeType);

    this.travelApproval = {
      ...this.travelApproval,
      Employees: newEmployee,
    };
  };

  @action
  setShowApproveDialog = (value: boolean) => {
    this.showApproveDialog = value;
  };

  @action
  setShowDeleteRequestDialog = (value: boolean) => {
    this.showDeleteRequestDialog = value;
  };

  @action
  setLoadingSendButton = (value: boolean) => {
    this.loadingSendButton = value;
  };

  @action
  setResetStore = () => {
    this.travelApproval = TravelApprovalSchema.getDefault();
    this.loading = true;
    this.loadingSendButton = false;
    this.showApproveDialog = false;
    this.showDeleteRequestDialog = false;
    this.purposes = DEFAULT_PURPOSE;
    this.employeesWithRights = [];
    this.admin = null;
    this.projectId = [];
    this.statusPage = APPROVAL_STATUS_PAGE.CREATE;
  };
}

export const travelApprovalStore = new TravelApprovalStore();
