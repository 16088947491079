import { RouteComponentProps } from 'react-router-dom';

import { ICompany } from '../employee/types';
import { DepartmentStatus } from './store';

interface DepartmentSave {
  CompanyId: number | null,
  EmployeesIds: number[],
  HeadOfDepartment?: number,
  HeadOfDepartmentId: number | null,
  Name: string,
  Id: number | null
}

interface DepartmentsService {
  store: IDepartmentsStore,
  loadList: () => void,
  justLoadList: () => Promise<ICompany[]>;
  getDepartmentsPaginationListNew(): void,
  clearList(): void,
  getDepartmentsCompanies(): void,
  openForm(value: ItemDepartments): void,
  getFilterDepartments(field: string, value: string | number): void,
  deleteDepartment(value: number): void,
  changePageDepartments(value: number): void
}

interface IDepartmentsStore {
  list: {
    value: ItemDepartments[],
  },
}

interface DepartmentsListItem {
  CompanyId: number,
  CompanyName: string,
  ShortCompanyName: string,
  Departments: DepartmentSave[],
  Name: string,
}

interface DepartmentsListStore {
  value: ItemDepartments[],
  error?: Error
  loading: boolean,
}

interface DepartmentFormStore {
  loading: boolean,
  value: {
    employees: any,
    headOfDepartment: any,
    Name: string,
    Id?: number | null,
    id?: number,
    CompanyId?: number | null,
  },
  employeesList: any[],
  employeesHash: any,
  hasChanges: boolean,
  forcedPrompt: boolean
}

export enum DialogTypes {
  empty,
  editHead,
  editEmployees,
  deleteEmployee,
  deleteHead,
  error,
}

export enum LoadingFields {
  loadDepartment,
  saveDepartment,
  setOrRemoveEmployee,
  updateName,
  loadDepartmentForSelectList,
  loadUserList,
}

export interface CompanyWithDepartmentList {
  value: number
  label: string
  nested: Omit<CompanyWithDepartmentList, 'nested'>[]
}

export interface UserByCompany {
  Id: number,
  Name: string
  Surname: string
  Patronymic: string
}

export enum GroupedDepartmentStatus {
  all = -1,
  active = 0,
  removed = 1,
}

export interface IDepartmentSearchArgs {
  Page: number
  Step: number
  Filters: {
    CompanyId?: number
    SearchString?: string
    DepartmentStatus?: DepartmentStatus.active | DepartmentStatus.deleted
  }
}

interface IDepartmentsTab {
  departmentsService: DepartmentsService,
  history: RouteComponentProps['history'],
}

interface ItemDepartments {
  CanBeDeleted: boolean;
  CompanyId: number;
  EmployeesIds: number[];
  HeadOfDepartment: {
    Name: string,
  }
  Id: number;
  Name: string;
  Status: string;
}

interface ItemDepartmentsLigth {
  Name: string,
  departmentId: number | null,
}

export type {
  DepartmentsListStore,
  DepartmentFormStore,
  DepartmentSave,
  DepartmentsListItem,
  DepartmentsService,
  IDepartmentsTab,
  ItemDepartmentsLigth,
  ItemDepartments,
};
