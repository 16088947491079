import React, { Component, createRef, RefObject } from 'react';
import { Input } from 'new-ui';
import { InputColorSchemas, InputThemes } from 'new-ui/src/components/Input/types';

interface ITimeInputProps {
  colorScheme?: InputColorSchemas,
  label?: string,
  qaAttr?: string,
  className?: string,
  value: string | null,
  onChange?: (hours: string, minutes: string) => void,
  onBlur: (hours: string, minutes: string) => void,
  onSelect: (ref: RefObject<HTMLInputElement>) => void,
  border: boolean,
  disabled?: boolean,
  max?: number,
}

interface ITimeInputState {
  hours: string,
  minutes: string,
}

interface IPropsInput {
  withLabel: boolean,
  theme: InputThemes,
}

class TimeInputWithoutValue extends Component<ITimeInputProps, ITimeInputState> {
  private ref: React.RefObject<HTMLInputElement>;

  constructor(props: ITimeInputProps) {
    super(props);

    let hours = '';
    let minutes = '';

    if (props.value) {
      const part = props.value.split(':');
      hours = part[0];
      minutes = part[1];
    }

    this.ref = createRef();

    this.state = {
      hours, minutes,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: ITimeInputProps) {
    let hours = '';
    let minutes = '';

    if (nextProps.value) {
      const part = nextProps.value.split(':');
      hours = part[0];
      minutes = part[1];
    }

    this.setState({
      hours, minutes,
    });
  }

  handleBlur = () => {
    let { hours, minutes } = this.state;
    const { onBlur } = this.props;

    if (hours.length !== 2) hours = '';

    if (minutes.length !== 2) minutes = '';

    onBlur(hours, minutes);
  };

  handleKeyPressTimeInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const re = /^[0-9]+$/g;
    const key = +e.key;
    const allowedKeys = [8, 9, 37, 39];

    if (!re.test(String(key)) && !allowedKeys.includes(e.keyCode)) {
      e.preventDefault();
    }

    const value: string = (e.target as HTMLInputElement).value;

    if (!this.ref.current) return;

    if ((this.ref.current.selectionStart === 0 && key > 2)
      || (this.ref.current.selectionStart === 1 && value[0] === '2' && key > 3)
      || (this.ref.current.selectionStart === 2 && !value.includes(':') && key > 5)
      || (this.ref.current.selectionStart === 3 && value.includes(':') && key > 5)) {
      e.preventDefault();
    }
  };

  handleChangeTimeInput = (value: string) => {
    const { onChange } = this.props;
    let newValue = value;

    newValue = newValue.substring(0, 5).replace(/:/g, '');

    let hours = '';
    let minutes = '';

    switch (newValue.length) {
      case 4:
        hours = newValue.charAt(0) + newValue.charAt(1);
        minutes = newValue.charAt(2) + newValue.charAt(3);
        break;
      case 3:
        hours = newValue.charAt(0) + newValue.charAt(1);
        minutes = newValue.charAt(2);
        break;
      case 2:
        hours = newValue.charAt(0) + newValue.charAt(1);
        break;
      case 1:
        hours = newValue.charAt(0);
        break;
      default:
        hours = '';
        minutes = '';
        break;
    }

    if (+hours > 24) hours = '00';

    if (+minutes > 59) minutes = '00';

    if (onChange) {
      onChange(hours, minutes);
    } else {
      this.setState({
        hours, minutes,
      });
    }
  };

  render() {
    const { onSelect, label, colorScheme, border, className, qaAttr, max, disabled } = this.props;
    const { hours, minutes } = this.state;

    const value = [];

    if (hours) {
      value.push(hours);

      if (minutes) {
        value.push(minutes);
      }
    }

    const props: IPropsInput = border ?
      { theme: 'default', withLabel: false } :
      { theme: 'open', withLabel: true };

    const inputValue = value ? value.join(':') : value;

    const currentColorScheme = colorScheme || 'light';

    return (
      <Input
        ref={ this.ref }
        debounceMs={ 0 }
        className={ className }
        colorScheme={ currentColorScheme }
        placeholder={ label }
        value={ inputValue }
        onKeyDown={ this.handleKeyPressTimeInput }
        onChange={ this.handleChangeTimeInput }
        onBlur={ this.handleBlur }
        onClick={ () => onSelect(this.ref) }
        disabled={ disabled }
        max={ max }
        qaAttr={ qaAttr }
        { ...props }
      />
    );
  }
}

export { TimeInputWithoutValue };
