export enum AnalyticSortHotel {
  recommended_first = 'recommended_first',
  cheaper_first = 'cheaper_first',
  more_expensive_first = 'more_expensive_first',
  distance_from_center = 'distance_from_center',
}

export enum DetailAddCartHotel {
  optional_choice = 'optional_choice',
  main_optional = 'main_optional',
  main = 'main',
}

export enum RRVKType {
  yes = 'yes',
  no = 'no',
}
