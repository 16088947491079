import React, { FC, useState } from 'react';
import { Button, Dialog, Text, Tooltip } from 'new-ui';

import { getText } from '../../../../../i18n';

import { FLIGHT_CERTIFICATE_TYPES } from '../../../../bi/constants/serviceType';

import styles from './styles/index.module.css';

const LABELS = {
  COMPLETED: getText('components:tripItem.orderHeader.tooltip.completed'),
  UNCOMPLETED_FREE: getText('components:tripItem.orderHeader.tooltip.uncompletedFree'),
  REFERENCE: getText('components:tripItem.orderHeader.reference'),
  DOWNLOAD: getText('trip:documents.download'),
  NOT_CONFIRMED: getText('components:tripDetailsDialog.flightCertDialogs.error.notConfirmed'),
  TO_TRAVEL_HELPER: getText('components:tripDetailsDialog.flightCertDialogs.error.toTravelHelper'),
  FLIGHT_CERTIFICATE_FOR_TICKET: getText('components:tripItem.orderHeader.textFreeFlightCertificateTicket'),
  FLIGHT_CERTIFICATE_FOR_E_TICKET: getText('components:tripItem.orderHeader.textFreeFlightCertificateETicket'),
  ERROR_DOWNLOADING_FIRST: getText('components:tripDetailsDialog.flightCertDialogs.error.downloadingFirst'),
  ERROR_DOWNLOADING_SECOND: getText('components:tripDetailsDialog.flightCertDialogs.error.downloadingSecond'),
};

interface TicketsExtendedI {
  Num: number;
}

interface ItemDataI {
  TicketsExtended: TicketsExtendedI[];
}

interface FreeFlightCertificateI {
  status: string;
  itemId: number;
  itemData: ItemDataI | null;
  downloadFlightCertificate: (itemId: number, isFree: boolean) => Promise<void>;
}

const FreeFlightCertificate: FC<FreeFlightCertificateI> = ({
  status,
  itemId,
  itemData,
  downloadFlightCertificate,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const isUnavailable = status === FLIGHT_CERTIFICATE_TYPES.UNAVAILABLE ||
    status === FLIGHT_CERTIFICATE_TYPES.WAITING ||
    status === FLIGHT_CERTIFICATE_TYPES.FAILED ||
    status === FLIGHT_CERTIFICATE_TYPES.NOT_CONFIRMED;

  const contentIsUnavailable = status === FLIGHT_CERTIFICATE_TYPES.WAITING ? LABELS.UNCOMPLETED_FREE : `${LABELS.NOT_CONFIRMED}. ${LABELS.TO_TRAVEL_HELPER}`;

  const tooltipTextComplete = isUnavailable ? contentIsUnavailable : LABELS.COMPLETED;

  const disabledButtonFlightCertificate = isUnavailable || isLoading;

  const handelDownloadFreeFlightCertificate = async () => {
    try {
      setIsLoading(true);

      await downloadFlightCertificate(itemId, true);

      setIsLoading(false);
    } catch (error) {
      setIsError(true);
    }
  };

  const renderTicketNumber = () => {
    if (itemData && itemData.TicketsExtended && itemData.TicketsExtended[0] && itemData.TicketsExtended[0].Num) {
      return `${LABELS.FLIGHT_CERTIFICATE_FOR_TICKET} #${itemData.TicketsExtended[0].Num}`;
    }

    return LABELS.FLIGHT_CERTIFICATE_FOR_E_TICKET;
  };

  const renderTooltipContent = (label: string) => (
    <Text
      type='NORMAL_14_130'
      color='white'
      className={ styles.content }
    >
      { label }
    </Text>
  );

  return (
    <>
      <div className={ styles.wrapper }>
        <Text
          color='gray'
        >
          { renderTicketNumber() }:
        </Text>
        <Tooltip
          className={ styles.air_reference }
          renderContent={ () => renderTooltipContent(tooltipTextComplete) }
          show={ !isLoading }
          position='bottom'
        >
          <Button
            type='textual'
            disabled={ disabledButtonFlightCertificate }
            loading={ isLoading }
            onClick={ handelDownloadFreeFlightCertificate }
          >
            { LABELS.DOWNLOAD }
          </Button>
        </Tooltip>
      </div>
      <Dialog
        show={ isError }
        showClosing
        onChange={ () => setIsError(false) }
      >
        <div className={ styles.wrapper_dialog }>
          <Text type='NORMAL_16_130'>{ LABELS.ERROR_DOWNLOADING_FIRST }</Text>
          <Text type='NORMAL_16_130'>{ LABELS.ERROR_DOWNLOADING_SECOND }</Text>
        </div>
      </Dialog>
    </>
  );
};

export { FreeFlightCertificate };
