// @ts-nocheck
import PropTypes from 'prop-types';
import React from 'react';
import { Dialog, Text, Icon, Button, LinkButton, PROPS } from 'new-ui';

import { ExternalLink } from '../ExternalLink';

import { getText } from '../../../i18n';

import styles from './styles/index.module.css';

import chromePng from './styles/images/chrome.png';
import firefoxPng from './styles/images/ff.png';
import edgePng from './styles/images/edge.png';
import yandexPng from './styles/images/yandex.png';
import operaSvg from './styles/images/opera.png';

const BROWSERS_LINKS = {
  chrome: 'https://www.google.com/chrome/',
  firefox: 'https://www.mozilla.org/ru/firefox/new/',
  edge: 'https://microsoft.com/ru-ru/windows/microsoft-edge',
  yandex: 'https://browser.yandex.ru',
  opera: 'https://opera.com/ru',
};

const STORAGE_KEY = 'outdated-browser-notification';

const LABELS = {
  OK: getText('components:outdatedBrowser.ok'),
  KEEP: getText('components:outdatedBrowser.keep'),
  UPDATE: getText('components:outdatedBrowser.update'),
  BROWSER_OUTDATED: getText('components:outdatedBrowser.browserOutdated'),
  DIALOG_TEXT: {
    ONE: getText('components:outdatedBrowser.dialogText.one'),
    TWO: getText('components:outdatedBrowser.dialogText.two'),
    THREE: getText('components:outdatedBrowser.dialogText.three'),
  },
};

class OutdatedBrowserNotification extends React.Component {
  static propTypes = {
    browser: PropTypes.object.isRequired,
    onRender: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { onRender } = this.props;

    const alreadyShown = window.localStorage.getItem(STORAGE_KEY);

    if (alreadyShown) {
      return this.setState({ show: false });
    }

    const show = this.isOutdated();

    return setTimeout(() => {
      this.setState({ initAnimation: true, show }, () => onRender(show));
    }, 1500);
  }

  state = {
    initAnimation: false,
    show: false,
    showIEDialog: false,
    hideAnimation: false,
  };

  isOutdated = () => {
    const { browser: { name, version } } = this.props;

    switch (name) {
      case 'firefox': {
        const parsedVersion = parseFloat(version);

        return parsedVersion < 55;
      }
      case 'ie':
        return true;
      default:
        return false;
    }
  };

  handleClose = (saveFlag = false) => {
    const { onRender } = this.props;
    this.setState({ initAnimation: false, hideAnimation: true }, () => setTimeout(() => this.setState({ show: false }), 250));

    onRender(false);

    if (saveFlag) {
      window.localStorage.setItem(STORAGE_KEY, JSON.stringify(Date.now()));
    }
  };

  isIE = browserName => browserName === 'ie';

  toggleIEDialog = (value) => this.setState({ showIEDialog: value });

  renderCommonPreview = (browserName) => (
    <ExternalLink
      link={ BROWSERS_LINKS[browserName] }
      className={ styles.link }
      onClick={ () => this.handleClose() }
      text={ LABELS.UPDATE }
    />
  );

  renderIEPreview = () => (
    <LinkButton
      theme={ PROPS.LINK_BUTTON.THEMES.LARGE_WHITE }
      className={ styles.linkbutton }
      onClick={ () => this.toggleIEDialog(true) }
    >
      Узнать больше
    </LinkButton>
  );

  renderPreview = () => {
    const { browser: { name } } = this.props;

    const linkContent = this.isIE(name) ? this.renderIEPreview() : this.renderCommonPreview(name);

    return (
      <div className={ styles.preview }>
        <Icon type={ PROPS.ICON.TYPES.WARNING } />
        &nbsp;
        <Text color={ PROPS.TEXT.COLORS.WHITE }>
          { LABELS.BROWSER_OUTDATED }
        </Text>
        &nbsp;
        { linkContent }
      </div>
    );
  };

  renderIEDialog = () => {
    const { showIEDialog } = this.state;

    const { DIALOG_TEXT: { ONE, TWO, THREE } } = LABELS;

    return (
      <Dialog
        showClosing
        show={ showIEDialog }
        innerClassName={ styles.inner_dialog }
        onChange={ () => this.toggleIEDialog(false) }
      >
        <div className={ styles.dialog }>
          <div className={ styles.texts }>
            <Text type={ PROPS.TEXT.TYPES.NORMAL_16_140 }>{ ONE }</Text>
            <Text type={ PROPS.TEXT.TYPES.NORMAL_16_140 }>{ TWO }</Text>
            <br />
            <Text type={ PROPS.TEXT.TYPES.NORMAL_16_140 }>{ THREE }</Text>
          </div>
          <div className={ styles.browsers }>
            <a href={ BROWSERS_LINKS.chrome }><img src={ chromePng } alt={ 'chrome' }/>Google Chrome</a>,
            <a href={ BROWSERS_LINKS.firefox }>{' '}<img src={ firefoxPng } alt={ 'firefox' }/>Mozilla Firefox</a>,
            <a href={ BROWSERS_LINKS.edge }>{' '}<img src={ edgePng } alt={ 'edge' }/>Microsoft Edge</a>,
            <a href={ BROWSERS_LINKS.yandex }>{' '}<img src={ yandexPng } alt={ 'yandex' }/>Yandex Browser</a>,
            <a href={ BROWSERS_LINKS.opera }>{' '}<img src={ operaSvg } alt={ 'opera' }/>Opera</a>
          </div>
          <div className={ styles.actions }>
            <Button
              onClick={ () => {
                this.toggleIEDialog(false);
                this.handleClose();
              } }
            >
              { LABELS.OK }
            </Button>
            <Button
              className={ styles.keep }
              type={ PROPS.BUTTON.TYPES.TEXTUAL_MEDIUM }
              onClick={ () => {
                this.toggleIEDialog();
                this.handleClose(true);
              } }
            >
              { LABELS.KEEP }
            </Button>
          </div>
        </div>
      </Dialog>
    );
  };

  render() {
    const { initAnimation, hideAnimation, show } = this.state;

    if (!show) {
      return null;
    }

    const wrapperClassNames = [styles.wrapper];

    if (initAnimation) {
      wrapperClassNames.push(styles.init);
    }

    if (hideAnimation) {
      wrapperClassNames.push(styles.hide);
    }

    return (
      <div className={ wrapperClassNames.join(' ') }>
        { this.renderPreview() }
        { this.renderIEDialog() }
      </div>
    );
  }
}

export { OutdatedBrowserNotification };
