// @ts-nocheck
import PropTypes from 'prop-types';
import React from 'react';

import styles from './styles/panel.module.css';

const Panel = ({ shadow, children, isLoading }) => (
  <div className={ `${styles.wrap} ${styles[shadow]} ${isLoading ? styles.loading : ''}` }>
    { children }
  </div>
);

/**
 * @param shadow - shadow type - main || light || panel
 */
Panel.propTypes = {
  shadow: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

Panel.defaultProps = {
  shadow: 'main',
  isLoading: false,
};

export default Panel;
