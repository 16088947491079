import { AnalyticFilterExpense } from '../../constants/expenseReport';
import { ANALYTIC_SERVICE_TYPES } from '../../constants/serviceType';
import { AnalyticAddExpense, AnalyticAdvanceStatus } from '../../services/expenseReports/types';
import { AnalyticTripFilter } from '../../types/trips';
import { AnalyticSortAir } from '../../types/airline';
import { AnalyticSortTrain } from '../../types/train';
import { AnalyticSortHotel, DetailAddCartHotel, RRVKType } from '../../types/hotel';
import { AnalyticTaxiClass } from '../../types/taxi';
import { AnalyticTypeClassAeroExpress } from '../../types/aeroExpress';
import { AnalyticSignTransfer, AnalyticSortTransfer, AnalyticTypeClassTransfer } from '../../types/transfer';
import { IAnalyticProperties } from '../../../page/Settings/components/FeeConstruction/types';

const CATEGORY = {
  SEARCH: 'SEARCH',
  AIRLINE: 'AIRLINE',
  ANALYTICS: 'ANALYTICS',
  HOTELS: 'HOTELS',
  TRAINS: 'TRAINS',
  TRANSFER: 'TRANSFER',
  VIP_HALL: 'VIP_HALL',
  VIP_HALL_BANNERS: 'VIP_HALL_BANNERS',
  NOTE: 'NOTE',
  CART: 'CART',
  PAYMENT: 'PAYMENT',
  TRIPS: 'TRIPS',
  SERVICEINTRIPS: 'SERVICEINTRIPS',
  ADDITIONALSERVICES: 'ADDITIONALSERVICES',
  SMARTDESC: 'SMARTDESC',
  MENU: 'MENU',
  QUALITYCONTROL: 'QUALITYCONTROL',
  FAVORITES: 'FAVORITES',
  APPROVEMENT: 'APPROVEMENT',
  TAXI: 'TAXI',
  TRAVEL_POLICY: 'TRAVEL_POLICY',
  APPLICATION_SCHEME: 'APPLICATION_SCHEME',
  SETTINGS: 'SETTINGS',
  REPORTING: 'REPORTING',
  SIMPLE_SIGNATURE: 'SIMPLE_SIGNATURE',
  SMART_AGENT: 'SMART_AGENT',
  MAIN: 'MAIN',
  MICE: 'MICE',
  PPR: 'PPR',
};

const CATEGORY_RU = {
  [CATEGORY.AIRLINE]: 'Авиа',
  [CATEGORY.HOTELS]: 'Отели',
  [CATEGORY.TRAINS]: 'ЖД',
  [CATEGORY.TRANSFER]: 'Трансфер',
  [CATEGORY.VIP_HALL]: 'Vip-зал',
  [CATEGORY.VIP_HALL_BANNERS]: 'Баннеры для vip-залов',
  [CATEGORY.NOTE]: 'Блокнот',
  [CATEGORY.CART]: 'Корзина',
  [CATEGORY.PAYMENT]: 'Оплата',
  [CATEGORY.TRIPS]: 'Поездки',
  [CATEGORY.SERVICEINTRIPS]: 'Услуга в поездке',
  [CATEGORY.ADDITIONALSERVICES]: 'Дополнительные услуги',
  [CATEGORY.SMARTDESC]: 'Смартдеск',
  [CATEGORY.MENU]: 'Меню',
  [CATEGORY.QUALITYCONTROL]: 'Контроль качества',
  [CATEGORY.FAVORITES]: 'Избранное',
  [CATEGORY.APPROVEMENT]: 'Согласование',
  [CATEGORY.REPORTING]: 'Отчетность',
  [CATEGORY.MICE]: 'Мероприятия',
};

const ACTIONS: { [key: string]: any } = {
  [CATEGORY.AIRLINE]: {
    SEARCH: 'SEARCH',
    UPDATESEARCH: 'UPDATESEARCH',
    SEARCHBYHISTORY: 'SEARCHBYHISTORY',

    SEARCHONEWAY: 'SEARCHONEWAY',
    SEARCHTHEREAGAIN: 'SEARCHTHEREAGAIN',
    SEARCHCOMPLEXROUTE: 'SEARCHCOMPLEXROUTE',

    SEARCHBYHISTORYONEWAY: 'SEARCHBYHISTORYONEWAY',
    SEARCHBYHISTORYTHEREAGAIN: 'SEARCHBYHISTORYTHEREAGAIN',
    SEARCHBYHISTORYCOMPLEXROUTE: 'SEARCHBYHISTORYCOMPLEXROUTE',

    UPDATESEARCHONEWAY: 'UPDATESEARCHONEWAY',
    UPDATESEARCHTHEREAGAIN: 'UPDATESEARCHTHEREAGAIN',

    CHANGESEARCHCOMPLEXROUTE: 'UPDATESEARCHCOMPLEXROUTE',
    CHANGEDIRECTION: 'CHANGEDIRECTION',

    NORESULT: 'NORESULT',
    FARESELECTION: 'FARESELECTION',

    ADDTONOTE: 'ADDTONOTE',
    ADDTOFAVORITES: 'ADDTOFAVORITES',
    ADDTOCART: 'ADDTOCART',

    SORTING: 'SORTING',

    FILTERSWITCH: 'FILTERSWITCH',
    FILTERCHECKBOX: 'FILTERCHECKBOX',
    FILTERSLIDER: 'FILTERSLIDER',
    FILTEROTHER: 'FILTEROTHER',
    FILTERADVANCE: 'FILTERADVANCE',

    SHOWDETAILS: 'SHOWDETAILS',

    GETLINK: 'GETLINK',
    COPYLINK: 'COPYLINK',

    DATECHANGE: 'DATECHANGE',

    BOOKING: 'BOOKING',

    SEARCH_SCREEN_OPENED: 'search_air_screen_opened',
    SEARCH_CITY_CHOSEN: 'search_air_city_chosen',
    SEARCH_AIR_DATE_CHOSEN: 'search_air_date_chosen',
    SEARCH_AIR_CLASS_CHOSEN: 'search_air_class_chosen',
    SEARCH_AIR_TRANSPLANTS_CHOSEN: 'search_air_transplants_chosen',
    SEARCH_AIR_DIFFICULT_ROUTE_CHOSEN: 'search_air_difficult_route_chosen',
    SEARCH_AIR_FIND_BUTTON_PRESSED: 'search_air_find_button_pressed',
  },

  [CATEGORY.SEARCH]: {
    SEARCH_FIND_BUTTON_PRESSED: (service: ANALYTIC_SERVICE_TYPES) => [
      'search_find_button_pressed', { service },
    ],
    SEARCH_RESULTS_SCREEN_OPENED: (service: ANALYTIC_SERVICE_TYPES) => [
      'search_results_screen_opened', { service },
    ],
    SEARCH_RESULTS_NOTEBOOK_BUTTON_PRESSED: (service: ANALYTIC_SERVICE_TYPES) => [
      'search_results_notebook_button_pressed', { service },
    ],
    SEARCH_RESULTS_LINK_BUTTON_PRESSED: (service: ANALYTIC_SERVICE_TYPES) => [
      'search_results_link_button_pressed', { service },
    ],
    SEARCH_RESULTS_FAVORITES_BUTTON_PRESSED: (service: ANALYTIC_SERVICE_TYPES) => [
      'search_results_favorites_button_pressed', { service },
    ],
    SEARCH_RESULTS_ADD_CART_BUTTON_PRESSED: (service: ANALYTIC_SERVICE_TYPES) => [
      'search_results_add_cart_button_pressed', { service },
    ],

    SEARCH_RESULTS_AIR_SORTING_CHOSEN: (sortAir: AnalyticSortAir) => [
      'search_results_air_sorting_chosen', { sortAir },
    ],

    SEARCH_TRAIN_SCREEN_OPENED: 'search_train_screen_opened',
    SEARCH_TRAIN_CITY_CHOSEN: (city: string) => [
      'search_train_city_chosen', { city },
    ],
    SEARCH_TRAIN_DATE_CHOSEN: (date: string) => [
      'search_train_date_chosen', { date },
    ],

    SEARCH_RESULTS_TRAIN_SORTING_CHOSEN: (sortTrain: AnalyticSortTrain) => [
      'search_results_train_sorting_chosen', { sortTrain },
    ],
    SEARCH_RESULTS_TRAIN_RAILW_CARRIAGE_CHOSEN: 'search_results_train_railw_carriage_chosen',
    SEARCH_RESULTS_TRAIN_PLACE_BUTTON_CHOSEN: 'search_results_train_place_button_chosen',

    SEARCH_HOTEL_SCREEN_OPENED: 'search_hotel_screen_opened',
    SEARCH_HOTEL_CITY_CHOSEN: 'search_hotel_city_chosen',
    SEARCH_HOTEL_DATE_CHOSEN: (date: string) => [
      'search_hotel_date_chosen', { date },
    ],
    SEARCH_HOTEL_EARLY_CHECK_IN_CHOSEN: 'search_hotel_early_check_in_chosen',
    SEARCH_HOTEL_LATE_CHECK_OUT_CHOSEN: 'search_hotel_late_check_out_chosen',
    SEARCH_RESULTS_HOTEL_SORTING_CHOSEN: (sortHotel: AnalyticSortHotel) => [
      'search_results_hotel_sorting_chosen', { sortHotel },
    ],

    SEARCH_TAXI_SCREEN_OPENED: 'search_taxi_screen_opened',
    SEARCH_TAXI_CLASS_CHOSEN: (typeClassTaxi: AnalyticTaxiClass) => [
      'search_taxi_class_chosen', { typeClassTaxi },
    ],
    SEARCH_TAXI_DATE_CHOSEN: (date: string) => [
      'search_taxi_date_chosen', { date },
    ],

    SEARCH_AERO_EXPRESS_SCREEN_OPENED: 'search_aeroexpress_screen_opened',
    SEARCH_AERO_EXPRESS_ROUTE_CHOSEN: 'search_aeroexpress_route_chosen',
    SEARCH_AERO_EXPRESS_DATE_CHOSEN: (date: string) => [
      'search_aeroexpress_date_chosen', { date },
    ],
    SEARCH_AERO_EXPRESS_CLASS_CHOSEN: (typeClassAeroExpress: AnalyticTypeClassAeroExpress) => [
      'search_aeroexpress_class_chosen', { typeClassAeroExpress },
    ],

    SEARCH_TRANSFER_SCREEN_OPENED: 'search_transfer_screen_opened',
    SEARCH_TRANSFER_CITY_CHOSEN: (city: string) => [
      'search_transfer_city_chosen', { city },
    ],
    SEARCH_RESULTS_TRANSFER_SORTING_CHOSEN: (sortTransfer: AnalyticSortTransfer) => [
      'search_results_transfer_sorting_chosen', { sortTransfer },
    ],
    SEARCH_RESULTS_TRANSFER_CLASS_CHOSEN: (typeClassTransfer: AnalyticTypeClassTransfer | null) => [
      'search_results_transfer_class_chosen', { typeClassTransfer },
    ],
    SEARCH_RESULTS_TRANSFER_SIGN_CHOSEN: (signTransfer: AnalyticSignTransfer) => [
      'search_results_transfer_sign_chosen', { signTransfer },
    ],

    SEARCH_VIPHALL_SCREEN_OPENED: 'search_viphall_screen_opened',

    SEARCH_RESULTS_SENT_BUTTON_PRESSED: (service: ANALYTIC_SERVICE_TYPES) => [
      'search_results_sent_button_pressed', { service },
    ],

    HOTEL_DETAIL_ADD_CART_BUTTON_PRESSED: (servicetype: DetailAddCartHotel, RRVKtype: RRVKType, RRVK: RRVKType) => [
      'hotel_detail_add_cart_button_pressed', { servicetype, RRVKtype, RRVK },
    ],
  },

  [CATEGORY.ANALYTICS]: {
    MAIN_PAGE_OPENED: 'main_analytics_opened',
    CHANGED_DATE: 'analytics_date_chosen',

    SPENDING_INDEX_PAGE_OPENED: 'analytics_index_screen_opened',
    SPENDING_AIRLINE_PAGE_OPENED: 'index_air_chosen',
    SPENDING_TRAIN_PAGE_OPENED: 'index_train_pressed',
    SPENDING_HOTEL_PAGE_OPENED: 'index_hotel_pressed',
    SPENDING_TAXI_PAGE_OPENED: 'index_taxi_pressed',
    SPENDING_TRANSFER_PAGE_OPENED: 'index_ransfer_pressed',
    SPENDING_AEROEXPRESS_PAGE_OPENED: 'index_aeroexpress_pressed',
    SPENDING_MICE_PAGE_OPENED: 'index_mice_chosen',

    SPENDING_AIRLINE_ADVANCE_PAYMENT_TICKET_OPENED: 'index_air_early_opened',
    SPENDING_AIRLINE_TICKET_RETURNS_OPENED: 'index_air_repayment_opened',
    SPENDING_AIRLINE_TRAVEL_APPROVAL_OPENED: 'index_air_travel_politics_opened',
    SPENDING_AIRLINE_CORPORATE_PROGRAM_OPENED: 'index_air_corporate_program_opened',
    SPENDING_AIRLINE_PERSONAL_BONUSES_OPENED: 'index_air_personal_bonuses_opened',

    SPENDING_TRAIN_ADVANCE_PAYMENT_TICKET_OPENED: 'index_train_early_opened',
    SPENDING_TRAIN_TICKET_RETURNS_OPENED: 'index_train_repayment_opened',
    SPENDING_TRAIN_TRAVEL_APPROVAL_OPENED: 'index_train_travel_politics_opened',
    SPENDING_TRAIN_PERSONAL_BONUSES_OPENED: 'index_train_personal_bonuses_opened',

    SPENDING_HOTEL_ADVANCE_PAYMENT_TICKET_OPENED: 'index_hotel_early_opened',
    SPENDING_HOTEL_TICKET_RETURNS_OPENED: 'index_hotel_repayment_opened',
    SPENDING_HOTEL_TRAVEL_APPROVAL_OPENED: 'index_hotel_travel_politics_opened',
    SPENDING_SMART_HOTEL_OPENED: 'index_hotel_smart_opened',

    SPENDING_TAXI_ADVANCE_PAYMENT_TICKET_OPENED: 'index_taxi_early_opened',
    SPENDING_TAXI_TICKET_RETURNS_OPENED: 'index_taxi_repayment_opened',
    SPENDING_TAXI_TRAVEL_APPROVAL_OPENED: 'index_taxi_travel_politics_opened',

    SPENDING_AEROEXPRESS_ADVANCE_PAYMENT_TICKET_OPENED: 'index_aeroexpress_early_opened',
    SPENDING_AEROEXPRESS_TICKET_RETURNS_OPENED: 'index_aeroexpress_repayment_opened',

    SPENDING_TRANSFER_ADVANCE_PAYMENT_TICKET_OPENED: 'index_ransfer_early_opened',
    SPENDING_TRANSFER_TICKET_RETURNS_OPENED: 'index_ransfer_repayment_opened',
    SPENDING_TRANSFER_TRAVEL_APPROVAL_OPENED: 'index_ransfer_travel_politics_opened',

    SPENDING_MICE_ADVANCE_PAYMENT_TICKET_OPENED: 'index_mice_early_opened',
    SPENDING_MICE_TICKET_RETURNS_OPENED: 'index_mice_repayment_opened',
    SPENDING_MICE_TRAVEL_APPROVAL_OPENED: 'index_mice_travel_politics_opened',

    SPENDING_DOWNLOAD_XLSX_ADVANCE_PAYMENT: 'index_service_early_report_pressed',
    SPENDING_DOWNLOAD_XLSX_TICKET_RETURNS: 'index_service_repayment_report_pressed',
    SPENDING_DOWNLOAD_XLSX_TRAVEL_APPROVAL: 'index_service_travel_politics_report_pressed',
    SPENDING_DOWNLOAD_XLSX_SMART: 'index_service_smart_report_pressed',
    SPENDING_DOWNLOAD_XLSX_CORPORATE_PROGRAM: 'index_service_corporate_program_report_pressed',
    SPENDING_DOWNLOAD_XLSX_PERSONAL_BONUSES: 'index_service_personal_bonuses_report_pressed',

    HOME_PAGE_OPENED: 'analytics_home_screen_opened',
    AIRLINE_SUMMARY_OPENED: 'analytics_air_summary_opened',
    AIRLINE_DIRECTIONS_OPENED: 'analytics_air_directions_opened',
    AIRLINE_COMPANIES_OPENED: 'analytics_air_airline_opened',
    AIRLINE_EARLINESS_OPENED: 'analytics_air_lead_time_opened',
    AIRLINE_MINIMAL_FARE_PRESSED: 'basket_mincharge_pressed',
    AIRLINE_MINIMAL_FARE_OPENED: 'basket_mincharge_opened',

    TRAIN_SUMMARY_OPENED: 'analytics_train_summary_opened',
    TRAIN_DIRECTIONS_OPENED: 'analytics_train_directions_opened',

    HOTEL_SUMMARY_OPENED: 'analytics_hotel_summary_opened',
    HOTEL_DIRECTIONS_OPENED: 'analytics_hotel_directions_opened',
    HOTEL_POPULAR_DIRECTIONS_OPENED: 'analytics_hotel_popular_opened',

    TAXI_SUMMARY_OPENED: 'analytics_taxi_summary_opened',
    TAXI_DIRECTIONS_OPENED: 'analytics_taxi_directions_opened',

    AEROEXPRESS_SUMMARY_OPENED: 'analytics_aeroexpress_summary_opened',
    AEROEXPRESS_DIRECTIONS_OPENED: 'analytics_aeroexpress_directions_opened',

    TRANSFER_SUMMARY_OPENED: 'analytics_transfer_summary_opened',
    TRANSFER_DIRECTIONS_OPENED: 'analytics_transfer_directions_opened',

    MICE_SUMMARY_OPENED: 'analytics_mice_summary_opened',

    STRUCTURE_SERVICE_TYPE_OPENED: 'analytics_spending_structure_service_opened',
    STRUCTURE_DEPARTMENTS_OPENED: 'analytics_spending_structure_department_opened',
    STRUCTURE_PROJECTS_OPENED: 'analytics_spending_structure_cost_centers_opened',
    STRUCTURE_CUSTOM_ANALYTICS_OPENED: 'analytics_spending_structure_analysts_opened',
  },

  [CATEGORY.HOTELS]: {
    SEARCHBYNAME: 'SEARCHBYNAME',
    SEARCHBYREGION: 'SEARCHBYREGION',
    SEARCHBYNAMEFROMHISTORY: 'SEARCHBYNAMEFROMHISTORY',
    SEARCHBYREGIONFROMHISTORY: 'SEARCHBYREGIONFROMHISTORY',
    SEARCHBYRADIUS: 'SEARCHBYRADIUS',
    SEARCHBYRADIUSABORT: 'SEARCHBYRADIUSABORT',
    UPDATESEARCHBYREGION: 'UPDATESEARCHBYREGION',
    UPDATESEARCHBYNAME: 'UPDATESEARCHBYNAME',
    SHOWDEHOTELFROMMAP: 'SHOWDEHOTELFROMMAP',
    SETPOINTTOMAP: 'SETPOINTTOMAP',
    SEARCH_RESULTS_HOTEL_RECOMMEND_RATE: 'search_results_hotel_recommend_rate_hotel_opened',

    TOHOTELPAGE: 'TOHOTELPAGE',

    EMPTYREQUEST: 'EMPTYREQUEST',

    REQUESTROOMS: 'REQUESTROOMS',

    ADDTONOTE: 'ADDTONOTE',
    ADDTOCART: 'ADDTOCART',
    ADDTOFAVORITES: 'ADDTOFAVORITES',

    SWITCHPHOTO: 'SWITCHPHOTO',

    OPENLISTMAP: 'OPENLISTMAP',
    CLOSELISTMAP: 'CLOSELISTMAP',

    FILTERSWITCH: 'SWITCH',
    FILTERCHECKBOX: 'CHECKBOX',
    FILTERSLIDER: 'SLIDER',
    FILTEROTHER: 'OTHER',
    FILTERADVANCE: 'ADVANCE',
    FILTERNUMBERS: 'NUMBERS',

    SORTING: 'SORTING',

    CHANGEDATES: 'CHANGEDATES',

    SENDTOTRAVELASSISTANT: 'SENDTOTRAVELASSISTANT',

    NONUMBERS: 'NONUMBERS',

    GETLINK: 'GETLINK',
    COPYLINK: 'COPYLINK',

    MULTIPLEROOMSSEARCH: 'MULTIPLEROOMSSEARCH',

    STARTRADIUSSEARCH: 'STARTRADIUSSEARCH',

    UPDATE_BREAKFAST_FILTER_ON_REGION: 'filters.breakfast.onRegion',
    UPDATE_BREAKFAST_FILTER_OFF_REGION: 'filters.breakfast.offRegion',

    UPDATE_CANCELLATION_FILTER_ON_REGION: 'filters.has_cancellation.onRegion',
    UPDATE_CANCELLATION_FILTER_OFF_REGION: 'filters.has_cancellation.offRegion',

    UPDATE_ONLINE_FILTER_ON_REGION: 'filters.online.onRegion',
    UPDATE_ONLINE_FILTER_OFF_REGION: 'filters.online.offRegion',

    UPDATE_BREAKFAST_FILTER_ON_HOTEL: 'filters.breakfast.onHotel',
    UPDATE_BREAKFAST_FILTER_OFF_HOTEL: 'filters.breakfast.offHotel',

    UPDATE_CANCELLATION_FILTER_ON_HOTEL: 'filters.has_cancellation.onHotel',
    UPDATE_CANCELLATION_FILTER_OFF_HOTEL: 'filters.has_cancellation.offHotel',

    UPDATE_ONLINE_FILTER_ON_HOTEL: 'filters.online.onHotel',
    UPDATE_ONLINE_FILTER_OFF_HOTEL: 'filters.online.offHotel',

    OPTIONAL_CHOICE_ON: 'search_results_hotel_optional_choice_switched_on',
    OPTIONAL_CHOICE_OFF: 'search_results_hotel_optional_choice_switched_off',

    UPDATE_MAP_VIEW: 'search_results_hotel_map_choice_changed',

    ALTERNATIVE_HOTEL: 'alternative.hotel',
    ALTERNATIVE_MORE_CARDS: 'alternative.more_cards',
    HOTEL_REGION_SEARCH: 'hotel.region_search',

    SEARCH_BUTTON: 'hotelsearch_button_pressed',
    SEARCH_EARLY_CHECKIN: 'hotelsearch_earlycheckin_chosen',
    SEARCH_LATE_CHECKOUT: 'hotelsearch_latecheckout_chosen',
    REGION_SEARCH_BUTTON: 'hotelregionsearch_button_pressed',
    REGION_SEARCH_EARLY_CHECKIN: 'hotelregionsearch_earlycheckindate_chosen',
    REGION_SEARCH_LATE_CHECKOUT: 'hotelregionsearch_latecheckoutdate_chosen',
    REGION_SEARCH_EARLY_CHECKIN_TIME: 'hotelregionsearch_earlycheckintime_chosen',
    REGION_SEARCH_LATE_CHECKOUT_TIME: 'hotelregionsearch_latecheckouttime_chosen',
    DETAIL_EARLY_CHECKIN_BUTTON: 'hoteldetail_earlycheckinbutton_pressed',
    DETAIL_LATE_CHECKOUT_BUTTON: 'hoteldetail_latecheckoutbutton_pressed',

    FILTER_PRICEGUARANTEE: 'hotelregionfilter_priceguarantee_filled',
    FILTER_ONLY_ONLINE: 'hotelregionfilter_onlyonline_filled',
    FILTER_BREAKFAST: 'hotelregionfilter_breakfastincl_filled',
    FILTER_FREE_CANCELLATION: 'hotelregionfilter_freecancel_filled',
    FILTER_ONLY_FAVOURITES: 'hotelregionfilter_onlyfavourites_filled',
  },

  [CATEGORY.TRAINS]: {
    SEARCHONEWAY: 'SEARCHONEWAY',
    SEARCHONEWAYFROMHISTORY: 'ONEWAYFROMHISTORY',
    SEARCHNORESULT: 'SEARCHNORESULT',

    UPDATESEARCHONEWAY: 'UPDATESEARCHONEWAY',

    SELECTTRAIN: 'SELECTTRAIN',
    SELECTTRAINCAR: 'SELECTTRAINCAR',
    ADDTOCART: 'ADDTOCART',
    ADDTONOTE: 'ADDTONOTE',

    ADDTOFAVORITES: 'ADDTOFAVORITES',

    CHANGEDIRECTION: 'CHANGEDIRECTION',

    FILTERSWITCH: 'FILTERSWITCH',
    FILTERCHECKBOX: 'FILTERCHECKBOX',
    FILTERSLIDER: 'FILTERSLIDER',
    FILTEROTHER: 'OTHER',

    SORTING: 'SORTING',

    GETLINK: 'GETLINK',
    COPYLINK: 'COPYLINK',
  },

  [CATEGORY.TRANSFER]: {
    SEARCHONEWAY: 'SEARCHONEWAY',
    SEARCHBYHISTORYONEWAY: 'SEARCHBYHISTORYONEWAY',
    UPDATESEARCHONEWAY: 'UPDATESEARCHONEWAY',

    CHANGEDIRECTION: 'CHANGEDIRECTION',

    ADDTONOTE: 'ADDTONOTE',
    ADDTOCART: 'ADDTOCART',

    SORTING: 'SORTING',

    FILTERCHECKBOX: 'CHECKBOX',
    FILTERSLIDER: 'SLIDER',
  },

  [CATEGORY.VIP_HALL]: {
    SEARCHONEWAY: 'SEARCHONEWAY',
    UPDATESEARCHONEWAY: 'UPDATESEARCHONEWAY',
    AIRTRIPDETAIL_ADDSERVICES_OPENED: 'airtripdetail_addservices_opened', // допуслуги открыты без связи с попапом
    ADDSERVICES_BUTTON_PRESSED: 'addservices_button_pressed',
    AIRTRIPDETAIL_SEATREQUEST_SENT: 'airtripdetail_seatrequest_sent',
    AIRTRIPDETAIL_LUGGAGEREQUEST_SENT: 'airtripdetail_luggagerequest_sent',
    AIRTRIPDETAIL_OTHERREQUEST_SENT: 'airtripdetail_otherrequest_sent',
    AIRTRIPDETAIL_REFERENCEREQUEST_SENT: 'airtripdetail_referencerequest_sent',
    VIP_ROUTE_ROUTE_CHOSEN: 'viphallroute_route_chosen',
    VIP_ROUTE_BUTTON_PRESSED: 'viphallroute_button_pressed',
    VIP_ROUTE_REQUEST_SENT: 'viphallroute_request_sent',
    VIP_AIRPORT_AIRPORT_CHOSEN: 'viphallairport_airport_chosen',
    VIP_AIRPORT_BUTTON_PRESSED: 'viphallairport_button_pressed',
    VIP_AIRPORT_REQUEST_SENT: 'viphallairport_request_sent',
    VIP_TERMINAL_BUTTON_PRESSED: 'viphallterminal_button_pressed',
    VIP_SORT_PRESSED: 'viphall_sort_pressed',
    VIP_BUTTON_PRESSED: 'viphall_button_pressed',
    VIP_DETAIL_PAGE_SCROLLED: 'viphalldetail_page_scrolled',
    VIP_DETAIL_CHECKBOX_CHECKED: 'viphalldetail_checkbox_checked',
    VIP_DETAIL_BUTTON_PRESSED: 'viphalldetail_button_pressed',
    VIP_PAYMENT_CHECKBOX_CHECKED: 'viphallpayment_checkbox_checked',
    VIP_PAYMENT_BUTTON_PRESSED: 'viphallpayment_button_pressed',
    VIP_PAYMENT_POPUP_CLOSED: 'viphallpayment_popup_closed',
    ESCORT_ROUTE_CHOSEN: 'escortroute_route_chosen',
    ESCORT_ROUTE_BUTTON_PRESSED: 'escortroute_button_pressed',
    ESCORT_REQUEST_SENT: 'escortroute_request_sent',
    ESCORT_AIRPORT_AIRPORT_CHOSEN: 'escortairport_airport_chosen',
    ESCORT_AIRPORT_BUTTON_PRESSED: 'escortairport_button_pressed',
    ESCORT_AIRPORT_REQUEST_SENT: 'escortairport_request_sent',
    ESCORT_TERMINAL_BUTTON_PRESSED: 'escortterminal_button_pressed',
    ESCORT_SORT_PRESSED: 'escort_sort_pressed',
    ESCORT_BUTTON_PRESSED: 'escort_button_pressed',
    ESCORT_DETAIL_PAGE_SCROLLED: 'escortdetail_page_scrolled',
    ESCORT_DETAIL_CHECKBOX_CHECKED: 'escortdetail_checkbox_checked',
    ESCORT_DETAIL_BUTTON_PRESSED: 'escortdetail_button_pressed',
    ESCORT_PAYMENT_CHECKBOX_CHECKED: 'escortpayment_checkbox_checked',
    ESCORT_PAYMENT_BUTTON_PRESSED: 'escortpayment_button_pressed',
    ESCORT_PAYMENT_POPUP_CLOSED: 'escortpayment_popup_closed',
  },

  [CATEGORY.VIP_HALL_BANNERS]: {
    MAIN_BANNER_SHOWED: 'main_banner_showed',
    MAIN_BANNER_CLOSED: 'main_banner_closed',
    AIRTICKETPURCHASE_POPUP_SHOWED: 'airticketpurchase_popup_showed',
    AIRTICKETPURCHASE_POPUP_CLOSED: 'airticketpurchase_popup_closed',
    AIRTRIPDETAIL_LEARNINGPOPUP_CLOSED: 'airtripdetail_learningpopup_closed',
    AIRTRIPDETAIL_ADDSERVICESLEARN_OPENED: 'airtripdetail_addserviceslearn_opened', // допуслуги открыты после обучающего попапа
  },

  [CATEGORY.NOTE]: {
    CREATERESERVATION: 'CREATERESERVATION',
    ADDTOCART: 'ADDTOCART',
    REMOVESERVICE: 'REMOVESERVICE',
    REMOVEFOULNOTE: 'REMOVEFOULNOTE',
    REMOVEFOULNOTEALL: 'REMOVEFOULNOTEALL',
    SAVEPDF: 'SAVEPDF',
    SENDAGREEMENT: 'SENDAGREEMENT',
    FASTSEARCH: 'FASTSEARCH',
    FILTER: 'FILTER',
    BOOKING: 'BOOKING',

    MENU_TO_NOTE: 'menu_note_pressed',
    AVIA_SEARCH_TO_NOTE: 'aviasearch_tonotebutton_pressed',
    TRAIN_SEARCH_TO_NOTE: 'trainsearch_tonotebutton_pressed',
    HOTEL_SEARCH_TO_NOTE: 'hotelsearch_tonotebutton_pressed',
    SAVE_PDF: 'note_savepdfbutton_pressed',
    SEND_PDF: 'note_sendpdfbutton_pressed',
    ALL_TO_CART: 'note_alltocartbutton_pressed',
    NOTE_TO_CART: 'note_tocartbutton_pressed',
    DELETE_ALL: 'note_deleteallbutton_pressed',
    DELETE_NOTE: 'note_deletebutton_pressed',
    SELECT_ALL_NOTE: 'note_selectallcheckbox_chosen',
    SELECT_ITEM_NOTE: 'note_selectcheckbox_chosen',
    RESERVE_AIR_NOTE: 'note_reserve_pressed',
    ADD_TAG_NOTE: 'note_tags_added',
    NOTE_ALL_TO_CARD: 'note_alltocard_pressed',
    NOTE_ALL_DELETE: 'note_alldelete_pressed',
  },

  [CATEGORY.CART]: {
    CART_PAGE_OPENED: 'main_basket_opened',
    CART_CHANGED_NAME: 'basket_name_trip_saved',
    CART_DELETE_EVERYTHING: 'basket_delete_everything_pressed',
    CART_PAY: 'basket_proceed_checkout_pressed',
    CART_PAY_SCREEN_PAY: 'basket_payment_payment_pressed',
    ADD_TRIP: 'basket_service_add_trip_pressed',
    ADD_TO_NOTEPAD: 'basket_service_notepad_pressed',
    DELETE_ITEM: 'basket_service_delete_pressed',
    REQUEST_1C_CHANGED: 'basket_service_application_1с_chosen',
    COST_CENTER_CHANGED: 'basket_service_cost_center_chosen',
    EMPLOYEE_CHANGED: 'basket_service_employee_chosen',
    DEPARTMENT_CHANGED: 'basket_service_department_chosen',
    COMPANY_CHANGED: 'basket_service_organization_chosen',
    CUSTOM_ANALYTIC_CHANGED: 'basket_service_analysts_chosen',
    TAGS_CHANGED: 'basket_service_tags_chosen',
    APPLICATION_CHANGED: 'basket_application_chosen',
    SEND_TO_APPROVE_BUTTON: 'basket_on_approval_button_pressed',
    SUCCESSFULLY_PAID: 'successfully_paid_screen_opened',
    ERROR_PAID: 'booking_error_screen_opened',
    APPROVE_BASKET: 'basket_approval_button_pressed',
    DECLINE_BASKET: 'basket_refuse_approval_pressed',
    SELECTEMPLOYEE: 'SELECTEMPLOYEE',
    ADDEMPLOYEE: 'ADDEMPLOYEE',
    SELECTDOCUMENT: 'SELECTDOCUMENT',
    SELECTORGANIZATION: 'SELECTORGANIZATION',
    SELECTPROJECT: 'SELECTPROJECT',
    SELECTOPTIONEMPLOYEE: 'SELECTOPTIONEMPLOYEE',
    REMOVEEMPLOYEE: 'REMOVEEMPLOYEE',
    REMOVEOPTIONALEMPLOYEE: 'REMOVEOPTIONALEMPLOYEE',
    ADDTONOTE: 'ADDTONOTE',
    REMOVESERVICE: 'REMOVESERVICE',
    REMOVESERVICEALL: 'REMOVESERVICEALL',
    TRANSITIONTOPAYMENT: 'TRANSITIONTOPAYMENT',
    OPENINGTARIFFRULES: 'OPENINGTARIFFRULES',
    HOTELPURCHASE: 'HOTELPURCHASE',
  },

  [CATEGORY.PAYMENT]: {
    SUCCESS: 'SUCCESS',
    DOWNLOADVOUCHER: 'DOWNLOADVOUCHER',
    DOWNLOADINVOICE: 'DOWNLOADINVOICE',
    DOWNLOADALLINVOICEBYDATA: 'DOWNLOADALLINVOICEBYDATA',
    RETURNINMAIN: 'RETURNINMAIN',
    GOTODETAILS: 'GOTODETAILS',
    BOOKINGERROR: 'BOOKINGERROR',
    RETURNINCART: 'RETURNINCART',
    REQUESTSINTRAVELASSISTANT: 'REQUESTSINTRAVELASSISTANT',
  },

  [CATEGORY.TRIPS]: {
    VIEWLIST: 'VIEWLIST',

    FILTERSUSE: 'FILTERSUSE',
    FILTERSSEARCH: 'FILTERSSEARCH',
    FILTERSCLEAR: 'FILTERSCLEAR',

    VIEWTRIPS: 'VIEWTRIPS',
    SENDVOUCHERS: 'SENDVOUCHERS',
    DOWNLOADVOUCHERS: 'DOWNLOADVOUCHERS',
    CANCELLATIONREQUEST: 'CANCELLATIONREQUEST',
    CANCELLATIONCANCEL: 'CANCELLATIONCANCEL',

    RETURN_COST_PRESSED: 'trip_detail_return_cost_pressed',
    RETURN_TICKET_PRESSED: 'trip_detail_return_ticket_pressed',
    TRIP_DETAIL_TP_BUTTON_PRESSED: 'tripdetail_tpbutton_pressed',
    TRIP_PLAN_VOUCHER_CHECKBOX_CHOSEN: 'travelplan_vouchercheckbox_chosen',
    TRIP_PLAN_TP_DOWNLOAD_BUTTON_PRESSED: 'travelplan_tpdownloadbutton_pressed',
    TRIP_PLAN_EMAIL_SEND_BUTTON_PRESSED: 'travelplanemail_sendbutton_pressed',
    TRIP_PLAN_EVENT_PRESSED: 'travelplan_event_pressed',
    TRIP_PLAN_EVENT_BUTTON_PRESSED: 'travelplan_addeventbutton_pressed',
    TRIP_PLAN_CANCEL_EVENT_BUTTON_PRESSED: 'travelplan_canceleventbutton_pressed',
    TRIP_PLAN_EDIT_EVENT_BUTTON_PRESSED: 'travelplan_editeventbutton_pressed',
    TRIP_PLAN_DELETE_EVENT_BUTTON_PRESSED: 'travelplan_deleteeventbutton_pressed',

    TRIPS_FILTER_PRESSED: (filterTrips: AnalyticTripFilter) => [
      'trips_filter_pressed', { filterTrips },
    ],
    TRIPS_REPORT_DOWNLOAD_PRESSED: 'trips_report_download_pressed',
    TRIP_DETAIL_SCREEN_OPENED: (id: number) => ['trip_detail_screen_opened', { id }],
    TRIP_VOUCHER_PRESSED: (id: number, service: ANALYTIC_SERVICE_TYPES, language: string = 'ru') => [
      'trip_voucher_pressed', { id, service, language },
    ],
    TRIP_REPORT_PRESSED: (date: string) => [
      'trip_report_pressed', { date },
    ],
    TRIP_CHANGE_PRESSED: (id: number, service: ANALYTIC_SERVICE_TYPES) => [
      'trip_change_pressed', { id, service },
    ],
    TRIP_ANNULATION_PRESSED: (id: number, service: ANALYTIC_SERVICE_TYPES) => [
      'trip_annulation_pressed', { id, service },
    ],
    TRIP_ANNULATION_CONFIRM_PRESSED: (id: number, service: ANALYTIC_SERVICE_TYPES) => [
      'trip_annulation_confirm_pressed', { id, service },
    ],
    TRIP_CHANGE_CONFIRM_PRESSED: (id: number, service: ANALYTIC_SERVICE_TYPES) => [
      'trip_change_confirm_pressed', { id, service },
    ],
    TRIP_DETAIL_ADD_COMMENT_PRESSED: (id: number, comment: string | null = null, service: ANALYTIC_SERVICE_TYPES) => [
      'trip_detail_add_comment_pressed', { id, service, comment },
    ],
    TRIP_DETAIL_ADDITIONAL_SERVICES_PRESSED: (id: number, service: ANALYTIC_SERVICE_TYPES) => [
      'trip_detail_additional_services_pressed', { id, service },
    ],
    TRIP_DETAIL_ADD_TRIP_PRESSED: (service: ANALYTIC_SERVICE_TYPES) => [
      'trip_detail_add_trip_pressed', { service },
    ],
    TRIP_DETAIL_APPLICATION_NUMBER_PRESSED: (service: ANALYTIC_SERVICE_TYPES) => [
      'trip_detail_application_number_pressed', { service },
    ],
    TRIP_DETAIL_INVOICE_PRESSED: 'trip_detail_invoice_pressed',
  },

  [CATEGORY.SERVICEINTRIPS]: {
    SENDVOUCHERS: 'SENDVOUCHERS',
    DOWNLOADVOUCHERS: 'DOWNLOADVOUCHERS',
    CANCELLATIONREQUEST: 'CANCELLATIONREQUEST',
    CANCELLATIONCANCEL: 'CANCELLATIONCANCEL',
    REQUESTAUTOMATICCANCELLATION: 'REQUESTAUTOMATICCANCELLATION',
    CONFIRMATIONAUTOMATICCANCELLATION: 'CONFIRMATIONAUTOMATICCANCELLATION',
    CANCELAUTOMATICCANCELLATION: 'CANCELAUTOMATICCANCELLATION',
    VIEWEDITHISTORY: 'VIEWEDITHISTORY',
  },

  [CATEGORY.ADDITIONALSERVICES]: {
    ORDER: 'ORDER',
  },

  [CATEGORY.SMARTDESC]: {
    MAIN_SCREEN_OPENED: 'main_screen_opened',
    GOTOSEARCH: 'GOTOSEARCH',
    GOTOTRIPS: 'GOTOTRIPS',
    ADDTASK: 'ADDTASK',
    COMPLETEDTODO: 'COMPLETEDTODO',
    RETURNTODO: 'RETURNTODO',
    DELETETODO: 'DELETETODO',
    OPENALLTODO: 'OPENALLTODO',
    DELETEALLTODO: 'DELETEALLTODO',

    MAIN_SMARTDESK_OPENED: 'main_smartdesk_opened',
    SMARTDESK_SEARCH_PRESSED: (service: ANALYTIC_SERVICE_TYPES) => [
      'smartdesk_search_pressed', { service },
    ],
    SMARTDESK_REPLENISHMENT_CARD_PRESSED: 'smartdesk_replenishment_card_pressed',
    SMARTDESK_DOWNLOAD_INVOICE_PRESSED: 'smartdesk_download_invoice_pressed',
    SMARTDESK_SEND_INVOICE_PRESSED: 'smartdesk_send_invoice_pressed',
    SMARTDESK_ADD_TASK_PRESSED: 'smartdesk_add_task_pressed',
  },

  [CATEGORY.MENU]: {
    SELECTION: 'SELECTION',
    LOGO: 'main_logo_pressed',
  },

  [CATEGORY.QUALITYCONTROL]: {
    COMPLAINT: 'COMPLAINT',
  },

  [CATEGORY.MICE]: {
    SHOWN: 'main_micebanner_shown',
    CLOSED: 'main_micebanner_closed',
    PRESSED: 'main_micebanner_pressed',
    OPENED: 'settings_constructor_opened',
    SAVE_PRESSED: 'settingsconstructor_save_pressed',
    EDIT_PRESSED: 'settingsconstructor_edit_pressed',
    DELETE_PRESSED: 'settingsconstructor_delete_pressed',
    POPUP: {
      SHOWN: 'main_micebanner_shown',
      CLOSED: 'main_micebanner_closed',
      PRESSED: 'main_micebanner_pressed',
    },
    EVENT: {
      OPEN: 'main_event_opened',
      INFO: 'event_info_pressed',
      NEW: 'event_new_pressed',
      CREATE: 'event_create_pressed',
      CANCEL: 'event_cancel_pressed',
      FILTER: 'event_filter_pressed',
      REVEALED: 'event_unit_opened',
    },
    GO_TO_TRIP: 'event_unittotrip_pressed',
    SWITHER_ON: 'cart_event_switched_on',
    SWITHER_OFF: 'cart_event_switched_off',
    BUY_APP_CART: 'cart_buy_pressed',
    OPEN_IN_TRIP: 'trip_event_pressed',
    CONTEXT: {
      HOTELS: {
        SHOWED: 'bannermice_hotels_showed',
        CLOSED: 'bannermice_hotels_closed',
        PRESSED: 'bannermice_hotels_pressed',
      },
      HOTELS_REGION: {
        SHOWED: 'bannermice_hotels_region_showed',
        CLOSED: 'bannermice_hotels_region_closed',
        PRESSED: 'bannermice_hotels_region_pressed',
      },
      TRAIN: {
        SHOWED: 'bannermice_train_showed',
        CLOSED: 'bannermice_train_closed',
        PRESSED: 'bannermice_train_pressed',
      },
      AVIA: {
        SHOWED: 'bannermice_avia_showed',
        CLOSED: 'bannermice_avia_closed',
        PRESSED: 'bannermice_avia_pressed',
      },
    },
  },

  [CATEGORY.FAVORITES]: {
    GOTOSEARCH: 'GOTOSEARCH',
    SEARCH: 'SEARCH',
    REMOVEFROMFAVORITES: 'REMOVEFROMFAVORITES',
    FASTSEARCH: 'FASTSEARCH',
    FILTER: 'FILTER',
  },

  [CATEGORY.APPROVEMENT]: {
    OPENSENDTOAPPROVEDIALOG: 'OPENSENDTOAPPROVEDIALOG',
    SENDTOAPPROVETOONEAPPROVER: 'SENDTOAPPROVETOONEAPPROVER',
    SENDTOAPPROVETOSEVERALAPPROVERS: 'SENDTOAPPROVETOSEVERALAPPROVERS',
    SENDTOAPPROVEWITHOPTIONS: 'SENDTOAPPROVEWITHOPTIONS',
    DELETEWAITINGAPPROVE: 'DELETEWAITINGAPPROVE',
    DELETEAPPROVED: 'DELETEAPPROVED',
    DELETEDECLINED: 'DELETEDECLINED',
    OPENAPPROVEPAGE: 'OPENAPPROVEPAGE',
    APPROVE: 'APPROVE',
    DECLINE: 'DECLINE',
    BUYTRIP: 'BUYTRIP',
    DELETEBYAPPROVER: 'DELETEBYAPPROVER',
    DELETEBYAPPROVEROPTION: 'DELETEBYAPPROVEROPTION',
    FASTAPPROVE: 'FASTAPPROVE',
    FASTDECLINE: 'FASTDECLINE',

    COORDINATION_TRIPS_SCREEN_OPENED: 'coordination_trips_screen_opened',
    COORDINATION_TRIPS_DOWNLOAD_PRESSED: 'coordination_trips_download_pressed',
    COORDINATION_TRIPS_TRIP_AGREED_CHOSE: 'coordination_trips_trip_agreed_chosed',
    COORDINATION_APPLICATION_SCREEN_OPENED: 'coordination_application_screen_opened',
    COORDINATION_APPLICATION_DOWNLOAD_PRESSED: 'coordination_application_download_pressed',
    COORDINATION_APPLICATION_TRIP_AGREED_CHOSE: 'coordination_application_trip_agreed_chosed',
    COORDINATION_EXPENSE_SCREEN_OPENED: 'coordination_expense_screen_opened',
    COORDINATION_EXPENSE_DOWNLOAD_PRESSED: 'coordination_expense_download_pressed',
    COORDINATION_EXPENSE_TRIP_AGREED_CHOSE: 'coordination_expense_trip_agreed_chosed',
  },

  [CATEGORY.TAXI]: {
    ADDTOCART: 'ADDTOCART',
  },

  [CATEGORY.TRAVEL_POLICY]: {
    OPEN_SURVEY_POPUP: 'smartdesk_popup_survey_tp_opened',
    PRESSED_SURVEY_POPUP: 'smartdesk_popup_survey_tp_button_pressed',
    CLOSED_SURVEY_POPUP: 'smartdesk_popup_survey_tp_button_closed',
    OPEN_TP_POPUP: 'smartdesk_popup_tp_opened',
    PRESSED_TP_POPUP: 'smartdesk_popup_tp_button_pressed',
    CLOSED_TP_POPUP: 'smartdesk_popup_tp_button_closed',
    PRESSED_SURVEY_TP_QUESTION_ONE_POPUP: 'survey_tp_question_1_next_button_pressed',
    BACK_SURVEY_TP_QUESTION_ONE_POPUP: 'survey_tp_question_1_back_button_pressed',
    PRESSED_SURVEY_TP_QUESTION_TWO_POPUP: 'survey_tp_question_2_next_button_pressed',
    BACK_SURVEY_TP_QUESTION_TWO_POPUP: 'survey_tp_question_2_back_button_pressed',
    PRESSED_SURVEY_TP_QUESTION_THREE_POPUP: 'survey_tp_question_3_next_button_pressed',
    BACK_SURVEY_TP_QUESTION_THREE_POPUP: 'survey_tp_question_3_back_button_pressed',
    PRESSED_SURVEY_TP_QUESTION_FOR_POPUP: 'survey_tp_question_4_next_button_pressed',
    BACK_SURVEY_TP_QUESTION_FOR_POPUP: 'survey_tp_question_4_back_button_pressed',
    PRESSED_SURVEY_TP_QUESTION_FIVE_POPUP: 'survey_tp_question_5_next_button_pressed',
    BACK_SURVEY_TP_QUESTION_FIVE_POPUP: 'survey_tp_question_5_back_button_pressed',
    PRESSED_SURVEY_TP_QUESTION_SIX_POPUP: 'survey_tp_question_6_next_button_pressed',
    BACK_SURVEY_TP_QUESTION_SIX_POPUP: 'survey_tp_question_6_back_button_pressed',
    PRESSED_SURVEY_TP_QUESTION_SEVEN_POPUP: 'survey_tp_question_7_next_button_pressed',
    BACK_SURVEY_TP_QUESTION_SEVEN_POPUP: 'survey_tp_question_7_back_button_pressed',

    FORMED_TP_OPENED: 'formed_tp_screen_opened',
    FORMED_TP_SAVE: 'formed_tp_save_button_pressed',
    FORMED_TP_EDIT: 'formed_tp_edit_button_pressed',
    FORMED_TP_BACK: 'formed_tp_back_pressed',
    CREATED_TP_OPEN: 'created_tp_screen_opened',
    CREATED_TP_SAVE: 'created_tp_save_button_pressed',
    CREATED_TP_CANCEL: 'created_tp_cansel_button_pressed',
    EMPLOYEES_TP_OPEN: 'employees_tp_screen_opened',
    EMPLOYEES_TP_ALL_CHECKBOX: 'employees_tp_all_checkbox_shown',
    EMPLOYEES_TP_SAVE: 'employees_tp_save_button_pressed',
    EMPLOYEES_TP_CANSEL: 'employees_tp_cansel_button_pressed',
  },

  [CATEGORY.APPLICATION_SCHEME]: {
    APPLICATION_SCHEME_CHANGED_IN_APPROVAL_SCHEME_SELECTOR: 'cost_reconsilition_applicaion_scheme_choose',
    APPLICATION_SCHEME_CHANGED_IN_APPROVAL_SCHEME_SAVE_BUTTON: 'cost_reconsilition_scheme_application_save_button_pressed',
    APPLICATION_SCHEME_SAVED: 'scheme_save_button_pressed',
    APPLICATION_SCHEME_OPENED_CREATE_PAGE: 'scheme_creation_opened',
    APPLICATION_SCHEME_PRESSED_DELETE_SCHEME_BUTTON: 'application_scheme_deleted_button_pressed',
    APPLICATION_SCHEME_PRESSED_EDIT_SCHEME_BUTTON: 'application_scheme_edit_button_pressed',
    APPLICATION_SCHEME_CREATE_NEW_SCHEME_BUTTON: 'application_scheme_add_button_pressed',
    APPLICATION_SCHEME_SETTINGS_PAGE_OPENED: 'settings_application_scheme_screen_pressed',
    APPLICATION_SCHEME_CANCELED_CREATION_NEW_SCHEME: 'new_application_cancel_button_pressed',
    APPLICATION_SCHEME_CREATED_NEW_SCHEME: 'new_application_create_button_pressed',
    CREATE_NEW_TRAVEL_APPROVAL_SCREEN_OPENED: 'new_application_screen_opened',
    CREATE_NEW_TRAVEL_APPROVAL_BUTTON: 'new_application_button_pressed',
    APPLICATION_SCHEME_REQUEST_ADD_TO_ARCHIVE: 'application_archive_button_pressed',
    APPLICATION_SCHEME_REQUEST_SEND_TO_EMAIL: 'application_send_button_pressed',
    APPLICATION_SCHEME_REQUEST_DELETE_TRAVEL_APPROVAL: 'application_deleted_button_pressed',
    APPLICATION_SCHEME_REQUEST_DOWNLOAD_TRAVEL_APPROVAL: 'application_download_button_pressed',
    APPLICATION_SCHEME_REQUEST_SCREEN_OPENED: 'application_screen_opened',
    APPLICATION_SCHEME_SEND_TO_APPROVER: 'new_application_on_approval_button_pressed',
    APPLICATION_SCHEME_APPROVE_BUTTON_PRESSED: 'new_application_approval_button_pressed',
  },

  [CATEGORY.SIMPLE_SIGNATURE]: {
    USER_CLICKED_PROVIDE_SS_BUTTON: 'settings_employee_electronic_signature_pressed',
    USER_CLICKED_DELETE_SS_BUTTON: 'settings_remove_signature_pressed',
    USER_CLOSED_AGREEMENT_SS_MODAL: 'settings_employee_agreement_pressed',
    USER_CONFIRMED_SMS_CODE_BUTTON_PRESSED: 'agreement_confirm_code_pressed',
    REPEAT_SMS_CODE_BUTTON_PRESSED: 'agreement_new_code_pressed',
    CHANGE_PHONE_BUTTON_PRESSED: 'agreement_new_phone_pressed',
    SIGNATURE_CREATED_BUTTON_PRESSED: 'settings_signature_created_pressed',
    SAVE_EMPLOYEE_BUTTON_PRESSED: 'employee_savebutton_pressed',
    DOCUMENTS_FOR_SIGNATURE_PAGE_OPENED: 'settings_documents_signed_opened',
    SIGN_DOCUMENTS_CHECKBOX_ON: 'documents_signed_checkbox_checked',
    DOCUMENTS_SIGN_BUTTON_PRESSED: 'documents_signed_signbutten_pressed',
    SIGNED_DOCUMENT_DOWNLOAD_BUTTON_PRESSED: 'document_download_button_pressed',
    SIGNED_DOCUMENT_OPEN_BUTTON_PRESSED: 'document_opened_button_pressed',
    SIGNED_DOCUMENT_SEND_TO_EMAIL_BUTTON_PRESSED: 'document_mail_button_pressed',
    DOWNLOAD_XLSX_REPORT_BUTTON_PRESSED: 'document_download_report_pressed',
  },

  [CATEGORY.SETTINGS]: {
    SETTINGS_OPEN: 'menu_settings_opened',
    EMPLOYEE_OPEN: 'settings_employee_opened',
    DEPARTMENTS_OPEN: 'settings_departments_opened',
    TRIP_ANALYTICS_OPEN: 'settings_tripanalytics_opened',
    TRAVEL_POLICY_OPEN: 'settings_travelpolicy_opened',
    APPROVED_SCHEME_OPEN: 'settings_approvedscheme_opened',
    COSTS_SCHEMES_OPEN: 'settings_costsscheme_opened',
    EXPENSE_REPORTS_SCHEME_OPEN: 'settings_expensereportsscheme_opened',
    S7_OPEN: 'settings_s7cashback_opened',
    PROFILE_OPEN: 'settings_profile_opened',
    INTEGRATION_OPEN: 'settings_integration_opened',
    EXPENSE_REPORTS_OPEN: 'settings_expensereports_opened',

    EMPLOYEES_SEARCH_PRESSED: 'settingsemployees_search_pressed',
    EMPLOYEES_ADD_BUTTON_PRESSED: 'settingsemployees_addbutton_pressed',
    EMPLOYEES_ORG_SELECT: 'settingsemployees_orgselect_chosen',
    EMPLOYEES_STATUS_SELECT: 'settingsemployees_statusselect_chosen',
    UNLINK_TG_BOT: 'settingsemplyee_tguntiebutton_pressed',

    DEPARTMENTS_SEARCH_PRESSED: 'settingsdepartments_search_pressed',
    DEPARTMENTS_ORG_SELECT: 'settingsdepartment_orgselect_chosen',
    DEPARTMENTS_ADD_BUTTON: 'settingsdepartment_addbutton_pressed',
    DEPARTMENTS_OPEN_ITEM: 'settingsdepartment_department_opened',
    DEPARTMENTS_EDIT_ITEM: 'settingsdepartment_editdepartment_pressed',
    DEPARTMENTS_ADD_DIRECTOR: 'department_adddirector_pressed',
    DEPARTMENTS_ADD_EMPLOYEE: 'department_addemployee_pressed',

    ANALYTICS_ADD_ITEM: 'settingsanalytics_addanalytics_pressed',
    ANALYTICS_PROJECT_OPEN: 'settingsanalytics_project_opened',
    ANALYTICS_PROJECT_EDIT: 'settingsanalytics_editprojectbutton_pressed',
    ANALYTICS_PROJECT_COMPANIES_OPEN: 'project_companies_opened',
    ANALYTICS_PROJECT_COMPANY_CHOSEN: 'projectcompany_checkbox_chosen',
    ANALYTICS_PROJECT_COMPANY_SAVE_BUTTON: 'projectcompany_savebutton_pressed',
    ANALYTICS_ADD_PROJECT: 'project_addbutton_pressed',
    ANALYTICS_ADD_PROJECT_SAVE: 'addproject_savebutton_pressed',
    ANALYTICS_MANDATORY_CHOSEN: 'project_mandatorycheckbox_chosen',

    ANALYTICS_OPEN: 'settingsanalytics_analytics_opened',
    ANALYTICS_CHECKBOX_1: 'analytics_checkbox1_chosen',
    ANALYTICS_CHECKBOX_2: 'analytics_checkbox2_chosen',
    ANALYTICS_CHECKBOX_3: 'analytics_checkbox3_chosen',
    ANALYTICS_ADD_BUTTON_PRESSED: 'analytics_addbutton_pressed',

    REQUISITES_EDIT_BUTTON: 'settingsrequisites_editbutton_pressed',
    REQUISITES_COMPANY_DRAG_N_DROP: 'settingsrequisites_companyheader_drugndrop',
    REQUISITES_SAVE_BUTTON: 'settingsrequisites_savebutton_pressed',

    PROFILE_EDIT_BUTTON: 'settingprofile_editbutton_pressed',
    PROFILE_SAVE_BUTTON: 'settingprofile_savebutton_pressed',

    INTEGRATION_CHECKBOX_CHOSEN: 'settingsintegration_checkbox_chosen',
    INTEGRATION_SAVE_BUTTON: 'settingsintegration_savebutton_pressed',
    INTEGRATION_DELETE_BUTTON: 'settingsintegration_deletebutton_pressed',
    INTEGRATION_INSTRUCTION: 'settingsintegration_instruction_pressed',
    INTEGRATION_IDENTIFIER: 'settingsintegration_addidbutton_pressed',

    EXPENSE_REPORTS_CHECKBOX: 'settingsexpensereport_checkbox_chosen',
    EXPENSE_REPORTS_SAVE_BUTTON: 'settingsexpensereport_savebutton_pressed',

    TRAVEL_POLICY_ADD_BUTTON: 'settingstravelpolicy_addbutton_pressed',
    TRAVEL_POLICY_OPENED: 'settingstravelpolicy_policy_opened',
    TRAVEL_POLICY_DELETE_BUTTON: 'settingstravelpolicy_deletebutton_pressed',
    TRAVEL_POLICY_ERROR_PRESSED: 'settingstravelpolicy_error_pressed',
    TRAVEL_POLICY_AVIA_BUTTON_PRESSED: 'settingstravelpolicy_aviaaddbutton_pressed',
    TRAVEL_POLICY_HOTEL_BUTTON_PRESSED: 'settingstravelpolicy_hoteladdbutton_pressed',
    TRAVEL_POLICY_TRAIN_BUTTON_PRESSED: 'settingstravelpolicy_trainaddbutton_pressed',
    TRAVEL_POLICY_TAXI_BUTTON_PRESSED: 'settingstravelpolicy_taxiaddbutton_pressed',
    TRAVEL_POLICY_TRANSFER_BUTTON_PRESSED: 'settingstravelpolicy_transferaddbutton_pressed',
    TRAVEL_POLICY_VIP_BUTTON_PRESSED: 'settingstravelpolicy_vipaddbutton_pressed',
    TRAVEL_POLICY_SAVE_BUTTON_PRESSED: 'settingstravelpolicy_savebutton_pressed',

    COST_SCHEME_ADD_BUTTON: 'settingscostscheme_addbutton_pressed',
    COST_SCHEME_OPENED: 'settingscostscheme_scheme_opened',
    COST_SCHEME_DELETE_BUTTON: 'settingscostscheme_deletebutton_pressed',
    COST_SCHEME_CHECKBOX: 'costscheme_checkbox_chosen',
    COST_SCHEME_SAVE_BUTTON: 'costscheme_savebutton_pressed',
    COST_SCHEME_ADD_STEP: 'costscheme_addstepbutton_pressed',

    REPORT_SCHEME_ADD_BUTTON: 'settingsreportsscheme_addbutton_pressed',
    REPORT_SCHEME_SCHEME_OPEN: 'settingsreportsscheme_scheme_opened',
    REPORT_SCHEME_DELETE: 'settingsreportsscheme_deletebutton_pressed',
    REPORT_SCHEME_CHECKBOX: 'reportsscheme_checkbox_chosen',
    REPORT_SCHEME_SAVE_BUTTON: 'reportsscheme_savebutton_pressed',
    REPORT_SCHEME_ADD_STEP: 'reportsscheme_addstepbutton_pressed',

    FILTERS_OPENED: 'settingsemployees_filters_opened',
    FILTERS_DELETE_PRESSED: 'employeesfilters_delete_pressed',
    FILTERS_APPLY: 'employeesfilters_apply_pressed',

    FILTERS_ACCESS: 'employeesfilters_access_filters',
    FILTERS_COMPANY: 'employeesfilters_company_chosen',
    FILTERS_DEPARTMENTS: 'employeesfilters_department_chosen',
    FILTERS_GENDER: 'employeesfilters_gender_chosen',
    FILTERS_CITIZENSHIP: 'employeesfilters_citizenship_chosen',
    FILTERS_DOCUMENTS: 'employeesfilters_document_chosen',
    FILTERS_TRAVEL_POLICY: 'employeesfilters_travelpolicy_chosen',
    FILTERS_PROJECTS: 'employeesfilters_project_chosen',
    FILTERS_TAGS: 'employeesfilters_tags_chosen',
    FILTERS_BONUS_CARDS: 'settingsemployees_bonuscards_chosen',

    FILTERS_DELETE_TAG: 'settingsemployees_deletefilter_pressed',
    FILTERS_DELETE_ALL_TAGS: 'settingsemployees_deleteallfilters_pressed',
  },

  [CATEGORY.REPORTING]: {
    SCREEN_OPENED: 'reporting_screen_opened',
    ACT_PRESSED: 'reporting_act_pressed',
    ACT_DOWNLOAD_PRESSED: 'act_download_pressed',
    TOTAL_ACT_CHOSEN: 'reporting_totalact_chosen',
    PREPAY_INVOICE_PRESSED: 'reporting_prepayinvoice_pressed',
    DEB_INVOICE_PRESSED: 'reporting_debtinvoice_pressed',
    CART_PRESSED: 'reporting_card_pressed',
    ALL_OPERATIONS: 'reporting_alloperations_opened',
    DOC_ARCHIVE: 'reporting_docarchive_pressed',
    SEND_INVOICE: 'reporting_tripinvoiceemail_pressed',

    MAIN_EXPENSE_OPENED: 'main_expense_opened',
    EXPENSE_CREATE_REPORT_PRESSED: 'expense_create_report_pressed',
    EXPENSE_DETAIL_SAVE_PRESSED: (advanceStatus: AnalyticAdvanceStatus, date: string, id: number) => [
      'expense_detail_save_pressed', { advanceStatus, date, id },
    ],
    EXPENSE_FILTER_APPLIED: (filterExpense: AnalyticFilterExpense) => [
      'expense_filter_applied', { filterExpense },
    ],
    EXPENSE_FILTER_CANCELED: 'expense_filter_canceled',
    EXPENSE_DOWNLOAD_PRESSED: 'expense_download_pressed',
    NEW_EXPENSE_SCREEN_OPENED: 'new_expense_screen_opened',
    NEW_EXPENSE_EMPLOYEE_CHOSEN: 'new_expense_employee_chosen',
    NEW_EXPENSE_TRIPS_CHOSEN: 'new_expense_trips_chosen',
    NEW_EXPENSE_SAVE_BUTTON_PRESSED: 'new_expense_save_button_pressed',
    NEW_EXPENSE_CANCEL_BUTTON_PRESSED: 'new_expense_cancel_button_pressed',
    NEW_EXPENSE_EDIT_DAILY_PRESSED: 'new_expense_edit_daily_pressed',
    NEW_EXPENSE_PREPAID_EXPENSE_PRESSED: 'new_expense_prepaid_expense_pressed',
    NEW_EXPENSE_CONSUMPTION_PRESSED: (addExpense: AnalyticAddExpense) => [
      'new_expense_consumption_pressed', { addExpense },
    ],
    NEW_EXPENSE_DOCUMENT_PRESSED: 'new_expense_document_pressed',
    NEW_EXPENSE_ON_APPROVAL_BUTTON_PRESSED: 'new_expense_on_approval_button_pressed',
    NEW_EXPENSE_APPROVAL_BUTTON_PRESSED: 'new_expense_approval_button_pressed',
    NEW_EXPENSE_REFUSE_APPROVAL_PRESSED: 'new_expense_refuse_approval_pressed',
  },
  [CATEGORY.MAIN]: {
    MAIN_SMARTDESK_OPENED: 'main_smartdesk_opened',
    MAIN_TRIPS_OPENED: 'main_trips_opened',
    MAIN_CARD_OPENED: 'main_card_opened',
  },
  [CATEGORY.SMART_AGENT]: {
    MAIN_SEARCH_OPENED: 'main_search_opened',
    MAIN_PASSENGERS_OPENED: 'main_passengers_opened',

    SETTINGS_FORM_OPENED: 'settings_forms_opened',
    SETTINGS_FORM_LOGO: 'settingsforms_logo_pressed',
    SETTINGS_FORM_PREVIEW: 'settingsforms_preview_pressed',
    SETTINGS_FORM_PREVIEW_DOWNLOAD: 'formspreview_download_pressed',
    SETTINGS_FORM_SAVE_BUTTON: 'settingsforms_savebutton_pressed',
    SETTINGS_FORM_EDIT_BUTTON: 'settingsforms_editbutton_pressed',
    SETTINGS_FORM_DELETE_BUTTON: 'settingsforms_detete_pressed',

    NOTE_USER_FILTER: 'note_userfilter_chosen',
    NOTE_SERVICE_FILTER: 'note_servicefilter_chosen',

    CARD_AGENCY_FEE_PRESSED: 'card_agencyfee_pressed',
    AGENCY_FEE_ADD_PRESSED: 'cardagencyfee_add_pressed',
    AGENCY_FEE_EDIT_PRESSED: 'cardagencyfee_edit_pressed',
    AGENCY_FEE_DELETE_PRESSED: 'cardagencyfee_delete_pressed',

    TRIP_AGENCY_FEE_PRESSED: 'trip_agencyfee_pressed',
    TRIP_AGENCY_FEE_ADD_PRESSED: 'tripagencyfee_add_pressed',
    TRIP_AGENCY_FEE_EDIT_PRESSED: 'tripagencyfee_edit_pressed',
    TRIP_AGENCY_FEE_DELETE_PRESSED: 'tripagencyfee_delete_pressed',

    OPENED: 'settings_constructor_opened',
    SAVE_PRESSED: ({ service, taxobject, taxtype }: IAnalyticProperties) => [
      'settingsconstructor_save_pressed', { service, taxobject, taxtype },
    ],
    EDIT_PRESSED: ({ service, taxobject, taxtype }: IAnalyticProperties) => [
      'settingsconstructor_edit_pressed', { service, taxobject, taxtype },
    ],
    DELETE_PRESSED: ({ service, taxobject, taxtype }: IAnalyticProperties) => [
      'settingsconstructor_delete_pressed', { service, taxobject, taxtype },
    ],
  },

  [CATEGORY.PPR]: {
    OPEN_POPUP: 'pprmain_banner_showed',
    CLOSE_POPUP: 'pprmain_banner_closed',
  },
};

const ACTIONS_RU: { [key: string]: any } = {
  [CATEGORY.AIRLINE]: {
    [ACTIONS.AIRLINE.SEARCHONEWAY]: 'Поиск в одну сторону',
    [ACTIONS.AIRLINE.SEARCHTHEREAGAIN]: 'Поиск туда-обратно',
    [ACTIONS.AIRLINE.SEARCHCOMPLEXROUTE]: 'Поиск сложного маршрута',

    [ACTIONS.AIRLINE.SEARCHBYHISTORYONEWAY]: 'Поиск в одну сторону из истории',
    [ACTIONS.AIRLINE.SEARCHBYHISTORYTHEREAGAIN]: 'Поиск туда-обратно из истории',
    [ACTIONS.AIRLINE.SEARCHBYHISTORYCOMPLEXROUTE]: 'Поиск сложного маршрута из истории',

    [ACTIONS.AIRLINE.UPDATESEARCHONEWAY]: 'Обновление поиска в одну сторону',
    [ACTIONS.AIRLINE.UPDATESEARCHTHEREAGAIN]: 'Обновление поиска туда-обратно',

    [ACTIONS.AIRLINE.CHANGESEARCHCOMPLEXROUTE]: 'Изменение сложного поиска',
    [ACTIONS.AIRLINE.CHANGEDIRECTION]: 'Изменение направления',

    [ACTIONS.AIRLINE.NORESULT]: 'Пустой результат',
    [ACTIONS.AIRLINE.FARESELECTION]: 'Выбор тарифа',

    [ACTIONS.AIRLINE.ADDTONOTE]: 'Добавление в блокнот',
    [ACTIONS.AIRLINE.ADDTOFAVORITES]: 'Добавление в избранное',
    [ACTIONS.AIRLINE.ADDTOCART]: 'Добавление в корзину',

    [ACTIONS.AIRLINE.SORTING]: 'Сортировака',

    [ACTIONS.AIRLINE.FILTERSWITCH]: 'Фильтр: переключатель',
    [ACTIONS.AIRLINE.FILTERCHECKBOX]: 'Фильтр: чекбокс',
    [ACTIONS.AIRLINE.FILTERSLIDER]: 'Фильтр: слайдер',
    [ACTIONS.AIRLINE.FILTEROTHER]: 'Фильтр: другой',
    [ACTIONS.AIRLINE.FILTERADVANCE]: 'Фильтр: предварительный',

    [ACTIONS.AIRLINE.SHOWDETAILS]: 'Просмотр деталей',

    [ACTIONS.AIRLINE.GETLINK]: 'Получение ссылки',
    [ACTIONS.AIRLINE.COPYLINK]: 'Копирование ссылки',

    [ACTIONS.AIRLINE.DATECHANGE]: 'Изменение дат',
  },

  [CATEGORY.HOTELS]: {
    [ACTIONS.HOTELS.SEARCHBYNAME]: 'Поиск по названию',
    [ACTIONS.HOTELS.SEARCHBYREGION]: 'Поиск по региону',
    [ACTIONS.HOTELS.SEARCHBYNAMEFROMHISTORY]: 'Поиск по названию из истории',
    [ACTIONS.HOTELS.SEARCHBYREGIONFROMHISTORY]: 'Поиск по региону из истории',
    [ACTIONS.HOTELS.SEARCHBYRADIUS]: 'Поиск по радиусу',
    [ACTIONS.HOTELS.SEARCHBYRADIUSABORT]: 'Прерывание поиска по радиусу',
    [ACTIONS.HOTELS.SETPOINTTOMAP]: 'Установлена точка на карту',

    [ACTIONS.HOTELS.UPDATESEARCHBYREGION]: 'Обновление поиска по региону',
    [ACTIONS.HOTELS.UPDATESEARCHBYNAME]: 'Обновление поиска по названию',
    [ACTIONS.HOTELS.SHOWDEHOTELFROMMAP]: 'Показ отеля на карте',

    [ACTIONS.HOTELS.TOHOTELPAGE]: 'Переход на страницу отеля',

    [ACTIONS.HOTELS.EMPTYREQUEST]: 'Пустой результат',

    [ACTIONS.HOTELS.REQUESTROOMS]: 'Запрос номера',

    [ACTIONS.HOTELS.ADDTONOTE]: 'Добавление в блокнот',
    [ACTIONS.HOTELS.ADDTOCART]: 'Добавление в корзину',

    [ACTIONS.HOTELS.SWITCHPHOTO]: 'Переключение фотографий',

    [ACTIONS.HOTELS.OPENLISTMAP]: 'Открытие режима карта+список',
    [ACTIONS.HOTELS.CLOSELISTMAP]: 'Закрытие режима карта+список',

    [ACTIONS.HOTELS.FILTERSWITCH]: 'Фильтр: переключатель',
    [ACTIONS.HOTELS.FILTERCHECKBOX]: 'Фильтр: чекбокс',
    [ACTIONS.HOTELS.FILTERSLIDER]: 'Фильтр: слайдер',
    [ACTIONS.HOTELS.FILTEROTHER]: 'Фильтр: другой',
    [ACTIONS.HOTELS.FILTERADVANCE]: 'Фильтр: предварительный',
    [ACTIONS.HOTELS.FILTERNUMBERS]: 'Фильтр: номера',

    [ACTIONS.HOTELS.SORTING]: 'Сортировка',

    [ACTIONS.HOTELS.CHANGEDATES]: 'Изменение дат',

    [ACTIONS.HOTELS.SENDTOTRAVELASSISTANT]: 'Запрос номера',
    [ACTIONS.HOTELS.NONUMBERS]: 'Отсутствие номеров',

    [ACTIONS.HOTELS.GETLINK]: 'Получение ссылки',
    [ACTIONS.HOTELS.COPYLINK]: 'Копирование ссылки',

    [ACTIONS.HOTELS.MULTIPLEROOMSSEARCH]: 'Поиск нескольких номеров',

    [ACTIONS.HOTELS.ALTERNATIVE_MORE_CARDS]: 'Прокрутка альтернатив',
    [ACTIONS.HOTELS.ALTERNATIVE_HOTEL]: 'Выбор альтернативы',
    [ACTIONS.HOTELS.HOTEL_REGION_SEARCH]: 'Поиск по региону со страницы отеля',
  },

  [CATEGORY.TRAINS]: {
    [ACTIONS.TRAINS.SEARCHONEWAY]: 'Поиск в одну сторону',
    [ACTIONS.TRAINS.SEARCHONEWAYFROMHISTORY]: 'Поиск в одну сторону из истории',
    [ACTIONS.TRAINS.SEARCHNORESULT]: 'Пустой результат',

    [ACTIONS.TRAINS.UPDATESEARCHONEWAY]: 'Обновление поиска в одну сторону',

    [ACTIONS.TRAINS.SELECTTRAIN]: 'Выбор поезда',
    [ACTIONS.TRAINS.SELECTTRAINCAR]: 'Выбор вагона',
    [ACTIONS.TRAINS.ADDTOCART]: 'Добавление в корзину',
    [ACTIONS.TRAINS.ADDTOFAVORITES]: 'Добавление в избранное',

    [ACTIONS.TRAINS.CHANGEDIRECTION]: 'Изменение направления',

    [ACTIONS.TRAINS.FILTERSWITCH]: 'Фильтр: переключатель',
    [ACTIONS.TRAINS.FILTERCHECKBOX]: 'Фильтр: чекбокс',
    [ACTIONS.TRAINS.FILTERSLIDER]: 'Фильтр: слайдер',
    [ACTIONS.TRAINS.FILTEROTHER]: 'Фильтер: другой',

    [ACTIONS.TRAINS.SORTING]: 'Сортировка',

    [ACTIONS.TRAINS.GETLINK]: 'Получение ссылки',
    [ACTIONS.TRAINS.COPYLINK]: 'Копирование ссылки',
  },

  [CATEGORY.TRANSFER]: {
    [ACTIONS.TRANSFER.SEARCHONEWAY]: 'Поиск в одну сторону',
    [ACTIONS.TRANSFER.SEARCHBYHISTORYONEWAY]: 'Поиск в одну сторону из истории',
    [ACTIONS.TRANSFER.UPDATESEARCHONEWAY]: 'Обновление поиска в одну сторону',

    [ACTIONS.TRANSFER.CHANGEDIRECTION]: 'Изменение направления',

    [ACTIONS.TRANSFER.ADDTONOTE]: 'Добавление в блокнот',
    [ACTIONS.TRANSFER.ADDTOCART]: 'Добавление в корзину',
    [ACTIONS.TRANSFER.ADDTOFAVORITES]: 'Добавление в избранное',

    [ACTIONS.TRANSFER.SORTING]: 'Сортировка',

    [ACTIONS.TRANSFER.FILTERCHECKBOX]: 'Фильтр: чекбокс',
    [ACTIONS.TRANSFER.FILTERSLIDER]: 'Фильтр: слайдер',
  },

  [CATEGORY.VIP_HALL]: {
    [ACTIONS.VIP_HALL.SEARCHONEWAY]: 'Поиск в одну сторону',
    [ACTIONS.VIP_HALL.VIP_ROUTE_ROUTE_CHOSEN]: 'Выбран участок маршрута',
    [ACTIONS.VIP_HALL.VIP_ROUTE_BUTTON_PRESSED]: 'Нет подходящего аэропорта',
    [ACTIONS.VIP_HALL.VIP_ROUTE_REQUEST_SENT]: 'Отправлен запрос в чат',
    [ACTIONS.VIP_HALL.VIP_AIRPORT_AIRPORT_CHOSEN]: 'Выбран аэропорт',
    [ACTIONS.VIP_HALL.VIP_AIRPORT_BUTTON_PRESSED]: 'Нет подходящего аэропорта',
    [ACTIONS.VIP_HALL.VIP_AIRPORT_REQUEST_SENT]: 'Отправлен запрос в чат',
    [ACTIONS.VIP_HALL.VIP_TERMINAL_BUTTON_PRESSED]: 'Выбран терминал',
    [ACTIONS.VIP_HALL.VIP_SORT_PRESSED]: 'Сортировка',
    [ACTIONS.VIP_HALL.VIP_BUTTON_PRESSED]: 'Переход к заказу зала ожидания',
    [ACTIONS.VIP_HALL.VIP_DETAIL_PAGE_SCROLLED]: 'Скролл страницы',
    [ACTIONS.VIP_HALL.VIP_DETAIL_CHECKBOX_CHECKED]: 'Нажат чекбокс согласия со стоимостью',
    [ACTIONS.VIP_HALL.VIP_DETAIL_BUTTON_PRESSED]: 'Переход к оплате',
    [ACTIONS.VIP_HALL.VIP_PAYMENT_CHECKBOX_CHECKED]: 'Нажат чекбокс согласия со стоимостью',
    [ACTIONS.VIP_HALL.VIP_PAYMENT_BUTTON_PRESSED]: 'Подтверждение оплаты',
    [ACTIONS.VIP_HALL.VIP_PAYMENT_POPUP_CLOSED]: 'Закрыта оплата',
    [ACTIONS.VIP_HALL.ESCORT_ROUTE_CHOSEN]: 'Выбран аэропорт',
    [ACTIONS.VIP_HALL.ESCORT_BUTTON_PRESSED]: 'Нет подходящего аэропорта',
    [ACTIONS.VIP_HALL.ESCORT_REQUEST_SENT]: 'Отправлен запрос в чат',
    [ACTIONS.VIP_HALL.ESCORT_AIRPORT_AIRPORT_CHOSEN]: 'Выбран аэропорт и подобраны аэропорты',
    [ACTIONS.VIP_HALL.ESCORT_AIRPORT_BUTTON_PRESSED]: 'Нет подходящего аэропорта',
    [ACTIONS.VIP_HALL.ESCORT_AIRPORT_REQUEST_SENT]: 'Отправлен запрос в чат',
    [ACTIONS.VIP_HALL.ESCORT_TERMINAL_BUTTON_PRESSED]: 'Выбран терминал',
    [ACTIONS.VIP_HALL.ESCORT_SORT_PRESSED]: 'Сортировка',
    [ACTIONS.VIP_HALL.ESCORT_BUTTON_PRESSED]: 'Переход к заказу услги',
    [ACTIONS.VIP_HALL.ESCORT_DETAIL_PAGE_SCROLLED]: 'Скролл страницы',
    [ACTIONS.VIP_HALL.ESCORT_DETAIL_CHECKBOX_CHECKED]: 'Нажат чекбокс согласия со стоимостью',
    [ACTIONS.VIP_HALL.ESCORT_DETAIL_BUTTON_PRESSED]: 'Переход к оплате',
    [ACTIONS.VIP_HALL.ESCORT_PAYMENT_CHECKBOX_CHECKED]: 'Нажат чекбокс согласия со стоимостью',
    [ACTIONS.VIP_HALL.ESCORT_PAYMENT_BUTTON_PRESSED]: 'Подтверждение оплаты',
    [ACTIONS.VIP_HALL.ESCORT_PAYMENT_POPUP_CLOSED]: 'Закрыта оплата',
  },

  [CATEGORY.NOTE]: {
    [ACTIONS.NOTE.CREATERESERVATION]: 'Переход к бронированию',
    [ACTIONS.NOTE.ADDTOCART]: 'Добавление в корзину',
    [ACTIONS.NOTE.REMOVESERVICE]: 'Удаление услуги',
    [ACTIONS.NOTE.REMOVEFOULNOTE]: 'Удаление неактуальной услуги',
    [ACTIONS.NOTE.REMOVEFOULNOTEALL]: 'Удаление всех неактуальных услуг',
    [ACTIONS.NOTE.SAVEPDF]: 'Сохранение PDF',
    [ACTIONS.NOTE.SENDAGREEMENT]: 'Отправка на согласование',
    [ACTIONS.NOTE.FASTSEARCH]: 'Быстрый поиск',
    [ACTIONS.NOTE.FILTER]: 'Фильтр',
    [ACTIONS.NOTE.BOOKING]: 'Бронирование',
  },

  [CATEGORY.CART]: {
    [ACTIONS.CART.SELECTEMPLOYEE]: 'Выбор сотрудника',
    [ACTIONS.CART.ADDEMPLOYEE]: 'Добавление сотрудника',
    [ACTIONS.CART.SELECTDOCUMENT]: 'Выбор документа',
    [ACTIONS.CART.SELECTORGANIZATION]: 'Выбор организации',
    [ACTIONS.CART.SELECTPROJECT]: 'Выбор центра затрат',
    [ACTIONS.CART.SELECTOPTIONEMPLOYEE]: 'Выбор опционального сотрудника',
    [ACTIONS.CART.REMOVEEMPLOYEE]: 'Удаление сотрудника',
    [ACTIONS.CART.REMOVEOPTIONALEMPLOYEE]: 'Удаление необязательного сотрудника',
    [ACTIONS.CART.ADDTONOTE]: 'Добавление в блокнот',
    [ACTIONS.CART.REMOVESERVICE]: 'Удаление услуги',
    [ACTIONS.CART.REMOVESERVICEALL]: 'Удаление всех услуг',
    [ACTIONS.CART.TRANSITIONTOPAYMENT]: 'Переход к оплате',
    [ACTIONS.CART.OPENINGTARIFFRULES]: 'Открытие правил тарифов',
    [ACTIONS.CART.HOTELPURCHASE]: 'Докупка отеля',
  },

  [CATEGORY.PAYMENT]: {
    [ACTIONS.PAYMENT.SUCCESS]: 'Успех',
    [ACTIONS.PAYMENT.DOWNLOADVOUCHER]: 'Скачивание ваучера',
    [ACTIONS.PAYMENT.DOWNLOADINVOICE]: 'Скачивание счета',
    [ACTIONS.PAYMENT.RETURNINMAIN]: 'Возврат на главную',
    [ACTIONS.PAYMENT.GOTODETAILS]: 'Переход к деталям',
    [ACTIONS.PAYMENT.BOOKINGERROR]: 'Ошибка бронирования',
    [ACTIONS.PAYMENT.RETURNINCART]: 'Возврат в корзину',
    [ACTIONS.PAYMENT.REQUESTSINTRAVELASSISTANT]: 'Запрос в Travel помощник',
  },

  [CATEGORY.TRIPS]: {
    [ACTIONS.TRIPS.VIEWLIST]: 'Просмотр списка',

    [ACTIONS.TRIPS.FILTERSUSE]: 'Фильтр: применение',
    [ACTIONS.TRIPS.FILTERSSEARCH]: 'Фильтр: поиск',
    [ACTIONS.TRIPS.FILTERSCLEAR]: 'Фильтр: сброс',

    [ACTIONS.TRIPS.VIEWTRIPS]: 'Просмотр поездки',
    [ACTIONS.TRIPS.SENDVOUCHERS]: 'Отправка ваучеров',
    [ACTIONS.TRIPS.DOWNLOADVOUCHERS]: 'Скачивание ваучеров',
    [ACTIONS.TRIPS.CANCELLATIONREQUEST]: 'Запрос аннуляции',
    [ACTIONS.TRIPS.CANCELLATIONCANCEL]: 'Отмена аннуляции',
  },

  [CATEGORY.SERVICEINTRIPS]: {
    [ACTIONS.SERVICEINTRIPS.SENDVOUCHERS]: 'Отправка ваучеров',
    [ACTIONS.SERVICEINTRIPS.DOWNLOADVOUCHERS]: 'Скачивание ваучеров',
    [ACTIONS.SERVICEINTRIPS.CANCELLATIONREQUEST]: 'Запрос аннуляции',
    [ACTIONS.SERVICEINTRIPS.CANCELLATIONCANCEL]: 'Отмена аннуляции',
    [ACTIONS.SERVICEINTRIPS.REQUESTAUTOMATICCANCELLATION]: 'Запрос автоматической аннуляции',
    [ACTIONS.SERVICEINTRIPS.CONFIRMATIONAUTOMATICCANCELLATION]: 'Подвтверждение автоматической аннуляции',
    [ACTIONS.SERVICEINTRIPS.CANCELAUTOMATICCANCELLATION]: 'Отмена автоматической аннуляции',
    [ACTIONS.SERVICEINTRIPS.VIEWEDITHISTORY]: 'Просмотр истории изменений',
  },

  [CATEGORY.ADDITIONALSERVICES]: {
    [ACTIONS.ADDITIONALSERVICES.ORDER]: 'Заказ',
  },

  [CATEGORY.SMARTDESC]: {
    [ACTIONS.SMARTDESC.GOTOSEARCH]: 'Переход в поиск',
    [ACTIONS.SMARTDESC.GOTOTRIPS]: 'Переход в поездки',
    [ACTIONS.SMARTDESC.ADDTASK]: 'Добавление задачи',
    [ACTIONS.SMARTDESC.COMPLETEDTODO]: 'Выполнение задачи',
    [ACTIONS.SMARTDESC.RETURNTODO]: 'Возврат задачи',
    [ACTIONS.SMARTDESC.DELETETODO]: 'Удаление задачи',
    [ACTIONS.SMARTDESC.OPENALLTODO]: 'Открытие всех задач',
    [ACTIONS.SMARTDESC.DELETEALLTODO]: 'Удаление всех задач',
  },

  [CATEGORY.MENU]: {
    [ACTIONS.MENU.SELECTION]: 'Выбор',
  },

  [CATEGORY.QUALITYCONTROL]: {
    [ACTIONS.QUALITYCONTROL.COMPLAINT]: 'Жалоба',
  },

  [CATEGORY.FAVORITES]: {
    [ACTIONS.FAVORITES.GOTOSEARCH]: 'Переход к поиску',
    [ACTIONS.FAVORITES.SEARCH]: 'Поиск',
    [ACTIONS.FAVORITES.REMOVEFROMFAVORITES]: 'Удаление из избранного',
    [ACTIONS.FAVORITES.FASTSEARCH]: 'Быстрый поиск',
    [ACTIONS.FAVORITES.FILTER]: 'Фильтр',
  },

  [CATEGORY.APPROVEMENT]: {
    [ACTIONS.APPROVEMENT.OPENSENDTOAPPROVEDIALOG]: 'Запрос на согласование',
    [ACTIONS.APPROVEMENT.SENDTOAPPROVETOONEAPPROVER]: 'Отправка запроса на согласование',
    [ACTIONS.APPROVEMENT.SENDTOAPPROVETOSEVERALAPPROVERS]: 'Отправка запроса на согласование нескольким пользователям',
    [ACTIONS.APPROVEMENT.SENDTOAPPROVEWITHOPTIONS]: 'Отправка запроса на выбор вариантов',
    [ACTIONS.APPROVEMENT.DELETEWAITINGAPPROVE]: 'Отмена запроса на согласование',
    [ACTIONS.APPROVEMENT.DELETEAPPROVED]: 'Удаление согласованной поездки',
    [ACTIONS.APPROVEMENT.DELETEDECLINED]: 'Удаление не согласованной поездки',
    [ACTIONS.APPROVEMENT.OPENAPPROVEPAGE]: 'Открытие запроса',
    [ACTIONS.APPROVEMENT.APPROVE]: 'Согласование поездки',
    [ACTIONS.APPROVEMENT.DECLINE]: 'Отклонение поездки',
    [ACTIONS.APPROVEMENT.BUYTRIP]: 'Выкуп поездки',
    [ACTIONS.APPROVEMENT.DELETEBYAPPROVER]: 'Удаление поездки из запроса',
    [ACTIONS.APPROVEMENT.DELETEBYAPPROVEROPTION]: 'Удаление услуги из запроса',
    [ACTIONS.APPROVEMENT.FASTAPPROVE]: 'Быстрое согласование поездки',
    [ACTIONS.APPROVEMENT.FASTDECLINE]: 'Быстрое отклонение поездки',
  },
};

const LABELS: { [key: string]: any } = {
  [CATEGORY.AIRLINE]: {
    Econom: 'Эконом',
    Business: 'Бизнес',
    First: 'Первый',

    price_down: 'По цене ↑',
    distance_down: 'По длительности перелета ↑',
    departureTime_down: 'По времени вылета ↑',
    transferTime_down: 'По длительности пересадки ↑',

    COMPLEXROUTE: 'Сложный маршрут',

    WITHOUTTRANSPLANT: 'Без пересадок',

    Included10AndMore: 'Включено 10кг и более',

    Charge: 'Возможен со сбором',
    Included: 'Возможен без сбора',

    0: 'Без пересадок',
    1: '1 пересадка',
    2: '2 пересадки',

    'filters.fewAirlineCompanies': 'Несколько авиакомпаний',
  },

  [CATEGORY.HOTELS]: {
    distance: 'Расстояние от центра ↑',
    smart_hotel: 'Сначала рекомендованные',
    price_up: 'Сначала дешевле',
    price_down: 'Сначала дороже ',
    stars_up: 'Количество звезд ↑',
    stars_down: 'Количество звезд ↓',

    PREVIEW: 'Превью',
    ARROWRIGHT: 'Стрелка вправо',
    ARROWLEFT: 'Стрелка влево',

    HASMEAL: 'с завтраком',
    NOHASMEAL: 'без завтрака',
    HASMEALNAME: 'питание',
    NOHASMEALNAME: 'без питания',
    HASCANCELLATION: 'возвратный',
    NOHASCANCELLATION: 'невозвратный',

    TARIFFSWITHVAT: 'Только тарифы с НДС',

    RADIUSFILTERTOPOINT: 'Расстояние до точки',
    RADIUSFILTERTOCENTER: 'Расстояние до центра',

    TOHOTELPAGEOUTOFLIST: 'Переход на отель из режима список',
    TOHOTELPAGEOUTOFLISTMAP: 'Переход на отель из режима карта+список',

    'filters.favorite': 'Избранное',
    'filters.smartHotel': 'Рекомендовано для командировок',
    'filters.hasVat': 'Работает с НДС',

    [ACTIONS.HOTELS.UPDATE_BREAKFAST_FILTER_ON_REGION]: 'Завтрак включен (поиск по региону)',
    [ACTIONS.HOTELS.UPDATE_BREAKFAST_FILTER_OFF_REGION]: 'Завтрак отключен (поиск по региону)',

    [ACTIONS.HOTELS.UPDATE_CANCELLATION_FILTER_ON_REGION]: 'Бесплатная отмена бронирования включена (поиск по региону)',
    [ACTIONS.HOTELS.UPDATE_CANCELLATION_FILTER_OFF_REGION]: 'Бесплатная отмена бронирования отключен (поиск по региону)',

    [ACTIONS.HOTELS.UPDATE_ONLINE_FILTER_ON_REGION]: 'Только онлайн включен (поиск по региону)',
    [ACTIONS.HOTELS.UPDATE_ONLINE_FILTER_OFF_REGION]: 'Только онлайн отключен (поиск по региону)',

    [ACTIONS.HOTELS.UPDATE_BREAKFAST_FILTER_ON_HOTEL]: 'Завтрак включен (гостиница)',
    [ACTIONS.HOTELS.UPDATE_BREAKFAST_FILTER_OFF_HOTEL]: 'Завтрак отключен (гостиница)',

    [ACTIONS.HOTELS.UPDATE_CANCELLATION_FILTER_ON_HOTEL]: 'Бесплатная отмена бронирования включена (гостиница)',
    [ACTIONS.HOTELS.UPDATE_CANCELLATION_FILTER_OFF_HOTEL]: 'Бесплатная отмена бронирования отключен (гостиница)',

    [ACTIONS.HOTELS.UPDATE_ONLINE_FILTER_ON_HOTEL]: 'Только онлайн включен (гостиница)',
    [ACTIONS.HOTELS.UPDATE_ONLINE_FILTER_OFF_HOTEL]: 'Только онлайн отключен (гостиница)',

  },

  [CATEGORY.TRAINS]: {
    duration_up: 'Время в пути ↑',
    duration_down: 'Время в пути ↓',
    departure_up: 'Время отбытия ↑',
    departure_down: 'Время отбытия ↓',
    arrival_up: 'Время прибытия ↑',
    arrival_down: 'Время прибытия ↓',
    price_up: 'Сначала дешевле',
    price_down: 'Сначала дороже',

    SEARCHRESULTS: 'Результаты поиска',

    TRAININFORMATION: 'Информация о поезде',
  },

  [CATEGORY.TRANSFER]: {
    price_up: 'Сначала дешевле',
    price_down: 'Сначала дороже',
    FAVORITES: 'Избранное',
  },

  [CATEGORY.NOTE]: {
    PANEL: 'Панель управления',
    CARD: 'Карточка услуги',
  },

  [CATEGORY.CART]: {
    DomesticPassport: 'Паспорт РФ',
    ForeignPassport: 'Заграничный паспорт',
    Air: 'Авиабилеты',
    Hotel: 'Гостиницы',
    Train: 'Ж/Д билеты',
    Transfer: 'Трансфер',
    GOTOSEARCH: 'Переход к поиску',
    SPECIFICOFFER: 'Конкретное предложение',
  },

  [CATEGORY.PAYMENT]: {
    Air: 'Авиабилеты',
    Hotel: 'Гостиницы',
    Train: 'Ж/Д билеты',
    Transfer: 'Трансфер',
    SUCCESSFULPAYMENT: 'Успешная оплата',
    WAITINGFORCONFIRMATION: 'Ожидание подтверждения',
  },

  [CATEGORY.TRIPS]: {
    CITY: 'Города',
    STATUSES: 'Статус',
    PROJECTS: 'Центры затрат',
    EMPLOYES: 'Сотрудники',
    SERVICES: 'Услуги',
    COMPANIES: 'Организации',
    ANYDATE: 'Любая дата',
    SELECTNUMBER: 'Выбрать число',
    SELECTPERIOD: 'Выбрать период',
  },

  [CATEGORY.SERVICEINTRIPS]: {
    Air: 'Авиабилеты',
    Hotel: 'Гостиницы',
    Train: 'Ж/Д билеты',
    Transfer: 'Трансфер',
  },

  [CATEGORY.SMARTDESC]: {
    searchAirline: 'Авиабилеты',
    searchHotel: 'Гостиницы',
    searchTrain: 'Ж/Д билеты',
    searchTransfer: 'Трансфер',

    CALENDAR: 'Календарь',
    CURRENTTRIPS: 'Текущие поездки',

    MAINDISPLAY: 'Главный экран',
    ALLTASK: 'Все задачи',
  },

  [CATEGORY.MENU]: {
    LOGO: 'Smartway',
    TRAVELASSISTANT: 'Travel помощник',
    QUALITYCONTROL: 'Контроль качества',
    NOTE: 'Блокнот',
    CART: 'Корзина',
    SETTINGS: 'Настройки',
    FAVORITES: 'Избранное',
    LOGOUT: 'Выйти',
    MATCHING: 'Согласование',
    ACCEPT: 'Доступно',
  },

  [CATEGORY.FAVORITES]: {
    Air: 'Авиабилеты',
    Hotel: 'Гостиницы',
    Train: 'Ж/Д билеты',
    Transfer: 'Трансфер',
  },
};

export {
  CATEGORY,
  CATEGORY_RU,
  ACTIONS,
  ACTIONS_RU,
  LABELS,
};
