// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Text, PROPS } from 'new-ui';

import { isNull } from '../../../../bi/utils/isNull';

import styles from './index.module.css';

const SortArrowOption = ({ item }) => {
  const { type, label } = item;

  const hasType = !isNull(type);
  const typeIsUp = hasType && !!type;

  const iconStyles = [styles.icon];

  if (!typeIsUp) {
    iconStyles.push(styles.down);
  }

  return (
    <Text
      type={ PROPS.TEXT.TYPES.NORMAL_16 }
      className={ styles.item }
    >
      { label }
      { hasType && (
        <Icon
          type={ PROPS.ICON.TYPES.ARROWS.UP }
          className={ iconStyles.join(' ') }
        />
      ) }
    </Text>
  );
};

SortArrowOption.propTypes = {
  item: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    type: PropTypes.number,
  }).isRequired,
};

export { SortArrowOption };
