import React from 'react';
import { Text } from 'new-ui';
import { NavLink } from 'react-router-dom';

import { getText } from '../../../../../i18n';

import ROUTES from '../../../../bi/constants/routes';

import { ITitleTrips } from '../../types';

import styles from '../Trips/styles/index.module.css';

const LABELS = {
  GO_TO_TRIPS: getText('smartdesk:tripsPanel.goTo'),
  UPCOMING_TRIPS: getText('smartdesk:tripsPanel.upcomingTrips'),
};

const TitleTrips = ({
  isShow,
  isShowGoTo = true,
}: ITitleTrips) => {
  if (!isShow) {
    return null;
  }

  const renderGoTo = () => {
    if (!isShowGoTo) {
      return null;
    }

    return (
      <NavLink to={ ROUTES.TRIPS.MAIN }>
        <Text color='emerald' type='NORMAL_14'>
          { LABELS.GO_TO_TRIPS }
        </Text>
      </NavLink>
    );
  };

  return (
    <div
      className={ styles.wrapper_title }
      onClick={ (e) => e.stopPropagation() }
    >
      <Text type='bold_20'>
        { LABELS.UPCOMING_TRIPS }
      </Text>
      { renderGoTo() }
    </div>
  );
};

export default TitleTrips;
