import React from 'react';
import { Button, IconButton } from 'new-ui';

import styles from '../styles/index.module.css';

interface IToDoItem {
  content: string,
  onDeleteItem: (idx: number) => void,
  idx: number,
}

const ToDoItem = ({
  content,
  onDeleteItem,
  idx,
}: IToDoItem) => (
  <div className={ styles.options_item }>
    <IconButton
      className={ styles.options_item__delete }
      iconType={ 'closeButton' }
      iconColor={ 'accent' }
      onClick={ () => onDeleteItem(idx) }
    />
    <Button
      type='textual'
    >
      { content }
    </Button>
  </div>
);

export { ToDoItem };
