import React, { FC } from 'react';
import { Text } from 'new-ui';

import { getText } from '../../../i18n';

import { prepareDataFlight, prepareDataPerson, prepareAirlineNames } from '../../bi/utils/airlineAdditionalServices';

import { AdditionalServicesHeaderProps, TicketDataInterface } from '../../bi/types/airlineAdditionalServices';

import styles from './index.module.css';

const LABELS = {
  ADDITIONAL_TITLE: getText('components:additionalServices.title'),
};

const AdditionalServicesHeader: FC<AdditionalServicesHeaderProps> = ({ details, ticketId }) => {
  const ticket = details?.OrderItems.find(item => item.Id === +ticketId);
  const ticketData: TicketDataInterface = ticket && JSON.parse(ticket.ActualVersion.JsonData);

  const renderDataFlight = () => ticketData.Routes.map((route, index) => {
    const { firstSegment, lastSegment, departureData, arrivalData } = prepareDataFlight(route);
    const { Segments } = route;

    return (
      <Text
        className={ styles.data }
        key={ `ticket_data_${index}` }
        color='gray'
      >
        { `${firstSegment?.DepartureCity} – ${lastSegment?.ArrivalCity},`}
        {` ${departureData} – ${arrivalData}, ${prepareAirlineNames(Segments, Segments[0])}` }
      </Text>
    );
  });

  return (
    <div className={ styles.wrapper }>
      <Text
        className={ styles.number_ticket }
        type='bold_18'
        color='gray'
      >
        { `${LABELS.ADDITIONAL_TITLE} #${ticketData.Tickets[0]}` }
      </Text>
      <Text
        className={ styles.data }
        color={ 'gray' }
      >
        { prepareDataPerson(ticketData, ticket) }
      </Text>
      { renderDataFlight() }
    </div>
  );
};

export { AdditionalServicesHeader };
