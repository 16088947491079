// @ts-nocheck
import PropTypes from 'prop-types';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';

import STATUS from '../../bi/constants/status';
import CONFIG from '../../../config';

const yaCounter = CONFIG.METRIKS.YANDEX.ID;
const enabled = CONFIG.METRIKS.YANDEX.ENABLED;

class YandexMetric extends Component {
  static propTypes = {
    workspaceService: PropTypes.object.isRequired,
    appService: PropTypes.object.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.object,
    ]).isRequired,
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    const { history } = props;

    this.state = {
      lastPathName: history.location.pathname,
    };

    this.initializeYandexMetric();
  }

  componentDidMount() {
    const { history } = this.props;

    history.listen((location) => {
      const { lastPathName } = this.state;

      if (lastPathName !== location.pathname) {
        this.changeUrl(lastPathName, location.pathname);
      }
    });
  }

  checkMetric = () => {
    const { appService } = this.props;
    const { appStatus } = appService.get();

    return enabled && appStatus !== STATUS.NOTAUTH && typeof (window[yaCounter]) !== 'undefined';
  };

  initializeYandexMetric = () => {
    const { workspaceService } = this.props;

    const { Email, CompanyName, CompanyId } = workspaceService.get();

    if (this.checkMetric()) {
      window[yaCounter].setUserID(CompanyId);
      window[yaCounter].userParams({
        email: Email,
        companyName: CompanyName,
      });
    }
  };

  changeUrl = (lastPathName, pathName) => {
    if (this.checkMetric()) {
      window[yaCounter].hit(pathName, { referer: lastPathName });
    }

    this.setState({ lastPathName: pathName });
  };

  render() {
    return this.props.children;
  }
}

export default withRouter(YandexMetric);
