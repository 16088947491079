import { MomentInput } from 'moment';

import { getText } from '../../../../i18n';

import { formatDate } from '../../utils/formatDate';

import { PATTERN } from '../../constants/dateFormats';

const AEROEXPRESS_TARIFF_TYPES = {
  STANDARD: 'Standard',
  PRE_STANDARD: 'PreStandard',
  BUSINESS: 'Business',
  TWOWAY: 'TwoWay',
};

const AEROEXPRESS_TARIFF_MAP = {
  [AEROEXPRESS_TARIFF_TYPES.STANDARD]: getText('services:aeroexpress.const.tariffMap.standard'),
  [AEROEXPRESS_TARIFF_TYPES.PRE_STANDARD]: getText('services:aeroexpress.const.tariffMap.preStandard'),
  [AEROEXPRESS_TARIFF_TYPES.BUSINESS]: getText('services:aeroexpress.const.tariffMap.business'),
  [AEROEXPRESS_TARIFF_TYPES.TWOWAY]: getText('services:aeroexpress.const.tariffMap.twoWay'),
};

const AEROEXPRESS_DIRECTION_TYPES = {
  FROM_AIRPORT: 'FromAirport',
  TO_AIRPORT: 'ToAirport',
};

const AEROEXPRESS_DIRECTION_TEXTS = {
  VNUKOVO: {
    FROM_AIRPORT: getText('services:aeroexpress.const.directionText.vnukovo.fromAirport'),
    DEFAULT: getText('services:aeroexpress.const.directionText.vnukovo.default'),
  },
  DOMODEDOVO: {
    FROM_AIRPORT: getText('services:aeroexpress.const.directionText.domodedovo.fromAirport'),
    DEFAULT: getText('services:aeroexpress.const.directionText.domodedovo.default'),
  },
  SHEREMETYEVO: {
    FROM_AIRPORT: getText('services:aeroexpress.const.directionText.sheremetevo.fromAirport'),
    DEFAULT: getText('services:aeroexpress.const.directionText.sheremetevo.default'),
  },
  DEFAULT: getText('services:aeroexpress.const.directionText.default'),
};

const GET_AEROEXPRESS_DIRECTION_TEXT = (airport: string) => {
  switch (airport) {
    case 'Vnukovo':
      return (direction: string) => {
        if (direction === AEROEXPRESS_DIRECTION_TYPES.FROM_AIRPORT) {
          return AEROEXPRESS_DIRECTION_TEXTS.VNUKOVO.FROM_AIRPORT;
        }

        return AEROEXPRESS_DIRECTION_TEXTS.VNUKOVO.DEFAULT;
      };
    case 'Domodedovo':
      return (direction: string) => {
        if (direction === AEROEXPRESS_DIRECTION_TYPES.FROM_AIRPORT) {
          return AEROEXPRESS_DIRECTION_TEXTS.DOMODEDOVO.FROM_AIRPORT;
        }

        return AEROEXPRESS_DIRECTION_TEXTS.DOMODEDOVO.DEFAULT;
      };
    case 'Sheremetyevo':
      return (direction: string) => {
        if (direction === AEROEXPRESS_DIRECTION_TYPES.FROM_AIRPORT) {
          return AEROEXPRESS_DIRECTION_TEXTS.SHEREMETYEVO.FROM_AIRPORT;
        }

        return AEROEXPRESS_DIRECTION_TEXTS.SHEREMETYEVO.DEFAULT;
      };
    default:
      return () => AEROEXPRESS_DIRECTION_TEXTS.DEFAULT;
  }
};

const GET_AEROEXPRESS_BACK_DIRECTION_TYPE = (direction: string) => ({
  [AEROEXPRESS_DIRECTION_TYPES.FROM_AIRPORT]: AEROEXPRESS_DIRECTION_TYPES.TO_AIRPORT,
  [AEROEXPRESS_DIRECTION_TYPES.TO_AIRPORT]: AEROEXPRESS_DIRECTION_TYPES.FROM_AIRPORT,
})[direction];

const AEROEXPRESS_TARIFF_DESCRIPTIONS = {
  [AEROEXPRESS_TARIFF_TYPES.STANDARD]: (date: MomentInput) => getText('services:aeroexpress.const.tariffDescriptions.standard', {
    date: formatDate(date, PATTERN.DAY_OF_MONTH_WITH_YEAR),
  }),
  [AEROEXPRESS_TARIFF_TYPES.PRE_STANDARD]: (date: MomentInput) => getText('services:aeroexpress.const.tariffDescriptions.preStandard', {
    date: formatDate(date, PATTERN.DAY_OF_MONTH_WITH_YEAR),
  }),
  [AEROEXPRESS_TARIFF_TYPES.BUSINESS]: (date: MomentInput) =>
    getText('services:aeroexpress.const.tariffDescriptions.business', {
      date: formatDate(date, PATTERN.DAY_OF_MONTH_WITH_YEAR),
    }),
  [AEROEXPRESS_TARIFF_TYPES.TWOWAY]: (date: MomentInput, dateBack?: MomentInput) =>
    getText('services:aeroexpress.const.tariffDescriptions.twoWay', {
      date: formatDate(date, PATTERN.DATE_WITH_YEAR),
      dateBack: formatDate(dateBack, PATTERN.DATE_WITH_YEAR),
    }),
};

const GET_AEROEXPRESS_BUSINESS_TWOWAY = (departure: MomentInput, back: MomentInput) =>
  getText('services:aeroexpress.const.businessTwoWay', {
    date: formatDate(departure, PATTERN.DATE_WITH_YEAR),
    dateBack: formatDate(back, PATTERN.DATE_WITH_YEAR),
  });

const AEROEXPRESS_LATE_TEXT = getText('services:aeroexpress.const.late');

const AEROEXPRESS_ROUTE_TYPES = {
  AIRPORT: 'airport',
};

const AEROEXPRESS_DEFAULT_SCHEDULE_LIST = [{ value: null, label: getText('aeroexpress:menu.notSelected'), rolledUpLabel: '' }];

const AEROEXPRESS_SHEREMETYEVO = 'Sheremetyevo';

const GET_AEROEXPRESS_ADDED_TO_CART_TEXT = getText('services:aeroexpress.const.addedToCart');

enum AeroexpressRouteDestinationType {
  station = 'station',
  airport = 'airport',
}

interface IAeroexpressRouteDestination {
  code: string
  name: string
  type: AeroexpressRouteDestinationType
}

export interface IAeroexpressRoutesResponse {
  from: IAeroexpressRouteDestination
  to: IAeroexpressRouteDestination
}

interface IAeroexpressPricingVariant {
  airport: string
  departure_date: string
  direction: string
  schedule_id: number
  tariffs: {
    price: {
      amount: string
      commission: string
      total: string
    }
    type: string
  }[]
}

export interface IAeroexpressPricingResponse {
  result: {
    count: number
    id: string
    variants: IAeroexpressPricingVariant[]
  }
  error_message: string
  status: string
}

export interface IAeroexpressPricingResponseMapped {
  amount: string
  type: string
  description: string
  count: number
  id: string
  departure_date: string
}

export interface IAeroexpressToCartRequest {
  requestId: string
  searchId: string
}

export interface IStandardModel {
  departure_date: string
  backward_date: string
}

export {
  AEROEXPRESS_TARIFF_TYPES,
  AEROEXPRESS_TARIFF_DESCRIPTIONS,
  AEROEXPRESS_ROUTE_TYPES,
  AEROEXPRESS_TARIFF_MAP,
  AEROEXPRESS_DIRECTION_TYPES,
  AEROEXPRESS_LATE_TEXT,
  AEROEXPRESS_DEFAULT_SCHEDULE_LIST,
  GET_AEROEXPRESS_BUSINESS_TWOWAY,
  GET_AEROEXPRESS_BACK_DIRECTION_TYPE,
  GET_AEROEXPRESS_DIRECTION_TEXT,
  GET_AEROEXPRESS_ADDED_TO_CART_TEXT,
  AEROEXPRESS_SHEREMETYEVO,
};
