// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import { Text, PROPS } from 'new-ui';

import { getText } from '../../../i18n';

import { serviceProviderBonusCard } from '../../bi/utils/airline';

import SERVICETYPE from '../../bi/constants/serviceType';

const {
  TEXT: {
    TYPES: {
      NORMAL_14,
    },
    COLORS: {
      GRAY,
    },
  },
} = PROPS;

const LABELS = {
  BONUS_CART: bonus => getText('components:employeeList.bonusCard', { bonus }),
};

const bonusType = (Type, type, ServiceType) => Type === type && type === ServiceType;

const checkType = ({ Type, Code }, routes, ServiceType) => Type
  && ((routes && bonusType(Type, SERVICETYPE.AIR, ServiceType)
  && (routes[0].Segments.find(({ Airline: { ID } }) => ID === Code) || serviceProviderBonusCard(routes[0].Segments, Code)))
    || bonusType(Type, SERVICETYPE.TRAIN, ServiceType));

const EmployeeBonusCard = ({ routes, Bonuses, ServiceType }) => {
  const bonusHtml = ({ Id, Number }) => (
    <div key={ Id } >
      <Text type={ NORMAL_14 } color={ GRAY }>
        { LABELS.BONUS_CART(Number) }
      </Text>
    </div>
  );

  const bonusesContent = Bonuses.map((bonus) => checkType(bonus, routes, ServiceType) && bonusHtml(bonus));

  return (
    <>
      {bonusesContent}
    </>
  );
};

EmployeeBonusCard.propTypes = {
  routes: PropTypes.array.isRequired,
  Bonuses: PropTypes.array.isRequired,
  ServiceType: PropTypes.string.isRequired,
};

export default EmployeeBonusCard;
