// @ts-nocheck
import moment from 'moment';

import parseJsonString from './parseJsonString';
import { diffYears } from './formatDate';
import parseUnix from './parseDateTime';

import { AIRLINE_PROVIDERS } from '../constants/airline';
import { SERVICETYPE } from '../constants/serviceType';
import { CARTACTIONTYPES } from '../constants/cart';

const AGE_OF_NOT_BABY_ANYMORE = 2;
const AGE_OF_NOT_CHILD_ANYMORE = 12;
const AGE_OF_ADULT_PERSON = 18;

const isDepartmentsSelect = ({ EmployeeCartItems, Employees }, isService) => EmployeeCartItems.every(({ DepartmentId, EmployeeId }) => {
  const { Employee: { Departments, Companies } } = Employees.find(({ Employee: currentEmployee }) => currentEmployee.Id === EmployeeId);

  const findDepartment = Companies.find(({ CompanyId }) => CompanyId === EmployeeCartItems[0].CompanyId);
  const departmentsLength = findDepartment && findDepartment.Departments ? findDepartment.Departments.length : null;
  const checkDepartment = departmentsLength ? EmployeeCartItems[0].DepartmentId : 1;
  const lastServiceCheck = isService && checkDepartment > 0;
  const departmentIdForCartItems = Employees[0].Employee.Departments && Employees[0].Employee.Departments.length > 0 ? EmployeeCartItems[0].DepartmentId : 1;

  if (isService && departmentIdForCartItems > 0) {
    return true;
  }

  if (!Departments?.length) {
    return true;
  }

  const isSingleCompany = Companies.length === 1;

  return !isSingleCompany || Boolean(DepartmentId) || lastServiceCheck;
});

const checkForeignPassportsFoulsCart = item => item.Employees.some(employeeItem =>
  !!employeeItem.errors && !!employeeItem.errors.selectedForeignPassportFoul);

const getMinMaxDate = ({ checkin, checkout, minDate, maxDate }) => {
  const newMinDate = minDate === null ? checkin : minDate;

  return {
    min: (checkin.isBefore(newMinDate)) ? checkin : newMinDate,
    max: (checkout.isAfter(maxDate)) ? checkout : maxDate,
  };
};

const isAirlineEmployeeListValid = (item, data) => {
  const employeesCount = item.EmployeeCartItems.length;
  const isCompaniesSelected = item.EmployeeCartItems.every(({ CompanyId }) => CompanyId);
  const isDepartmentsSelected = isDepartmentsSelect(item);
  const isDocument = item.EmployeeCartItems.filter(itemEmployee => itemEmployee.DocumentId).length === item.EmployeeCartItems.length;

  return isDocument && employeesCount === data.Metadata.TravellersCount && isCompaniesSelected && isDepartmentsSelected && !checkForeignPassportsFoulsCart(item);
};

const isCorrectMobile = ({ Employees }) => (Employees ? Employees.every(({ Employee: { MobilePhone } }) => (MobilePhone.charAt(1) === '7' ? (MobilePhone.charAt(2) === '7' || MobilePhone.charAt(2) === '9') : true)) : true);

const getDataForCart = (cartItems) => {
  let minDate = null;
  let maxDate = moment();
  let unlock = true;
  let price = 0;
  const unlockProject = [];

  cartItems.forEach((item) => {
    const employeesCount = item.EmployeeCartItems.length;

    price += item.Price;
    unlockProject.push(employeesCount > 0 ? item.EmployeeCartItems.every(({ ProjectId, CompanyId, EmployeeId }) => {
      if (ProjectId || !CompanyId) return true;

      const { Employee: { Companies } } = item.Employees.find(({ Employee }) => Employee.Id === EmployeeId);

      const company = Companies.find(Company => Company.CompanyId === CompanyId);

      return !(company?.CompanySettings ? company.CompanySettings.MandatoryProject : false);
    }) : false);

    switch (item.ServiceType) {
      case SERVICETYPE.AIR: {
        const data = parseJsonString(item.Data);
        unlock = unlock && isAirlineEmployeeListValid(item, data) && isCorrectMobile(item);

        const firstRoute = data.Routes[0];
        const lastRoute = data.Routes[data.Routes.length - 1];

        const firstSegment = firstRoute.Segments[0];
        const lastSegment = lastRoute.Segments[lastRoute.Segments.length - 1];

        const checkin = parseUnix(firstSegment.DepartureTime_DateTime);
        const checkout = parseUnix(lastSegment.ArrivalTime_DateTime);

        const { min, max } = getMinMaxDate({ checkin, checkout, minDate, maxDate });

        minDate = min;
        maxDate = max;

        break;
      }
      case SERVICETYPE.HOTEL: {
        const meta = parseJsonString(item.Data);

        unlock = unlock && employeesCount >= 1 && item.EmployeeCartItems[0].CompanyId && isDepartmentsSelect(item, true);

        const checkin = moment(meta.checkin);
        const checkout = moment(meta.checkout);

        const { min, max } = getMinMaxDate({ checkin, checkout, minDate, maxDate });

        minDate = min;
        maxDate = max;
        break;
      }
      case SERVICETYPE.TRANSFER: {
        const meta = parseJsonString(item.Data);

        unlock = unlock && employeesCount >= 1 && item.EmployeeCartItems[0].CompanyId && isDepartmentsSelect(item, true);

        const checkin = moment(meta.DateArrival);

        const { min, max } = getMinMaxDate({ checkin, checkout: checkin, minDate, maxDate });

        minDate = min;
        maxDate = max;
        break;
      }
      case SERVICETYPE.TRAIN: {
        const { Data, EmployeeCartItems } = item;
        const data = parseJsonString(Data);
        const { DateDepartureLocal, DateArrivalLocal, Travellers } = data;
        const isCompaniesSelected = EmployeeCartItems.every(({ CompanyId }) => CompanyId);
        const isDepartmentsSelected = isDepartmentsSelect(item);
        const isDocument = EmployeeCartItems.filter(itemEmployee => itemEmployee.DocumentId).length === EmployeeCartItems.length;

        unlock = unlock && isDocument && employeesCount === Travellers && isCompaniesSelected && isDepartmentsSelected && !checkForeignPassportsFoulsCart(item);

        const checkin = moment(DateDepartureLocal);
        const checkout = moment(DateArrivalLocal);

        const { min, max } = getMinMaxDate({ checkin, checkout, minDate, maxDate });

        minDate = min;
        maxDate = max;
        break;
      }
      case SERVICETYPE.AEROEXPRESS: {
        const { Trip: { departure_date, backward_date: backDate, count } } = parseJsonString(item.Data);
        const isCompaniesSelected = item.EmployeeCartItems.every(({ CompanyId }) => CompanyId);
        const isDepartmentsSelected = isDepartmentsSelect(item);
        const isDocument = item.EmployeeCartItems.filter(itemEmployee => itemEmployee.DocumentId).length === item.EmployeeCartItems.length;

        unlock = unlock && isDocument && employeesCount === count && isCompaniesSelected && isDepartmentsSelected && !checkForeignPassportsFoulsCart(item);

        const checkin = moment(departure_date);
        const checkout = moment(backDate);

        const { min, max } = getMinMaxDate({ checkin, checkout: backDate ? checkout : checkin, minDate, maxDate });

        minDate = min;
        maxDate = max;

        break;
      }
      case SERVICETYPE.TAXI_VOUCHER: {
        const { Data, EmployeeCartItems } = item;
        const data = parseJsonString(Data);
        const { StartDate, EndDate } = data;

        unlock = unlock && employeesCount >= 1 && EmployeeCartItems[0].CompanyId && isDepartmentsSelect(item, true);

        const checkin = parseUnix(StartDate);
        const checkout = parseUnix(EndDate);

        const { min, max } = getMinMaxDate({ checkin, checkout, minDate, maxDate });

        minDate = min;
        maxDate = max;

        break;
      }
    }
  });

  const project = unlockProject.every(pr => pr);

  return { minDate, maxDate, unlock, price, project };
};

const isCartHasAirChildren = items => {
  const onlyAviaItems = items.filter(({ ServiceType }) => ServiceType === SERVICETYPE.AIR);

  if (!onlyAviaItems.length) {
    return false;
  }

  const departureDates = onlyAviaItems.reduce((acc, { Id, Data }) => {
    const jsonData = parseJsonString(Data);
    const departureDate = jsonData.Routes[0].Segments[0].DepartureTime_DateTime;

    return { ...acc, [Id]: departureDate };
  }, {});

  const allEmployees = onlyAviaItems.flatMap(({ Employees, Id: itemId }) => Employees.map(empl => ({ ...empl, itemId })));

  const hasAdultEmployee = allEmployees
    .some(({ Employee: { Birthday }, itemId }) => diffYears(Birthday, departureDates[itemId]) >= AGE_OF_ADULT_PERSON);

  if (hasAdultEmployee) {
    return false;
  }

  return allEmployees.some(({ Employee: { Birthday }, itemId }) => {
    const diff = diffYears(Birthday, departureDates[itemId]);

    return diff < AGE_OF_NOT_CHILD_ANYMORE;
  });
};

const s7BonusCardWarning = (cartItems: any): boolean => {
  const isS7BonusCardExist = cartItems.filter((item) => {
    const { Data, Employees } = item;
    const isS7Provider = JSON.parse(Data)?.ProviderName === AIRLINE_PROVIDERS.S7;
    const isEmployeeHaveS7BonusCard = Employees.flatMap(({ Employee }) => {
      const { Bonuses } = Employee;

      return Bonuses.filter(({ Code }) => Code === AIRLINE_PROVIDERS.S7);
    });

    return isS7Provider && isEmployeeHaveS7BonusCard.length;
  });

  return !!isS7BonusCardExist.length;
};

const cartHasAirUnderageByProviders = (items) => items.some(({ ServiceType, Employees, Data }) => {
  if (ServiceType !== SERVICETYPE.AIR) {
    return false;
  }

  const { ProviderName, Routes } = parseJsonString(Data);
  const allowingProvider = ProviderName === AIRLINE_PROVIDERS.AMADEUS
    || ProviderName === AIRLINE_PROVIDERS.SIRENA
    || ProviderName === AIRLINE_PROVIDERS.AEROFLOT
    || ProviderName === AIRLINE_PROVIDERS.MIXVEL;
  const departureDate = Routes[0].Segments[0].DepartureTime_DateTime;

  return Employees.some(({ Employee: { Birthday } }) => {
    const diff = diffYears(Birthday, departureDate);

    const allowingLimit = allowingProvider && diff >= AGE_OF_NOT_BABY_ANYMORE;

    if (allowingLimit) {
      return false;
    }

    return diff < AGE_OF_NOT_CHILD_ANYMORE;
  });
});

const getCountOfTravellers = (type, item) => ({
  [SERVICETYPE.AIR]: () => item.Metadata.TravellersCount,
  [SERVICETYPE.TRAIN]: () => item.Travellers,
  [SERVICETYPE.HOTEL]: () => item.GuestsCount,
  [SERVICETYPE.TRANSFER]: () => item.Capacity,
  [SERVICETYPE.AEROEXPRESS]: () => item.Trip.count,
  [SERVICETYPE.TAXI]: () => item.GuestsCount,
  [SERVICETYPE.TAXI_VOUCHER]: () => item.GuestsCount,
}[type]());

const isOptionalEmployeeItem = (type, maxEmployees) => {
  const typeMayHaveOptionalEmployee = type === SERVICETYPE.HOTEL || type === SERVICETYPE.TRANSFER;

  return typeMayHaveOptionalEmployee && maxEmployees > 1;
};

const cartItemIsNotTotallyFilled = (item, mandatoryProject) => {
  const { Data, Employees, ServiceType, EmployeeCartItems } = item;

  const itemData = parseJsonString(Data);

  const linkedEmplsCount = Employees.length;
  const maxEmplsForItem = getCountOfTravellers(ServiceType, itemData);
  const linkedEmplsLessThanMax = linkedEmplsCount < maxEmplsForItem;

  const allEmplsHaveCompanyId = EmployeeCartItems.some(({ CompanyId }) => !!CompanyId);
  const allEmplsHaveProjectId = EmployeeCartItems.some(({ ProjectId }) => !!ProjectId);

  const itemHasOptionalEmployee = isOptionalEmployeeItem(ServiceType, maxEmplsForItem);

  const linkedEmplsIsNotOk = itemHasOptionalEmployee
    ? !(itemHasOptionalEmployee && !!linkedEmplsCount)
    : linkedEmplsLessThanMax;

  return linkedEmplsIsNotOk || !allEmplsHaveCompanyId || (!allEmplsHaveProjectId && mandatoryProject);
};

const getFieldWithTA = (isTravelApproval, field, approvedRequest, usersNotValid) => {
  if (isTravelApproval && !approvedRequest) {
    return CARTACTIONTYPES.TRAVEL_APPROVAL_SCHEME;
  }

  if (isTravelApproval && usersNotValid) {
    return CARTACTIONTYPES.CHOOSE_VALID_USER;
  }

  return field;
};

const getUnlockTravelApproval = (isTravelApproval, isApprovedRequest, unlockValue, usersNotValid) => {
  if (isTravelApproval) {
    return isApprovedRequest && unlockValue && !usersNotValid;
  }

  return unlockValue;
};

const getUsersNotValid = (isTravelApproval, sources, chosenApprovedRequest) => {
  const getCartSelectedEmployeesIds = () => {
    const cartSelectedEmployeesIds = sources.reduce((acc, source) => {
      const employeeIds = source.EmployeeCartItems.map(({ EmployeeId }) => EmployeeId);

      return [...acc, ...employeeIds];
    }, []);

    return [...new Set(cartSelectedEmployeesIds)];
  };

  const getUsersValid = (cartSelectedEmployeesIds) => {
    const chosenApprovedRequestEmployeesIds = chosenApprovedRequest ? chosenApprovedRequest.Employees.map(({ Id }) => Id) : [];

    return chosenApprovedRequestEmployeesIds.some(id => cartSelectedEmployeesIds.includes(id));
  };

  const cartSelectedEmployeesIds = getCartSelectedEmployeesIds();
  const usersValid = getUsersValid(cartSelectedEmployeesIds);

  return isTravelApproval && !usersValid;
};

const addSelectedTripTags = (selectedTripTags, newTags) => {
  const tripTagsIds = newTags?.map(({ Id }) => Id) || null;
  const mergedTags = [...selectedTripTags, ...tripTagsIds];
  const tagsSet = new Set(mergedTags);

  return Array.from(tagsSet);
};

const getCartByStatus = cartsParam => (status, findAll = false) => {
  if (findAll) {
    return cartsParam.filter(cart => cart.status === status);
  }

  return cartsParam.find(cart => cart.status === status);
};

const prepareDepartmentsAndProjectHeads = (cart) => {
  const departmentsSet = new Set();
  const projectsSet = new Set();

  cart.sources.forEach(({ EmployeeCartItems }) => {
    EmployeeCartItems.forEach(({ DepartmentId, ProjectId }) => {
      if (DepartmentId) {
        departmentsSet.add(DepartmentId);
      }

      if (ProjectId) {
        projectsSet.add(ProjectId);
      }
    });
  });

  return { departments: Array.from(departmentsSet), projects: Array.from(projectsSet) };
};

export {
  cartHasAirUnderageByProviders,
  isCartHasAirChildren,
  isAirlineEmployeeListValid,
  getDataForCart,
  getCountOfTravellers,
  isOptionalEmployeeItem,
  cartItemIsNotTotallyFilled,
  getFieldWithTA,
  getUnlockTravelApproval,
  getUsersNotValid,
  addSelectedTripTags,
  getCartByStatus,
  prepareDepartmentsAndProjectHeads,
  s7BonusCardWarning,
  getDataForCart as default,
};
