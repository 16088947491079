// @ts-nocheck
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Icon, IconButton, PROPS, Text, Tooltip } from 'new-ui';
import { TechnicalStopTooltip } from '../../AirlineTechnicalStop/components/Tooltip';

import { getText } from '../../../../i18n';

import AirlineItemSegment from '../../AirlineItemSegment';

import { secondsToLabel } from '../../../bi/utils/time';
import { dateWithoutMoment } from '../../../bi/utils/formatDate';
import guid from '../../../bi/utils/guid';
import toDecline from '../../../bi/utils/toDecline';
import {
  segmentsArrivalCity,
  mappedSegments,
  getAirlineType,
  getTerminals,
  isArrivalChanged,
  isDepartureChanged,
  getNameByFirstSegment,
  getImageLogo,
} from '../../../bi/utils/airline';

import { DATEFORMATS, PATTERN } from '../../../bi/constants/dateFormats';
import { TRANSFER, AIRLINE_IDS } from '../../../bi/constants/airline';

import plusImg from '../images/plus.svg';

import styles from '../styles/airItem.module.css';

const LABELS = {
  tooltipFirstPart: getText('components:airlineSearchItem.airItem.tooltip.first'),
  tooltipSecondPart: getText('components:airlineSearchItem.airItem.tooltip.second'),
  clickToShow: getText('components:airlineSearchItem.airItem.airlines.clickToShow'),
  inCity: getText('components:airlineSearchItem.airItem.inCity'),
  inCities: getText('components:airlineSearchItem.airItem.inCities'),
  attention: getText('components:airlineSearchItem.airItem.tooltip.attention'),
  changingAirport: getText('components:airlineSearchItem.airItem.tooltip.changingAirport'),
  withoutTransfers: getText('components:airlineSearchItem.airItem.withoutTransfers'),
};

const AIRLINES = {
  [AIRLINE_IDS.S7]: getText('components:airlineSearchItem.airItem.airlines.s7'),
  [AIRLINE_IDS.POBEDA]: getText('components:airlineSearchItem.airItem.airlines.pobeda'),
};

class AirItem extends Component {
  static propTypes = {
    route: PropTypes.object.isRequired,
    countRoutes: PropTypes.bool.isRequired,
    showDetails: PropTypes.bool.isRequired,
    lastChild: PropTypes.bool.isRequired,
    onUpdateCheckbox: PropTypes.func.isRequired,
    onShowSegments: PropTypes.func.isRequired,
    hasComplexJoins: PropTypes.bool.isRequired,
    seatMapAirline: PropTypes.string,
    qaAttrs: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.object.isRequired,
    ]),
    isFirstEl: PropTypes.bool,
    index: PropTypes.number,
    manyRoutes: PropTypes.bool,
  };

  static defaultProps = {
    seatMapAirline: null,
    qaAttrs: null,
    isFirstEl: false,
    index: 0,
    manyRoutes: false,
  };

  getQAAttrs = () => {
    const { isFirstEl, qaAttrs, index, manyRoutes } = this.props;

    let qaAttrDepartureTime = '';
    let qaAttrDepartureDate = '';
    let qaAttrArrivalTime = '';
    let qaAttrArrivalDate = '';
    let qaAttrDepartureCity = '';
    let qaAttrArrivalCity = '';
    let qaAttrName = '';
    let qaAttrRouteDuration = '';
    let qaAttrTransfer = '';

    if (isFirstEl && qaAttrs) {
      qaAttrDepartureTime = qaAttrs.departureTime;
      qaAttrDepartureDate = qaAttrs.departureDate;
      qaAttrArrivalTime = qaAttrs.arrivalTime;
      qaAttrArrivalDate = qaAttrs.arrivalDate;
      qaAttrDepartureCity = qaAttrs.departureCity;
      qaAttrArrivalCity = qaAttrs.arrivalCity;
      qaAttrName = qaAttrs.name;
      qaAttrRouteDuration = qaAttrs.routeDuration;
      qaAttrTransfer = qaAttrs.transfer;
    }

    if (manyRoutes && qaAttrs) {
      qaAttrDepartureTime = `${qaAttrDepartureTime}-${index}`;
      qaAttrDepartureDate = `${qaAttrDepartureDate}-${index}`;
      qaAttrArrivalTime = `${qaAttrArrivalTime}-${index}`;
      qaAttrArrivalDate = `${qaAttrArrivalDate}-${index}`;
      qaAttrDepartureCity = `${qaAttrDepartureCity}-${index}`;
      qaAttrArrivalCity = `${qaAttrArrivalCity}-${index}`;
      qaAttrName = `${qaAttrName}-${index}`;
      qaAttrRouteDuration = `${qaAttrRouteDuration}-${index}`;
      qaAttrTransfer = `${qaAttrTransfer}-${index}`;
    }

    return {
      qaAttrDepartureTime,
      qaAttrDepartureDate,
      qaAttrArrivalTime,
      qaAttrArrivalDate,
      qaAttrDepartureCity,
      qaAttrArrivalCity,
      qaAttrName,
      qaAttrRouteDuration,
      qaAttrTransfer,
    };
  };

  renderPin = () => {
    const { route, countRoutes, onUpdateCheckbox } = this.props;

    if (!countRoutes) return null;

    const { Selected } = route;
    const iconType = Selected ? PROPS.ICON.TYPES.PINS.ACTIVE : PROPS.ICON.TYPES.PINS.INACTIVE;

    const tooltip = () => (
      <Text type={ PROPS.TEXT.TYPES.NORMAL_14_130 } color={ PROPS.TEXT.COLORS.WHITE } className={ styles.text }>
        { LABELS.clickToShow }
      </Text>
    );

    return (
      <Tooltip renderContent={ tooltip } className={ styles.pin } show={ !Selected } childrenClassName={ styles.tooltip }>
        <IconButton iconType={ iconType } onClick={ () => onUpdateCheckbox(!Selected, route) } />
      </Tooltip>
    );
  };

  renderSegments = () => {
    const { route: { Segments }, onShowSegments } = this.props;

    const segmentsHtml = Segments.map((segment, index) => {
      const arrivalTerminal = getTerminals(segment, index, Segments).arrival;
      const departureTerminal = getTerminals(segment, index, Segments).departure;

      const departureChanged = isDepartureChanged(index, segment, Segments);
      const arrivalChanged = isArrivalChanged(index, segment, Segments);

      return (
        <AirlineItemSegment
          key={ `segment_${guid()}_${index}` }
          segment={ segment }
          departureChanged={ departureChanged }
          arrivalChanged={ arrivalChanged }
          arrivalTerminal={ arrivalTerminal }
          departureTerminal={ departureTerminal }
          opts={ mappedSegments(segment) }
        />
      );
    });

    return (
      <div
        onClick={ onShowSegments }
        className={ styles.segments }
      >
        { segmentsHtml }
      </div>
    );
  };

  renderTooltip = (infoCity) => {
    const text = infoCity.length === 1
      ? ` ${LABELS.inCity} ${infoCity[0]}`
      : ` ${LABELS.inCities} ${infoCity.join(', ')}`;

    const tooltip = () => (
      <Text
        type={ PROPS.TEXT.TYPES.NORMAL_14_130 }
        color={ PROPS.TEXT.COLORS.WHITE }
        className={ styles.tooltip_box }
      >
        <div>{ LABELS.attention }</div>
        <div>{ LABELS.changingAirport }{ text }</div>
      </Text>
    );

    return (
      <Tooltip
        className={ styles.transfer_icon }
        renderContent={ tooltip }
      >
        <Icon type={ PROPS.ICON.TYPES.WARNING } />
      </Tooltip>
    );
  };

  renderCity = (city, airportId, qaAttr = '') => (
    <Text qaAttr={ qaAttr } className={ styles.city }>
      { city }, { airportId }
    </Text>
  );

  renderSingleIcon = ({ id, name }, qaAttr = '') => (
    <div className={ styles.large_img_wrap }>
      <img
        className={ styles.large_img }
        src={ getImageLogo(id) }
        title={ name }
        alt={ name }
        data-qa={ qaAttr }
      />
      { this.renderPlusSeat() }
    </div>
  );

  renderMultipleIcons = (list = []) => (
    <div className={ styles.image_list }>
      {list.map(({ id, name }) => (
        <img
          key={ id }
          className={ styles.small_img }
          src={ getImageLogo(id) }
          title={ name }
          alt={ name }
        />
      ))}
    </div>
  );

  renderIcons = () => {
    const { hasComplexJoins, route, qaAttrs } = this.props;

    if (!hasComplexJoins) {
      const firstSegment = route.Segments[0];
      const firstSegmentAirlineType = getAirlineType(firstSegment);

      const { ID: id, Name: name } = firstSegment[firstSegmentAirlineType];

      return this.renderSingleIcon({ id, name }, qaAttrs?.icon);
    }

    const reducedSegmentsList = route.Segments.reduce((acc, segment) => {
      const airlineType = getAirlineType(segment);
      const { ID, Name } = segment[airlineType];

      if (acc.findIndex(({ id }) => ID === id) >= 0) {
        return acc;
      }

      return [
        ...acc,
        {
          id: ID,
          name: Name,
        },
      ];
    }, []);

    if (reducedSegmentsList.length === 1) {
      return this.renderSingleIcon({ ...reducedSegmentsList[0] }, qaAttrs?.icon);
    }

    return this.renderMultipleIcons(reducedSegmentsList);
  };

  renderTransfer = (changeCount, infoCity, sumChangeDuration, qaAttr = '') => {
    const { route } = this.props;
    const questionHtml = !!infoCity.length && this.renderTooltip(infoCity);

    if (changeCount) {
      return (
        <div className={ styles.transfer }>
          <Text
            type={ PROPS.TEXT.TYPES.NORMAL_14 }
            color={ infoCity.length ? PROPS.TEXT.COLORS.RED : PROPS.TEXT.COLORS.GRAY }
            qaAttr={ qaAttr }
          >
            {changeCount} {toDecline(changeCount, TRANSFER)} ({secondsToLabel(sumChangeDuration)})
          </Text>
          { questionHtml }
        </div>
      );
    }

    return !route.Segments[0].TechnicalStop?.length && (
      <Text
        type={ PROPS.TEXT.TYPES.NORMAL_14 }
        color={ PROPS.TEXT.COLORS.GRAY }
        qaAttr={ qaAttr }
      >
        { LABELS.withoutTransfers }
      </Text>
    );
  };

  renderTooltipContent = () => <div className={ styles.plus_seat_tooltip }>
    { LABELS.tooltipFirstPart } {AIRLINES[this.props.seatMapAirline]} { LABELS.tooltipSecondPart }
  </div>;

  renderPlusSeat = () => {
    const { seatMapAirline } = this.props;

    if (!seatMapAirline) {
      return null;
    }

    const classNames = [styles.plus_seat_icon];

    if (seatMapAirline === AIRLINE_IDS.POBEDA) {
      classNames.push(styles.plus_seat_icon__pobeda);
    }

    return (
      <Tooltip
        className={ classNames.join(' ') }
        renderContent={ this.renderTooltipContent }
      >
        <img
          src={ plusImg }
          alt=''
          className={ styles.plus_icon }
        />
        <img
          src='/images/seat.png'
          alt=''
          className={ styles.seat_icon }
        />
      </Tooltip>
    );
  };

  render() {
    const { route, showDetails, lastChild } = this.props;

    const qaAttrs = this.getQAAttrs();

    const wrappedStyles = [styles.wrapper];
    const segments = route.Segments;
    const firstSegment = segments[0];
    const lastSegment = segments[segments.length - 1];
    const routeDurationHtml = secondsToLabel(route.Duration, true);

    const changeCount = segments.length - 1;
    const sumChangeDuration = segments
      .map(segment => segment.ChangeDuration)
      .reduce((acc, current) => acc + current);

    const infoCity = segmentsArrivalCity(segments);
    const detailsHtml = showDetails && this.renderSegments();

    const filterAirlines = segments.filter((segment) => {
      const airlineType = getAirlineType(segment);

      return segment[airlineType].ID === firstSegment[airlineType].ID;
    });

    const flightNumbers = segments.map((segment) => {
      const airlineType = getAirlineType(segment);

      return `${segment[airlineType].ID} ${segment.FlightNumber}`;
    }).join(', ');

    const airlineName = getNameByFirstSegment(firstSegment);

    const airlineNameWithNumbers = segments.length === 1 || (segments.length === 2 && filterAirlines.length === 2)
      ? `${airlineName} (${flightNumbers})`
      : flightNumbers;

    const departure = this.renderCity(firstSegment.DepartureCity, firstSegment.DepartureAirport.ID, qaAttrs?.qaAttrDepartureCity);
    const arrival = this.renderCity(lastSegment.ArrivalCity, lastSegment.ArrivalAirport.ID, qaAttrs?.qaAttrArrivalCity);

    if (lastChild) {
      wrappedStyles.push(styles.last);
    }

    const technicalStopWrappedStyles = route.Segments[0].TechnicalStop?.length
      ? styles.duration_with_technical_stop
      : styles.duration;

    return (
      <div className={ wrappedStyles.join(' ') }>
        <div className={ styles.icon }>
          { this.renderPin() }
          { this.renderIcons() }
        </div>
        <div className={ styles.wrapper_info }>
          <div className={ styles.info }>
            <div className={ styles.col_departure }>
              <Text
                type={ PROPS.TEXT.TYPES.BOLD_22 }
                qaAttr={ qaAttrs?.qaAttrDepartureTime || '' }
              >
                { dateWithoutMoment(firstSegment.DepartureTime, DATEFORMATS.TIME) }
              </Text>
              { departure }
              <Text
                type={ PROPS.TEXT.TYPES.NORMAL_14 }
                color={ PROPS.TEXT.COLORS.GRAY }
                qaAttr={ qaAttrs?.qaAttrDepartureDate || '' }
              >
                { dateWithoutMoment(firstSegment.DepartureTime) }, { dateWithoutMoment(firstSegment.DepartureTime, PATTERN.WEEK) }
              </Text>
            </div>
            <div className={ styles.col_airline }>
              <Text
                type={ PROPS.TEXT.TYPES.NORMAL_14 }
                color={ PROPS.TEXT.COLORS.GRAY }
                qaAttr={ qaAttrs?.qaAttrName || '' }
              >
                { airlineNameWithNumbers }
              </Text>
              <Text
                type={ PROPS.TEXT.TYPES.SEMIBOLD_16 }
                className={ technicalStopWrappedStyles }
                qaAttr={ qaAttrs?.qaAttrRouteDuration || '' }
              >
                { routeDurationHtml }
              </Text>
              { this.renderTransfer(changeCount, infoCity, sumChangeDuration, qaAttrs?.qaAttrTransfer) }
              { !!route.Segments[0].TechnicalStop?.length &&
                <TechnicalStopTooltip
                  changeCount={ changeCount }
                  route={ route }
                  className={ styles.technical_title }
                />
              }
            </div>
            <div className={ styles.col_arrival }>
              <Text
                type={ PROPS.TEXT.TYPES.BOLD_22 }
                qaAttr={ qaAttrs?.qaAttrArrivalTime || '' }
              >
                { dateWithoutMoment(lastSegment.ArrivalTime, DATEFORMATS.TIME) }
              </Text>
              { arrival }
              <Text
                type={ PROPS.TEXT.TYPES.NORMAL_14 }
                color={ PROPS.TEXT.COLORS.GRAY }
                qaAttr={ qaAttrs?.qaAttrArrivalDate || '' }
              >
                { dateWithoutMoment(lastSegment.ArrivalTime) }, { dateWithoutMoment(lastSegment.ArrivalTime, PATTERN.WEEK) }
              </Text>
            </div>
          </div>
          { detailsHtml }
        </div>
      </div>
    );
  }
}

export default AirItem;
