// @ts-nocheck
import PropTypes from 'prop-types';
import React from 'react';
import { Dropdown, Text, Button, Icon, Collapse, PROPS } from 'new-ui';
import { getText } from '../../../../i18n';

import FORMATS from '../../../bi/constants/formats';

import MoneyFormat from '../../../bi/utils/money';
import { formatDate, defaultPattern, daysAndMounth } from '../../../bi/utils/formatDate';
import { MainAnalytic } from '../../../bi/utils/analytics';

import styles from '../styles/row.module.css';

const {
  TEXT: {
    TYPES: {
      NORMAL_14_130,
      NORMAL_16_140,
      SEMIBOLD_16,
      SEMIBOLD_18,
    },
    COLORS: {
      GRAY,
    },
  },
  BUTTON: {
    TYPES: {
      TEXTUAL,
    },
  },
  ICON: {
    TYPES: {
      ARROWS: {
        DOWN_GRAY,
      },
    },
  },
} = PROPS;

const {
  PDF,
  XLSX,
} = FORMATS;

const LABELS = {
  SEND: getText('components:operationsTable.row.send'),
  INVOICE_PDF: getText('components:operationsTable.row.invoicePdf'),
  INVOICE_XLSX: getText('components:operationsTable.row.invoiceXlsx'),
  INVOICE_PENALTY: date => getText('components:operationsTable.row.invoicePenalty', { date }),
};

const CREDIT_TYPE = '3';

const isPenalty = (type, details) => type === 2 && details.find(detail => detail.Type === 2);

const getDebitAndCreditInMoneyFormat = ({ IsOutgoing, Credit, Debit, Type }) => {
  if (IsOutgoing) {
    return { creditMoneyFormat: MoneyFormat.moneyWithDecimal(Credit, true), debitMoneyFormat: null };
  }

  if (Type === CREDIT_TYPE) {
    return { creditMoneyFormat: MoneyFormat.moneyWithDecimal(Credit, true), debitMoneyFormat: null };
  }

  return { creditMoneyFormat: null, debitMoneyFormat: MoneyFormat.moneyWithDecimal(Debit, true) };
};

const renderDownloadButton = (
  { InvoiceName, InvoiceNum, EventDate, Type, Details, IsOutgoing },
  renderPenaltyBySchema,
  handleDownload,
  handleOpenSendInvoiceDialog,
) => {
  const hasPenalty = isPenalty(Type, Details);
  const date = formatDate(EventDate, defaultPattern);

  const handleSendAnalyticsAndDownload = (e, pdf, type) => {
    handleDownload(e, pdf, type);
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.REPORTING.DOC_ARCHIVE);
  };

  const handleOpenSendAnalyticsAndInvoiceDialog = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.REPORTING.SEND_INVOICE);
    handleOpenSendInvoiceDialog(e);
  };

  const sendButtonHtml = (
    <Button
      type={ TEXTUAL }
      onClick={ handleOpenSendAnalyticsAndInvoiceDialog }
    >
      {LABELS.SEND}
    </Button>
  );

  if (IsOutgoing && InvoiceNum && !hasPenalty) {
    return (
      <div>
        <Text>{ InvoiceName }</Text>
        <Text type={ NORMAL_16_140 }> от { date }</Text>
        <div className={ styles.download }>
          <Dropdown
            title={ LABELS.INVOICE_PDF }
            onClick={ e => (handleSendAnalyticsAndDownload(e, PDF, 'invoice')) }
            smooth
          >
            <Button
              className={ styles.btn }
              type={ TEXTUAL }
              onClick={ e => handleDownload(e, XLSX, 'invoice') }
            >
              {LABELS.INVOICE_XLSX}
            </Button>
          </Dropdown>
        </div>
        { sendButtonHtml }
      </div>
    );
  }

  if (hasPenalty && renderPenaltyBySchema) {
    return (
      <div>
        <Text>{LABELS.INVOICE_PENALTY(date)}</Text>
        <div className={ styles.download }>
          <Dropdown
            title={ LABELS.INVOICE_PDF }
            onClick={ e => (handleSendAnalyticsAndDownload(e, PDF, 'penalty')) }
            smooth
          >
            <Button
              className={ styles.btn }
              type={ TEXTUAL }
              onClick={ e => (handleDownload(e, XLSX, 'penalty')) }
            >
              {LABELS.INVOICE_XLSX}
            </Button>
          </Dropdown>
        </div>
        { sendButtonHtml }
      </div>
    );
  }

  return null;
};

const renderDetail = ({ Description, Amount, Id }) => {
  const refillInMoneyFormat = Amount > 0 ? null : MoneyFormat.moneyWithDecimal(Math.abs(Amount), true);
  const amountInMoneyFormat = Amount < 0 ? null : MoneyFormat.moneyWithDecimal(Math.abs(Amount), true);

  const refillHtml = refillInMoneyFormat && <Text type={ SEMIBOLD_16 }>{refillInMoneyFormat}</Text>;
  const amountHtml = amountInMoneyFormat && <Text type={ SEMIBOLD_16 }>{amountInMoneyFormat}</Text>;

  return (
    <div key={ Id } className={ styles.row }>
      <div className={ styles.date } />
      <Text type={ NORMAL_14_130 } color={ GRAY } className={ styles.name }>
        { Description }
      </Text>
      <div className={ styles.refill }>{refillHtml}</div>
      <div className={ styles.amount }>{amountHtml}</div>
      <div className={ styles.document } />
    </div>
  );
};

const renderDetails = (details) => {
  if (!details.length) {
    return null;
  }

  return (
    <div className={ styles.details }>
      {details.map(renderDetail)}
    </div>
  );
};

const Row = ({ item, onDownload, onOpenSendInvoiceDialog, renderPenaltyBySchema }) => {
  const handleOpenSendInvoiceDialog = (e) => {
    e.preventDefault();
    e.stopPropagation();

    onOpenSendInvoiceDialog(item.InvoiceNum);
  };

  const handleDownload = (e, format, type) => {
    e.preventDefault();
    e.stopPropagation();

    onDownload(item, format, type);
  };

  const { Description, EventDate, Details } = item;

  const { creditMoneyFormat, debitMoneyFormat } = getDebitAndCreditInMoneyFormat(item);
  const debitHtml = debitMoneyFormat && <Text type={ SEMIBOLD_18 }>{ debitMoneyFormat }</Text>;
  const creditHtml = creditMoneyFormat && <Text type={ SEMIBOLD_18 }>{creditMoneyFormat }</Text>;

  const [title, nameAndDate] = Description;
  const nameAndDateHtml = nameAndDate &&
    (<Text type={ NORMAL_16_140 } className={ styles.names }>{nameAndDate}</Text>);

  const downLoadButtonHtml = renderDownloadButton(item, renderPenaltyBySchema, handleDownload, handleOpenSendInvoiceDialog);
  const descriptionHtml = renderDetails(Details);

  return (
    <Collapse
      className={ styles.wrap }
      openedClassName={ styles.open }
      opened={ false }
      renderContent={ () => (
        <div className={ styles.row }>
          <Text className={ styles.date }>{ formatDate(EventDate, daysAndMounth) }</Text>
          <div className={ styles.name }>
            <Text>{ title }</Text>
            { nameAndDateHtml }
          </div>
          <div className={ styles.refill }>{ debitHtml }</div>
          <div className={ styles.amount }>{ creditHtml }</div>
          <div className={ styles.document }>
            {downLoadButtonHtml}
          </div>
          <div className={ styles.arrow }>
            <Icon type={ DOWN_GRAY }/>
          </div>
        </div>
      ) }
    >
      { descriptionHtml }
    </Collapse>
  );
};

Row.propTypes = {
  item: PropTypes.object.isRequired,
  onDownload: PropTypes.func.isRequired,
  onOpenSendInvoiceDialog: PropTypes.func.isRequired,
  renderPenaltyBySchema: PropTypes.bool.isRequired,
};

export { Row };
