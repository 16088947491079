import React from 'react';
import { Button, Text, DotLoading } from 'new-ui';

import { getText } from '../../../../../../i18n';

import parseJsonString from '../../../../../bi/utils/parseJsonString';
import { isCurrentDateAfterMoscow } from '../../../../../bi/utils/formatDate';
import { prepareTripDialogTitle } from '../../../../../bi/utils/tripDetails';

import { QA_ATTRIBUTES } from '../../../../../bi/constants/attributesForTests';

import { AutomaticCancelTrip } from '../../AutomaticCancelTrip';
import { ButtonTrip } from '../../ButtonTrip';

import { IAdditionalService } from '../../../../../bi/types/trips';

import styles from '../styles/index.module.css';

const LABELS = {
  TITLE: getText('components:tripDetailsDialog.cancelDialog.title'),
  AIR_TRAIN_TITLE: getText('components:tripDetailsDialog.cancelDialog.airTrainTitle'),
  AIR_TITLE_MULTI: getText('components:tripDetailsDialog.cancelDialog.airTitleMulti'),
  SOON_ANSWER: getText('components:tripDetailsDialog.cancelDialog.soonAnswer'),
  CONTENT: {
    DEFAULT: getText('components:tripDetailsDialog.cancelDialog.content.default'),
    TRAIN_NOT_CANCEL: getText('components:tripDetailsDialog.cancelDialog.content.trainNotCancel'),
    TRAIN_INFO: getText('components:tripDetailsDialog.cancelDialog.content.trainInfo'),
  },
  CLOSE: getText('components:tripDetailsDialog.cancelDialog.close'),
  CANCEL: getText('components:tripDetailsDialog.cancelDialog.cancel'),
  LOADING: getText('components:tripDetailsDialog.automaticCancelTrip.loading'),
  CONTENT_CHAT: getText('components:tripDetailsDialog.automaticCancelTrip.content'),
  MULTI_TRIP_INFO: getText('components:tripDetailsDialog.cancelDialog.multiTripCancelInfo'),
  CANCEL_TICKET_ADDITIONAL: {
    TICKET_SERVICE: getText('components:tripDetailsDialog.cancelTicketAdditional.ticketService'),
    CANCEL_TICKET_SERVICE: getText('components:tripDetailsDialog.cancelTicketAdditional.cancelTicketService'),
  },
};

interface ICancelDialogContentProps {
  ticketsWithSamePNR?: any[],
  multipleTickets?: boolean,
  nameTrip: string,
  isTrain: boolean,
  isAir: boolean,
  isAeroexpress: boolean,
  isHotel: boolean,
  isTaxiVoucher: boolean,
  isTransfer: boolean,
  isVipHall: boolean,
  isChatLoading: boolean,
  isDisabled: boolean,
  orderService: any,
  item: any,
  onCloseDialog(): void,
  onRefreshTrain(): void,
  onCancelTrip(): void,
  toggleErrorDialog(): void,
  onSubmitCancellationModal(): void,
  onCancellationBack?(): void,
  onRequestAutomaticCancellation?(): void,
  onAutomaticCancellation?(): void,
  onCancelAutomaticCancellation?(): void,
  voidTimeLimit?: any,
  isSuccessAirRefund: boolean,
  loadingRefund: boolean,
  cancelError: string,
  isVipHallAutoCancellation: boolean,
  isTransferAutoCancellation: boolean,
  showErrorDialog: boolean,
  loadingCancellation: boolean,
  errorMsg: string,
  isCalculation: boolean,
  isSuccessTransferRefund: boolean,
  additionalServiceTrip?: IAdditionalService | null,
}

const CancelDialogContent = ({
  isAir,
  isVipHall,
  isTransfer,
  loadingRefund,
  isAeroexpress,
  isTrain,
  isSuccessAirRefund,
  cancelError,
  item,
  ticketsWithSamePNR = [],
  multipleTickets = false,
  nameTrip,
  additionalServiceTrip,
  isHotel,
  isTaxiVoucher,
  isVipHallAutoCancellation,
  isTransferAutoCancellation,
  isDisabled,
  orderService,
  isChatLoading,
  onCloseDialog,
  onRefreshTrain,
  onSubmitCancellationModal,
  onCancellationBack = () => {},
  onRequestAutomaticCancellation = () => {},
  onAutomaticCancellation = () => {},
  onCancelAutomaticCancellation = () => {},
  voidTimeLimit = null,
  showErrorDialog,
  loadingCancellation,
  errorMsg,
  onCancelTrip,
  toggleErrorDialog,
  isCalculation,
  isSuccessTransferRefund,
}: ICancelDialogContentProps) => {
  const renderLoading = () => (
    <div className={ styles.loading_wrap }>
      <Text type='NORMAL_16_140'>{ LABELS.LOADING }</Text>
      <div className={ styles.loader }>
        <DotLoading />
      </div>
    </div>
  );

  const renderAutomaticCancel = () => (
    <AutomaticCancelTrip
      multipleTickets={ multipleTickets }
      hasDisabled={ isDisabled }
      item={ item }
      orderService={ orderService }
      onCloseDialog={ onCloseDialog }
      onRefreshTrain={ onRefreshTrain }
      onSubmitCancellationModal={ onSubmitCancellationModal }
      onCancellationBack={ onCancellationBack }
      onRequestAutomaticCancellation={ onRequestAutomaticCancellation }
      onAutomaticCancellation={ onAutomaticCancellation }
      onCancelAutomaticCancellation={ onCancelAutomaticCancellation }
      isHotel={ isHotel }
      isAir={ isAir }
      isTransfer={ isTransfer }
      isTaxiVoucher={ isTaxiVoucher }
      isVipHall={ isVipHall }
      isChatLoading={ isChatLoading }
      voidTimeLimit={ voidTimeLimit }
      showErrorDialog={ showErrorDialog }
      loadingCancellation={ loadingCancellation }
      errorMsg={ errorMsg }
      onCancelTrip={ onCancelTrip }
      toggleErrorDialog={ toggleErrorDialog }
      hidePreCancelActions={ isCalculation }
      labelError={ LABELS.CONTENT_CHAT }
    />
  );

  const renderNotAutomaticCancelTrain = () => (
    <div className={ styles.not_cancel }>
      <Text type='NORMAL_16_140'>
        { LABELS.CONTENT.TRAIN_NOT_CANCEL }
      </Text>
      <Text className={ styles.text } type='NORMAL_14_130' color='gray'>
        { LABELS.CONTENT.TRAIN_INFO }
      </Text>
      <div className={ styles.not_cancel_button }>
        <Button onClick={ onCloseDialog }>
          { LABELS.CLOSE }
        </Button>
      </div>
    </div>
  );

  const renderCancelledTrain = () => {
    const { ActualVersion: { JsonData } } = item;

    const data = parseJsonString(JsonData);
    const { NonRefundable, CancellationDate } = data;

    if (!data || (!NonRefundable && CancellationDate && isCurrentDateAfterMoscow(CancellationDate))) {
      return renderNotAutomaticCancelTrain();
    }

    return renderAutomaticCancel();
  };

  const renderCancelErrorWithSendToChat = () => (
    <Text type='NORMAL_16_130' color='red'>
      { cancelError }
    </Text>
  );

  const renderCancelTrip = () => {
    if (isTrain) {
      return renderCancelledTrain();
    }

    if ((isHotel || isTransfer) && cancelError) {
      return renderCancelErrorWithSendToChat();
    }

    if (
      isAeroexpress ||
      isHotel ||
      (isAir && isSuccessAirRefund) ||
      isTaxiVoucher ||
      isVipHallAutoCancellation ||
      isTransferAutoCancellation
    ) {
      return renderAutomaticCancel();
    }

    const defaultText = isCalculation ? LABELS.CONTENT_CHAT : LABELS.CONTENT.DEFAULT;

    return (
      <Text type='NORMAL_14_130' color='gray'>
        { defaultText }
      </Text>
    );
  };

  const renderActions = () => {
    if (
      isTrain ||
      isAeroexpress ||
      (isHotel && !cancelError) ||
      (isAir && isSuccessAirRefund) ||
      isTaxiVoucher ||
      isVipHallAutoCancellation ||
      isTransferAutoCancellation
    ) {
      return null;
    }

    if (isHotel && cancelError) {
      return (
        <div className={ styles.cancel_button }>
          <Button onClick={ onCloseDialog } type='textual'>
            { LABELS.CLOSE }
          </Button>
        </div>
      );
    }

    const presenceText = additionalServiceTrip ? additionalServiceTrip.text : '';

    return (
      <ButtonTrip
        text={ presenceText }
        hasDisabled={ isDisabled }
        loading={ isChatLoading }
        firstWord={ LABELS.CANCEL }
        onCloseDialog={ onCloseDialog }
        onSubmitCancellationModal={ onSubmitCancellationModal }
        onClick={ onCancellationBack }
      />
    );
  };

  const renderCancelAditionlCervice = () => {
    if (!additionalServiceTrip) {
      return null;
    }

    if (isAir && additionalServiceTrip.newService) {
      return (
        <Text
          type='NORMAL_16_140'
          className={ styles.trip_name }
        >
          <span>
            { LABELS.CANCEL_TICKET_ADDITIONAL.TICKET_SERVICE }
          </span>
          <span>
            { additionalServiceTrip.tripInfo }
          </span>
          <span
            style={ { fontWeight: 'bold' } }
          >
            { LABELS.CANCEL_TICKET_ADDITIONAL.CANCEL_TICKET_SERVICE }
          </span>
        </Text>
      );
    }

    return null;
  };

  const renderContent = () => {
    if ((isAir || isVipHall || isTransfer) && loadingRefund) {
      return renderLoading();
    }

    const title = () => {
      if (isAir && multipleTickets && isSuccessAirRefund) {
        return LABELS.AIR_TITLE_MULTI;
      }

      return (isAir || isTrain || isAeroexpress)
        ? LABELS.AIR_TRAIN_TITLE
        : LABELS.TITLE;
    };

    const renderSoonAnswer = () => (isTransfer && !isSuccessTransferRefund ? LABELS.SOON_ANSWER : null);

    const renderMulltiAirTripWarning = () => (isAir && multipleTickets && !isCalculation ? (
      <Text type='SEMIBOLD_16' className={ styles.multi_trip_info }>
        { LABELS.MULTI_TRIP_INFO }
      </Text>
    ) : null);

    const nameInfoTrip = () => {
      const renderText = (tripName: string | React.JSX.Element | React.JSX.Element[]) =>
        <Text type='NORMAL_16_140' className={ styles.trip_name }>{ tripName }</Text>;

      if (isAir && multipleTickets && !isSuccessAirRefund) {
        return (
          renderText(prepareTripDialogTitle(item?.ActualVersion))
        );
      }

      if (ticketsWithSamePNR.length) {
        const tripNameHtml = ticketsWithSamePNR.map(({ ActualVersion: av }, index) => (
          <p key={ `trip_name_item_${index}` } className={ styles.trip_name_item }>
            { prepareTripDialogTitle(av) }
          </p>
        ));

        return renderText(tripNameHtml);
      }

      return renderText(nameTrip);
    };

    return (
      <div className={ styles.content_wrapper } data-qa={ QA_ATTRIBUTES.trips.trip.cancelDialog.wrapper }>
        <Text type='bold_20'>{ title() }</Text>
        { renderMulltiAirTripWarning() }
        { nameInfoTrip() }
        { renderCancelAditionlCervice() }
        { renderSoonAnswer() }
        <div className={ styles.content }>
          { renderCancelTrip() }
        </div>
        { renderActions() }
      </div>
    );
  };

  return renderContent();
};

export { CancelDialogContent };
