const languages = {
  ru: 'ru',
  en: 'en',
};

const cloudPaymentsLanguages = {
  [languages.ru]: 'ru-RU',
  [languages.en]: 'en-US',
};

const storageKeys = {
  userLanguage: 'userLanguage',
};

const namespaces = {
  applicationScheme: 'applicationScheme',
  common: 'common',
  smartdesk: 'smartdesk',
  services: 'services',
  components: 'components',
  search: 'search',
  booking: 'booking',
  favorites: 'favorites',
  constants: 'constants',
  expenseReports: 'expenseReports',
  hotels: 'hotels',
  air: 'air',
  trains: 'trains',
  taxi: 'taxi',
  login: 'login',
  quality: 'quality',
  quickApprove: 'quickApprove',
  reports: 'reports',
  requests: 'requests',
  note: 'note',
  cart: 'cart',
  approve: 'approve',
  approvalScheme: 'approvalScheme',
  checkout: 'checkout',
  settings: 'settings',
  trip: 'trip',
  trips: 'trips',
  utils: 'utils',
  aeroexpress: 'aeroexpress',
  analytics: 'analytics',
  chartsAnalytics: 'chartsAnalytics',
  offer: 'offer',
  offerSmartAgent: 'offerSmartAgent',
  transfer: 'transfer',
  vipHall: 'vipHall',
  travelPolicyQuiz: 'travelPolicyQuiz',
  event: 'event',
  payment: 'payment',
};

export { languages, cloudPaymentsLanguages, namespaces, storageKeys };
