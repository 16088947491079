// @ts-nocheck
import React from 'react';
import { Text, PROPS } from 'new-ui';

import { QA_ATTRIBUTES } from '../../../bi/constants/attributesForTests';

import parseJsonString from '../../../bi/utils/parseJsonString';
import { formatHotelRangeDate } from '../../../bi/utils/hotel';

import styles from './styles/index.module.css';

type TripHotelTypeProps = {
  item: any,
  travellers: string,
  serviceType: string,
};

const TripHotel = (item, travellers, serviceType): TripHotelTypeProps => {
  const { jsonData, id } = item;
  const data = parseJsonString(jsonData);

  if (!data) return null;

  const {
    CheckinDate,
    CheckoutDate,
    CustomCheckInDate,
    CustomCheckOutDate,
    Hotel: {
      Name,
      City,
    },
    Room: { Category },
  } = data;

  const date = formatHotelRangeDate(CustomCheckInDate, CustomCheckOutDate, CheckinDate, CheckoutDate);
  const category = Category ? `, ${Category}` : '';

  return (
    <div key={ `trip_hotel_${id}` } className={ styles.wrapper }>
      <Text qaAttr={ QA_ATTRIBUTES.cart.duplicate.date }>{date},</Text>
      <Text qaAttr={ QA_ATTRIBUTES.cart.duplicate.info } type={ PROPS.TEXT.TYPES.NORMAL_14_130 }>{serviceType} {Name} ({City}){category},</Text>
      <Text qaAttr={ QA_ATTRIBUTES.cart.duplicate.travellers } type={ PROPS.TEXT.TYPES.NORMAL_14 } color={ PROPS.TEXT.COLORS.GRAY }>{travellers}</Text>
    </div>
  );
};

export default TripHotel;
