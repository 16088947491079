// @ts-nocheck
import PropTypes from 'prop-types';
import React, { Component, createRef } from 'react';
import { Suggest, Text, PROPS } from 'new-ui';
import { getText } from '../../../../i18n';

import styles from './styles/index.module.css';

const LABELS = {
  ADD_COST_CENTRE: getText('components:employeeList.projectSelect.addCostCenter'),
  QUICK_SEARCH: getText('components:employeeList.projectSelect.quickSearch'),
};

class ProjectSelect extends Component {
  static propTypes = {
    projects: PropTypes.array.isRequired,
    onSelect: PropTypes.func.isRequired,
    // onClose: PropTypes.func.isRequired,
    projectName: PropTypes.string,
    loading: PropTypes.bool,
    showAdd: PropTypes.bool,
    onAddProject: PropTypes.func,
    qaAttr: PropTypes.string,
    qaAttrFirstEl: PropTypes.string,
    qaAttrLoading: PropTypes.string,
    setInterNameCostCenter: PropTypes.func,
  };

  static defaultProps = {
    showAdd: false,
    firstAdd: false,
    loading: false,
    projectName: '',
    onAddProject: () => {},
    setInterNameCostCenter: () => {},
    qaAttr: '',
    qaAttrFirstEl: '',
    qaAttrLoading: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      value: props.projectName,
      // инициализируется полным списком, а меняется в зависимости от input, т.е. this.state.value
      suggestions: this.listSuggestions(),
      meaning: '',
    };

    this.ref = createRef();
  }

  componentDidUpdate(prevProps) {
    const { projectName } = this.props;

    if (prevProps.projectName !== projectName) {
      this.setState({ value: projectName });
    }
  }

  listSuggestions = () => {
    const { projects } = this.props;

    return [
      ...projects.filter(i => !i.IsArchival),
    ];
  };

  getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();

    return (
      this.listSuggestions().filter((project) => {
        const name = project.Name.toLowerCase();

        return name.includes(inputValue);
      })
    );
  };

  handleUpdateSuggestions = (value) => {
    this.setState({
      meaning: value,
      suggestions: this.getSuggestions(value),
    });
  };

  handleSelectSuggestion = ({ Id, Name }) => {
    const { onSelect } = this.props;

    return onSelect(Id, Name);
  };

  handleAdd = () => {
    const { onAddProject, setInterNameCostCenter } = this.props;
    const { meaning } = this.state;

    setInterNameCostCenter(meaning);
    onAddProject();
  };

  renderSuggestion = suggestion => (
    <Text className={ styles.item }>{ suggestion.Name }</Text>
  );

  render() {
    const { loading, qaAttr, qaAttrFirstEl, qaAttrLoading, showAdd } = this.props;
    const { value, suggestions } = this.state;

    return (
      <div className={ styles.projects }>
        <Suggest
          onAdd={ this.handleAdd }
          addButton={ showAdd }
          addButtonLabel={ LABELS.ADD_COST_CENTRE }
          withLabel={ false }
          loading={ loading }
          disabled={ loading }
          placeholder={ LABELS.QUICK_SEARCH }
          onChange={ this.handleUpdateSuggestions }
          theme={ PROPS.SUGGEST.THEME.BORDER }
          items={ suggestions }
          renderItem={ this.renderSuggestion }
          onSelect={ this.handleSelectSuggestion }
          value={ value }
          autoFocus
          ref={ this.ref }
          // useRegisterOutsideClick fix
          // onClose={ onClose }
          shouldFocusItem={ () => false }
          qaAttr={ qaAttr }
          qaAttrFirstEl={ qaAttrFirstEl }
          qaAttrLoading={ qaAttrLoading }
        />
      </div>
    );
  }
}

export default ProjectSelect;
