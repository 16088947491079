import React from 'react';
import { Text, TextColor, Tooltip } from 'new-ui';

import { getText } from '../../../../../i18n';

import { TechnicalStopText } from '../Text';

import toDecline from '../../../../bi/utils/toDecline';

import { AirRoute, ITechnicalStop } from '../../../../bi/types/airline';

import styles from './style.module.css';

const LABELS = {
  withoutTransfers: getText('components:airlineSearchItem.airItem.withoutTransfers'),
  technicalStopDeclines: ['техническая посадка', 'технические посадки'],
};

interface ITechnicalStopTooltipProps {
  changeCount: number;
  route: AirRoute;
  className?: string;
}

const renderTooltipTechnicalStop = (technicalStop: ITechnicalStop[], textColor?: TextColor) => {
  if (!technicalStop.length) return null;

  return (
    <div className={ styles.tooltip }>
      <TechnicalStopText
        className={ styles.text }
        technicalStop={ technicalStop }
        textColor={ textColor }
      />
    </div>
  );
};

const TechnicalStopTooltip = ({ changeCount, route, className = '' }: ITechnicalStopTooltipProps) => {
  const technicalStopsCount = route.Segments[0].TechnicalStop?.length;
  const mayHaveRenderTechnicalStops = !!technicalStopsCount && !changeCount;

  const handleRenderContent = () => {
    const technicalStop = route.Segments[0].TechnicalStop;

    if (technicalStop) {
      return renderTooltipTechnicalStop(technicalStop, 'white');
    }

    return null;
  };

  if (!mayHaveRenderTechnicalStops) return null;

  const titleClassNames = [styles.title];

  if (className) {
    titleClassNames.push(className);
  }

  return (
    <div className={ styles.technical_stop_wrapper }>
      <Text type='NORMAL_14' color='gray'>{ LABELS.withoutTransfers }</Text>
      <Tooltip
        className={ styles.technical_stop }
        renderContent={ handleRenderContent }
        position='bottom'
      >
        <Text
          className={ titleClassNames.join(' ') }
          type='NORMAL_14'
          color='gray'
        >
          { technicalStopsCount } { toDecline(technicalStopsCount, LABELS.technicalStopDeclines) }
        </Text>
      </Tooltip>
    </div>
  );
};

export { TechnicalStopTooltip };
