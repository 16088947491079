import React from 'react';
import { NavLink } from 'react-router-dom';
import { Text, Icon, Price as PriceComponent, IconType, Sticker } from 'new-ui';
import { TypeSticker } from 'new-ui/src/components/Sticker/types';

import { getText } from '../../../i18n';

import { StatusMarker } from '../StatusMarker';

import ROUTES from '../../bi/constants/routes';
import { TRIPSTATUS, FLIGHT_CERTIFICATE } from '../../bi/constants/trips';

import { formatDate, defaultPatternWithTime, formatRangeDateWithSimplicity } from '../../bi/utils/formatDate';
import { getIconType } from '../../bi/utils/trip';
import { isSmartAgent } from '../../bi/utils/env';

import { TripData } from '../../bi/types/trips';

import styles from './styles/trip.module.css';

interface ITripProps {
  value: TripData,
  showEventSticker?: boolean,
}

const LABELS = {
  EDITED: getText('components:trip.edited'),
  ADD_FEE: (price: number) => getText('cart:addFee:trip:trip', { price }),
};

const Trip = ({
  value: {
    Name,
    Id,
    ChangeDate,
    CheckInDate,
    CheckOutDate,
    AdditionalFee,
    Price,
    Services,
    Status,
    EmployeesFull,
    MICEEventId,
  },
  showEventSticker = false,
}: ITripProps) => {
  const servicesType = isSmartAgent ? Services.filter((item) => item !== FLIGHT_CERTIFICATE) : Services;

  const isRenderPrice = !!Price && Status !== TRIPSTATUS.AWAITINGTICKETING;

  const servicesHtml = (service: string, key: number) => {
    const iconType: IconType | null = getIconType(service);

    if (!iconType) {
      return null;
    }

    return (
      <div className={ styles.icon } key={ `${service}_${key}` }>
        <Icon type={ iconType } className={ styles.service } alternativeDesign={ isSmartAgent }/>
      </div>
    );
  };

  const employeesFull = () => {
    if (!EmployeesFull.length) {
      return null;
    }

    return EmployeesFull.map(({ FullName, EmployeeId }) => (
      <Text
        key={ EmployeeId }
        type='NORMAL_14'
        className={ styles.employee }
      >
        {FullName}&nbsp;
      </Text>
    ));
  };

  const renderPrice = () => {
    if (!isRenderPrice) {
      return null;
    }

    return (
      <PriceComponent
        marginSmall
        type='SEMIBOLD_18'
        value={ Price }
      />
    );
  };

  const renderAdditionalFee = () => {
    if (!AdditionalFee || !isRenderPrice || !isSmartAgent) {
      return null;
    }

    return (
      <div
        className={ styles.add_wrapper }
      >
        <Text
          color='gray'
          type='NORMAL_12'
        >
          { LABELS.ADD_FEE(AdditionalFee) }
        </Text>
      </div>
    );
  };

  const renderStickers = () => {
    // моканные данные для объяснения создания последующих стикеров
    // когда добавится второй стикер - удалить
    const isPaySticker = false;

    if (!isPaySticker && !MICEEventId) {
      return null;
    }

    const renderSticker = (value: string | boolean | null, type: TypeSticker, first = true) => value && (
      <div
        className={ styles.sticker }
      >
        <Sticker
          firstSticker={ first }
          type={ type }
        />
      </div>
    );

    return (
      <div
        className={ styles.wrapper_sticker }
      >
        { renderSticker(isPaySticker, 'pay') }
        { renderSticker(MICEEventId && showEventSticker, 'event', !isPaySticker) }
        {/* у последующего будет третьим пропсом в firstSticker будет !isPaySticker && !MICEEventId */}
      </div>
    );
  };

  return (
    <NavLink
      to={ `${ROUTES.TRIP.MAIN}/${Id}` }
    >
      { renderStickers() }
      <div className={ styles.wrapper }>
        <div className={ `${styles.column} ${styles.three}` }>
          <Text
            type='bold_18'
          >
            { Name }
          </Text>
          <div className={ styles.employees }>
            { employeesFull() }
          </div>
          <Text
            type='NORMAL_14'
            className={ styles['change-date'] }
          >
            { LABELS.EDITED }
            { formatDate(ChangeDate, defaultPatternWithTime) }
          </Text>
        </div>
        <div
          className={ `${styles.column} ${styles.icons} ${styles.three}` }
        >
          { servicesType.map(servicesHtml) }
        </div>
        <div className={ `${styles.column} ${styles.three}` }>
          <Text type='NORMAL_18'>
            { formatRangeDateWithSimplicity(CheckInDate, CheckOutDate) }
          </Text>
        </div>
        <div className={ `${styles.column} ${styles.two}` }>
          { renderPrice() }
          { renderAdditionalFee() }
        </div>
        <div className={ `${styles.column} ${styles.three}` }>
          <StatusMarker status={ Status } rightIcon />
        </div>
      </div>
    </NavLink>
  );
};

export default Trip;
