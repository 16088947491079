import React, { FC } from 'react';
import { Text, Tooltip } from 'new-ui';

import type { TextType, TextColor } from 'new-ui';

import { getText } from '../../../i18n';

import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';

import styles from './styles.module.css';

const LABELS = {
  SENT: getText('cart:approveStatuses.sent'),
  SEND_OFFLINE: getText('cart:approveStatuses.sendOffline'),
  APPROVE_REQUEST_FROM: getText('cart:approveStatuses.approveRequestFrom'),
};

interface AddresserInfoItemProps {
  date: string;
  email?: string;
  name?: string;
  label?: string;
  typeText?: TextType;
  color?: TextColor;
  isOffline?: boolean;
}

const AddresserInfoItem: FC<AddresserInfoItemProps> = ({
  email,
  name,
  date,
  label = LABELS.APPROVE_REQUEST_FROM,
  typeText = 'NORMAL_14',
  color = 'default',
  isOffline = false,
}) => {
  const sendLabel = isOffline ?
    LABELS.SEND_OFFLINE :
    LABELS.SENT;

  return (
    <>
      <Text color={ color } className={ styles['approve-list-item'] } type={ typeText }>
        { label }&nbsp;
        <Tooltip renderContent={ () => (
          <div className={ styles['tooltip-content'] }>
            <Text color='white' type='NORMAL_14_130' qaAttr={ QA_ATTRIBUTES.approve.cart.sender.email }>{ email }</Text>
          </div>
        ) }
        >
          <Text
            type={ typeText }
            color={ color }
            className={ styles.name }
            qaAttr={ QA_ATTRIBUTES.approve.cart.sender.name }
          >
            { name }
          </Text>
        </Tooltip>
      </Text>
      <div className={ styles.sent }><Text type={ typeText } color={ color }>{ sendLabel } { date }.</Text></div>
    </>
  );
};

export { AddresserInfoItem };
