// @ts-nocheck
import PropTypes from 'prop-types';
import React from 'react';
import { getText } from '../../../i18n';

import { statuses } from '../../../constants/Request';

import styles from './styles/request-header.module.css';

const LABELS = {
  REQUEST_NUMBER: getText('components:requestHeader.requestNumber'),
  SENT: getText('components:requestHeader.sent'),
};

const RequestHeader = ({ id, status, createdDate, comment, employees }) => (
  <div className={ styles.header }>
    <div className={ styles.name }>{LABELS.REQUEST_NUMBER}{ id }</div>
    <div className={ styles.info }>
      <div className={ styles.block }>
        <div className={ styles.employees }>{ employees }</div>
        <div>{ comment }</div>
      </div>
      <div className={ styles.block }>
        <div className={ styles['created-date'] }>{LABELS.SENT} { createdDate }</div>
        <div>
          <span className={ `${styles['status-label']} ${styles[status.toLowerCase()]}` } />
          { statuses.get(status) }
        </div>
      </div>
    </div>
  </div>
);

RequestHeader.propTypes = {
  id: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  createdDate: PropTypes.string.isRequired,
  employees: PropTypes.string.isRequired,
  comment: PropTypes.string,
};

RequestHeader.defaultProps = {
  comment: '',
};

export default RequestHeader;
