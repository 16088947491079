import { getText } from '../../../i18n';

const MEAL = {
  HAS_MEAL: getText('constants:hotel.meal.hasMeal'),
  NOT_HAS_MEAL: getText('constants:hotel.meal.notHasMeal'),
  HAS_MEAL_NAME: getText('constants:hotel.meal.hasMealName'),
  NOT_HAS_MEAL_NAME: getText('constants:hotel.meal.notHasMealName'),
  MEAL_INCLUDED: getText('constants:hotel.meal.mealIncluded'),
  MEAL_NO_INCLUDED: getText('constants:hotel.meal.mealNoIncluded'),
};

const HOTEL_FORM_REQUEST = {
  SELECT_EMPLOYEE: getText('constants:hotel.hotelFormRequest.selectEmployee'),
  SELECT_TRAVELER: getText('constants:hotel.hotelFormRequest.selectTraveler'),
  HOTELS: getText('constants:hotel.hotelFormRequest.hotels'),
  REGIONS: getText('constants:hotel.hotelFormRequest.regions'),
  BREAKFAST: getText('constants:hotel.hotelFormRequest.breakfast'),
  FREE_CANCELLATION: getText('constants:hotel.hotelFormRequest.freeCancellation'),
  SEND_REQUEST: getText('constants:hotel.hotelFormRequest.sendRequest'),
  YES: getText('constants:hotel.hotelFormRequest.yes'),
  NO: getText('constants:hotel.hotelFormRequest.no'),
  COMMENT: getText('constants:hotel.hotelFormRequest.comment'),
};

const HOTEL_FORM_REQUEST_FIELDS = {
  TRAVELLERS_COUNT: 'travellersCount',
  BREAKFAST: 'breakfast',
  FREE_CANCELLATION: 'freeCancellation',
  COMMENT: 'comment',
};

const FIELD_HOTEL_FORM_RADIO = {
  NOT_DEFINED: 'NotDefined',
  INCLUDES: 'Includes',
  NO_INCLUDED: 'NoIncludes',
};

const CANCELLATION = {
  HAS_CANCELLATION: getText('constants:hotel.cancellation.hasCancellation'),
  NOT_HAS_CANCELLATION: getText('constants:hotel.cancellation.notHasCancellation'),
};

const MAP_SIZE_VIEW_MODE = {
  LIST: 311,
  LISTMAP: 280,
  LIST_MAP_WITH_RATE: 390,
};

const HEIGHT_LIST = 1400;

const WIDTH_LIST = 278;

const CONTRACT_ACTIONS = {
  DECLINE: 'decline',
  ACCEPT: 'accept',
};

const CONTRACT_MESSAGE = {
  [CONTRACT_ACTIONS.ACCEPT]: getText('hotels:contractHotel.requestConfirmed'),
  [CONTRACT_ACTIONS.DECLINE]: getText('hotels:contractHotel.requestRejected'),
};

const FILTER_FIELD_ONLINE = 'online';

const POSTFIXES_ANALYTIC_ACTIONS = {
  ON_REGION: '.onRegion',
  OFF_REGION: '.offRegion',

  ON_HOTEL: '.onHotel',
  OFF_HOTEL: '.offHotel',
};

const HOTEL_TYPE = ['Hotel', 'Hostel', 'Mini-hotel', 'Guesthouse', 'Sanatorium', 'Resort', 'Apartment'];

const ACTIONS = {
  FILTERSTRAVELPOLICY: 'filters.travelPolicy',
  FILTERSPRICE: 'filters.price',
  FILTERSFAVORITE: 'filters.favorite',
  FILTERSSMARTHOTEL: 'filters.smartHotel',
  FILTER_PRICE_GUARANTEED: 'filters.priceGuaranteed',
  FILTERSHASVAT: 'filters.hasVat',
  FILTERSSTARS: 'filters.stars',
  FILTERSTYPE: 'filters.type',
  FILTERSAMENITIES: 'filters.amenities',
  SORTING: 'sortBy',
  NOTTP: 'NotApplied',

  UPDATE_RADIUS_FILTER: 'filters.radius',
  UPDATE_BREAKFAST_FILTER: 'filters.breakfast',
  UPDATE_HAS_CANCELLATION_FILTER: 'filters.has_cancellation',
  UPDATE_ONLINE_FILTER: 'filters.online',

  UPDATEHOTELFILTER: 'hotel.filter.update',
  UPDATECOORDSPOINT: 'update.coordsPoint',
};

const SORT_TYPES = {
  PRICE_GUARANTEED: 'price_guaranteed',
  SMART_HOTEL: 'smart_hotel',
  DISTANCE: 'distance',
  PRICE_UP: 'price_up',
  PRICE_DOWN: 'price_down',
  STARS_UP: 'stars_up',
  STARS_DOWN: 'stars_down',
};

const NEW_SEARCH_SORT_TYPES = {
  PRICE_ASC: 'PriceAsc',
  PRICE_DESC: 'PriceDesc',
  RECOMMEND: 'Recommend',
  DISTANCE_TO_CENTER: 'DistanceToCenter',
};

const NEW_SEARCH_SORT = [
  { label: getText('services:hotels.sort.priceUp'), value: 'PriceAsc', type: null },
  { label: getText('services:hotels.sort.priceDown'), value: 'PriceDesc', type: null },
  { label: getText('services:hotels.sort.distance'), value: 'DistanceToCenter', type: 1 },
  { label: getText('services:hotels.sort.smart'), value: 'Recommend', type: null },
];

enum SEARCH_TYPES {
  RADIUS = 'radius',
  REGION = 'region',
}

const UPSELL_EXPERIMENTS = {
  FIRST: '1',
  SECOND: '2',
  THIRD: '3',
  FOURTH: '4',
  FIFTH: '5',
};

export {
  MEAL,
  HOTEL_FORM_REQUEST,
  HOTEL_FORM_REQUEST_FIELDS,
  FIELD_HOTEL_FORM_RADIO,
  CANCELLATION,
  MAP_SIZE_VIEW_MODE,
  HEIGHT_LIST,
  WIDTH_LIST,
  CONTRACT_ACTIONS,
  CONTRACT_MESSAGE,
  FILTER_FIELD_ONLINE,
  POSTFIXES_ANALYTIC_ACTIONS,
  ACTIONS,
  HOTEL_TYPE,
  SORT_TYPES,
  NEW_SEARCH_SORT_TYPES,
  NEW_SEARCH_SORT,
  SEARCH_TYPES,
  UPSELL_EXPERIMENTS,
};
