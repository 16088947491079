// @ts-nocheck
import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { SearchMenuDate } from './components/SearchMenuDate';
import { SearchMenuDirection } from './components/SearchMenuDirection';

import { PATH } from '../../bi/constants/changeAirTrip';

const ChangeAirTripSearchMenu = (props) => {
  const isSearchDateRef = useRef(props.path === PATH.DATE || props.path === PATH.DATE_RESULT);

  const renderSearchMenu = () => (isSearchDateRef.current ? (
    <SearchMenuDate { ...props }/>
  ) : (
    <SearchMenuDirection { ...props } />
  ));

  return renderSearchMenu();
};

ChangeAirTripSearchMenu.propTypes = {
  airlineService: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  tripItem: PropTypes.object,
  routesInfo: PropTypes.array,
  routesSearch: PropTypes.array,
  useRoutesInfo: PropTypes.bool,
  onSearch: PropTypes.func.isRequired,
  ticketData: PropTypes.object.isRequired,
};

ChangeAirTripSearchMenu.defaultProps = {
  routesInfo: [],
  routesSearch: [],
  tripItem: null,
  useRoutesInfo: false,
};

export { ChangeAirTripSearchMenu };
