import { action, computed, makeObservable, observable } from 'mobx';

import { SIGNATURE_DATA_FIELDS } from '../../../constants/employee';
import {
  DEFAULT_SIGNATURE,
  DEFAULT_DATA,
  DEFAULT_SIGNATURE_CODE,
  DEFAULT_LOADINGS,
  ERRORS,
  DEFAULT_PAGINATE,
} from '../../../constants/signature';

import { isEmail } from '../../../utils/validation';

import {
  SimpleSignatureType,
  DataSignatureType,
  PaginateType,
  DocumentType,
} from '../types';

const TYPE = {
  ASC: 'asc',
  DESC: 'desc',
};

enum DOCUMENTS_SIGN_TYPES {
  DOCUMENT_SIGNED = 1,
  DOCUMENT_NOT_SIGNED = 0,
}

export interface ISimpleSignatureStore {
  signature: SimpleSignatureType,
  loading: boolean,
  isActiveTimer: boolean,
  data: DataSignatureType,
  errorData: DataSignatureType,
  imagesSignature: string,
  confirmationId: string,
  signatureCode: any[],
  codeResponse: string,
  loadings: any,
  employee: any,
  documentsForSignature: DocumentType[],
  paginate: PaginateType,
  sortType: string,
  checkBoxValues: string[],
  documentPreview: string[],
}

class Store {
  constructor() {
    makeObservable(this);
  }

  @observable signature = DEFAULT_SIGNATURE;
  @observable loading = false;
  @observable isActiveTimer = true;
  @observable data = DEFAULT_DATA;
  @observable errorData = DEFAULT_DATA;
  @observable imagesSignature = '';
  @observable loadings = DEFAULT_LOADINGS;
  @observable confirmationId = '';
  @observable signatureCode = DEFAULT_SIGNATURE_CODE;
  @observable codeResponse = '';
  @observable employee: any = {};
  @observable documentsForSignature: DocumentType[] = [];
  @observable paginate = DEFAULT_PAGINATE;
  @observable sortType = TYPE.DESC;
  @observable checkBoxValues: string[] = [];
  @observable documentPreview = [];

  @action
  setLoading = (value: boolean) => {
    this.loading = value;
  };

  @action
  setActiveTimer = (value: boolean) => {
    this.isActiveTimer = value;
  };

  @action
  setSignature = (value: SimpleSignatureType) => {
    this.signature = value;
  };

  @action
  setCodeResponse = (value: string) => {
    this.codeResponse = value;
  };

  @action
  setImagesSignature = (value: string[] | any) => {
    this.imagesSignature = value;
  };

  @action
  setDocumentPreview = (values: string[] | any) => {
    this.documentPreview = values;
  };

  @action
  setConfirmationId = (value: string) => {
    this.confirmationId = value;
  };

  @action
  setCheckBoxValues = (values: any) => {
    this.checkBoxValues = values;
  };

  @action
  setDataSignature = (field: string, value: string) => {
    this.data = {
      ...this.data,
      [field]: value,
    };
  };

  @action
  setDataSignatureErrors = (field: string, value: any) => {
    switch (field) {
      case SIGNATURE_DATA_FIELDS.EMAIL: {
        if (value.trim() === '' || !isEmail(value)) {
          this.errorData = {
            ...this.errorData,
            [field]: ERRORS.EMAIL_FORMAT,
          };

          break;
        }

        this.errorData = {
          ...this.errorData,
          [field]: '',
        };

        break;
      }

      case SIGNATURE_DATA_FIELDS.PHONE_NUMBER: {
        if (!value) {
          if (this.data.phoneNumber) {
            this.errorData = {
              ...this.errorData,
              [field]: ERRORS.PHONE_FORMAT,
            };

            break;
          }

          this.errorData = {
            ...this.errorData,
            [field]: ERRORS.PHONE_LENGTH,
          };

          break;
        }

        this.errorData = {
          ...this.errorData,
          [field]: '',
        };

        break;
      }
    }
  };

  @action
  setLoadings = (field: string, value: boolean) => {
    this.loadings = {
      [field]: value,
    };
  };

  @action
  setSignatureCode = (val: string, clue: number) => {
    this.signatureCode = this.signatureCode.map((item) => ({
      ...item,
      value: item.key === clue ? val : item.value,
    }));
  };

  @action
  setResetSignatureCode = () => {
    this.signatureCode = DEFAULT_SIGNATURE_CODE;
    this.isActiveTimer = true;
  };

  @action
  setEmployeeForDocsSignature = (emp: any) => {
    this.employee = emp;
  };

  @action
  setDocumentsForSignature = (values: DocumentType[] | any) => {
    this.documentsForSignature = values;
  };

  @action
  setDocumentsForSignaturePages = (value: number) => {
    this.paginate = {
      ...this.paginate,
      total: value,
    };
  };

  @action
  setPageSignature = (page: number) => {
    this.paginate = {
      ...this.paginate,
      currentPage: page,
    };
  };

  @action
  resetSignatureCode = () => {
    this.signatureCode = DEFAULT_SIGNATURE_CODE;
  };

  @action
  setSortType = (value: string) => {
    this.sortType = value;
  };

  @action
  resetStore = () => {
    this.signature = DEFAULT_SIGNATURE;
    this.errorData = DEFAULT_DATA;
    this.data = DEFAULT_DATA;
    this.signatureCode = DEFAULT_SIGNATURE_CODE;
    this.employee = {};
    this.documentsForSignature = [];
    this.checkBoxValues = [];
  };

  @action
  resetCheckedDocumentsForSignature = () => {
    this.checkBoxValues = [];
  };

  @computed
  get getBlockedRequestsIds() {
    return this.documentsForSignature
      .filter(({ signingStatus }) => signingStatus === 1)
      .map(({ documentId }) => documentId);
  }

  @computed
  get isDisabledSignButton(): boolean {
    const checkedDocumentsForSignature = this.documentsForSignature
      .filter(({ documentId }) => this.checkBoxValues.includes(documentId));

    return checkedDocumentsForSignature.some(({ signingStatus }) => signingStatus === DOCUMENTS_SIGN_TYPES.DOCUMENT_SIGNED)
      || !this.checkBoxValues.length;
  }
}

const SimpleSignatureStore = new Store();

export { SimpleSignatureStore, Store as SimpleSignatureStoreType };
