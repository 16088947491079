// @ts-nocheck
import PropTypes from 'prop-types';
import React, { Component, createRef } from 'react';
import { observer } from 'mobx-react';
import { Button, Text, Datepicker, Select, StyledWrapper, PROPS, PageLoader } from 'new-ui';
import { getText } from '../../../../i18n';

import { withStores } from '../../../bi/context';
import { MOBX_STORES } from '../../../bi/context/stores';

import splitWithoutRemovingSeparator from '../../../bi/utils/splitWithoutRemovingSeparator';
import { isSmartAgent } from '../../../bi/utils/env';
import { MainAnalytic } from '../../../bi/utils/analytics';

import { SERVICETYPE } from '../../../bi/constants/serviceType';
import { QA_ATTRIBUTES } from '../../../bi/constants/attributesForTests';

import { SelectedRoutes } from '../../SelectedRoutes';
import { SearchSubMenu } from '../SearchSubMenu';
import { WarningBlock } from '../../WarningBlock';

import styles from './styles/index.module.css';

const SELECTTRAVELLERS = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9' },
];

const {
  TEXT: {
    TYPES: { SEMIBOLD_16, SEMIBOLD_12, NORMAL_14, NORMAL_12 },
    COLORS: { GRAY },
  },
  DATEPICKER: {
    INPUT_THEMES: { OPEN },
    TYPES: { DATE },
    THEMES: { DARK: DATEPICKER_DARK },
  },
  BUTTON: { TYPES: { LARGE } },
  SELECT: {
    THEMES: { DEFAULT_SMALL, DARK: SELECT_DARK },
  },
  SUGGEST: { THEME: { DARK } },
} = PROPS;

const LABELS = {
  STATIONFROM: getText('components:menu.train.from'),
  STATIONTO: getText('components:menu.train.to'),
  DATE: getText('components:menu.train.date'),
  DATEBACK: getText('components:menu.train.dateBack'),
  TRAVELLERS: getText('components:menu.train.passengers'),
  SEARCH: getText('common:search'),
};

@withStores([MOBX_STORES.TRAIN_SEARCH])
@observer
class TrainsSearchMenu extends Component {
  static propTypes = {
    trainsService: PropTypes.object.isRequired,
    onSearch: PropTypes.func.isRequired,
    subMenu: PropTypes.bool,
    stores: PropTypes.shape({
      trainSearchStore: PropTypes.object.isRequired,
    }),
  };

  static defaultProps = {
    subMenu: false,
  };

  constructor(props) {
    super(props);

    this.suggestFrom = createRef();
    this.suggestTo = createRef();

    this.state = {
      dateOpen: false,
      dateBackOpen: false,
    };
  }

  componentDidMount() {
    const { trainsService: { setWarningTemplate } } = this.props;

    setWarningTemplate();
  }

  handleFromSelected = (value) => {
    if (value !== null) {
      const { stores: { trainSearchStore: { from } }, trainsService } = this.props;
      trainsService.setFromSelected(value);

      MainAnalytic.sendAmplitudeArrayArgs(
        MainAnalytic.ACTIONS.SEARCH.SEARCH_TRAIN_CITY_CHOSEN(value.Name),
      );

      if (from.label !== value.Name) this.suggestTo.current.focus();
    }
  };

  handleToSelected = (value) => {
    if (value !== null) {
      const { stores: { trainSearchStore: { to } }, trainsService } = this.props;
      trainsService.setToSelected(value);

      MainAnalytic.sendAmplitudeArrayArgs(
        MainAnalytic.ACTIONS.SEARCH.SEARCH_TRAIN_CITY_CHOSEN(value.Name),
      );

      if (to.label !== value.Name) this.handleOpenDate(true);
    }
  };

  handleOpenDate = value => this.setState({ dateOpen: value });

  handleOpenBackDate = value => this.setState({ dateBackOpen: value });

  getWordSuggest = (name, query, typeText) => splitWithoutRemovingSeparator(name)
    .reduce((acc, word) => {
      const foundQueryWord = query.find((queryWord) => word.toLowerCase().startsWith(queryWord.toLowerCase()));

      if (foundQueryWord) {
        return [...acc, ...this.highlightWordStart(word, foundQueryWord, typeText)];
      }

      return [...acc, word];
    }, []);

  handleSearch = () => {
    const { trainsService, onSearch } = this.props;

    trainsService.setSavedTicket(null);
    trainsService.clearSavedTicketsWithTransfer();

    return onSearch();
  };

  setDateWrap = <Fn extends (...args: any[]) => void>(func: Fn, arg: Parameters<Fn>[0]) => {
    func(arg);

    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.SEARCH.SEARCH_TRAIN_DATE_CHOSEN(arg.format()),
    );
  };

  highlightWordStart = (string, query, typeText = SEMIBOLD_16) => {
    const parts = splitWithoutRemovingSeparator(string, query, true);

    return [
      <Text
        type={ typeText }
        className={ styles.highlight }
        key={ Math.random() * Math.random() }
      >
        { parts[0] }
      </Text>,
      ...parts.slice(1),
    ];
  };

  renderSuggestItem = (Name, Hint) => {
    const hintHtml = Hint && (
      <Text
        type={ NORMAL_12 }
        className={ styles.hint }
        color={ GRAY }
      >
        { Hint }
      </Text>
    );

    return (
      <>
        <Text>{ Name }</Text>
        { hintHtml }
      </>
    );
  };

  renderSuggestion = ({ Name, Hint }, query) => {
    if (!query) {
      return this.renderSuggestItem(Name, Hint);
    }

    const querySplit = splitWithoutRemovingSeparator(query);
    const name = this.getWordSuggest(Name, querySplit);
    const hint = Hint ? this.getWordSuggest(Hint, querySplit, SEMIBOLD_12) : null;

    return this.renderSuggestItem(name, hint);
  };

  getRoutesOpts = () => {
    const {
      trainsService: {
        autocompleteFrom,
        autocompleteTo,
      },
      stores: {
        trainSearchStore: {
          from,
          to,
        },
      },
    } = this.props;

    return {
      from: {
        value: from.label || from.selected.Name,
        items: from.suggests,
        placeholder: LABELS.STATIONFROM,
        ref: this.suggestFrom,
        loading: from.loading,
        onSelect: this.handleFromSelected,
        onChange: autocompleteFrom,
      },
      to: {
        value: to.label || to.selected.Name,
        items: to.suggests,
        placeholder: LABELS.STATIONTO,
        ref: this.suggestTo,
        loading: to.loading,
        onSelect: this.handleToSelected,
        onChange: autocompleteTo,
      },
    };
  };

  renderWarningMessage = () => {
    const { stores: { trainSearchStore: { textTemplateWarning } } } = this.props;

    if (!textTemplateWarning) return null;

    return <WarningBlock text={ textTemplateWarning } type='SEMIBOLD_16' color='red' />;
  };

  renderPanelMenu() {
    const { dateOpen, dateBackOpen } = this.state;
    const {
      trainsService: {
        setDate,
        setDateBack,
        setTravellers,
        setFlipFromTo,
      },
      stores: {
        trainSearchStore: {
          date, dateBack, travellers, minDate, minDateBack, isValid,
        },
      },
    } = this.props;

    return (
      <StyledWrapper className={ styles.menu }>
        <div
          className={ styles.row_route }
          ref={ (main) => { this.main = main; } }
        >
          <SelectedRoutes
            opts={ this.getRoutesOpts() }
            renderItem={ this.renderSuggestion }
            keyExtractor={ ({ Code }) => Code }
            onRevert={ setFlipFromTo }
            qaAttrs={ QA_ATTRIBUTES.search.train.panel.cities }
          />
          <div className={ styles.date }>
            <Datepicker
              closeOnTabOut
              withLabel
              open={ dateOpen }
              placeholder={ LABELS.DATE }
              inputTheme={ OPEN }
              value={ date }
              min={ minDate }
              onChange={ (value) => {
                this.setDateWrap(setDate, value);
                this.handleOpenBackDate(true);
              } }
              wrapperClassName={ styles.wrapper }
              inputClassName={ styles.input }
              isDuration
              durationDates={ [date, dateBack] }
              alternativeDesign={ isSmartAgent }
              qaAttr={ QA_ATTRIBUTES.search.train.panel.dates.from }
            />
          </div>
          <div className={ styles.date }>
            <Datepicker
              closeOnTabOut
              withLabel
              isCleansing
              open={ dateBackOpen }
              placeholder={ LABELS.DATEBACK }
              inputTheme={ OPEN }
              value={ dateBack }
              min={ minDateBack }
              onChange={ (value) => this.setDateWrap(setDateBack, value) }
              wrapperClassName={ styles.wrapper }
              inputClassName={ styles.input }
              isDuration
              durationDates={ [date, dateBack] }
              alternativeDesign={ isSmartAgent }
              qaAttr={ QA_ATTRIBUTES.search.train.panel.dates.to }
            />
          </div>
          <div className={ styles.action }>
            <Button
              forwardedRef={ (button) => { this.button = button; } }
              className={ styles.search_button }
              type={ LARGE }
              disabled={ !isValid }
              onClick={ this.handleSearch }
              qaAttr={ QA_ATTRIBUTES.search.train.panel.searchButton }
            >
              { LABELS.SEARCH }
            </Button>
          </div>
        </div>
        <div className={ styles.options }>
          <div className={ styles.passengers }>
            <Text type={ NORMAL_14 }>{LABELS.TRAVELLERS}</Text>
            <div className={ styles.select }>
              <Select
                theme={ DEFAULT_SMALL }
                items={ SELECTTRAVELLERS }
                value={ travellers }
                onChange={ setTravellers }
                qaAttr={ QA_ATTRIBUTES.search.train.panel.passengers }
              />
            </div>
          </div>
        </div>
      </StyledWrapper>
    );
  }

  renderSubMenu() {
    const { dateOpen, dateBackOpen } = this.state;
    const {
      trainsService: {
        setDate,
        setDateBack,
        setTravellers,
        setFlipFromTo,
      },
      stores: {
        trainSearchStore: {
          date, dateBack, travellers, minDate, minDateBack, isValid,
        },
      },
    } = this.props;

    return (
      <SearchSubMenu
        serviceType={ SERVICETYPE.TRAIN }
        disabled={ !isValid }
        buttonLabel={ LABELS.SEARCH }
        onClick={ this.handleSearch }
        qaAttrIcon={ QA_ATTRIBUTES.search.train.menu.icon }
        qaAttrSearchButton={ QA_ATTRIBUTES.search.train.menu.searchButton }
      >
        <div className={ styles.submenu }>
          <div className={ styles.routes }>
            <SelectedRoutes
              theme={ DARK }
              opts={ this.getRoutesOpts() }
              renderItem={ this.renderSuggestion }
              keyExtractor={ ({ Code }) => Code }
              onRevert={ setFlipFromTo }
              qaAttrs={ QA_ATTRIBUTES.search.train.menu.cities }
            />
          </div>
          <Datepicker
            placeholder={ LABELS.DATE }
            withLabel
            open={ dateOpen }
            type={ DATE }
            inputTheme={ OPEN }
            theme={ DATEPICKER_DARK }
            min={ minDate }
            value={ date }
            onChange={ (value) => {
              setDate(value);
              this.handleOpenBackDate(true);
            } }
            getCurrentOpen={ this.handleOpenDate }
            inputClassName={ styles.input }
            wrapperClassName={ styles.wrapper }
            isDuration
            durationDates={ [date, dateBack] }
            alternativeDesign={ isSmartAgent }
            qaAttr={ QA_ATTRIBUTES.search.train.menu.dates.from }
          />
          <div className={ styles.item }>
            <Datepicker
              placeholder={ LABELS.DATEBACK }
              withLabel
              isCleansing
              open={ dateBackOpen }
              type={ DATE }
              inputTheme={ OPEN }
              theme={ DATEPICKER_DARK }
              min={ minDateBack }
              value={ dateBack }
              onChange={ setDateBack }
              getCurrentOpen={ this.handleOpenBackDate }
              inputClassName={ styles.input }
              wrapperClassName={ styles.wrapper }
              isDuration
              durationDates={ [date, dateBack] }
              alternativeDesign={ isSmartAgent }
              qaAttr={ QA_ATTRIBUTES.search.train.menu.dates.to }
            />
          </div>
          <div className={ `${styles.passengers} ${styles.item}` }>
            <Select
              withLabel
              className={ styles.select }
              value={ travellers }
              placeholder={ LABELS.TRAVELLERS }
              items={ SELECTTRAVELLERS }
              onChange={ setTravellers }
              theme={ SELECT_DARK }
              alternativeDesign={ isSmartAgent }
              qaAttr={ QA_ATTRIBUTES.search.train.menu.passengers }
            />
          </div>
        </div>
      </SearchSubMenu>
    );
  }

  renderSearchMenu = () => {
    const { subMenu } = this.props;

    return (
      <>
        { subMenu ? this.renderSubMenu() : this.renderPanelMenu() }
        { !subMenu && this.renderWarningMessage() }
      </>
    );
  };

  render() {
    const { stores: { trainSearchStore: { schemeLoading } } } = this.props;

    if (schemeLoading) {
      return <PageLoader />;
    }

    return this.renderSearchMenu();
  }
}

export { TrainsSearchMenu };
