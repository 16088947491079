// @ts-nocheck
import PropTypes from 'prop-types';
import React from 'react';
import { Input } from 'new-ui';

import { StickyPanel } from '../Sticky';

import styles from './index.module.css';

const StickySearchPanel = ({
  adjustment,
  initial,
  style,
  query,
  placeholder,
  children,
  disabled,
  onChangeInput,
  onBlurFastSearch,
  qaAttr,
}) => (
  <StickyPanel
    adjustment={ adjustment }
    initial={ initial }
    fixedClass={ styles['search--fixed'] }
    style={ style }
    className={ styles.panel }
    renderOnHeader
  >
    <div className={ styles.wrapper }>
      <div className={ styles.content }>
        <div className={ styles.search }>
          <Input
            className={ styles.input }
            onChange={ onChangeInput }
            value={ query }
            disabled={ disabled }
            isCleansing
            placeholder={ placeholder }
            onBlur={ onBlurFastSearch }
            qaAttr={ qaAttr }
          />
        </div>
        { children }
      </div>
    </div>
  </StickyPanel>
);

StickySearchPanel.defaultProps = {
  placeholder: '',
  children: null,
  disabled: false,
  onBlurFastSearch: () => {},
  onChangeInput: () => {},
  qaAttr: '',
};

StickySearchPanel.propTypes = {
  adjustment: PropTypes.number.isRequired,
  initial: PropTypes.number.isRequired,
  style: PropTypes.object.isRequired,
  query: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  onChangeInput: PropTypes.func,
  onBlurFastSearch: PropTypes.func,
  qaAttr: PropTypes.string,
};

export default StickySearchPanel;
