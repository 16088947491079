import { getText } from '../../../i18n';

import { HOTEL_TYPE, SORT_TYPES } from '../constants/hotel';
import { TRAVELPOLICYFILTER } from '../constants/travelPolicy';
import { HOTELFILTERTYPE } from '../constants/tagsFilterType';
import { RATING_RU, AMENITITESRU, TYPERU } from '../constants/hotelsSearch';
import {
  Amenities,
  Filters, HotelAutocompleteItem, HotelRegionStatic,
  Paging,
  PreparedVariant, Price, RegionRate, HotelAutocompleteObj,
  ITag,
  Variant,
  HotelStatic,
  IHotel,
  IHotelsFilters,
  IDisplayFilters,
} from '../services/hotels/types';
import { TravelPolicyItem } from '../services/userSession/types';

import translit from './translit';
import switchKeyboard from './switchKeyboard';
import MoneyFormat from './money';
import { getDistanceToCenter } from './gis';
import { isSmartAgent } from './env';

const LABELS = {
  ONLINE: getText('services:hotels.store.hotels.tags.onlyOnline'),
  HAS_VAT: getText('services:hotels.store.hotels.tags.hasVat'),
  FREE_CANCELLATION: getText('services:hotels.store.hotels.tags.freeCancellation'),
  MEAL_INCLUDED: getText('services:hotels.store.hotels.tags.mealIncluded'),
  SMART_HOTEL: getText('services:hotels.store.hotels.tags.smartHotel'),
  PRICE_GUARANTEED: getText('services:hotels.store.hotels.tags.priceGuaranteed'),
  FAVORITE: getText('services:hotels.store.hotels.tags.favorite'),
  Recommended: getText('services:hotels.store.hotels.tags.recommended'),
  HOTEL_NAME: (hotelName: string) => getText('services:hotels.store.hotels.tags.hotelName', { hotelName }),
  PRICE_FROM_TO: (from: string, to: string) => getText('services:hotels.store.hotels.tags.priceFromTo', { from, to }),
  STARS: (count: string) => getText('services:hotels.store.hotels.tags.stars', { count }),
  RATING: (rating: string) => getText('services:hotels.store.hotels.tags.rating', { rating }),
  DISTANCE_FROM_CENTER: (distance: number) => getText('services:hotels.store.hotels.tags.distanceFromCenter', { distance }),
  DISTANCE_FROM_POINT: (distance: number) => getText('services:hotels.store.hotels.tags.distanceFromPoint', { distance }),
  REGIONS: getText('services:hotels.store.search.tags.regions'),
  HOTELS: getText('services:hotels.store.search.tags.hotels'),
};

const cloneFilterState = (oldV: any, newV: any) => {
  const result = { ...newV, ...oldV };

  Object.keys(result).forEach((item) => {
    result[item].count = newV[item] ? newV[item].count : 0;
  });

  return result;
};

const mergeVariants = (newVariants: Variant[] = [], oldVariants: Variant[] = []): Variant[] => [
  ...newVariants,
  ...oldVariants.filter(({ HotelId }) => !newVariants.some(({ HotelId: newHotelId }) => HotelId === newHotelId)),
];

const checkAmenities = (item: HotelRegionStatic, filters: Filters, amenitiesKeys: string[]): boolean => {
  const filAm = filters.amenities;

  return amenitiesKeys.some((key: string) => filAm[key as keyof Amenities] && item.Amenities[key as keyof Amenities]);
};

const applyFilter = (sources: Variant[], filters: Filters): PreparedVariant[] => {
  const starsKey = Object.keys(filters.stars);
  const hasAllStars = starsKey.filter(key => !filters.stars[key].selected).length === starsKey.length;

  const typeKey = Object.keys(filters.type);
  const hasAllType = typeKey.filter(key => !filters.type[key].selected).length === typeKey.length;

  const amenitiesKey = Object.keys(filters.amenities);
  const hasAllAmenities = amenitiesKey.filter((key) => !filters.amenities[key as keyof Amenities]).length === amenitiesKey.length;
  const maxCheckedRating = Math.max(
    ...Object.keys(filters.rating).reduce((result: number[], rating) => {
      const newResult: number[] = [...result];

      if (filters.rating[rating]) {
        newResult.push(Number(rating));
      }

      return newResult;
    }, []),
  );

  const hotelName = filters.hotelName.toLowerCase();
  const translitHotelName = translit(hotelName);
  const switchKeyboardHotelName = switchKeyboard(hotelName);

  const sortRatesByNight = (a: RegionRate, b: RegionRate) => a.Night - b.Night;

  return sources.reduce<PreparedVariant[]>((acc, item) => {
    const {
      Rates = [],
      Static,
      IsContract = false,
      IsPriceGuaranteed = false,
      Static: {
        FavoriteId = '',
      },
    } = item;

    const ratesWithoutTP = Rates.filter(({ TravelPolicy: { Apply } }) => !Apply);
    const ratesWithTP = Rates.filter(({ TravelPolicy: { Apply } }) => Apply);

    const ratesSortedByPrice = [...ratesWithoutTP, ...ratesWithTP].sort(sortRatesByNight);

    if (IsContract && Rates.length) {
      return [
        ...acc,
        {
          static: Static,
          rate: ratesSortedByPrice[0],
          isContract: IsContract,
          favoriteId: FavoriteId,
          isPriceGuaranteed: IsPriceGuaranteed,
        },
      ];
    }

    const hasSmartHotel = filters.smartHotel ? Static.IsSmartHotel : true;
    const hasGuaranteedHotel = filters.priceGuaranteed ? IsPriceGuaranteed : true;

    const itemName = Static.HotelName.toLowerCase();

    const hasHotelName =
      itemName.includes(hotelName) ||
      (translitHotelName && itemName.includes(translitHotelName)) ||
      (switchKeyboardHotelName && itemName.includes(switchKeyboardHotelName));

    const hasStars = hasAllStars || (filters.stars[Static.Stars] && filters.stars[Static.Stars].selected);
    const hasType = hasAllType || (filters.type[Static.Type] && filters.type[Static.Type].selected);
    const hasAmenities = hasAllAmenities || checkAmenities(Static, filters, amenitiesKey);
    const isFavorite = filters.favoriteId ? FavoriteId : true;

    const hasRating = Static.Rating && Static.Rating.Value >= maxCheckedRating;

    const hasRadius = filters.radius.custom
      || !filters.radius.value
      || (filters.radius.value && filters.radius.value >= Static.DistanceFromCenter);

    if (
      !isFavorite ||
      !hasGuaranteedHotel ||
      !hasSmartHotel ||
      !hasHotelName ||
      !hasStars ||
      !hasType ||
      !hasAmenities ||
      !hasRating ||
      !hasRadius
    ) {
      return acc;
    }

    for (let index = 0; index < ratesSortedByPrice.length; index++) {
      const rate = ratesSortedByPrice[index];

      const price = rate.Night;
      const hasPrice = price >= filters.price.from && price <= filters.price.to;

      const hasVat = filters.hasVat ? rate.HasVat : true;
      const hasCancellation = filters.hasCancellation ? rate.Refundable : true;

      const isOnline = filters.online ? rate.Online : true;

      const hasBreakfast = filters.breakfast ? rate.Breakfast : true;

      if (hasPrice && hasCancellation && hasVat && isOnline && hasBreakfast) {
        return [
          ...acc,
          {
            rate,
            static: Static,
            favoriteId: FavoriteId,
            isContract: IsContract,
            isPriceGuaranteed: IsPriceGuaranteed,
          },
        ];
      }
    }

    return acc;
  }, []);
};

const sortByNewSearch = (sources: Variant[], radiusValue: number, radiusCustom: boolean) =>
  sources.reduce<PreparedVariant[]>((acc, item) => {
    const {
      Static,
      IsContract = false,
      IsPriceGuaranteed = false,
      Rate = null,
      DebugInfo = '',
      Static: {
        FavoriteId = '',
      },
    } = item;

    const hasRadius = radiusCustom
        || !radiusValue
        || (radiusValue && radiusValue >= Static.DistanceFromCenter);

    if (!hasRadius || !Rate) {
      return acc;
    }

    return [
      ...acc,
      {
        static: Static,
        rate: Rate,
        isContract: IsContract,
        favoriteId: FavoriteId,
        debugInfo: DebugInfo,
        isPriceGuaranteed: IsPriceGuaranteed,
      },
    ];
  }, []);

const sortBy = (sources: Variant[], sortValue: string, filters: Filters): PreparedVariant[] => {
  const newSources: PreparedVariant[] = applyFilter(sources, filters);

  let order = 1;
  let fn = null;

  const favoritesHotelsWithoutTP: PreparedVariant[] = [];
  const favoritesHotelsWithTP: PreparedVariant[] = [];
  const otherHotelsWithoutTP: PreparedVariant[] = [];
  const otherHotelsWithTP: PreparedVariant[] = [];
  const hotelsWithoutTravelPolicy: PreparedVariant[] = [];
  const hotelsWithTravelPolicy: PreparedVariant[] = [];
  const hotelsContract: PreparedVariant[] = [];

  newSources.forEach((item) => {
    if (item.isContract) {
      hotelsContract.push(item);

      return;
    }

    if (item?.rate?.TravelPolicy.Apply) {
      if (filters.selectedTravelPolicy !== TRAVELPOLICYFILTER.NOTAPPLIED) {
        if (typeof item.rate.TravelPolicy.Errors[filters.selectedTravelPolicy] !== 'undefined') {
          hotelsWithTravelPolicy.push(item);

          return;
        }

        hotelsWithoutTravelPolicy.push(item);

        return;
      }

      hotelsWithoutTravelPolicy.push(item);

      return;
    }

    hotelsWithoutTravelPolicy.push(item);
  });

  if (hotelsWithoutTravelPolicy.length > 0) {
    hotelsWithoutTravelPolicy.forEach(item => {
      if (item.favoriteId) {
        favoritesHotelsWithoutTP.push(item);
      } else {
        otherHotelsWithoutTP.push(item);
      }
    });
  }

  if (hotelsWithTravelPolicy.length > 0) {
    hotelsWithTravelPolicy.forEach(item => {
      if (item.favoriteId) {
        favoritesHotelsWithTP.push(item);
      } else {
        otherHotelsWithTP.push(item);
      }
    });
  }

  const nameSort = (first: PreparedVariant, second: PreparedVariant, newOrder?: number) => {
    const currentOrder = newOrder || order;

    if (first.static.HotelName > second.static.HotelName) return currentOrder;

    if (first.static.HotelName < second.static.HotelName) return -currentOrder;

    return 0;
  };

  const priceSort = (first: PreparedVariant, second: PreparedVariant) => {
    // @ts-ignore
    if (first.rate.Total > second.rate.Total) return order;

    // @ts-ignore
    if (first.rate.Total < second.rate.Total) return -order;

    return nameSort(first, second, 1);
  };

  const starsValueSort = (first: PreparedVariant, second: PreparedVariant) => {
    if (first.static.Stars > second.static.Stars) return order;

    if (first.static.Stars < second.static.Stars) return -order;

    return nameSort(first, second, 1);
  };

  const distanceSort = (first: PreparedVariant, second: PreparedVariant) => {
    if (first.static.DistanceFromCenter > second.static.DistanceFromCenter) return order;

    if (first.static.DistanceFromCenter < second.static.DistanceFromCenter) return -order;

    return nameSort(first, second, 1);
  };

  const smartSort = (list: PreparedVariant[]) => {
    const smart: { [key: string]: PreparedVariant[] } = {};
    const other: { [key: string]: PreparedVariant[] } = {};

    list.forEach((item) => {
      if (item.static.IsSmartHotel) {
        if (!smart[item.static.Stars]) smart[item.static.Stars] = [];

        smart[item.static.Stars].push(item);
      } else {
        if (!other[item.static.Stars]) other[item.static.Stars] = [];

        other[item.static.Stars].push(item);
      }
    });

    order = 1;

    const keySmart = Object.keys(smart).reverse();
    const keyOther = Object.keys(other).reverse();

    const smartArray = keySmart.reduce<PreparedVariant[]>((prev, curr) => [...prev, ...smart[curr].sort(nameSort)], []);
    const otherArray = keyOther.reduce<PreparedVariant[]>((prev, curr) => [...prev, ...other[curr].sort(nameSort)], []);

    return [...smartArray, ...otherArray];
  };

  switch (sortValue) {
    case SORT_TYPES.SMART_HOTEL:
      return [
        ...smartSort(hotelsContract),
        ...smartSort(favoritesHotelsWithoutTP),
        ...smartSort(otherHotelsWithoutTP),
        ...smartSort(favoritesHotelsWithTP),
        ...smartSort(otherHotelsWithTP),
      ];
    case SORT_TYPES.PRICE_UP:
      order = 1;
      fn = priceSort;
      break;
    case SORT_TYPES.PRICE_DOWN:
      order = -1;
      fn = priceSort;
      break;
    case SORT_TYPES.DISTANCE:
      fn = distanceSort;
      break;
    case SORT_TYPES.STARS_UP:
      order = 1;
      fn = starsValueSort;
      break;
    case SORT_TYPES.STARS_DOWN:
      order = -1;
      fn = starsValueSort;
      break;
    default: {
      fn = nameSort;
    }
  }

  return [
    ...hotelsContract.sort(fn),
    ...favoritesHotelsWithoutTP.sort(fn),
    ...otherHotelsWithoutTP.sort(fn),
    ...favoritesHotelsWithTP.sort(fn),
    ...otherHotelsWithTP.sort(fn),
  ];
};

const sortTypesByCount = (first: any[], second: any[]) => {
  if (first[1].count < second[1].count) return 1;

  if (first[1].count > second[1].count) return -1;

  return 0;
};

const sortByVisibleHotelOnMap = (hotels: PreparedVariant[], visibleHotel: number[]) => hotels.filter(({ static: { HotelId } }) => visibleHotel.some(id => id === HotelId));

const updatePriceFilter = (price: Price, value: number[]) => ({
  border: {
    ...price.border,
  },
  roundBy: 0,
  from: value[0],
  to: value[1],
});

const updateFiltersParams = (
  sources: Variant[],
  filters: Filters,
  unavailableTravelPolicy: boolean,
  accountTravelPolicy: any,
  travelPolicyAllList: TravelPolicyItem[],
  isWhiteLabel: boolean,
  isFiltersHotelsInMicroservice: boolean,
) => {
  let min = 0;
  let max = 0;

  const stars = {};
  let type: { [key: string]: { selected: boolean, count: number } } = {};

  let hasOneSmart = false;
  let hasOneGuaranteed = false;
  let isAnyFavorite = false;
  let selectedTP = filters.selectedTravelPolicy;

  const travelPolicyList: string[] = [];
  travelPolicyList.push(TRAVELPOLICYFILTER.NOTAPPLIED);

  sources.forEach((item) => {
    let price = 0;

    if (!isFiltersHotelsInMicroservice) {
      price = Math.max(...item.Rates.map(({ Night }) => Night));
    }

    min = Math.min(min, price);
    max = Math.max(max, price);

    if (item.Static.Stars !== 0) {
      // @ts-ignore
      if (!stars[item.Static.Stars]) stars[item.Static.Stars] = { selected: false, count: 0 };

      // @ts-ignore
      stars[item.Static.Stars].count += 1;
    }

    if (HOTEL_TYPE.indexOf(item.Static.Type) !== -1) {
      if (!type[item.Static.Type]) type[item.Static.Type] = { selected: false, count: 0 };

      type[item.Static.Type].count += 1;

      const typesSortByCount = Object.entries(type).sort(sortTypesByCount);
      type = Object.fromEntries(typesSortByCount);
    }

    if (item.Static?.IsSmartHotel && !isFiltersHotelsInMicroservice) {
      hasOneSmart = true;
    }

    if (item.IsPriceGuaranteed && !isWhiteLabel && !isSmartAgent && !isFiltersHotelsInMicroservice) {
      hasOneGuaranteed = true;
    }

    if (item.Static.FavoriteId && !isFiltersHotelsInMicroservice) {
      isAnyFavorite = true;
    }

    travelPolicyAllList.forEach((tp) => {
      if (tp.HotelRule.Apply && !travelPolicyList.some(tpInList => tpInList === tp.Id)) {
        travelPolicyList.push(tp.Id);
      }
    });

    if (item.Static?.TravelPolicy?.Apply) {
      const travelPolicyErrors = Object.keys(item.Static.TravelPolicy.Errors);

      travelPolicyErrors.forEach((tpE) => {
        if (travelPolicyList.length === 1) {
          travelPolicyList.push(tpE);
        } else if (travelPolicyList.length > 1) {
          const tpFind = travelPolicyList.filter(tp => tp === tpE);

          if (!tpFind.length) {
            travelPolicyList.push(tpE);
          }
        }
      });
    }
  });

  if (unavailableTravelPolicy && travelPolicyList.length > 1 && !!accountTravelPolicy) {
    const hasAccountTP = travelPolicyList.filter(tp => tp === accountTravelPolicy.Id);

    if (hasAccountTP.length > 0) {
      selectedTP = hasAccountTP[0];
    } else {
      travelPolicyList.splice(0, travelPolicyList.length);
    }
  }

  const newFilters = {
    ...filters,
    price: {
      border: {
        from: min,
        to: max,
      },
      roundBy: 0,
      from: filters.changed.price ? filters.price.from : min,
      to: filters.changed.price || isFiltersHotelsInMicroservice ? filters.price.to : max,
    },
    selectedTravelPolicy: selectedTP,
    travelPolicyList,
    stars: cloneFilterState(filters.stars, stars),
    type: cloneFilterState(filters.type, type),
  };

  return { filters: newFilters, hasOneSmart, hasOneGuaranteed, isAnyFavorite };
};

const getPagingView = (items: PreparedVariant[], paging: Paging): PreparedVariant[] => {
  const startInd = paging.count * (paging.current - 1);

  return items.slice(startInd, startInd + paging.count);
};

const hasChangeFilter = (filter: any) => {
  const keys = Object.keys(filter);

  return Boolean(keys.filter(key => filter[key]).length);
};

const hasChangeObjFilter = (filter: { selected: boolean, count: number }[]) => {
  const keys = Object.keys(filter);

  // @ts-ignore
  return Boolean(keys.filter((key) => filter[key].selected).length);
};

const hasChangePriceFilter = (price: Omit<Price, 'roundBy'>) => price.border.from !== price.from || price.border.to !== price.to;

const getDiffPercentOnStartSearch = (start: number): number => {
  const now = Date.now();

  // получаем разницу с текущего момента времени до старта поиска в секундах
  const diff = ((((start - now) % 86400000) % 3600000) / 60000) * 60;
  // чтобы выдать прогресс в диапазоне от 0 до 1 (считая что max = 120s)
  // вычисляем по пропорции x = diff * 100% / 120s
  // дополнительно делим на 100 - чтобы получить значение в пределах от 0 до 1
  let result = (diff * 5) / 6 / 100;

  if (result > 1) result = 1;

  if (result < 0) result *= -1;

  return result;
};

const createNewSearchTags = (
  hotelFilters: IHotelsFilters,
  travelPolicyAllList: TravelPolicyItem[],
  unavailableTravelPolicy: boolean,
  displayFilters: IDisplayFilters | null,
  radiusCustom: boolean,
  travelPolicyList: string[],
  priceTo: number,
) => {
  const tags: ITag[] = [];

  if (!displayFilters) return tags;

  const {
    Rate: {
      PriceFrom,
      HasCancellation,
      Online,
      Breakfast,
      TravelPolicy,
      Recommended,
    },
    Hotel: {
      SmartHotel,
      PriceGuaranteed,
      Name,
      Favorite,
      Proximity: {
        Radius,
      },
      Type,
      Stars,
      Amenities: AmenitiesFilter,
      Rating,
    },
  } = hotelFilters;
  const {
    Type: displayTypes,
    Stars: displayStars,
    Amenities: displayAmenities,
    Rating: displayRating,
  } = displayFilters;

  if (Recommended) {
    tags.push({
      name: LABELS.Recommended,
      filter: HOTELFILTERTYPE.RECOMMENDED,
      key: HOTELFILTERTYPE.RECOMMENDED,
    });
  }

  if ((PriceFrom !== displayFilters.PriceFrom || priceTo !== displayFilters.PriceTo)) {
    tags.push({
      name: LABELS.PRICE_FROM_TO(MoneyFormat.money(PriceFrom), MoneyFormat.money(priceTo)),
      key: 'price',
      filter: 'price',
    });
  }

  if (SmartHotel) {
    tags.push({
      name: LABELS.SMART_HOTEL,
      filter: HOTELFILTERTYPE.SMARTHOTEL,
      key: HOTELFILTERTYPE.SMARTHOTEL,
    });
  }

  if (PriceGuaranteed) {
    tags.push({
      name: LABELS.PRICE_GUARANTEED,
      filter: HOTELFILTERTYPE.PRICE_GUARANTEED,
      key: HOTELFILTERTYPE.PRICE_GUARANTEED,
    });
  }

  if (Name) {
    tags.push({
      name: LABELS.HOTEL_NAME(Name),
      filter: HOTELFILTERTYPE.HOTELNAME,
      key: HOTELFILTERTYPE.HOTELNAME,
    });
  }

  if (HasCancellation) {
    tags.push({
      name: LABELS.FREE_CANCELLATION,
      filter: HOTELFILTERTYPE.HASCANCELLATION,
      key: HOTELFILTERTYPE.HASCANCELLATION,
    });
  }

  if (Favorite) {
    tags.push({
      name: LABELS.FAVORITE,
      filter: HOTELFILTERTYPE.FAVORITES,
      key: HOTELFILTERTYPE.FAVORITES,
    });
  }

  if (Online && !isSmartAgent) {
    tags.push({
      name: LABELS.ONLINE,
      filter: HOTELFILTERTYPE.ONLINE,
      key: HOTELFILTERTYPE.ONLINE,
    });
  }

  if (Radius) {
    const distanceFromLabel = radiusCustom
      ? LABELS.DISTANCE_FROM_POINT(Radius)
      : LABELS.DISTANCE_FROM_CENTER(Radius);

    tags.push({
      name: distanceFromLabel,
      filter: HOTELFILTERTYPE.RADIUS,
      key: HOTELFILTERTYPE.RADIUS,
    });
  }

  if (Breakfast) {
    tags.push({
      name: LABELS.MEAL_INCLUDED,
      filter: HOTELFILTERTYPE.BREAKFAST,
      key: HOTELFILTERTYPE.BREAKFAST,
    });
  }

  if (displayTypes) {
    Object.keys(displayTypes).forEach((key) => {
      if (Type && Type.includes(key)) {
        tags.push({
          name: TYPERU[key],
          filter: HOTELFILTERTYPE.TYPE,
          key,
        });
      }
    });
  }

  if (displayStars) {
    Object.keys(displayStars).forEach((key) => {
      if (Stars && Stars.includes(+key)) {
        tags.push({
          name: LABELS.STARS(key),
          filter: HOTELFILTERTYPE.STARS,
          key,
        });
      }
    });
  }

  if (displayAmenities) {
    Object.keys(displayAmenities).forEach((key: string) => {
      if (AmenitiesFilter && AmenitiesFilter.includes(key)) {
        tags.push({
          name: AMENITITESRU[key],
          filter: HOTELFILTERTYPE.AMENITIES,
          key,
        });
      }
    });
  }

  if (displayRating) {
    Object.keys(displayRating).forEach((key: string) => {
      if (Rating && Rating.includes(+key)) {
        tags.push({
          name: LABELS.RATING(RATING_RU[key]),
          filter: HOTELFILTERTYPE.RATING,
          key,
        });
      }
    });
  }

  if (travelPolicyList.length > 1 && TravelPolicy !== TRAVELPOLICYFILTER.NOTAPPLIED) {
    const travelPolicy = travelPolicyAllList.find(item => item.Id === TravelPolicy);

    if (!!travelPolicy && !!travelPolicy.Name) {
      tags.push({
        name: travelPolicy.Name,
        filter: HOTELFILTERTYPE.SELECTEDTRAVELPOLICY,
        key: HOTELFILTERTYPE.SELECTEDTRAVELPOLICY,
        readOnly: unavailableTravelPolicy,
      });
    }
  }

  return tags;
};

// создание тегов для фильтров старой выдачи
const createTags = (filters: Filters, travelPolicyAllList: TravelPolicyItem[], unavailableTravelPolicy: boolean): ITag[] => {
  const tags = [];

  if (filters.price.border.from !== filters.price.from || filters.price.border.to !== filters.price.to) {
    tags.push({
      name: LABELS.PRICE_FROM_TO(MoneyFormat.money(filters.price.from), MoneyFormat.money(filters.price.to)),
      key: 'price',
      filter: 'price',
    });
  }

  if (filters.smartHotel) {
    tags.push({
      name: LABELS.SMART_HOTEL,
      filter: HOTELFILTERTYPE.SMARTHOTEL,
      key: HOTELFILTERTYPE.SMARTHOTEL,
    });
  }

  if (filters.priceGuaranteed) {
    tags.push({
      name: LABELS.PRICE_GUARANTEED,
      filter: HOTELFILTERTYPE.PRICE_GUARANTEED,
      key: HOTELFILTERTYPE.PRICE_GUARANTEED,
    });
  }

  if (filters.hotelName) {
    tags.push({
      name: LABELS.HOTEL_NAME(filters.hotelName),
      filter: HOTELFILTERTYPE.HOTELNAME,
      key: HOTELFILTERTYPE.HOTELNAME,
    });
  }

  if (filters.hasCancellation) {
    tags.push({
      name: LABELS.FREE_CANCELLATION,
      filter: HOTELFILTERTYPE.HASCANCELLATION,
      key: HOTELFILTERTYPE.HASCANCELLATION,
    });
  }

  if (filters.favoriteId) {
    tags.push({
      name: LABELS.FAVORITE,
      filter: HOTELFILTERTYPE.FAVORITES,
      key: HOTELFILTERTYPE.FAVORITES,
    });
  }

  if (filters.hasVat) {
    tags.push({
      name: LABELS.HAS_VAT,
      filter: HOTELFILTERTYPE.VAT,
      key: HOTELFILTERTYPE.VAT,
    });
  }

  if (filters.online && !isSmartAgent) {
    tags.push({
      name: LABELS.ONLINE,
      filter: HOTELFILTERTYPE.ONLINE,
      key: HOTELFILTERTYPE.ONLINE,
    });
  }

  if (filters.radius.value) {
    const distanceFromLabel = filters.radius.custom ?
      LABELS.DISTANCE_FROM_POINT(filters.radius.value) : LABELS.DISTANCE_FROM_CENTER(filters.radius.value);

    tags.push({
      name: distanceFromLabel,
      filter: HOTELFILTERTYPE.RADIUS,
      key: HOTELFILTERTYPE.RADIUS,
    });
  }

  if (filters.breakfast) {
    tags.push({
      name: LABELS.MEAL_INCLUDED,
      filter: HOTELFILTERTYPE.BREAKFAST,
      key: HOTELFILTERTYPE.BREAKFAST,
    });
  }

  Object.keys(filters.type).forEach((key) => {
    if (filters.type[key].selected) {
      tags.push({
        name: TYPERU[key],
        filter: HOTELFILTERTYPE.TYPE,
        key,
      });
    }
  });

  Object.keys(filters.stars).forEach((key) => {
    if (filters.stars[key].selected) {
      tags.push({
        name: LABELS.STARS(key),
        filter: HOTELFILTERTYPE.STARS,
        key,
      });
    }
  });

  Object.keys(filters.amenities).forEach((key: string) => {
    if (filters.amenities[key as keyof Amenities]) {
      tags.push({
        name: AMENITITESRU[key],
        filter: HOTELFILTERTYPE.AMENITIES,
        key,
      });
    }
  });

  Object.keys(filters.rating).forEach((k: string) => {
    if (filters.rating[k]) {
      tags.push({
        name: LABELS.RATING(RATING_RU[k]),
        filter: HOTELFILTERTYPE.RATING,
        key: k,
      });
    }
  });

  if (filters.travelPolicyList.length > 1 && filters.selectedTravelPolicy !== TRAVELPOLICYFILTER.NOTAPPLIED) {
    const travelPolicy = travelPolicyAllList.find(item => item.Id === filters.selectedTravelPolicy);

    if (!!travelPolicy && !!travelPolicy.Name) {
      tags.push({
        name: travelPolicy.Name,
        filter: HOTELFILTERTYPE.SELECTEDTRAVELPOLICY,
        key: HOTELFILTERTYPE.SELECTEDTRAVELPOLICY,
        readOnly: unavailableTravelPolicy,
      });
    }
  }

  return tags;
};

const uncheckFilter = (obj: any, isPrimitive = false) => {
  const newObj: { [key: string]: any } = {};

  Object.keys(obj).forEach((item) => {
    const newValue = isPrimitive
      ? false
      : {
        ...obj[item],
        selected: false,
      };

    newObj[item] = newValue;
  });

  return newObj;
};

// HotelsRegion
const updateDistanceFromCenterOrPoint = (variants: Variant[], center: number[] = [], point: number[] = []): Variant[] =>
  variants.map((item) => {
    const { Static: { Latitude, Longitude, DistanceFromCenter } } = item;
    const region: number[] = [Latitude, Longitude];

    return {
      ...item,
      Static: {
        ...item.Static,
        DistanceFromCenter: center.length
          ? Math.round(getDistanceToCenter(center, region) * 100) / 100
          : DistanceFromCenter,
        DistanceFromPoint: point.length
          ? Math.round(getDistanceToCenter(point, region) * 100) / 100
          : 0,
      },
    };
  });

const createSuggests = (list: HotelAutocompleteItem[] = []): HotelAutocompleteObj[] => {
  const regions: HotelAutocompleteItem[] = [];
  const hotels: HotelAutocompleteItem[] = [];

  const result: HotelAutocompleteObj[] = [];

  if (list) {
    list.forEach((item) => {
      if (item.IsRegion) regions.push(item);
      else hotels.push(item);
    });

    if (regions.length) {
      result.push({
        title: LABELS.REGIONS,
        items: regions,
      });
    }

    if (hotels.length) {
      result.push({
        title: LABELS.HOTELS,
        items: hotels,
      });
    }
  }

  return result;
};

const changeFilter = <Type>(countValueFilter: Type[] | null, selectedFilter: Type): Type[] => {
  if (!countValueFilter) return [selectedFilter];

  const isSelectedFilter = countValueFilter.find(value => value === selectedFilter);

  if (!isSelectedFilter) return [...countValueFilter, selectedFilter];

  return countValueFilter.filter(value => value !== isSelectedFilter);
};

const getHotelStatic = (hotel: IHotel): HotelStatic => ({
  Id: hotel.Id,
  Name: hotel.Name,
  Stars: hotel.Stars,
  Phone: hotel.Phone,
  Email: hotel.Email,
  Description: hotel.Description,
  DistanceFromCenter: hotel.DistanceFromCenter,
  Address: hotel.Address,
  CheckInTime: hotel.CheckInTime,
  CheckOutTime: hotel.CheckOutTime,
  Thumbnail: hotel.Thumbnail,
  Images: hotel.Images,
  Latitude: hotel.Latitude,
  Longitude: hotel.Longitude,
  ClassificatorId: hotel.ClassificatorId,
  RegionId: hotel.RegionId,
  RegionName: hotel.RegionName,
  City: hotel.City,
  IsSmartHotel: hotel.IsSmartHotel,
  Amenities: hotel.Amenities,
  Type: hotel.Type,
  FavoriteId: hotel.FavoriteId,
  CountryCode: hotel.CountryCode,
  Reviews: hotel.Reviews,
  Deleted: hotel.Deleted,
});

const prepareRating = (value: number) => {
  if (value === 10 || value === 0) {
    return value;
  }

  return value.toFixed(1);
};

const getNewSourcesWithFavorites = (
  sources: Variant[],
  hotelId: number,
  favoriteId: string,
): Variant[] => sources.map(item => ({
  ...item,
  Static: {
    ...item.Static,
    FavoriteId: item.Static.HotelId === hotelId ? favoriteId : item.Static.FavoriteId,
  },
}));

export {
  cloneFilterState,
  mergeVariants,
  sortBy,
  sortTypesByCount,
  sortByVisibleHotelOnMap,
  applyFilter,
  updatePriceFilter,
  updateFiltersParams,
  getPagingView,
  hasChangeFilter,
  hasChangeObjFilter,
  hasChangePriceFilter,
  getDiffPercentOnStartSearch,
  createTags,
  uncheckFilter,
  updateDistanceFromCenterOrPoint,
  createSuggests,
  getHotelStatic,
  prepareRating,
  changeFilter,
  createNewSearchTags,
  getNewSourcesWithFavorites,
  sortByNewSearch,
};
