import React from 'react';

import { CONSTRUCTORS } from '../services';
import { IProviderProps, servicesProtocolsContext } from './context';

export type ServiceConstructorsList = typeof CONSTRUCTORS;
type ServiceConstructorsKeys = keyof ServiceConstructorsList;

export type UseServicesReturnType<Names extends ServiceConstructorsKeys> = {
  [i in Names as `${Uncapitalize<i & string>}Service`]: InstanceType<ServiceConstructorsList[i]>
};

export type ServiceReturnType<Names extends ServiceConstructorsKeys = ServiceConstructorsKeys> = {
  [i in Names]: InstanceType<ServiceConstructorsList[i]>
};

export const useServices = <Names extends ServiceConstructorsKeys>(
  names: Names[] = [],
) => {
  const ctx = React.useContext(servicesProtocolsContext);

  if (ctx === null) throw Error('Called outside ServicesProvider.');

  return names.reduce((r, serviceClass) => {
    const service = ctx.getService(serviceClass);

    const serviceName = `${serviceClass.charAt(0).toLowerCase() + serviceClass.slice(1)}Service`;

    return { ...r, [serviceName]: service };
  }, {}) as UseServicesReturnType<Names>;
};

/**
 *  Example context usage in a class component
 *  ```
 *  class Example extends React.Component {
 *    static contextType = servicesProtocolsContext;
 *    services: UseServicesReturnType<'Auth'>;
 *
 *    constructor(props, context) {
 *      super(props);
 *
 *      this.services = bindServices(context, ['Auth']);
 *    }
 *
 *    someFunc = () => {
 *      this.services.authService.setField('password', 'example')
 *    }
 *  }
 *  ```
 */
export const bindServices = <Names extends ServiceConstructorsKeys>(
  ctx: IProviderProps, names: Names[] = [],
) => {
  if (ctx === null) throw Error('Called outside ServicesProvider (but in general, class component API deprecated).');

  return names.reduce((r, serviceClass) => {
    const service = ctx.getService(serviceClass);

    const serviceName = `${serviceClass.charAt(0).toLowerCase() + serviceClass.slice(1)}Service`;

    return { ...r, [serviceName]: service };
  }, {}) as UseServicesReturnType<Names>;
};
