// @ts-nocheck
import { FIELD_APPROVAL_REQUEST } from '../constants/approve';
import { COMPANY_DEPARTMENT_DIVIDER } from '../constants/cart';

const checkAllSteps = (step, userId) => step &&
  step.length === 1 &&
  step[0]?.Approvers.length === 1 &&
  step[0].Approvers[0] === userId;

const checkEmployeesCompanies = Employees =>
  Employees.reduce((r, { Companies = [], CompanyId: selectedCompanyId, DepartmentId: selectedDepartmentId }) => {
    const finderDepartments = selectedCompanyId && Companies.find(({ CompanyId }) => CompanyId === selectedCompanyId);

    if (finderDepartments && finderDepartments.Departments.length) {
      const filterDepartment = !!selectedDepartmentId;

      return [...r, { value: filterDepartment }];
    }

    return [...r, { value: selectedCompanyId }];
  }, []);

const checkEmployeeRequiredProject = (employees, companiesCostCenter) => {
  const isRequiredProjectExist = employees.some(({ Companies, ProjectId }) => {
    if (ProjectId || !companiesCostCenter.length || !Companies?.length) return false;

    const companyIds = Companies?.map(({ CompanyId }) => CompanyId);

    return companyIds.some((companyId) => companiesCostCenter.includes(companyId) && !ProjectId);
  });

  return !isRequiredProjectExist;
};

const checkCustomAnalyticRequiredNotFilled = (userCustomAnalytics: object[], selectedCustomAnalytics: number[]): boolean =>
  userCustomAnalytics.some(({ Required, Values }) => {
    if (!Required) return false;

    const valuesIds = Values.map(({ Id: id }) => id);

    return !valuesIds.some(value => selectedCustomAnalytics.includes(value));
  });

const disabledApprovalButton = (
  Employees,
  Budget,
  destination,
  Dates,
  Purpose,
  Comment,
  applicationScheme,
  isApprover,
  companiesWithCostCenter,
  sortedCustomAnalytics,
  chosenAnalytics,
): { disabled: boolean, notValidFields: string[] } => {
  const notValidFields: string[] = [];

  const validationFunctions: {
    [key:
    'IsRequiredDestinationCity' |
    'IsRequiredDates' |
    'IsRequiredBudget' |
    'IsRequiredPurpose' |
    'IsRequiredComment'
    ]: () => boolean } = {
    IsRequiredDestinationCity: (): boolean => destination,
    IsRequiredDates: (): boolean => !!Dates,
    IsRequiredBudget: (): boolean => !!Budget,
    IsRequiredPurpose: (): boolean => Purpose !== null,
    IsRequiredComment: (): boolean => !!Comment && !!Comment.length,
  };

  Object.keys(applicationScheme).forEach((key) => {
    if (Object.hasOwn(validationFunctions, key) && applicationScheme[key] && typeof applicationScheme[key] === 'boolean') {
      const validationFunction = validationFunctions[key];

      if (validationFunction && typeof validationFunction === 'function' && !validationFunction()) {
        notValidFields.push(key);
      }
    }
  },
  );
  const companiesArray = isApprover || checkEmployeesCompanies(Employees);
  const checkCompanies = isApprover || companiesArray.every(({ value }) => !!value);
  const checkRequiredProject = checkEmployeeRequiredProject(Employees, companiesWithCostCenter);
  const checkRequiredCustomAnalyticNotFilled = checkCustomAnalyticRequiredNotFilled(sortedCustomAnalytics, chosenAnalytics);
  const disabled = !Employees.length || !checkCompanies || !!notValidFields.length || !checkRequiredProject || checkRequiredCustomAnalyticNotFilled;

  if (!checkRequiredProject) {
    notValidFields.push('IsRequiredProject');
  }

  if (checkRequiredCustomAnalyticNotFilled) {
    notValidFields.push('IsRequiredCustomAnalytic');
  }

  return ({ disabled, notValidFields });
};

const checkFieldsForTooltip = (
  Employees,
  Budget,
  Destination,
  date,
  Purpose,
  Comment,
  applicationScheme,
  isApprover,
  companiesWithCostCenter,
  sortedCustomAnalytics,
  chosenAnalytics,
) => {
  const nonFilledFields = [];
  const { notValidFields } = disabledApprovalButton(
    Employees,
    Budget,
    Destination,
    date,
    Purpose,
    Comment,
    applicationScheme,
    isApprover,
    companiesWithCostCenter,
    sortedCustomAnalytics,
    chosenAnalytics,
  );

  if (notValidFields.includes('IsRequiredDestinationCity')) {
    nonFilledFields.push(FIELD_APPROVAL_REQUEST.CITY);
  }

  if (notValidFields.includes('IsRequiredDates')) {
    nonFilledFields.push(FIELD_APPROVAL_REQUEST.DATES);
  }

  if (notValidFields.includes('IsRequiredBudget')) {
    nonFilledFields.push(FIELD_APPROVAL_REQUEST.BUDGET);
  }

  if (notValidFields.includes('IsRequiredPurpose')) {
    nonFilledFields.push(FIELD_APPROVAL_REQUEST.PURPOSE);
  }

  if (notValidFields.includes('IsRequiredComment')) {
    nonFilledFields.push(FIELD_APPROVAL_REQUEST.COMMENT);
  }

  if (notValidFields.includes('IsRequiredProject')) {
    nonFilledFields.push(FIELD_APPROVAL_REQUEST.PROJECT);
  }

  if (notValidFields.includes('IsRequiredCustomAnalytic')) {
    nonFilledFields.push(FIELD_APPROVAL_REQUEST.CUSTOM_ANALYTIC);
  }

  const foundEmployee = Employees.find(({ Departments, DepartmentId: selectedDepartmentId, CompanyId: selectedCompanyId }) => (
    Departments?.length ? !selectedDepartmentId : !selectedCompanyId
  ));

  if (!Employees.length) {
    nonFilledFields.push(FIELD_APPROVAL_REQUEST.EMPLOYEES);
  } else if (foundEmployee && !isApprover) {
    nonFilledFields.push(FIELD_APPROVAL_REQUEST.COMPANIES);
  }

  return nonFilledFields;
};

const checkAddCompany = (isMulti, companiesLength, index) => {
  if (!isMulti || (companiesLength === 1)) return true;

  return (!isMulti || companiesLength === 1) && Number(index) === 0;
};

const checkApprovalSchemeArray = steps => steps.every(({ Approvers, Roles }) => Approvers.length + Roles.length >= 1);

const getEmployeeCompaniesWithDepartments = employeeCompanies =>
  employeeCompanies.reduce((r, { CompanyId, Departments = [], ShortCompanyName, CompanyName }) => {
    if (Departments.length) {
      const mapperDepartment = Departments.map(({ Id: depId, Name }) =>
        ({ value: `${CompanyId}${COMPANY_DEPARTMENT_DIVIDER}${depId}`, label: `${ShortCompanyName || CompanyName} (${Name})` }),
      );

      return [...r, ...mapperDepartment];
    }

    return [...r, { value: CompanyId, label: ShortCompanyName || CompanyName }];
  }, []);

const getApproversAndRolesLength = (roles, approvers) => ((roles && roles.length) || 0) + ((approvers && approvers.length) || 0);

const getScheme = scheme => {
  const steps = scheme.Steps.map((item) => {
    const approvers = item.Approvers.map(({ UserId }) => UserId);

    return {
      ...item,
      Approvers: approvers,
    };
  });

  const preSteps = scheme.PreSteps.map((item) => {
    const approvers = item.Approvers.map(({ UserId }) => UserId);

    return {
      ...item,
      Approvers: approvers,
    };
  });

  return ({
    ...scheme,
    Steps: steps,
    PreSteps: preSteps,
  });
};

export {
  disabledApprovalButton,
  checkFieldsForTooltip,
  getEmployeeCompaniesWithDepartments,
  checkAddCompany,
  checkAllSteps,
  checkApprovalSchemeArray,
  getApproversAndRolesLength,
  getScheme,
};
