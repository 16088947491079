import React, { FC, memo } from 'react';
import { Text } from 'new-ui';

import { getText } from '../../../i18n';

import { formatDate } from '../../bi/utils/formatDate';
import { getEmployeeFullNameWithFeatureFlags } from '../../bi/utils/employees';
import { getPassportChangeAirTrip } from '../../bi/utils/changeAirTrip';

import { PATTERN } from '../../bi/constants/dateFormats';
import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';

import { TicketDataInterface, SegmentInterface } from './types';
import { IOrderDetailsData } from '../../bi/types/order';
import { ICommonEmployees } from '../../bi/types/employees';

import styles from './styles/index.module.css';

interface IChangeAirTripHeaderProps {
  details: IOrderDetailsData | null;
  ticketId: string;
}

const LABELS = {
  CHANGEABLE_TICKET: getText('components:changeAirTrip.header.changeableTicket'),
};

const {
  changeTrip:
  { air:
    {
      headerInfoFlight: {
        personData,
        ticketData: ticketDataTest,
        ticketNumber,
      },
    },
  },
} = QA_ATTRIBUTES;

const ChangeAirTripHeader: FC<IChangeAirTripHeaderProps> = memo(({ details, ticketId }) => {
  const ticket = details?.OrderItems.find(item => item.Id === +ticketId);
  const ticketData: TicketDataInterface = ticket && JSON.parse(ticket.ActualVersion.JsonData);

  const airlineNames = (segments: SegmentInterface[], firstSegment: SegmentInterface) => {
    const segmentsLength = segments.length;

    const filterAirlines = segments.filter(segment =>
      segment.MarketingAirline.Code === firstSegment.MarketingAirline.Code,
    );
    const flightNumbers = segments
      .map(({ MarketingAirline: { Code }, FlightNumber }) => `${Code} ${FlightNumber}`)
      .join(', ');

    if (segmentsLength === 1 || (segmentsLength === 2 && filterAirlines.length === 2)) {
      return `${firstSegment.MarketingAirline.Name} (${flightNumbers})`;
    }

    return flightNumbers;
  };

  const renderDataPerson = () => {
    const { Type, Number } = ticketData.Travellers.ADT[0].Document;

    const fullName = getEmployeeFullNameWithFeatureFlags(
      ticket?.ActualVersion.Employees[0] as unknown as ICommonEmployees,
    );

    const validNumber = `${Number.substring(0, 2)} ${Number.substring(2, 4)} ${Number.substring(4)}`;

    return `${fullName}, ${getPassportChangeAirTrip(Type)} ${validNumber}`;
  };

  const renderDataFlight = () => ticketData.Routes.map((route, index) => {
    const firstSegment = route.Segments.at(0);
    const lastSegment = route.Segments.at(-1);

    const departureData = formatDate(firstSegment?.DepartureDate, PATTERN.DAY_OF_MONTH_WITH_YEAR_AND_TIME);
    const arrivalData = formatDate(firstSegment?.ArrivalDate, PATTERN.DAY_OF_MONTH_WITH_YEAR_AND_TIME);

    return (
      <Text
        qaAttr={ ticketDataTest }
        className={ styles.data }
        key={ `ticket_data_${index}` }
        color='gray'
      >
        { `${firstSegment?.DepartureCity} – ${lastSegment?.ArrivalCity},`}
        {` ${departureData} – ${arrivalData}, ${airlineNames(route.Segments, route.Segments[0])}` }
      </Text>
    );
  });

  return (
    <div className={ styles.wrapper }>
      <Text
        qaAttr={ ticketNumber }
        className={ styles.number_ticket }
        type='bold_18'
        color='gray'
      >
        { `${LABELS.CHANGEABLE_TICKET} #${ticketData.Tickets[0]}` }
      </Text>
      <Text
        qaAttr={ personData }
        className={ styles.data }
        color={ 'gray' }
      >
        { renderDataPerson() }
      </Text>
      { renderDataFlight() }
    </div>
  );
});

export { ChangeAirTripHeader };
