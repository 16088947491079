export const TYPE_ACTION = {
  PLUS: 'plus',
  MINUS: 'minus',
};

export const TYPE_PASS = {
  ADULTS: 'adults',
  CHILDREN: 'children',
  INFANT: 'infant',
};
