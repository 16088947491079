// @ts-nocheck
import React from 'react';
import { Button, Text, IconButton, StyledWrapper, MultiSelect } from 'new-ui';

import { getText } from '../../../i18n';

import { MainAnalytic } from '../../bi/utils/analytics';

import ROUTES from '../../bi/constants/routes';
import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';

import styles from './styles/index.module.css';

const LABELS = {
  TITLE_APPLICTIONS_NOT_EXISTS: getText('components:travelApproval.applicationsNotExists'),
  TITLE: getText('components:travelApproval.selectUpcomingTrip'),
  CREATE: getText('components:travelApproval.createRequest'),
  CREATE_NEW: getText('components:travelApproval.newRequestLower'),
  TEXT: getText('components:travelApproval.approveForRules'),
  APPROVED: getText('components:travelApproval.approved'),
  AWAITING_APPROVAL: getText('components:travelApproval.awaitingApprove'),
  COMMENTS: getText('approve:travelItem.comments'),
  HISTORY_COMMENTS: getText('approve:travelItem.historyComments'),
  RESET_FILTERS: getText('components:travelApproval.resetFilters'),
  FILTER_BY_EMPLOYEE: getText('components:travelApproval.filters.employees'),
  FILTER_BY_STATUS: getText('components:travelApproval.filters.status'),
  SMARTWAY_APPLICATION: getText('components:travelApproval.smartwayApplication'),
  AGGREGATOR_APPLICATION: getText('components:travelApproval.requests'),
  STATUSES: {
    ARCHIVED: getText('components:travelApproval.statuses.archived'),
    WAITING_APPROVE: getText('components:travelApproval.statuses.waitingApprove'),
    DECLINED: getText('components:travelApproval.statuses.declined'),
    ACCEPTED: getText('components:travelApproval.statuses.approve'),
  },
};

const STATUSES = {
  ACCEPTED: 'Accepted',
  WAITING_APPROVE: 'WaitingApprove',
  ARCHIVED: 'Archived',
  DECLINED: 'Declined',
};

const statusFiltersList = [
  { label: LABELS.STATUSES.ACCEPTED, value: STATUSES.ACCEPTED },
  { label: LABELS.STATUSES.WAITING_APPROVE, value: STATUSES.WAITING_APPROVE },
  { label: LABELS.STATUSES.ARCHIVED, value: STATUSES.ARCHIVED },
  { label: LABELS.STATUSES.DECLINED, value: STATUSES.DECLINED },
];

interface TravelApprovalsProps {
  history: object,
  onCreate: () => void,
  onResetAllFilters(): void,
  employeeListForFilter: any,
  schemesAlreadyExist: boolean,
  onChangeStatusFilter: (checkedStatuses: object[]) => void,
  onChangeEmployeeFilter: (employeeId: object[]) => void,
  checkedStatusFilter: string[],
  checkedEmployeeFilter: number[],
  isAdmin: boolean,
  isTravelApproval: boolean,
  isAggregator: boolean,
  cKRRestrictions: boolean,
}

const TravelApprovals = ({
  history,
  onCreate,
  schemesAlreadyExist,
  onChangeStatusFilter,
  onChangeEmployeeFilter,
  checkedStatusFilter,
  checkedEmployeeFilter,
  onResetAllFilters,
  employeeListForFilter,
  isAdmin,
  isTravelApproval,
  isAggregator,
  cKRRestrictions,
}: TravelApprovalsProps) => {
  const handleGoTravelApprovalCreate = () => {
    onCreate();

    MainAnalytic.sendFirebase(MainAnalytic.ACTIONS.APPLICATION_SCHEME.CREATE_NEW_TRAVEL_APPROVAL_BUTTON);
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.APPLICATION_SCHEME.CREATE_NEW_TRAVEL_APPROVAL_BUTTON);

    history.push(`${ROUTES.NEW_REQUEST}`);
  };

  const renderTravelApprovals = () => (
    <div className={ styles.travel_approvals }>
      <IconButton
        iconType={ 'plusRound' }
        onClick={ () => handleGoTravelApprovalCreate() }
        qaAttr={ QA_ATTRIBUTES.approvalRequest.trips.createNew }
      >
        { LABELS.CREATE_NEW }
      </IconButton>
    </div>
  );

  const renderEmpty = () => (<div className={ styles.empty }>
    <Text type={ 'NORMAL_14_130' } className={ styles.text }>
      { LABELS.TEXT }
    </Text>
    <Button
      type={ 'secondary' }
      onClick={ () => handleGoTravelApprovalCreate() }
      qaAttr={ QA_ATTRIBUTES.approvalRequest.trips.create }
    >
      { LABELS.CREATE }
    </Button>
  </div>);

  const html = schemesAlreadyExist ? renderTravelApprovals() : renderEmpty();
  const blockStyles = schemesAlreadyExist ? styles.block : `${styles.block} ${styles['border-top']}`;
  const applicationTitle = isAggregator ? LABELS.AGGREGATOR_APPLICATION : LABELS.SMARTWAY_APPLICATION;

  const renderWithApplicationSchemes = () => (
    <div className={ styles.header }>
      <Text type={ 'bold_32' } qaAttr={ QA_ATTRIBUTES.approvalRequest.trips.title }>
        { applicationTitle }
      </Text>
      <MultiSelect
        placeholder={ LABELS.FILTER_BY_STATUS }
        values={ checkedStatusFilter }
        list={ statusFiltersList }
        theme={ 'large' }
        onChange={ onChangeStatusFilter }
      />
      <MultiSelect
        placeholder={ LABELS.FILTER_BY_EMPLOYEE }
        values={ checkedEmployeeFilter }
        list={ employeeListForFilter }
        theme={ 'large' }
        onChange={ onChangeEmployeeFilter }
        search
      />
      <Button
        type={ 'textual' }
        onClick={ onResetAllFilters }
      >
        { LABELS.RESET_FILTERS }
      </Button>
    </div>
  );

  const renderCreateSchemeApplicationHtml = () => {
    if (isAdmin || !isTravelApproval || cKRRestrictions) return null;

    return <StyledWrapper className={ blockStyles }>{ html }</StyledWrapper>;
  };

  const renderHeader = schemesAlreadyExist ? renderWithApplicationSchemes() : null;

  return (
    <div className={ styles.wrapper } >
      { renderHeader }
      { renderCreateSchemeApplicationHtml() }
    </div>
  );
};

export { TravelApprovals };
