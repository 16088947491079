// @ts-nocheck
import React from 'react';
import { Dialog, Text, Button, PROPS } from 'new-ui';
import { getText } from '../../../i18n';

import styles from './styles.module.css';

import appStoreSvg from './images/appstore_button.svg';
import googlePlaySvg from './images/google_play_button.svg';
import storePopupSvg from './images/store_popup-cover.png';
import logoWhiteSvg from './images/logo_white.svg';

const PLATFORMS = {
  IOS: 'iOS',
  ANDROID: 'Android',
  WEB: 'Web',
};

const DISMISSED_MODAL_STORAGE_KEY = 'smartway_mobile_app_dismissed';
const TWELVE_HRS_IN_MS = 1000 * 60 * 60 * 12;

const CURRENT_PLATFORM = window.navigator.userAgent;

const getPlatform = () => {
  if (/android/i.test(CURRENT_PLATFORM)) {
    return PLATFORMS.ANDROID;
  }

  if (/iPhone/.test(CURRENT_PLATFORM) && !window.MSStream) {
    return PLATFORMS.IOS;
  }

  return PLATFORMS.WEB;
};

const LABELS = {
  TITLE: getText('components:mobileAppPopUp.title'),
  BUTTON: getText('components:mobileAppPopUp.button'),
};

class MobileAppPopUp extends React.Component {
  constructor(props) {
    super(props);

    const storedValue = window.localStorage.getItem(DISMISSED_MODAL_STORAGE_KEY);

    this.state = {
      opened: !storedValue || (storedValue && Date.now() - JSON.parse(storedValue) >= TWELVE_HRS_IN_MS),
    };
  }

  handleDismiss = () => {
    window.localStorage.setItem(DISMISSED_MODAL_STORAGE_KEY, JSON.stringify(Date.now()));

    this.setState({ opened: false });
  };

  getLink = platform => ({
    [PLATFORMS.IOS]: <a
      href='https://itunes.apple.com/app/id1446267313'
      target='_blank'
      rel='noopener noreferrer'
      onClick={ () => {
        this.handleDismiss();

        return true;
      } }
    ><img src={ appStoreSvg } alt='ios_btn' /></a>,
    [PLATFORMS.ANDROID]: <a
      href='https://play.google.com/store/apps/details?id=com.smartway'
      target='_blank'
      rel='noopener noreferrer'
      onClick={ () => {
        this.handleDismiss();

        return true;
      } }
    ><img src={ googlePlaySvg } alt='android_btn' /></a>,
  }[platform]);

  render() {
    const { opened } = this.state;

    const platform = getPlatform();

    if (platform === PLATFORMS.WEB) {
      return null;
    }

    const linkContent = this.getLink(platform);

    return (
      <Dialog
        show={ opened }
        className={ styles.dialog }
        onChange={ this.handleDismiss }
      >
        <div className={ styles.wrapper }>
          <img src={ storePopupSvg } alt='popup-cover' className={ styles.image } />
          <img src={ logoWhiteSvg } alt='popup-logo' className={ styles.logo } />

          <div className={ styles.content }>
            <Text
              className={ styles.title }
              type={ PROPS.TEXT.TYPES.BOLD_32 }
            >
              {LABELS.TITLE}
            </Text>
            <div className={ styles.actions }>
              { linkContent }
              <Button
                type={ PROPS.BUTTON.TYPES.TEXTUAL }
                className={ styles.dismiss }
                onClick={ this.handleDismiss }
              >
                {LABELS.BUTTON}
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}

export { MobileAppPopUp };
