import { Moment } from 'moment';

import { getText, getTextArray } from '../../../i18n';

import {
  formatDate,
  formatRangeDateWithSimplicity,
} from './formatDate';
import MoneyFormat from './money';
import toDecline from './toDecline';

import { PATTERN, DATEFORMATS } from '../constants/dateFormats';
import { SELECTTRAVELLERS } from '../constants/hotelsSearch';

import { SearchSettings, NoAvailabilityPeriods } from '../services/hotels/types';
import { DetailAddCartHotel } from '../types/hotel';

const RZ_PV_FIELDS = ['customCheckin', 'customCheckout'];

const LABELS = {
  customCheckin: getText('utils:hotel.earlyIn'),
  customCheckout: getText('utils:hotel.lateOut'),
  AND: getText('utils:hotel.and'),
  INCLUDES: (rzpv: string) => getText('utils:hotel.includes', { rzpv }),
  ROOM_DECLINES: getTextArray('utils:hotel.roomDeclines'),
  NIGHT_DECLINES: getTextArray('utils:hotel.nightDeclines'),
  NO_AVAILABLE_PERIODS: (dates: string) => getText('hotels:hotelResult.item.rate.noAvailablePeriods', { dates }),
  FROM: getText('hotels:hotelResult.item.rate.from'),
};

const getRzPvModifiers = (searchSettings: any) =>
  // @ts-ignore
  RZ_PV_FIELDS.reduce((r, f) => {
    if (searchSettings[f]) {
      return [...r, LABELS[f as keyof typeof LABELS]];
    }

    return r;
    // @ts-ignore
  }, []).join(LABELS.AND);

const getPattern = (date: Moment) =>
  (date ? PATTERN.DAY_OF_MONTH_TIME : PATTERN.DAY_OF_MONTH);

const formatHotelRangeDate = (first: Moment, second: Moment, checkin: Moment, checkout: Moment) => {
  if (!first && !second) {
    return formatRangeDateWithSimplicity(checkin, checkout);
  }

  const formatCheckin = getPattern(first);
  const formatCheckout = getPattern(second);

  return `${formatDate(checkin, formatCheckin)} - ${formatDate(
    checkout,
    formatCheckout,
  )}`;
};

interface PrepareHotelPriceReturn {
  countString: string,
  daysString: string
  nightRate: string
  rzpvString: string
  price: string,
  fee: number,
}

const prepareHotelPrice = (
  count: number,
  totalPrice: number,
  agentFee: number,
  daysCount: number,
  NightRate: number,
  searchSettings: SearchSettings,
  customCheckin: Moment | null,
  customCheckout: Moment | null,
): PrepareHotelPriceReturn => {
  const selectCount = count || 1;
  const price = `${selectCount * totalPrice}`;
  const fee = agentFee;
  const countString =
    count > 1
      ? `${count} ${toDecline(count, LABELS.ROOM_DECLINES)} x `
      : '';
  const daysString =
    daysCount > 1
      ? `${daysCount} ${toDecline(daysCount, LABELS.NIGHT_DECLINES)} x `
      : '';
  const nightRate =
    countString || daysString
      ? MoneyFormat.money(NightRate, true)
      : '';
  const includesRzpv = LABELS.INCLUDES(getRzPvModifiers(searchSettings));
  const rzpvHtml =
    customCheckin || customCheckout ? includesRzpv : '';
  const rzpvString =
    daysString || countString
      ? rzpvHtml
      : `${daysCount} ${toDecline(daysCount, LABELS.NIGHT_DECLINES)} ${includesRzpv}`;

  return {
    countString,
    daysString,
    nightRate,
    rzpvString,
    price,
    fee,
  };
};

const prepareRecRatePrice = (
  count: number,
  recRate: number,
) => {
  const selectCount = count || 1;

  return selectCount * recRate;
};

const prepareCustomCheckinCheckoutWithDate = (
  checkin: Moment,
  checkout: Moment,
  customCheckin: Moment,
  customCheckout: Moment,
): { CheckinTime?: string, CheckoutTime?: string } => {
  const customParams: { CheckinTime?: string, CheckoutTime?: string } = {};

  if (customCheckin) {
    customParams.CheckinTime = formatDate(checkin, DATEFORMATS.TIME);
  }

  if (customCheckout) {
    customParams.CheckoutTime = formatDate(checkout, DATEFORMATS.TIME);
  }

  return customParams;
};

const roomCountFinder = (travellersCount: number, adult: number) => {
  const finderTravellers = SELECTTRAVELLERS[travellersCount].find(
    ({ value }) => value === adult,
  );

  return finderTravellers?.roomCount || null;
};

const prepareCustomCheckinCheckoutTime = (newCheckin: Moment | null, newCheckout: Moment | null): { CheckinTime?: string, CheckoutTime?: string } => {
  const customParams: { CheckinTime?: string, CheckoutTime?: string } = {};

  if (newCheckin) {
    customParams.CheckinTime = formatDate(newCheckin, DATEFORMATS.TIME);
  }

  if (newCheckout) {
    customParams.CheckoutTime = formatDate(newCheckout, DATEFORMATS.TIME);
  }

  return customParams;
};

interface GetSearchParamsHotelParams {
  checkin: Moment | null,
  checkout: Moment | null,
  customCheckin: Moment | null,
  customCheckout: Moment | null,
  travellersCount: number,
  adult: number,
  redirectSearchId: number,
  redirectSearchType: string,
}

interface GetSearchParamsHotelReturn {
  CheckinDate: string,
  CheckoutDate: string,
  TravellersCount: number,
  GuestsCount: number,
  RedirectSearchId: number,
  RedirectSearchType: string,
  RoomCount: number | null,
  CheckinTime?: string,
  CheckoutTime?: string
}

const getSearchParamsHotel = ({
  checkin,
  checkout,
  customCheckin,
  customCheckout,
  travellersCount,
  adult,
  redirectSearchId,
  redirectSearchType,
}: GetSearchParamsHotelParams): GetSearchParamsHotelReturn => ({
  CheckinDate: formatDate(checkin, PATTERN.YEAR_MONTH_DAY_TIME_WITHOUT_SECONDS),
  CheckoutDate: formatDate(checkout, PATTERN.YEAR_MONTH_DAY_TIME_WITHOUT_SECONDS),
  TravellersCount: travellersCount,
  GuestsCount: adult,
  RedirectSearchId: redirectSearchId,
  RedirectSearchType: redirectSearchType,
  RoomCount: roomCountFinder(travellersCount, adult),
  ...prepareCustomCheckinCheckoutTime(customCheckin, customCheckout),
});

interface GetSearchParamsCityParams {
  checkin: Moment | null,
  checkout: Moment | null,
  RegionId: string,
  RegionName: string,
  adult: number,
  travellersCount: number,
  customParams: any,
}

interface GetSearchParamsCityReturn {
  date_from: string,
  date_to: string,
  name: string,
  region_id: number,
  region_name: string,
  placing_type: number,
  t_count: number,
}

const getSearchParamsCity = ({
  checkin,
  checkout,
  RegionId,
  RegionName,
  adult,
  travellersCount,
  customParams,
}: GetSearchParamsCityParams): GetSearchParamsCityReturn => ({
  date_from: formatDate(checkin, PATTERN.YEARMONTHDAY),
  date_to: formatDate(checkout, PATTERN.YEARMONTHDAY),
  name: '',
  region_id: RegionId,
  region_name: RegionName,
  placing_type: adult,
  t_count: travellersCount,
  ...customParams,
});

const getPrepareCheckinCheckoutDate = (
  label: (time: string) => string,
  date: Moment,
  customDate: Moment | null,
): string => {
  const newDate = customDate || date;
  let prepareDate = formatDate(newDate, DATEFORMATS.DATE);

  if (customDate) {
    const time = formatDate(newDate, DATEFORMATS.TIME);
    prepareDate += ` (${label(time)})`;
  }

  return prepareDate;
};

const getParamDetailAddCart = (isRecommended: boolean, isOptimalRate: boolean) => {
  if (isRecommended) return DetailAddCartHotel.optional_choice;

  if (isOptimalRate && !isRecommended) return DetailAddCartHotel.main_optional;

  return DetailAddCartHotel.main;
};

const preparedDatesAvailableContract = (noAvailabilityPeriods: NoAvailabilityPeriods[]) => {
  if (!noAvailabilityPeriods || !noAvailabilityPeriods.length) return '';

  const datesNoAvailableText = noAvailabilityPeriods.reduce((pre, { StartDate, EndDate }) => {
    const periodDatesNoAvailable =
      formatRangeDateWithSimplicity(StartDate, EndDate, { fullPattern: PATTERN.DAY_OF_MONTH });

    if (!pre) return `${LABELS.FROM} ${periodDatesNoAvailable}`;

    return `${pre}, ${LABELS.FROM} ${periodDatesNoAvailable}`;
  }, '');

  return LABELS.NO_AVAILABLE_PERIODS(datesNoAvailableText);
};

export {
  formatHotelRangeDate,
  formatHotelRangeDate as default,
  prepareCustomCheckinCheckoutWithDate,
  prepareCustomCheckinCheckoutTime,
  prepareHotelPrice,
  getRzPvModifiers,
  roomCountFinder,
  getSearchParamsHotel,
  getSearchParamsCity,
  getPrepareCheckinCheckoutDate,
  prepareRecRatePrice,
  getParamDetailAddCart,
  preparedDatesAvailableContract,
};
