import React from 'react';
import { Button, Text } from 'new-ui';
import { getText } from '../../../i18n';

import { SPACE_OR_HYPHEN } from '../../bi/constants/regExp';
import ROUTES from '../../bi/constants/routes';

import { isWithoutMentionSmartway } from '../../bi/utils/companies';
import { isSmartAgent } from '../../bi/utils/env';

import styles from './styles/index.module.css';

const LABELS = {
  travelHelper: getText('components:footer.travelHelper'),
  help: getText('components:footer.help'),
  telegram: getText('components:footer.telegram'),
};

interface FooterProps {
  copyrightName: string,
  email: string,
  phone: string,
  wiki: string,
  companyName: string,
  handleOpenTravelChat(): void,
}

const Footer = React.memo(({
  copyrightName,
  email,
  phone,
  wiki,
  companyName,
  handleOpenTravelChat,
}: FooterProps) => {
  const phoneReplace = phone.replace(SPACE_OR_HYPHEN, '');
  const renderHelp = () => !isSmartAgent && !isWithoutMentionSmartway(companyName) && (
    <Text type='NORMAL_14'>
      <a href={ wiki } target='_blank' rel='noreferrer'> { LABELS.help } </a>
    </Text>
  );
  const renderTelegramLink = () => !isSmartAgent && !isWithoutMentionSmartway(companyName) && (
    <Text className={ styles.helper } type='NORMAL_14'>
      <a href={ ROUTES.SMARTWAY_TELEGRAM } target='_blank' rel='noreferrer'> { LABELS.telegram } </a>
    </Text>
  );

  return (
    <div className={ styles.wrapper }>
      <div className={ styles.left }>
        <div className={ styles.item }>
          <Text type='NORMAL_14'>
            &copy; { copyrightName }
          </Text>
        </div>
        <div className={ styles.item }>
          <Text type='NORMAL_14'>
            <a href={ `tel:${phoneReplace}` }>{ phone }</a>
          </Text>
        </div>
        <div className={ styles.item }>
          <Text type='NORMAL_14'>
            <a href={ `mailto:${email}` }>{ email }</a>
          </Text>
        </div>
        <div className={ styles.item }>
          { renderHelp() }
        </div>
      </div>
      <div className={ styles.row }>
        <Button
          type='textual'
          className={ styles.location }
          onClick={ handleOpenTravelChat }
        >
          <Text type='NORMAL_14' className={ styles.helper }>
            { LABELS.travelHelper }
          </Text>
        </Button>
        <div className={ styles.item }>
          <div className={ styles.telegram }>
            { renderTelegramLink() }
          </div>
        </div>
      </div>
    </div>
  );
});

export default Footer;
