// @ts-nocheck
import { getText } from '../../../../../i18n';

const SAPSANTYPES = {
  '1В': getText('services:trains.corporateTrains.sapsan.class.firstClass'),
  '1Р': getText('services:trains.corporateTrains.sapsan.class.conversation'),
  '1С': getText('services:trains.corporateTrains.sapsan.class.business'),
  '1Ж': getText('services:trains.corporateTrains.sapsan.class.coupeSuite'),
  '2В': getText('services:trains.corporateTrains.sapsan.class.economyPlus'),
  '2Ю': getText('services:trains.corporateTrains.sapsan.class.family'),
  '2Е': getText('services:trains.corporateTrains.sapsan.class.bistro'),
  '2С': getText('services:trains.corporateTrains.sapsan.class.economy'),
  '2Р': getText('services:trains.corporateTrains.sapsan.class.base'),
};

const typeA = [
  '751А',
  '752А',
  '753А',
  '754А',
  '755А',
  '756А',
  '758А',
  '757А',
  '759А',
  '759Р',
  '760А',
  '760Р',
  '761А',
  '762А',
  '763А',
  '764А',
  '765А',
  '766А',
  '767А',
  '768А',
  '769А',
  '770А',
  '771А',
  '772А',
  '773А',
  '774А',
  '775А',
  '776А',
  '777А',
  '778А',
  '779А',
  '780А',
  '781А',
  '782А',
  '783А',
  '784А',
  '785А',
  '786А',
];

const typeB = [
  '771Н',
  '757Н',
  '758Н',
];

const sapsanTrains = [
  ...typeA,
  ...typeB,
];

const SAPSAN_TYPE = {
  BUSINESS: getText('services:trains.corporateTrains.sapsan.class.business'),
  ECONOM: getText('services:trains.corporateTrains.sapsan.class.economy'),
};

const TYPE = '1'; // TODO: убрать после изменения схемы для сапсана нижний новгород 1ж

const isSapsan = ({ Number: trainNumber }) => sapsanTrains.includes(trainNumber);

const getSapsanType = type => {
  const finderNumber = type.includes(TYPE);
  const updateType = finderNumber ? SAPSAN_TYPE.BUSINESS : SAPSAN_TYPE.ECONOM;

  return SAPSANTYPES[type] || updateType;
};

export { getSapsanType, isSapsan, sapsanTrains };
