import React, { useState, useEffect, useRef, ReactNode } from 'react';
import { Icon, IconButton, IconType } from 'new-ui';

import { InputPhone } from '../InputPhone';

import { isSmartAgent } from '../../bi/utils/env';

import styles from './styles/index.module.css';

const MODES = {
  PREVIEW: 'preview',
  EDIT: 'edit',
};

const ENTER_KEY = 'Enter';
const ESC_KEY = 'Escape';

export enum EnumEditableTextType {
  PHONE = 'phone',
}

export interface IEditableText {
  renderPreview(): ReactNode,
  onChange(value: string): void,
  value?: string,
  placeholder?: string,
  iconType?: IconType,
  editClassName?: string,
  editWrapperClassName?: string,
  toggleModeOnChange?: boolean,
  onInputChange?(value: string): void,
  onBlur?(value: string | boolean): void,
  onFocus?: () => void,
  qaAttrInput?: string,
  qaAttrSave?: string,
  qaAttrClose?: string,
  qaAttrWrapper?: string,
  editType?: EnumEditableTextType | null,
  errorMsg?: string,
  disable?: boolean,
}

const EditableText = ({
  value = '',
  placeholder = '',
  toggleModeOnChange = true,
  onInputChange = () => {},
  qaAttrInput = '',
  qaAttrSave = '',
  qaAttrClose = '',
  qaAttrWrapper = '',
  iconType,
  renderPreview,
  editClassName,
  onChange,
  editType = null,
  onBlur = () => {},
  onFocus = () => {},
  editWrapperClassName = '',
  errorMsg = '',
  disable = false,
}: IEditableText) => {
  const inputRef = useRef(null);

  const [mode, setMode] = useState(MODES.PREVIEW);
  const [localValue, setLocalValue] = useState(value);

  const onSave = () => {
    if (errorMsg) return;

    if (localValue !== value) {
      onChange(localValue);
    }

    if (!toggleModeOnChange) {
      setLocalValue('');
      onInputChange('');
      // @ts-ignore
      inputRef?.current?.focus();

      return;
    }

    setMode(MODES.PREVIEW);
  };

  const onClose = () => {
    setMode(MODES.PREVIEW);
    setLocalValue('');
    onInputChange('');
  };

  const handleChangeEditMode = () => {
    if (disable) return;

    setMode(MODES.EDIT);
  };

  useEffect(() => {
    if (mode === MODES.EDIT) {
      setLocalValue(value);
      // @ts-ignore
      inputRef?.current?.focus();
    }
  }, [mode, value]);

  const iconColor = isSmartAgent ? 'orange' : 'red';
  const editClassNames = [styles['input-wrapper']];
  const editWrapperClassNames = [styles.edit];

  if (editWrapperClassName) {
    editWrapperClassNames.push(editWrapperClassName);
  }

  if (editClassName) {
    editClassNames.push(editClassName);
  }

  const handleInputChange = (inputValue: string) => {
    setLocalValue(inputValue);
    onInputChange(inputValue);
  };

  const handleBlurEditInput = (event: React.FocusEvent<HTMLInputElement>) =>
    onBlur(event.target.value);

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    switch (event.key) {
      case ENTER_KEY:
        return onSave();
      case ESC_KEY:
        return onClose();
      default:
        return null;
    }
  };

  const renderEditIcon = () => {
    if ((editType === EnumEditableTextType.PHONE && !toggleModeOnChange) || !iconType) return null;

    return (
      <Icon className={ styles.icon } type={ iconType } />
    );
  };

  const defaultEditInput = (
    <input
      ref={ inputRef }
      onFocus={ (e) => e.target.select() }
      onBlur={ handleBlurEditInput }
      placeholder={ placeholder }
      value={ localValue }
      onKeyDown={ handleInputKeyDown }
      onChange={ ({ target }) => handleInputChange(target.value) }
      data-qa={ qaAttrInput }
    />
  );

  const editElement = editType === EnumEditableTextType.PHONE
    ? <InputPhone
      debounceMs={ 0 }
      value={ localValue }
      onChange={ handleInputChange }
      onBlur={ onBlur }
      onFocus={ onFocus }
      qaAttr={ qaAttrInput }
      error={ errorMsg }
    />
    : defaultEditInput;

  const content = mode === MODES.PREVIEW ?
    (
      <div
        className={ styles.preview }
        onClick={ handleChangeEditMode }
      >
        { renderPreview() }
      </div>
    ) : (
      <div className={ editWrapperClassNames.join(' ') }>
        <div className={ editClassNames.join(' ') }>
          { renderEditIcon() }
          { editElement }
        </div>
        <div className={ styles.actions }>
          <IconButton
            iconType='checkMark'
            iconColor={ iconColor }
            onClick={ onSave }
            className={ styles.icon }
            qaAttr={ qaAttrSave }
          />
          <IconButton
            iconType='closeButton'
            iconColor='blue'
            onClick={ onClose }
            className={ styles.icon }
            qaAttr={ qaAttrClose }
          />
        </div>
      </div>
    );

  return (
    <div data-qa={ qaAttrWrapper } className={ styles.wrapper }>
      { content }
    </div>
  );
};

export { EditableText };
