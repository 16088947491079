// @ts-nocheck
import PropTypes from 'prop-types';
import { IconButton, PROPS } from 'new-ui';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import * as ReactDOM from 'react-dom';

import { MAIN_MENU_WRAPPER } from '../../bi/constants/elementsIds';

import styles from './styles/index.module.css';

const StickyHidingPanel = ({
  children,
  fixedClass,
  className,
  renderOnHeader,
  initial,
  adjustment,
  hiddenAdjustment,
  style: pStyle,
  tags,
}) => {
  const [isSticky, setSticky] = useState(false);
  const [isHidden, setHidden] = useState(false);
  const ref = useRef();

  useEffect(() => setHidden(false), [tags]);

  const getRect = useCallback(() => (ref.current ? ref.current.getBoundingClientRect() : {}), [ref]);

  const handleScroll = useCallback((event) => {
    const scrollY = event.target.scrollingElement.scrollTop + adjustment;
    const pageHeight = document.documentElement.scrollHeight - hiddenAdjustment;

    if (scrollY > pageHeight) {
      return null;
    }

    if (getRect().top < scrollY) {
      setSticky(true);
      setHidden(true);
    }

    if (adjustment >= scrollY) {
      setSticky(false);
      setHidden(false);
    }

    return null;
  }, [adjustment, hiddenAdjustment, getRect]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const classNames = [];

  const style = {
    left: getRect().left,
    width: getRect().width,
    ...pStyle,
  };

  const handleHidden = () => {
    setHidden(!isHidden);
  };

  if (isSticky) {
    classNames.push(styles.fixed, fixedClass);
  }

  if (initial) {
    style.top = initial;
  }

  if (isSticky && renderOnHeader) {
    style.zIndex = 1;
  }

  if (isSticky) {
    style.top = adjustment;
  }

  if (!isSticky && className) {
    classNames.push(className);
  }

  if (isSticky) {
    const showBody = !isHidden && children;

    const stickyContent = (
      <div>
        <div style={ { height: `${getRect().height}px` } } />
        <div
          ref={ ref }
          className={ classNames.join(' ') }
          style={ style }
        >
          { showBody }
          <div
            className={ styles.sticky_footer }
            onClick={ () => handleHidden() }
          >
            <IconButton
              iconType={ PROPS.ICON.TYPES.ARROWS.DOWN }
              className={ !isHidden ? styles.arrow_up : styles.arrow_down }
            />
          </div>
        </div>
      </div>
    );

    if (renderOnHeader) {
      return ReactDOM.createPortal(stickyContent, document.getElementById(MAIN_MENU_WRAPPER));
    }

    return stickyContent;
  }

  return (
    <div
      className={ classNames.join(' ') }
      style={ style }
      ref={ ref }
    >
      { children }
    </div>
  );
};

StickyHidingPanel.propTypes = {
  children: PropTypes.node.isRequired,
  adjustment: PropTypes.number,
  hiddenAdjustment: PropTypes.number,
  fixedClass: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  renderOnHeader: PropTypes.bool,
  initial: PropTypes.number,
  tags: PropTypes.array,
};

export { StickyHidingPanel };
