import { observable, action, computed, makeObservable } from 'mobx';

import type { SegmentType, SeatType } from '../../../components/AirlineSeatMap/types/types';

export class AirlineSeatsStore {
  constructor() {
    makeObservable(this);
  }

  @observable currentSegmentIndex?: number | null = null;
  @observable segments: SegmentType[] = [];
  @observable loading = false;
  @observable loadingForCart = false;
  @observable selectedPlaces = new Map<number, Array<{ seat: SeatType, travellerId: number }>>();
  @observable segmentsByItemId = new Map<number, SegmentType[]>();
  @observable airlineAdditionalServices: IAirAdditionalService | null = null;
  @observable arrayInfo: null | string = null;

  @computed
  get currentSegment(): SegmentType {
    // @ts-ignore
    return this.segments.find(({ SegmentId }) => this.currentSegmentIndex === SegmentId);
  }

  @action
  setArrayInfo = (arrayInfo: string) => {
    this.arrayInfo = arrayInfo;
  };

  @action
  clearArrayInfo = () => {
    this.arrayInfo = null;
  };

  @action
  updateAirlineAdditionalServices = (additionalData: IAirAdditionalService) => {
    this.airlineAdditionalServices = additionalData;
  };

  @action
  setSegmentsForItem = (itemId: number, segments: SegmentType[]) => {
    this.segmentsByItemId.set(itemId, segments);
  };

  @action
  setSelectedPlace = (segmentId: number, seat: SeatType, travellerId: number | null): void => {
    const selectedPlacesForCurrentSegment = this.selectedPlaces.get(segmentId) || [];

    if (!selectedPlacesForCurrentSegment.length && travellerId) {
      this.selectedPlaces.set(segmentId, [{
        seat,
        travellerId,
      }]);

      return;
    }

    const index = selectedPlacesForCurrentSegment.findIndex(({ seat: { Row, Column } }) => Row === seat.Row && Column === seat.Column);

    if (index >= 0) {
      const newSelectedPlaces = [
        ...selectedPlacesForCurrentSegment.slice(0, index),
        ...selectedPlacesForCurrentSegment.slice(index + 1),
      ];

      this.selectedPlaces.set(segmentId, newSelectedPlaces);

      return;
    }

    if (!travellerId) {
      return;
    }

    const modifiedSelectedPlaces = selectedPlacesForCurrentSegment.filter(place => place.travellerId !== travellerId);

    const newSelectedPlaces = [
      ...modifiedSelectedPlaces,
      { seat, travellerId },
    ];

    this.selectedPlaces.set(segmentId, newSelectedPlaces);
  };

  @action
  clearPlace = (seats: { seat: SeatType, travellerId: number }[]) => {
    if (this.currentSegmentIndex != null) {
      this.selectedPlaces.set(this.currentSegmentIndex, seats);
    }
  };

  @action
  setCurrentSegmentIndex = (index: number | null = null): void => {
    if (!this.segments.length) {
      return;
    }

    if (!index) {
      this.currentSegmentIndex = this.segments[0].SegmentId;

      return;
    }

    this.currentSegmentIndex = index;
  };

  @action
  setSegmentsMaps = (segments: SegmentType[]): void => {
    this.segments = segments;
  };

  @action
  setLoading = (value = false): void => {
    this.loading = value;
  };

  @action
  setLoadingForCart = (value = false): void => {
    this.loadingForCart = value;
  };

  @action
  reset = (): void => {
    this.currentSegmentIndex = null;
    this.loading = false;
    this.loadingForCart = false;
    this.segments = [];
    this.selectedPlaces.clear();
    this.segmentsByItemId.clear();
  };

  @action
  clearPlaces = () => {
    this.selectedPlaces.clear();
  };
}

interface IAirAdditionalService { [key: string]: string[]; }

export interface IAirlineSeatsStore {
  currentSegmentIndex?: number | null;
  currentSegment: SegmentType;
  segments: SegmentType[];
  loading: boolean;
  loadingForCart: boolean;
  selectedPlaces: Map<number, Array<{ seat: SeatType, travellerId: number }>>;
  segmentsByItemId: Map<number, SegmentType[]>;
  setSegmentsForItem(itemId: number, segments: SegmentType[]): void;
  setSegmentsMaps(segments: SegmentType[]): void;
  setSelectedPlace(segmentId: number, seat: SeatType, travellerId: number | null): void;
  setCurrentSegmentIndex(index: number | null): void;
  setLoading(value: boolean): void;
  setLoadingForCart(value: boolean): void;
  clearPlaces(): void;
  clearPlace(seats: { seat: SeatType, travellerId: number }[]): void;
  reset(): void;
  updateAirlineAdditionalServices(additionalData: IAirAdditionalService): void;
  setArrayInfo(arrayInfo: string): void;
  clearArrayInfo(): void;
  arrayInfo: string | null;
}

export const airlineSeatsStore = new AirlineSeatsStore();
