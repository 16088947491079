// @ts-nocheck
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Checkbox, Dialog, DotLoading, Icon, PROPS, Text, Textarea } from 'new-ui';
import { getText } from '../../../../../i18n';

import parseJsonString from '../../../../bi/utils/parseJsonString';
import MoneyFormat from '../../../../bi/utils/money';
import { textualMonthWithHoursAndMinutesPattern } from '../../../../bi/utils/formatDate';
import { isSmartAgent } from '../../../../bi/utils/env';

import { SERVICETYPE } from '../../../../bi/constants/serviceType';
import { TRAIN_TARIFFS } from '../../../../bi/constants/train';
import { ERRORS_TYPES } from '../../../../bi/constants/trips';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import { ButtonTrip } from '../ButtonTrip';

import styles from './styles/index.module.css';

const {
  TEXT: {
    TYPES: {
      NORMAL_14_130,
      NORMAL_16_140,
    },
    COLORS: {
      RED,
      GRAY,
    },
  },
  BUTTON: {
    TYPES: {
      TEXTUAL_MEDIUM,
    },
  },
  ICON: {
    TYPES: {
      WARNING,
    },
  },
} = PROPS;

const LABELS = {
  CONFIRM_CANCELLATION: {
    APPROVE: getText('components:tripDetailsDialog.automaticCancelTrip.confirmCancellation.approve'),
    AGREE: getText('components:tripDetailsDialog.automaticCancelTrip.confirmCancellation.agree'),
  },
  CLOSE_DIALOG: getText('components:tripDetailsDialog.automaticCancelTrip.close'),
  CANCEL_BOTH_TICKETS: getText('components:tripDetailsDialog.automaticCancelTrip.cancelBothTickets'),
  CANCEL_COUPE: getText('components:tripDetailsDialog.automaticCancelTrip.cancelCoupe'),
  CANCEL_KUPEK: getText('components:tripDetailsDialog.automaticCancelTrip.cancelKupek'),
  CANCEL: getText('components:tripDetailsDialog.automaticCancelTrip.cancel'),
  FINAL_STEP: value => getText('components:tripDetailsDialog.automaticCancelTrip.finalStep', { value }),
  AGREEMENT: getText('components:tripDetailsDialog.automaticCancelTrip.agreement'),
  WARNING: value => getText('components:tripDetailsDialog.automaticCancelTrip.warning', { value }),
  MULTI_WARNING: getText('components:tripDetailsDialog.automaticCancelTrip.multiWarning'),
  FULL_COST: {
    FIRST: getText('components:tripDetailsDialog.automaticCancelTrip.fullCost.first'),
    SECOND: getText('components:tripDetailsDialog.automaticCancelTrip.fullCost.second'),
  },
  FREE_CANCELLED: {
    FIRST: getText('components:tripDetailsDialog.automaticCancelTrip.freeCancelled.first'),
    SECOND: getText('components:tripDetailsDialog.automaticCancelTrip.freeCancelled.cancelled'),
  },
  TICKETS_WILL_BE_CANCELLED: getText('components:tripDetailsDialog.automaticCancelTrip.ticketsWillBeCancelled'),
  TICKET_WILL_BE_CANCELLED: getText('components:tripDetailsDialog.automaticCancelTrip.ticketWillBeCancelled'),
  SERVICE_WILL_BE_CANCELLED: getText('components:tripDetailsDialog.automaticCancelTrip.serviceWillBeCancelled'),
  OF_TICKETS: getText('components:tripDetailsDialog.automaticCancelTrip.ofTickets'),
  OF_TICKET: getText('components:tripDetailsDialog.automaticCancelTrip.ofTicket'),
  THIS_TICKETS: getText('components:tripDetailsDialog.automaticCancelTrip.thisTicket'),
  THIS_TICKET: getText('components:tripDetailsDialog.automaticCancelTrip.thisTickets'),
  VOUCHER: getText('components:tripDetailsDialog.automaticCancelTrip.voucher'),
  WILL_BE_CANCELLED: getText('components:tripDetailsDialog.automaticCancelTrip.willBeCancelled'),
  REFUND: value => getText('components:tripDetailsDialog.automaticCancelTrip.refund', { value }),
  REFUND_SMARTAGENT: value => getText('components:tripDetailsDialog.automaticCancelTrip.refundSmartagent', { value }),
  REFUND_PENALTY: value => getText('components:tripDetailsDialog.automaticCancelTrip.refundPenalty', { value }),
  REFUND_PENALTY_SMARTAGENT: value => getText('components:tripDetailsDialog.automaticCancelTrip.refundPenaltySmartagent', { value }),
  REFUND_REMAINDER: value => getText('components:tripDetailsDialog.automaticCancelTrip.refundRemainder', { value }),
  PENALTY: getText('components:tripDetailsDialog.automaticCancelTrip.penalty'),
  FREE_PENALTY: {
    FIRST: getText('components:tripDetailsDialog.automaticCancelTrip.freePenalty.first'),
    SECOND: getText('components:tripDetailsDialog.automaticCancelTrip.freePenalty.second'),
  },
  CURRENCY: '₽',
  BOOKING_WILL_BE_CANCELLED: getText('components:tripDetailsDialog.automaticCancelTrip.bookingWillBeCancelled'),
  LOADING: getText('components:tripDetailsDialog.automaticCancelTrip.loading'),
  LOADING_CANCELLATION: getText('components:tripDetailsDialog.automaticCancelTrip.loadingCancellation'),
  SOON_ANSWER: getText('components:tripDetailsDialog.cancelDialog.soonAnswerDate'),
  SOON_ANSWER_ERROR: getText('components:tripDetailsDialog.cancelDialog.soonAnswerError'),
  HEADER: {
    HOTEL: getText('components:tripDetailsDialog.cancelDialog.header.hotel'),
  },
  CONTENT: getText('components:tripDetailsDialog.automaticCancelTrip.content'),
};

class AutomaticCancelTrip extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    orderService: PropTypes.object.isRequired,
    hasDisabled: PropTypes.bool.isRequired,
    onCloseDialog: PropTypes.func.isRequired,
    onSubmitCancellationModal: PropTypes.func.isRequired,
    onCancellationBack: PropTypes.func,
    onRequestAutomaticCancellation: PropTypes.func,
    onCancelAutomaticCancellation: PropTypes.func,
    isHotel: PropTypes.bool,
    isAir: PropTypes.bool,
    isTaxiVoucher: PropTypes.bool,
    isVipHall: PropTypes.bool,
    isTransfer: PropTypes.bool,
    isChatLoading: PropTypes.bool,
    voidTimeLimit: PropTypes.object,
    multipleTickets: PropTypes.bool,

    showErrorDialog: PropTypes.bool.isRequired,
    loadingCancellation: PropTypes.bool.isRequired,
    errorMsg: PropTypes.string.isRequired,
    onCancelTrip: PropTypes.func.isRequired,
    toggleErrorDialog: PropTypes.func.isRequired,
    hidePreCancelActions: PropTypes.bool,
    labelError: PropTypes.string,
  };

  static defaultProps = {
    onCancellationBack: () => {},
    onRequestAutomaticCancellation: () => {},
    onAutomaticCancellation: () => {},
    onCancelAutomaticCancellation: () => {},
    isHotel: false,
    isAir: false,
    isTaxiVoucher: false,
    isVipHall: false,
    isTransfer: false,
    isChatLoading: false,
    voidTimeLimit: null,
    multipleTickets: false,
    hidePreCancelActions: false,
    labelError: '',
  };

  state = {
    loading: true,
    agreement: false,
    showCancel: false,
    error: '',
    refund: null,
    penalty: null,
    errorCancel: null,
    success: true,
    version: {},
    tickets: [],
    inputValue: '',
  };

  componentDidMount = () => {
    const version = this.props.item.ActualVersion;
    const { CompanyId, TripItemId, ServiceType } = version;

    this.setState({ version });

    this.getRefund(CompanyId, TripItemId, ServiceType);
  };

  getRefund = (companyId, tripItemId, serviceType) => {
    const { orderService } = this.props;

    let method = () => {};

    switch (serviceType) {
      case SERVICETYPE.AEROEXPRESS: {
        method = (ci, ti) => orderService.getRefundAeroexpress(ti);
        break;
      }
      case SERVICETYPE.TRAIN: {
        method = orderService.getRefundTrain;
        break;
      }
      case SERVICETYPE.HOTEL: {
        method = (ci, ti) => orderService.getRefundHotel(ti);
        break;
      }
      case SERVICETYPE.AIR: {
        const { refundAir: { TotalPenaltySum, TotalRefundSum } } = orderService.get();

        return this.setState({
          loading: false,
          penalty: TotalPenaltySum,
          refund: TotalRefundSum,
        });
      }
      case SERVICETYPE.TAXI_VOUCHER: {
        method = (ci, ti) => orderService.getRefundTaxi(ti);
        break;
      }
      case SERVICETYPE.VIP_HALL: {
        const { refundVipHall: { Penalty } } = orderService.get();

        return this.setState({
          loading: false,
          penalty: Penalty,
          refund: 0,
        });
      }
      case SERVICETYPE.TRANSFER: {
        const { refundTransfer: { Penalty, Success } } = orderService.get();

        return this.setState({
          loading: false,
          penalty: Penalty,
          success: Success,
          refund: 0,
        });
      }
    }

    return method(companyId, tripItemId).then((res) => {
      if (res.Result && serviceType === SERVICETYPE.AEROEXPRESS) {
        return this.setState({
          loading: false,
          penalty: res.RefundSum,
          refund: 0,
        });
      }

      if (serviceType === SERVICETYPE.HOTEL) {
        return this.setState({
          loading: false,
          penalty: res.Penalty,
          refund: res.Refund,
          success: res.Success,
          errorCancel: res.Error,
        });
      }

      if (serviceType === SERVICETYPE.TAXI_VOUCHER) {
        return this.setState({
          loading: false,
          penalty: 0,
          refund: res.Amount,
        });
      }

      if (res.Result) {
        return this.setState({
          loading: false,
          penalty: res.PenaltySum,
          refund: res.RefundSum,
          tickets: res.Tickets || [],
        });
      }

      return this.setState({
        loading: false,
        error: res.Error,
      });
    });
  };

  getRuleText = () => {
    const { isHotel, isVipHall, isTransfer } = this.props;

    if (isHotel) {
      return LABELS.BOOKING_WILL_BE_CANCELLED;
    }

    if (isVipHall || isTransfer) {
      return LABELS.SERVICE_WILL_BE_CANCELLED;
    }

    return LABELS.TICKET_WILL_BE_CANCELLED;
  };

  handleChangeCheckbox = (value) => {
    this.setState({
      agreement: value,
    });

    if (!value) {
      this.setState({
        showCancel: false,
      });
    }
  };

  handleShowCancelTrip = () => {
    this.setState({
      showCancel: true,
    });

    this.props.onRequestAutomaticCancellation();
  };

  handleConfirmDialog = () => this.props.onSubmitCancellationModal(this.state.inputValue);

  handleChange = (value) => this.setState({ inputValue: value });

  // обертка над функцией отмены ваучера для отлавливания ошибки при отмене аэроэкспресса
  handleCancelTripDiolog = async () => {
    const { onCancelTrip } = this.props;

    const result = await onCancelTrip();

    if (result?.Error) {
      this.setState({
        loading: false,
        error: result.Error,
      });
    }
  };

  renderError = () => {
    const { showErrorDialog, toggleErrorDialog, errorMsg } = this.props;

    return showErrorDialog && (
      <Dialog
        show
        showClosing
        onChange={ () => toggleErrorDialog() }
        className={ styles.error_dialog }
      >
        <Text type={ NORMAL_16_140 }>{ errorMsg }</Text>
        <Button className={ styles.error_button } type={ TEXTUAL_MEDIUM } onClick={ toggleErrorDialog } >
          { LABELS.CLOSE_DIALOG }
        </Button>
      </Dialog>
    );
  };

  renderLoading = (label) => (
    <div className={ styles.loading_wrap }>
      <Text type={ NORMAL_16_140 }>{ label }</Text>
      <div className={ styles.loader }>
        <DotLoading />
      </div>
    </div>
  );

  renderCancel = () => {
    const {
      hasDisabled,
      onCloseDialog,
      onCancelAutomaticCancellation,
      isAir,
      loadingCancellation,
    } = this.props;
    const { agreement } = this.state;

    return (
      <>
        <Text className={ styles.warning } type={ NORMAL_16_140 } color={ RED }>
          {LABELS.FINAL_STEP(this.getRuleText())}
        </Text>
        <ButtonTrip
          loading={ loadingCancellation }
          hasDisabled={ hasDisabled }
          isDoubleCancelTrain
          isAir={ isAir }
          disabledTrain={ !agreement }
          onCloseDialog={ onCloseDialog }
          onSubmitCancellationModal={ this.handleCancelTripDiolog }
          onCancel={ onCancelAutomaticCancellation }
        />
        { this.renderError() }
      </>
    );
  };

  renderPenalty = () => {
    const {
      penalty,
      version: { ServiceType },
    } = this.state;
    const {
      isHotel,
      isAir,
      isVipHall,
      voidTimeLimit,
      multipleTickets,
      isTransfer,
    } = this.props;

    const theseTicketsString = multipleTickets
      ? LABELS.THIS_TICKETS
      : LABELS.THIS_TICKET;

    if (!penalty && isHotel) {
      return (
        <Text type={ NORMAL_16_140 }>
          { LABELS.FREE_PENALTY.FIRST }
          &nbsp;
          <span style={ { fontWeight: 'bold' } } data-qa={ QA_ATTRIBUTES.trips.trip.cancelDialog.freeCancellation }>
            { LABELS.FREE_PENALTY.SECOND }
          </span>
        </Text>
      );
    }

    if (isAir && voidTimeLimit) {
      return (
        <Text type={ NORMAL_16_140 }>
          { LABELS.FREE_CANCELLED.FIRST }
          &nbsp;
          {theseTicketsString}
          &nbsp;
          <span style={ { fontWeight: 'bold' } } data-qa={ QA_ATTRIBUTES.trips.trip.cancelDialog.freeCancellation }>
            { LABELS.FREE_CANCELLED.SECOND }
          </span>
        </Text>
      );
    }

    if (!penalty && (isVipHall || isTransfer)) {
      return (
        <Text type={ NORMAL_16_140 }>
          { LABELS.FREE_PENALTY.FIRST }
          &nbsp;
          <span style={ { fontWeight: 'bold' } }>
            { LABELS.FREE_PENALTY.SECOND }
          </span>
        </Text>
      );
    }

    if (!penalty && ServiceType !== SERVICETYPE.AEROEXPRESS) {
      return null;
    }

    return (
      <Text type={ NORMAL_16_140 }>
        { LABELS.PENALTY }
        &nbsp;
        <span style={ { fontWeight: 'bold' } }>
          <span data-qa={ QA_ATTRIBUTES.trips.trip.cancelDialog.penalty }>
            { MoneyFormat.moneyWithDecimal(penalty) }
          </span>
          { LABELS.CURRENCY }
        </span>
      </Text>
    );
  };

  renderRefund = () => {
    const { refund, penalty, version: { ServiceType } } = this.state;

    if (refund <= 0) {
      return null;
    }

    if (penalty) {
      const labelRefundPenalty = isSmartAgent ? LABELS.REFUND_PENALTY_SMARTAGENT : LABELS.REFUND_PENALTY;

      return (
        <Text className={ styles.penalty } type={ NORMAL_16_140 }>
          { labelRefundPenalty(MoneyFormat.moneyWithDecimal(refund)) }
        </Text>
      );
    }

    if (ServiceType === SERVICETYPE.TAXI_VOUCHER) {
      return (
        <Text type={ NORMAL_16_140 }>
          { LABELS.REFUND_REMAINDER(MoneyFormat.moneyWithDecimal(refund)) }
        </Text>
      );
    }

    const labelRefund = isSmartAgent ? LABELS.REFUND_SMARTAGENT : LABELS.REFUND;

    return (
      <Text type={ NORMAL_16_140 }>
        { labelRefund(MoneyFormat.moneyWithDecimal(refund)) }
      </Text>
    );
  };

  renderRoundTripWarning = () => {
    const { version: { ServiceType, JsonData }, tickets } = this.state;

    if (ServiceType !== SERVICETYPE.TRAIN || tickets.length !== 2) {
      return null;
    }

    const { Tariff } = parseJsonString(JsonData);

    if (Tariff !== TRAIN_TARIFFS.TO) {
      return null;
    }

    return (
      <Text
        className={ styles.warning }
        color={ RED }
        type={ NORMAL_16_140 }
      >
        { LABELS.CANCEL_BOTH_TICKETS }
      </Text>
    );
  };

  renderAllTicketNumbers = tickets => tickets.map((ticket, ind) => (
    <div key={ ind }>{ ticket.TicketId }</div>
  ));

  renderCoupeWarning = () => {
    const { version: { ServiceType, JsonData }, tickets } = this.state;
    const { Tariff } = parseJsonString(JsonData);

    if (ServiceType !== SERVICETYPE.TRAIN ||
        tickets.length === 1 ||
        (Tariff !== TRAIN_TARIFFS.COUPE_MEETING &&
          Tariff !== TRAIN_TARIFFS.COUPE_BUY_TWO &&
          Tariff !== TRAIN_TARIFFS.KUPEK_IN_TRIP)
    ) {
      return null;
    }

    const cancelText = Tariff === TRAIN_TARIFFS.KUPEK_IN_TRIP
      ? LABELS.CANCEL_KUPEK
      : LABELS.CANCEL_COUPE;

    return (
      <Text
        className={ styles.warning }
        color={ RED }
        type={ NORMAL_16_140 }
      >
        { cancelText }
        { this.renderAllTicketNumbers(tickets) }
      </Text>
    );
  };

  renderInfo = () => {
    const { isHotel, loadingCancellation } = this.props;

    if (isHotel && loadingCancellation) return null;

    return (
      <>
        { this.renderPenalty() }
        { this.renderRefund() }
      </>
    );
  };

  renderActions = () => {
    const {
      isAir,
      isHotel,
      isVipHall,
      isTransfer,
      loadingCancellation,
      hidePreCancelActions,
      hasDisabled,
      onCloseDialog,
      onCancellationBack,
    } = this.props;
    const { penalty, agreement, showCancel } = this.state;

    if (hidePreCancelActions) return null;

    if (isHotel && loadingCancellation) {
      return this.renderLoading(LABELS.LOADING_CANCELLATION);
    }

    const isHotelWithoutPenalty = isHotel && penalty === 0;
    const disabledButton = isHotelWithoutPenalty
      ? !agreement
      : agreement;

    const checkboxLabel = isHotelWithoutPenalty
      ? LABELS.CONFIRM_CANCELLATION.APPROVE
      : LABELS.CONFIRM_CANCELLATION.AGREE;

    const cancelHtml = (agreement && showCancel) || isHotelWithoutPenalty || isVipHall
      ? this.renderCancel()
      : (
        <ButtonTrip
          isHotel={ isHotel }
          hasDisabled={ hasDisabled }
          firstWord={ LABELS.CANCEL }
          isCancelTrain
          isTransfer={ isTransfer }
          isAir={ isAir }
          disabledTrain={ !disabledButton }
          onCloseDialog={ onCloseDialog }
          onSubmitCancellationModal={ this.handleShowCancelTrip }
          onClick={ onCancellationBack }
        />
      );

    return (
      <>
        <div className={ styles.checkbox }>
          <Checkbox
            value={ agreement }
            onChange={ this.handleChangeCheckbox }
            qaAttr={ QA_ATTRIBUTES.trips.trip.cancelDialog.checkboxAgree }
          >
            { checkboxLabel }
          </Checkbox>
        </div>
        { cancelHtml }
      </>
    );
  };

  renderPreCancel = () => {
    const { isAir, voidTimeLimit } = this.props;

    const voidWarning = (isAir && voidTimeLimit) &&
      LABELS.WARNING(voidTimeLimit.local().format(textualMonthWithHoursAndMinutesPattern));

    return (
      <>
        { this.renderInfo() }
        { this.renderRoundTripWarning() }
        { this.renderCoupeWarning() }
        <Text
          className={ styles.warning }
          color={ GRAY }
          type={ NORMAL_14_130 }
        >
          { voidWarning }
        </Text>
        { this.renderActions() }
      </>
    );
  };

  renderPreCancelActualDate = () => {
    const {
      isAir,
      hasDisabled,
      onCloseDialog,
      voidTimeLimit,
      isChatLoading,
    } = this.props;
    const { inputValue, errorCancel } = this.state;

    const voidWarning = (isAir && voidTimeLimit) &&
      LABELS.WARNING(voidTimeLimit.local().format(textualMonthWithHoursAndMinutesPattern));

    const text = errorCancel?.Type === ERRORS_TYPES.CHECK_IN_TIME_BEGAN
      ? LABELS.SOON_ANSWER
      : LABELS.SOON_ANSWER_ERROR;

    return (
      <>
        <Text type={ NORMAL_16_140 }>{ text }</Text>
        { this.renderRoundTripWarning() }
        { this.renderCoupeWarning() }
        <Text
          className={ styles.warning }
          color={ GRAY }
          type={ NORMAL_14_130 }
        >
          { voidWarning }
        </Text>
        <Textarea
          placeholder={ LABELS.HEADER.HOTEL }
          className={ styles.textarea }
          value={ inputValue }
          onChange={ this.handleChange }
        />
        <Text type={ NORMAL_14_130 } color={ GRAY } className={ styles.content }>
          { LABELS.CONTENT }
        </Text>
        <ButtonTrip
          valueIsEmpty={ !inputValue }
          loading={ isChatLoading }
          hasDisabled={ hasDisabled }
          onCloseDialog={ onCloseDialog }
          onSubmitCancellationModal={ this.handleConfirmDialog }
        />
      </>
    );
  };

  renderFreeTaxi = () => {
    const {
      hasDisabled,
      loadingCancellation,
      onCancelTrip,
      onCloseDialog,
      onCancelAutomaticCancellation,
      isTaxiVoucher,
    } = this.props;
    const { agreement, refund } = this.state;

    const agreementHtml = agreement && (
      <Text className={ styles.warning } type={ NORMAL_16_140 } color={ RED }>
        { LABELS.FINAL_STEP(LABELS.WILL_BE_CANCELLED) }
      </Text>
    );

    return (
      <>
        <Text type={ NORMAL_16_140 }>
          { LABELS.REFUND_PENALTY(MoneyFormat.moneyWithDecimal(refund)) }
        </Text>

        <div className={ styles.checkbox }>
          <Checkbox
            value={ agreement }
            onChange={ this.handleChangeCheckbox }
          >
            {LABELS.AGREEMENT} {LABELS.VOUCHER}
          </Checkbox>
        </div>
        { agreementHtml }
        <ButtonTrip
          isTaxiVoucher={ isTaxiVoucher }
          loading={ loadingCancellation }
          disabledTrain={ !agreement }
          hasDisabled={ hasDisabled }
          onCloseDialog={ onCloseDialog }
          onSubmitCancellationModal={ onCancelTrip }
          onCancel={ onCancelAutomaticCancellation }
        />

        { this.renderError() }
      </>
    );
  };

  renderFreeVoid = () => {
    const {
      hasDisabled,
      loadingCancellation,
      voidTimeLimit,
      multipleTickets,
      onCancelTrip,
      onCloseDialog,
      onCancelAutomaticCancellation,
    } = this.props;
    const { agreement, refund } = this.state;

    const theseTicketsString = multipleTickets ? LABELS.THIS_TICKETS : LABELS.THIS_TICKET;
    const tickets = multipleTickets ? LABELS.OF_TICKETS : LABELS.OF_TICKET;
    const ticketsWillBeCancelled = multipleTickets ? LABELS.TICKETS_WILL_BE_CANCELLED : LABELS.TICKET_WILL_BE_CANCELLED;

    const warning = multipleTickets && (
      <div className={ styles.multiple_tickets_warning }>
        <div className={ styles.warning_icon }>
          <Icon type={ WARNING } />
        </div>
        <Text type={ NORMAL_16_140 }>{ LABELS.MULTI_WARNING }</Text>
      </div>
    );

    const agreementHtml = agreement && (
      <Text className={ styles.warning } type={ NORMAL_16_140 } color={ RED }>
        { LABELS.FINAL_STEP(ticketsWillBeCancelled) }
      </Text>
    );

    return (
      <>
        { warning }
        <Text type={ NORMAL_16_140 }>
          { LABELS.FREE_CANCELLED.FIRST } {theseTicketsString} <span style={ { fontWeight: 'bold' } }>{ LABELS.FREE_CANCELLED.SECOND }</span>
        </Text>
        <Text type={ NORMAL_16_140 }>
          { LABELS.FULL_COST.FIRST } <span style={ { fontWeight: 'bold' } }>{ MoneyFormat.moneyWithDecimal(refund) } {LABELS.CURRENCY}</span> { LABELS.FULL_COST.SECOND }
        </Text>
        <Text
          className={ styles.warning }
          color={ GRAY }
          type={ NORMAL_14_130 }
        >
          { LABELS.WARNING(voidTimeLimit.local().format(textualMonthWithHoursAndMinutesPattern)) }
        </Text>
        <div className={ styles.checkbox }>
          <Checkbox
            value={ agreement }
            onChange={ this.handleChangeCheckbox }
          >
            {LABELS.AGREEMENT} {tickets}
          </Checkbox>
        </div>
        { agreementHtml }
        <ButtonTrip
          loading={ loadingCancellation }
          disabledTrain={ !agreement }
          hasDisabled={ hasDisabled }
          isDoubleCancelTrain
          onCloseDialog={ onCloseDialog }
          onSubmitCancellationModal={ onCancelTrip }
          onCancel={ onCancelAutomaticCancellation }
        />
        { this.renderError() }
      </>
    );
  };

  renderContent = () => {
    const { error, success } = this.state;
    const {
      hasDisabled,
      onCloseDialog,
      onSubmitCancellationModal,
      isTaxiVoucher,
      labelError,
    } = this.props;

    if (error.length) {
      return (
        <>
          <Text type={ NORMAL_16_140 }>{ labelError }</Text>
          <ButtonTrip
            hasDisabled={ hasDisabled }
            firstWord={ LABELS.CANCEL }
            onCloseDialog={ onCloseDialog }
            onSubmitCancellationModal={ onSubmitCancellationModal }
          />
        </>
      );
    }

    if (isTaxiVoucher) {
      return this.renderFreeTaxi();
    }

    if (!success) {
      return this.renderPreCancelActualDate();
    }

    return this.renderPreCancel();
  };

  render() {
    const { loading } = this.state;

    if (loading) {
      return this.renderLoading(LABELS.LOADING);
    }

    return this.renderContent();
  }
}

export { AutomaticCancelTrip };
