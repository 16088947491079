import React, { useState, useRef, useEffect } from 'react';
import { Button, Icon, IconButton, Text, Select, Suggest, DotLoading, CircleLoading } from 'new-ui';
import { getText } from '../../../i18n';

import AnalyticsSelectDialog from './components/AnalyticsSelectDialog';
import HighlitedItem from '../HighlitedItem';

import { onlyNewAnalyticsValues } from '../../bi/utils/analyticsSelect';

import { QaAttrDialog } from '../../bi/types/analyticsSelect';
import { AnalyticsType, AnalyticsValueType } from '../../bi/services/customAnalytics/types';

import styles from './styles.module.css';

const LABELS = {
  SELECT: getText('components:analyticsSelect.select'),
  NOT_SELECTED: getText('components:analyticsSelect.notSelect'),
  QUICK_SEARCH: getText('components:analyticsSelect.quickSearch'),
  ADD: getText('components:analyticsSelect.add'),
};

const NOT_SELECTED_ITEM = {
  label: getText('components:analyticsSelect.notSelect'),
  value: 0,
};

const ADD_ITEM = {
  label: getText('components:analyticsSelect.add'),
  value: -1,
};

type AnalyticsSelectProps = {
  readonly?: boolean,
  withRequiredValidation?: boolean,
  barMode?: boolean,
  analytics: AnalyticsType
  value: AnalyticsValueType,
  analyticsLoading?: boolean,
  onSet(value: any): void,
  onUnset(value: number): void,
  onAdd(id: number, value: string): void,
  selectedClassName?: string;
  highlighted?: boolean,
  onlyNewAnalytics?: boolean,
  displaySearchIcon?: boolean,
  qaAttrTitle?: string,
  qaAttrSelect?: string,
  qaAttrDelete?: string,
  qaAttrLoading?: string,
  qaAttrAdd?: string,
  qaAttrSuggestFirstEl?: string,
  qaAttrDialog?: QaAttrDialog,
};

const AnalyticsSelect = ({
  readonly = false,
  withRequiredValidation = false,
  barMode = false,
  analytics: {
    Id: analyticsId,
    Name,
    Values,
    Required,
    Editable,
  },
  value,
  analyticsLoading = false,
  onSet = () => {},
  onUnset = () => {},
  onAdd = () => {},
  selectedClassName = '',
  highlighted = false,
  onlyNewAnalytics = false,
  displaySearchIcon = false,
  qaAttrTitle = '',
  qaAttrSelect = '',
  qaAttrDelete = '',
  qaAttrLoading = '',
  qaAttrAdd = '',
  qaAttrSuggestFirstEl = '',
  qaAttrDialog = {
    title: '',
    input: '',
    buttons: {
      cancel: '',
      save: '',
    },
  },
}: AnalyticsSelectProps) => {
  const ref = useRef(null);
  const [analyticValue, setAnalyticValue] = useState('');
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingFade, setLoadingFade] = useState(false);
  const [deleteAnalyticLoading, setDeleteAnalyticLoading] = useState(false);
  const [filteredValues, setFilteredValues] = useState(barMode ? [] : Values);
  const [meaning, setMeaning] = useState('');
  const [timeoutId, setTimeoutId] = useState(0);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [noHighlightedSelected, setNoHighlightedSelected] = useState(false);

  useEffect(() => () => clearTimeout(timeoutId), [timeoutId]);

  const setLoadingWithFade = (v = false) => {
    if (v) {
      setLoading(true);
      // @ts-ignore
      setTimeoutId(setTimeout(() => setLoadingFade(true), 10));

      return;
    }

    setLoadingFade(false);
    // @ts-ignore
    setTimeoutId(setTimeout(() => setLoading(false), 200));
  };

  useEffect(() => {
    setLoadingWithFade(analyticsLoading);
  }, [analyticsLoading]);

  const addButton = (
    <div data-qa={ qaAttrAdd } className={ styles.add }>
      <Icon type='plusRound'/>
      <div className={ styles.add_label }>
        { ADD_ITEM.label }
      </div>
    </div>
  );

  const renderSelectItem = (item: { label: string, value: number }) => {
    const { value: valueItem, label } = item;

    if (valueItem === ADD_ITEM.value) {
      return addButton;
    }

    return label;
  };

  const handleSelect = (analyticsValue: { Id: number, Value: string }) => {
    const { Value, Id } = analyticsValue;

    setAnalyticValue(Value);
    setLoadingWithFade(true);

    if (!Id) {
      setDialogVisible(true);
      setLoadingWithFade(false);

      return;
    }

    onSet(analyticsValue);
    setEdit(false);
    setLoadingWithFade(false);
  };

  const handleAddAnalytics = (searchValue: string) => {
    setMeaning(searchValue);

    setLoadingWithFade(true);
    setDialogVisible(true);
    setLoadingWithFade(false);
  };

  const handleSelectById = (analyticsValueId: number) => {
    setLoadingWithFade(true);

    if (analyticsValueId === NOT_SELECTED_ITEM.value) {
      onUnset(analyticsId);
      setLoadingWithFade(false);
      setNoHighlightedSelected(true);

      return;
    }

    const analyticsValue = Values.find(({ Id }) => Id === analyticsValueId);

    onSet(analyticsValue);
    setLoadingWithFade(false);
  };

  const handleChange = (newValue: any) => {
    setMeaning(newValue);

    if (!newValue) {
      setFilteredValues(Values.filter(({ Id }) => Id !== value?.Id));

      return;
    }

    setFilteredValues(Values.filter(({ Id, Value }) =>
      Value.toLowerCase().includes(newValue.toLowerCase() || Id !== value?.Id)),
    );
  };

  const onIconClick = () => {
    if (withRequiredValidation && Required) {
      setEdit(true);

      return;
    }

    setDeleteAnalyticLoading(true);
    onUnset(analyticsId);
    setDeleteAnalyticLoading(false);
    setAnalyticValue('');
  };

  const handleOnAdd = (data: string) => {
    if (!loadingFade || loading) {
      return;
    }

    setLoadingWithFade(true);
    onAdd(analyticsId, data);
    setLoadingWithFade(false);
    setDialogVisible(false);
    setEdit(false);
  };

  const renderHighlightedItem: any = (highlightedItem: { Value: string, Id: number }) => (
    <HighlitedItem
      text={ highlightedItem.Value }
      highlitedWord={ meaning }
    />
  );

  const dialog = (qaAttrShow: boolean) => (
    <AnalyticsSelectDialog
      visible={ dialogVisible }
      onChangeVisible={ setDialogVisible }
      onAdd={ handleOnAdd }
      loading={ !loadingFade || loading }
      initialValue={ meaning }
      setInitialValue={ setMeaning }
      qaAttrTitle={ qaAttrShow ? qaAttrDialog.title : '' }
      qaAttrInput={ qaAttrShow ? qaAttrDialog.input : '' }
      qaAttrButtonSave={ qaAttrShow ? qaAttrDialog.buttons.save : '' }
      qaAttrButtonCancel={ qaAttrShow ? qaAttrDialog.buttons.cancel : '' }
    />
  );

  if (barMode) {
    const mappedValues = Values.map(analyticsValue => ({ label: analyticsValue.Value, value: analyticsValue.Id }));

    const notSelectedItem = [NOT_SELECTED_ITEM];
    const selectItems = onlyNewAnalytics && Editable ? onlyNewAnalyticsValues(Values, value) : mappedValues;
    const selectItem = withRequiredValidation && Required ? [...selectItems] : [...notSelectedItem, ...selectItems];
    const items = selectItem.filter(Boolean);

    const loadingClassNames = [styles.loading];

    if (loadingFade) {
      loadingClassNames.push(styles.loading_show);
    }

    const analyticsClassNames = [styles.analytics_select];

    if (highlighted) {
      analyticsClassNames.push(styles.analytics_select_highlight);
    }

    return (
      <div className={ analyticsClassNames.join(' ') }>
        { dialog(false) }
        { loading && <div className={ loadingClassNames.join(' ') }>
          <DotLoading />
        </div> }
        <Select
          disabled={ readonly || loading }
          search
          withLabel
          excludeValue
          highlighted
          noHighlightedSelected={ noHighlightedSelected }
          value={ value?.Id || '' }
          theme='default-large'
          items={ items }
          placeholder={ Name }
          onChange={ handleSelectById }
          renderItem={ renderSelectItem }
          alwaysIncluded={ [ADD_ITEM.value] }
          addButton={ Editable }
          addButtonLabel={ LABELS.ADD }
          onAdd={ (searchValue: string) => handleAddAnalytics(searchValue) }
        />
      </div>
    );
  }

  if (edit) {
    const values: any = onlyNewAnalytics && Editable ? onlyNewAnalyticsValues(Values, value) : filteredValues;

    return (
      <div className={ styles.analytics_select }>
        { dialog(true) }
        <Suggest
          addButton={ Editable }
          addButtonLabel={ LABELS.ADD }
          // @ts-ignore
          onAdd={ handleSelect }
          withLabel={ false }
          loading={ loading }
          autoFocus
          ref={ ref }
          placeholder={ LABELS.QUICK_SEARCH }
          onChange={ handleChange }
          theme='border'
          items={ values }
          renderItem={ renderHighlightedItem }
          // @ts-ignore
          onSelect={ handleSelect }
          value={ analyticValue }
          // useRegisterOutsideClick fix
          onClose={ () => setEdit(true) }
          shouldFocusItem={ () => false }
          qaAttrFirstEl={ qaAttrSuggestFirstEl }
          qaAttrAddButton={ qaAttrAdd }
          displaySearchIcon={ displaySearchIcon }
        />
      </div>
    );
  }

  const deleteIcon = !readonly && (
    <IconButton
      iconType='closeButton'
      onClick={ onIconClick }
      className={ styles.remove }
      qaAttr={ qaAttrDelete }
    />
  );

  const selectButtonOrNotSelectedText = readonly
    ? <Text className={ styles.analytics_value }>{ LABELS.NOT_SELECTED }</Text>
    : <Button qaAttr={ qaAttrSelect } onClick={ () => setEdit(true) } type='textual'>{ LABELS.SELECT }</Button>;

  const valueOrSelectButton = value
    ? (
      <>
        <Text className={ styles.analytics_value }>{ value.Value }</Text>
        { deleteAnalyticLoading ? <CircleLoading size={ 20 } className={ styles.loader } qaAttr={ qaAttrLoading } /> : deleteIcon }
      </>
    )
    : selectButtonOrNotSelectedText;

  const classNames = [styles.analytics];

  if (selectedClassName) {
    classNames.push(selectedClassName);
  }

  if (highlighted) {
    classNames.push(styles.analytics_select_highlight);
  }

  return (
    <div className={ classNames.join(' ') }>
      { dialog(false) }
      <Text
        className={ styles.analytics_name }
        color='gray'
        qaAttr={ qaAttrTitle }
      >
        { Name }:
      </Text>
      &nbsp;
      { valueOrSelectButton }
    </div>
  );
};

export default AnalyticsSelect;
