// @ts-nocheck
import { PROPS, Text, Price } from 'new-ui';
import PropTypes from 'prop-types';
import React from 'react';

import { getText } from '../../../../i18n';

import styles from '../styles/tooltipText.module.css';

const LABELS = {
  BASE: getText('components:airlineSearchItem.tooltipText.base'),
  TAX: getText('components:airlineSearchItem.tooltipText.tax'),
  FEE: getText('components:airlineSearchItem.tooltipText.fee'),
  COMMISSION: getText('components:airlineSearchItem.tooltipText.commission'),
  TOTAL_PRICE: getText('components:airlineSearchItem.tooltipText.totalPrice'),
  AVERAGE_COST: getText('components:airlineSearchItem.tooltipText.averageCost'),
};

const normalText = text => (
  <Text type={ PROPS.TEXT.TYPES.NORMAL_14_130 } color={ PROPS.TEXT.COLORS.WHITE }>{ text }</Text>
);

const semiBoldText = text => (
  <Price
    marginSmall
    type={ PROPS.TEXT.TYPES.SEMIBOLD_14 }
    typeCurrency={ PROPS.TEXT.TYPES.SEMIBOLD_14 }
    color={ PROPS.TEXT.COLORS.WHITE }
    value={ text }
  />
);

const TooltipText = ({ price: {
  Base,
  Tax,
  Fee,
  Commission,
  TotalPrice,
}, isDetails }) => {
  const renderTooltipTextOptions = () => (
    <div className={ styles.wrapper }>
      <div className={ styles.row }>
        {normalText(LABELS.BASE)}&ensp;
        {semiBoldText(Base)}
      </div>
      <div className={ styles.row }>
        {normalText(LABELS.TAX)}&ensp;
        {semiBoldText(Tax)}
      </div>
      <div className={ styles.row }>
        {normalText(LABELS.FEE)}&ensp;
        {semiBoldText(Fee)}
      </div>
      <div className={ styles.row }>
        {normalText(LABELS.COMMISSION)}&ensp;
        {semiBoldText(Commission)}
      </div>
      <div className={ styles.row }>
        {normalText(LABELS.TOTAL_PRICE)}&ensp;
        {semiBoldText(TotalPrice)}
      </div>
    </div>
  );

  const renderTooltipTextPrice = () => (
    <Text
      type={ PROPS.TEXT.TYPES.NORMAL_14_130 }
      color={ PROPS.TEXT.COLORS.WHITE }
      className={ styles.wrapper_options }
    >
      { LABELS.AVERAGE_COST }
    </Text>
  );

  return isDetails ? renderTooltipTextOptions() : renderTooltipTextPrice();
};

TooltipText.propTypes = {
  price: PropTypes.object.isRequired,
  isDetails: PropTypes.bool,
  travellers: PropTypes.number,
};

TooltipText.defaultProps = {
  isDetails: false,
  travellers: 0,
};

export default TooltipText;
