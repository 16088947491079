// @ts-nocheck
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Tooltip, Icon, Text, BurgerMenu, DynamicLoginBurgerMenu, PROPS } from 'new-ui';
import MediaQuery from 'react-responsive';
import PropTypes from 'prop-types';

import { getText } from '../../../../../i18n';

import ItemWrap from '../ItemWrap';

import { isSmartAgent } from '../../../../bi/utils/env';

import ROUTES from '../../../../bi/constants/routes';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import styles from './styles/index.module.css';

const {
  ICON: { TYPES: { MENU: { FAVORITE, NOTE }, WARNING }, COLORS: { DEFAULT, YELLOW } },
  TEXT: { TYPES: { NORMAL_14, NORMAL_14_130 } },
  TOOLTIP: { POSITION: { BOTTOM } },
} = PROPS;

const LABELS = {
  qualityControl: getText('components:header.headerItems.qualityControl'),
  note: getText('components:header.headerItems.note'),
  settings: getText('components:header.settings'),
  exit: getText('components:header.exit'),
  favorites: getText('components:header.headerItems.favorites'),
  travelHelper: getText('components:header.headerItems.travelHelper'),
  INTERCOM_WARNING: {
    ONE: getText('components:header.headerItems.intercomWarning.one'),
    TWO: getText('components:header.headerItems.intercomWarning.two'),
    THREE: getText('components:header.headerItems.intercomWarning.three'),
  },
  INTERCOM_WARNING_SA: {
    ONE: getText('components:header.headerItems.intercomWarning.one'),
    TWO: getText('components:header.headerItems.intercomWarning.two'),
    THREE: getText('components:header.headerItems.intercomWarning.three'),
  },
};

class MainHeaderItems extends Component {
  static propTypes = {
    noteItemsCount: PropTypes.number.isRequired,
    favoriteItemsCount: PropTypes.number.isRequired,
    isDemo: PropTypes.bool.isRequired,
    handleLogout: PropTypes.func.isRequired,
    onOpenTravelChat: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    qaAttrFavorites: PropTypes.string,
    qaAttrFavoritesIcon: PropTypes.object,
    sendIntercomAfterLoadPage: PropTypes.func.isRequired,
    accountRights: PropTypes.object.isRequired,
    getShowElementForSmartagent: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    qaAttrFavorites: '',
    qaAttrFavoritesIcon: {
      default: '',
      yellow: '',
    },
  };

  state = {
    showIntercomWarning: false,
    enterOnMenu: false,
    showMenuList: false,
  };

  intervalId;

  componentDidMount() {
    this.intervalId = setInterval(() => this.getIntercomWarning(), 2000);
    setTimeout(this.sendErrorToProductAnalytics, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  getIntercomWarning = () => {
    const intercomIframe = document.getElementById('intercom-frame');

    if (
      (intercomIframe && !window.carrotquest)
      || (!intercomIframe && window.carrotquest)
      || (intercomIframe && window.carrotquest)
    ) {
      this.setState({ showIntercomWarning: false });

      return clearInterval(this.intervalId);
    }

    return this.setState({ showIntercomWarning: true });
  };

  sendErrorToProductAnalytics = () => {
    const intercomIframe = document.getElementById('intercom-frame');

    if (!intercomIframe) {
      this.props.sendIntercomAfterLoadPage();
    }
  };

  renderFavorite = () => {
    const {
      favoriteItemsCount,
      qaAttrFavorites,
      qaAttrFavoritesIcon,
      onClick,
    } = this.props;

    if (isSmartAgent) return null;

    const favoriteIcon = favoriteItemsCount ? qaAttrFavoritesIcon.yellow : qaAttrFavoritesIcon.default;
    const iconColor = favoriteItemsCount ? YELLOW : DEFAULT;

    const label = (
      <MediaQuery minWidth={ 750 }>{LABELS.favorites}</MediaQuery>
    );

    return (
      <div className={ styles.item }>
        <ItemWrap
          subItem
          href={ ROUTES.FAVORITES }
          getIcon={ () => FAVORITE }
          getIconColor={ () => iconColor }
          label={ label }
          onClick={ () => onClick(LABELS.favorites, 0) }
          qaAttr={ qaAttrFavorites }
          qaAttrIcon={ favoriteIcon }
        />
      </div>
    );
  };

  renderNote = () => {
    const {
      noteItemsCount,
      onClick,
      getShowElementForSmartagent,
    } = this.props;

    if (isSmartAgent && getShowElementForSmartagent) {
      return this.renderTravelHelper();
    }

    const clssNames = isSmartAgent ? 'item none_margin' : 'item';

    const label = (
      <MediaQuery minWidth={ 750 }>{LABELS.note}</MediaQuery>
    );

    return (
      <div className={ styles[`${clssNames}`] }>
        <ItemWrap
          subItem
          count={ noteItemsCount }
          href={ ROUTES.NOTE.MAIN }
          getIcon={ () => NOTE }
          label={ label }
          onClick={ () => onClick(LABELS.note, noteItemsCount) }
          className={ styles['note-link'] }
          qaAttr={ QA_ATTRIBUTES.header.note }
          qaAttrCounter={ QA_ATTRIBUTES.header.noteCounter }
        />
      </div>
    );
  };

  renderTravelHelper = () => {
    const { onOpenTravelChat } = this.props;

    if (!isSmartAgent) {
      return null;
    }

    return (
      <div onClick={ onOpenTravelChat }>
        <Text
          className={ styles['travel-helper'] }
          type={ NORMAL_14 }
        >
          { LABELS.travelHelper }
        </Text>
      </div>
    );
  };

  handleEnterOnMenu = value => this.setState({ enterOnMenu: value });

  handleShowMenuList = value => this.setState({ showMenuList: value, enterOnMenu: value });

  getBurgerItems = () => ([
    {
      label: LABELS.settings,
      link: `${ROUTES.SETTINGS.MAIN}`,
      onItemClick: null,
    },
    // В редизайне СА не будет этих полей комментарий пока оставлю чтобы не потерять и не забыть
    {
      label: `${LABELS.qualityControl}`,
      link: `${ROUTES.QUALITY.GENERAL}`,
      onItemClick: null,
    },
    // В редизайне СА не будет этих полей комментарий пока оставлю чтобы не потерять и не забыть
    {
      label: `${LABELS.travelHelper}`,
      link: '',
      onItemClick: () => this.props.onOpenTravelChat(),
    },
    {
      label: LABELS.exit,
      link: '',
      onItemClick: () => this.props.handleLogout(),
    },
  ]);

  renderBeforeRdesignNote = () => {
    const { getShowElementForSmartagent } = this.props;

    const wrapper = isSmartAgent && getShowElementForSmartagent
      ? `${styles.with_border} ${styles.no_border}`
      : styles.with_border;

    return (
      <div className={ wrapper }>
        { this.renderNote() }
      </div>
    );
  };

  renderOldNotePade = () => {
    if (isSmartAgent && !this.props.getShowElementForSmartagent) {
      return this.renderBeforeRdesignNote();
    }

    return null;
  };

  renderAlternativeMenu = () => {
    const { enterOnMenu, showMenuList } = this.state;

    const iconColor = enterOnMenu || showMenuList
      ? 'second_green'
      : 'default';

    return (
      <>
        {this.renderOldNotePade()}
        <div
          onMouseEnter={ () => this.handleEnterOnMenu(true) }
          onMouseLeave={ () => this.handleEnterOnMenu(false) }
          className={ `${styles.with_border} ${styles.none_padding}` }
        >
          <BurgerMenu
            icon='burgerMenu'
            listPosition='right'
            items={ this.getBurgerItems() }
            className={ styles.burger_menu }
            onClick={ () => this.handleShowMenuList(!this.state.showMenuList) }
            onBlur={ () => this.handleShowMenuList(false) }
            iconColor={ iconColor }
          />
        </div>
      </>
    );
  };

  renderNotePade = () => {
    if (isSmartAgent && this.props.getShowElementForSmartagent) {
      return this.renderBeforeRdesignNote();
    }

    return null;
  };

  renderAfterRedisignMenu = () => {
    const {
      accountRights,
    } = this.props;

    const accountLogin = accountRights.Login.charAt(0).toUpperCase();

    return (
      <>
        {this.renderNotePade()}
        <div className={ `${styles.with_border} ${styles.none_padding} ${styles.no_border}` }>
          <DynamicLoginBurgerMenu
            title={ accountLogin }
            icon='burgerMenu'
            listPosition='right'
            items={ this.getBurgerItems() }
            className={ styles.burger_menu }
            onClick={ () => this.handleShowMenuList(!this.state.showMenuList) }
            onBlur={ () => this.handleShowMenuList(false) }
          />
        </div>
      </>
    );
  };

  renderMenuBurger = () => {
    if (isSmartAgent && !this.props.getShowElementForSmartagent) {
      return this.renderAlternativeMenu();
    }

    return this.renderAfterRedisignMenu();
  };

  renderIntercomWarning = () => {
    const { isDemo } = this.props;
    const { showIntercomWarning } = this.state;

    if (showIntercomWarning && !isDemo) {
      const intercomTextOne = isSmartAgent ? LABELS.INTERCOM_WARNING_SA.ONE : LABELS.INTERCOM_WARNING.ONE;
      const intercomTextTwo = isSmartAgent ? LABELS.INTERCOM_WARNING_SA.TWO : LABELS.INTERCOM_WARNING.TWO;
      const intercomTextThree = isSmartAgent ? LABELS.INTERCOM_WARNING_SA.THREE : LABELS.INTERCOM_WARNING.THREE;

      return (
        <Tooltip
          position={ BOTTOM }
          className={ styles['intercom-warning-wrapper'] }
          renderContent={ () => (
            <div className={ styles.content }>
              <Text className={ styles.text } type={ NORMAL_14_130 }>
                { intercomTextOne }
              </Text>
              <Text className={ styles.text } type={ NORMAL_14_130 }>
                { intercomTextTwo }
              </Text>
              <Text className={ styles.text } type={ NORMAL_14_130 }>
                { intercomTextThree }
              </Text>
            </div>
          ) }
        >
          <Icon type={ WARNING } />
        </Tooltip>
      );
    }

    return null;
  };

  renderMenu = () => {
    const {
      onOpenTravelChat,
      onClick,
    } = this.props;

    return (
      <>
        <MediaQuery minWidth={ 820 }>
          <div className={ `${styles.item}` }>
            <div onClick={ onOpenTravelChat }>
              <Text
                className={ styles['travel-helper'] }
                type={ NORMAL_14 }
              >
                {LABELS.travelHelper}
              </Text>
            </div>
            { this.renderIntercomWarning() }
          </div>
        </MediaQuery>
        <MediaQuery minWidth={ 970 }>
          <div
            className={ `${styles.item} ${styles.quality_item}` }
            onClick={ () => onClick(LABELS.qualityControl, 0) }
          >
            <NavLink to={ ROUTES.QUALITY.GENERAL }>
              <Text type={ NORMAL_14 } className={ styles.quality }>
                {LABELS.qualityControl}
              </Text>
            </NavLink>
          </div>
        </MediaQuery>
        { this.renderNote() }
        { this.renderFavorite() }
      </>
    );
  };

  renderContent = () => {
    if (isSmartAgent) {
      return this.renderMenuBurger();
    }

    return this.renderMenu();
  };

  render() {
    return (
      <div className={ styles.wrapper }>{ this.renderContent() }</div>
    );
  }
}

export default MainHeaderItems;
