import React, { useState } from 'react';
import { Dialog, Text, Textarea } from 'new-ui';
import { getText } from '../../../../../i18n';

import { SERVICETYPE } from '../../../../bi/constants/serviceType';

import { ButtonTrip } from '../ButtonTrip';
import { ContainsHotelOrders } from '../ContainsHotelOrders';

import { ITripItem } from '../../../../bi/types/trips';

import styles from './styles/index.module.css';

const LABELS = {
  TITLE: getText('components:tripDetailsDialog.editDialog.title'),
  AIR_TITLE: getText('components:tripDetailsDialog.editDialog.airTitle'),
  CONTENT: getText('components:tripDetailsDialog.editDialog.content'),
  SOON_ANSWER: getText('components:tripDetailsDialog.editDialog.soonAnswer'),
  HEADER: {
    AIR: getText('components:tripDetailsDialog.editDialog.header.air'),
    HOTEL: getText('components:tripDetailsDialog.editDialog.header.hotel'),
    DEFAULT: getText('components:tripDetailsDialog.editDialog.header.default'),
  },
  PLACEHOLDER: getText('components:tripDetailsDialog.editDialog.placeholder'),
  BUTTON: getText('components:tripDetailsDialog.editDialog.edit'),
};

interface IEditDialogProps {
  orderItems: ITripItem[],
  nameTrip: string,
  item: ITripItem,
  isChatLoading: boolean,
  isDisabled: boolean,
  onCloseDialog: () => void,
  onSubmitCancellationModal: (result: boolean, item: any, inputValue: string, serviceType: string) => void,
}

const EditDialog = ({
  orderItems,
  item,
  item: { ActualVersion: { ServiceType } },
  nameTrip,
  isChatLoading,
  isDisabled,
  onCloseDialog,
  onSubmitCancellationModal,
}: IEditDialogProps) => {
  const [inputValue, setInputValue] = useState('');
  const [selectedHotelOrders, setSelectedHotelOrders] = useState([item]);
  const title = ServiceType === SERVICETYPE.AIR ? LABELS.AIR_TITLE : LABELS.TITLE;

  const getTooltipText = (type: string) => {
    if (type === SERVICETYPE.AIR) {
      return LABELS.HEADER.AIR;
    }

    if (type === SERVICETYPE.HOTEL) {
      return LABELS.HEADER.HOTEL;
    }

    return LABELS.HEADER.DEFAULT;
  };

  const handleChangeContainsCheckbox = (value: boolean, containsOrder: ITripItem) => {
    let newOrders = [...selectedHotelOrders];

    if (value) {
      newOrders.push(containsOrder);

      return setSelectedHotelOrders(newOrders);
    }

    newOrders = newOrders.filter(({ Id }) => Id !== containsOrder.Id);

    return setSelectedHotelOrders(newOrders);
  };

  const handleConfirmDialog = () => {
    if (ServiceType === SERVICETYPE.HOTEL) {
      return onSubmitCancellationModal(true, selectedHotelOrders, inputValue, ServiceType);
    }

    return onSubmitCancellationModal(true, item, inputValue, ServiceType);
  };

  const handleChange = (value: string) => setInputValue(value);

  return (
    <Dialog
      show
      showClosing
      className={ styles.wrapper }
      onChange={ () => onCloseDialog() }
    >
      <div className={ styles.content_wrapper }>
        <Text type={ 'bold_20' }>{ title }</Text>
        <Text type={ 'NORMAL_16_140' } className={ styles.trip_name }>{ nameTrip }</Text>
        <Text type={ 'NORMAL_16_140' }>{ LABELS.SOON_ANSWER }</Text>
        <ContainsHotelOrders
          serviceType={ ServiceType }
          orderItems={ orderItems }
          currentOrder={ item }
          onChangeCheckbox={ handleChangeContainsCheckbox }
          selectedHotelOrders={ selectedHotelOrders }
        />
        <Textarea
          placeholder={ getTooltipText(ServiceType) }
          className={ styles.textarea }
          value={ inputValue }
          onChange={ handleChange }
        />
        <Text type={ 'NORMAL_14_130' } color={ 'gray' } className={ styles.content }>
          { LABELS.CONTENT }
        </Text>
        <ButtonTrip
          valueIsEmpty={ !inputValue }
          loading={ isChatLoading }
          hasDisabled={ isDisabled }
          firstWord={ LABELS.BUTTON }
          onCloseDialog={ onCloseDialog }
          onSubmitCancellationModal={ handleConfirmDialog }
        />
      </div>
    </Dialog>
  );
};

export { EditDialog };
