// @ts-nocheck
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Icon, PROPS, Text } from 'new-ui';
import { getText } from '../../../i18n';

import {
  getAirlineType,
  getTerminals,
  mappedDates,
  mappedSegments,
  mappedSegmentsFromTrip,
} from '../../bi/utils/airline';
import guid from '../../bi/utils/guid';
import toDecline from '../../bi/utils/toDecline';

import { AIRLINE_CLASSES_MAP, AIRLINE_IDS_W_CUSTOM_REFUND, TRANSFER } from '../../bi/constants/airline';
import { SERVICETYPE } from '../../bi/constants/serviceType';
import SYMBOLS from '../../bi/constants/symbols';

import AirlineItemSegment from '../AirlineItemSegment';
import FareDetails from '../FareDetails';
import { UnderageWarning } from '../UnderageWarning';
import { TechnicalStopTooltip } from '../AirlineTechnicalStop/components/Tooltip';

import styles from './styles/index.module.css';
import InfoDialog from '../AirlineSearchItem/components/infoDialog';

const {
  TEXT: {
    COLORS: { GRAY },
    TYPES: {
      NORMAL_14,
      NORMAL_14_130,
      NORMAL_16,
      SEMIBOLD_16,
      BOLD_14,
      BOLD_18,
      BOLD_22,
    },
  },
  BUTTON: {
    TYPES: { TEXTUAL },
  },
  ICON: {
    TYPES: {
      WARNING,
    },
  },
} = PROPS;

const getAirlineId = segment => {
  const airlineType = getAirlineType(segment);

  return segment[airlineType].ID || segment[airlineType].Code;
};

const airlineNames = (segments, firstSegment) => {
  const filterAirlines = segments.filter(
    segment => getAirlineId(segment) === getAirlineId(firstSegment),
  );
  const flightNumbers = segments
    .map(
      segment => `${getAirlineId(segment)} ${segment.FlightNumber}`,
    )
    .join(', ');

  if (
    segments.length === 1 ||
    (segments.length === 2 && filterAirlines.length === 2)
  ) {
    return `${
      firstSegment[getAirlineType(firstSegment)].Name
    } (${flightNumbers})`;
  }

  return flightNumbers;
};

const getRateName = (metaFare, metaClass) => {
  const airlineClass = AIRLINE_CLASSES_MAP.get(metaClass);

  if (!metaFare.Name && !airlineClass) {
    return '';
  }

  if (!metaFare.Name) {
    return airlineClass;
  }

  if (
    !airlineClass ||
    metaFare.Name.toLowerCase().includes(airlineClass.toLowerCase())
  ) {
    return metaFare.Name;
  }

  return `${airlineClass} (${metaFare.Name})`;
};

const LABELS = {
  withoutTransfers: getText('components:airlineSearchItem.airItem.withoutTransfers'),
  complex: getText('components:airlineSearchItem.complex.complexRoute'),
  terminal: getText('components:airlineItemSegment.terminal'),
};

export default class AirlineItem extends Component {
  static propTypes = {
    route: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    fromTrip: PropTypes.bool,
    pnr: PropTypes.string,
    hasUnderageEmployees: PropTypes.bool,
    getFreeSeatsData: PropTypes.func,
    isCart: PropTypes.bool,
    qaAttr: PropTypes.object,
    isMultiTrip: PropTypes.bool,
    airTripChange: PropTypes.bool,
    displayFareAirTripChange: PropTypes.bool,
    showTicketBullits: PropTypes.bool,
  };

  static defaultProps = {
    fromTrip: false,
    pnr: null,
    isCart: false,
    isDemo: false,
    hasUnderageEmployees: false,
    getFreeSeatsData: () => ({
      caption: null,
      data: null,
    }),
    qaAttr: {},
    isMultiTrip: false,
    airTripChange: false,
    displayFareAirTripChange: false,
  };

  state = {
    isShowSegments: false,
    isShowComplexDialog: false,
  };

  toggleSegments = () =>
    this.setState({
      isShowSegments: !this.state.isShowSegments,
    });

  toggleComplexInfo = () =>
    this.setState({
      isShowComplexDialog: !this.state.isShowComplexDialog,
    });

  renderSegments = () => {
    const {
      fromTrip,
      route: { Segments },
    } = this.props;

    const segmentsHtml = Segments.map((segment, index) => {
      const arrivalTerminal = getTerminals(segment, index, Segments)
        .arrival;
      const departureTerminal = getTerminals(segment, index, Segments)
        .departure;

      return (
        <AirlineItemSegment
          key={ `segment_${guid()}_${index}` }
          fromTrip={ fromTrip }
          segment={ segment }
          arrivalTerminal={ arrivalTerminal }
          departureTerminal={ departureTerminal }
          opts={
            fromTrip
              ? mappedSegmentsFromTrip(segment)
              : mappedSegments(segment)
          }
        />
      );
    });

    return (
      <div onClick={ this.toggleSegments } className={ styles.segments }>
        {segmentsHtml}
      </div>
    );
  };

  renderPNR = () => {
    const {
      meta: { TicketsExtended },
      pnr,
    } = this.props;

    if (TicketsExtended) {
      const items = [];
      TicketsExtended.forEach(item => {
        let element = item.PNR;

        if (item.PNRLocator) {
          element += ` / ${item.PNRLocator}`;
        }

        items.push(element);
      });

      return (
        <div className={ styles.pnr }>
          <Text type={ SEMIBOLD_16 }>
            {items.join(', ')}
          </Text>
        </div>
      );
    }

    if (pnr) {
      return (
        <div className={ styles.pnr }>
          <Text color={ GRAY } type={ SEMIBOLD_16 }>
            {pnr}
          </Text>
        </div>);
    }

    return null;
  };

  renderFreeSeats = () => {
    const { getFreeSeatsData, route } = this.props;
    const { data, caption } = getFreeSeatsData(route);

    if (!caption) {
      return null;
    }

    return (
      <div className={ styles.free_seats }>
        <Text type={ BOLD_14 }>{caption}:&nbsp;</Text>
        <Text type={ NORMAL_14_130 }>{data}</Text>
      </div>
    );
  };

  renderRate = () => {
    const {
      meta: { Class, Fare },
      isCart,
      airTripChange,
      route,
      showTicketBullits,
    } = this.props;

    if (!Fare) {
      return null;
    }

    const customTitle = getRateName(Fare, Class);
    const { MarketingAirline, Airline } = route.Segments[0];

    const customRefundabilityAndExchangeability = AIRLINE_IDS_W_CUSTOM_REFUND.includes(
      MarketingAirline ? MarketingAirline.ID : Airline.Code,
    );

    const displayCart = !airTripChange && isCart;

    return (
      <Text type={ NORMAL_14_130 }>
        <FareDetails
          customRefundabilityAndExchangeability={
            customRefundabilityAndExchangeability
          }
          isCart={ displayCart }
          title
          customTitle={ customTitle }
          fare={ Fare }
          separateSymbol={ SYMBOLS.COMMA }
          className={ styles.fare }
          showTicketBullits={ showTicketBullits }
        />
      </Text>
    );
  };

  renderComplex = () => {
    const { isMultiTrip } = this.props;

    if (!isMultiTrip) {
      return null;
    }

    return (
      <div className={ styles['complex-wrapper'] } onClick={ this.toggleComplexInfo }>
        <Text type={ BOLD_14 } className={ styles.text }>
          { LABELS.complex }
        </Text>
        <Icon type={ WARNING } />
      </div>
    );
  };

  changesContent = (changeCount, segments) => {
    if (changeCount) {
      return (
        <Button
          type={ TEXTUAL }
          className={ styles['change-toggle'] }
          onClick={ this.toggleSegments }
        >
          { changeCount } { toDecline(changeCount, TRANSFER) }
        </Button>
      );
    }

    return (
      !segments[0].TechnicalStop?.length && (
        <Text
          type={ NORMAL_14 }
          color={ GRAY }
        >
          {LABELS.withoutTransfers}
        </Text>
      )
    );
  };

  renderVoucherDetails = () => {
    const {
      airTripChange,
      displayFareAirTripChange,
    } = this.props;

    if (airTripChange && !displayFareAirTripChange) {
      return null;
    }

    const displayRenderComplex = !airTripChange && this.renderComplex();
    const displayRenderPNR = !airTripChange && this.renderPNR();

    return (
      <div className={ styles['voucher-details'] }>
        {displayRenderComplex}
        {this.renderRate()}
        {displayRenderPNR}
      </div>
    );
  };

  render() {
    const { route, fromTrip, hasUnderageEmployees, qaAttr } = this.props;

    const { isShowSegments, isShowComplexDialog } = this.state;

    const segments = route.Segments;
    const firstSegment = segments[0];
    const lastSegment = segments[segments.length - 1];

    const changeCount = segments.length - 1;

    const {
      departureTime,
      arrivalTime,
      departureDate,
      arrivalDate,
      departureWeek,
      arrivalWeek,
      duration,
    } = mappedDates(route, fromTrip);

    const airlineNameWithNumbers = airlineNames(
      segments,
      firstSegment,
    );

    const durationStyles = route.Segments[0].TechnicalStop?.length
      ? styles.duration_with_technical
      : styles.duration;

    const departureTerminal = firstSegment.DepartureTerminal && (
      <Text className={ styles.terminal } qaAttr={ qaAttr.fromTerminal } type={ NORMAL_16 }>
        {LABELS.terminal}: {`${firstSegment.DepartureTerminal}`}
      </Text>
    );

    const arrivalTerminal = lastSegment.ArrivalTerminal && (
      <Text className={ styles.terminal } qaAttr={ qaAttr.toTerminal } type={ NORMAL_16 }>
        {LABELS.terminal}: {`${lastSegment.ArrivalTerminal}`}
      </Text>
    );

    return (
      <div className={ styles.item }>
        <div className={ styles.top }>
          <div className={ styles.left }>
            <Text qaAttr={ qaAttr.from } type={ BOLD_18 }>
              {`${firstSegment.DepartureCity}, `}
              {firstSegment.DepartureAirport.ID ||
                firstSegment.DepartureAirport.Code}
            </Text>
            {departureTerminal}
            <Text qaAttr={ qaAttr.timeFrom } type={ BOLD_22 } className={ styles.time }>
              {departureTime}
            </Text>
            <div data-qa={ qaAttr.dateFrom } className={ styles.date }>
              <Text
                className={ 'notranslate' }
                color={ GRAY }
                type={ NORMAL_14 }
              >
                { `${departureDate},` }
              </Text>
                &nbsp;
              <Text color={ GRAY } type={ NORMAL_14 }>
                {departureWeek}
              </Text>
            </div>
          </div>
          <div className={ styles.center }>
            <Text color={ GRAY } type={ NORMAL_14 }>
              {airlineNameWithNumbers}
            </Text>
            <Text className={ durationStyles } type={ SEMIBOLD_16 }>
              {duration}
            </Text>
            <div className={ styles.changes }>
              {this.changesContent(changeCount, segments)}
              {!!route.Segments[0].TechnicalStop?.length && (
                <TechnicalStopTooltip
                  changeCount={ changeCount }
                  route={ route }
                />
              )}
            </div>
          </div>
          <div className={ styles.right }>
            <Text qaAttr={ qaAttr.to } type={ BOLD_18 }>
              {`${lastSegment.ArrivalCity}, `}
              {lastSegment.ArrivalAirport.ID ||
                lastSegment.ArrivalAirport.Code}
            </Text>
            {arrivalTerminal}
            <Text qaAttr={ qaAttr.timeTo } type={ BOLD_22 } className={ styles.time }>
              {arrivalTime}
            </Text>
            <div data-qa={ qaAttr.dateTo } className={ styles.date }>
              <Text
                className={ 'notranslate' }
                color={ GRAY }
                type={ NORMAL_14 }
              >
                { `${arrivalDate},` }
              </Text>
                &nbsp;
              <Text color={ GRAY } type={ NORMAL_14 }>
                {arrivalWeek}
              </Text>
            </div>
          </div>
        </div>
        {changeCount && isShowSegments ? this.renderSegments() : null}
        {this.renderVoucherDetails()}
        {this.renderFreeSeats()}
        {hasUnderageEmployees && (
          <UnderageWarning type={ SERVICETYPE.AIR } />
        )}
        <InfoDialog
          show={ isShowComplexDialog }
          onAddToCart={ this.toggleComplexInfo }
          onlyInfo
        />
      </div>
    );
  }
}
