import { UUID } from 'node:crypto';

import { yup, yupEnum } from '../../../../utils/yup';

export enum EnhancedSignatureModalTypes {
  docToEmailInput,
  docToEmailSent,
  docToGovSent,
  docPreview,
}

enum SortField {
  Status = 'status',
  Name = 'name',
}

export enum SortType {
  Desc = 'desc',
  Asc = 'asc',
}

enum DocumentSourceType {
  Undefined,
  ExpenseReport,
}

export enum SigningStatus {
  Processing,
  SimpleSigned,
  NotSigned,
  EnhancedSigned,
  SignError,
}

export enum SigningStatusSimple {
  Undefined,
  Signed,
  NotValid,
}

export const GetDocumentsQuerySchema = yup.object({
  employeeId: yup.number(),
  page: yup.number().default(1),
  limit: yup.number().default(6),
  sortField: yupEnum(SortField).default(SortField.Status),
  sortType: yupEnum(SortType).default(SortType.Desc),
});

export interface IDocumentInfo {
  documentId: UUID,
  name: string,
  signingStatus: SigningStatus,
  accountId: number,
  companyId: number | null,
  sourceType: DocumentSourceType,
  employeeIds: number[],
  changedDate: string,
}

export interface IGetDocumentsResponse {
  documents: IDocumentInfo[]
  count: number
}

export type GetDocumentsQueryType = yup.InferType<typeof GetDocumentsQuerySchema>;
