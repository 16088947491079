import React, { useState } from 'react';

import { SERVICETYPE } from '../../bi/constants/serviceType';

import EmployeeItem from './item';

import { IEmployeeItem, IProjects } from '../../bi/types/employees';
import WorkspaceService from '../../bi/services/workspace';
import EmployeeService from '../../bi/services/employee';
import SettingsService from '../../bi/services/settings';
import AccountSettings from '../../bi/services/accountSettings';

import styles from './styles/index.module.css';

const DEFAULT_QA_ATTR_PAID_SEATS = {
  wrapper: '',
  price: '',
  seat: '',
  title: '',
  delete: '',
  employee: '',
  errors: {
    noForeignPassport: '',
    noDomesticPassport: '',
    foulForeignPassport: '',
  },
};

const DEFAULT_ON_HANDLES_METRICS = {
  handleMetricStartSelectEmployee: () => {},
  handleMetricStartDeleteEmployee: () => {},
  handleMetricStartSelectProject: () => {},
  handleMetricStartDeleteProject: () => {},
  handleMetricStartDeleteItem: () => {},
  handleMetricStartSelectTag: () => {},
  endTime: () => {},
};

interface IQaAttrPaidSeats {
  wrapper: string;
  price: string;
  seat: string;
  title: string;
  delete: string;
  employee: string;
  errors: {
    noForeignPassport: string;
    noDomesticPassport: string;
    foulForeignPassport: string;
  },
}

interface IOnHandlesMetrics {
  handleMetricStartSelectEmployee(): void;
  handleMetricStartDeleteEmployee(): void;
  handleMetricStartSelectProject(): void;
  handleMetricStartDeleteProject(): void;
  handleMetricStartDeleteItem(): void;
  handleMetricStartSelectTag(): void;
  endTime(): void;
}

interface EmployeeListProps {
  items: any[]; // TODO заменить после типизаци cart-wp
  routes?: any[]; // TODO заменить после типизаци cart-wp
  projects: IProjects[];
  employeesWithCompanies: IEmployeeItem[];
  max: number;
  enumOfDocuments: any;
  employeeService: EmployeeService;
  workspaceService: WorkspaceService;
  settingsService: SettingsService;
  buyingEmployee?: any | null;
  onHandlesMetrics?: IOnHandlesMetrics;
  serviceType: string;
  lastAddedEmployee?: any | null;
  chosenApprovedRequest?: any | null;
  qaAttrPaidSeats?: IQaAttrPaidSeats;
  qaAttr?: { [key: string]: string } | null;
  approvedList?: any[];
  accountSettingsService: AccountSettings;
  isMulti?: boolean;
  isCart?: boolean;
  isPersonalBonusCards: boolean;
  showDocument: boolean;
  readOnly: boolean;
  departmentOnly: boolean;
  readonlyProjects?: boolean;
  mandatoryProject: boolean;
  optionalProject?: boolean;
  isChoiceForEachEmployee: boolean;
  isOptionalEmployee: boolean;
  isReserved?: boolean;
  highlightNonFilledEmployee?: boolean;
  withNumber?: boolean;
  isTravelApprovalAndNoBooking?: boolean;
  cartEmployeeBirthday?: boolean;
  isSpecificCart?: boolean;
  handleChangeLastAddedEmployee?(): void;
  redirectToProject(): void;
  onChangeProjectSelect(): void;
  onSetEmployeeInCartItem?(): void;
  handleAddEmployee(): void;
  handleRemoveEmployee(): void;
  handleAddDocument(): void;
  handleAddCompany(): void;
  onResetNonFilledItem?(): void;
  redirectToEmployee(): void;
  onSelectedEmployee?(): void;
  onSelectedOptionEmployee?(): void;
  onSelectDocument?(): void;
  onSelectCompany?(): void;
  onSelectProject?(): void;
  onRemoveEmployee?(): void;
  onRemoveOptionalEmployee?(): void;
  onAddProject?(): void;
  onValidRequest?(): void;
}

const EmployeeList = ({
  items,
  enumOfDocuments,
  workspaceService,
  settingsService,
  employeesWithCompanies,
  max,
  handleAddEmployee,
  handleRemoveEmployee,
  handleAddDocument,
  handleAddCompany,
  departmentOnly,
  mandatoryProject,
  isChoiceForEachEmployee,
  redirectToProject,
  isPersonalBonusCards,
  serviceType,
  employeeService,
  accountSettingsService,
  isCart = false,
  isMulti = false,
  showDocument = false,
  readOnly = false,
  isOptionalEmployee = false,
  projects = [],
  routes = [],
  chosenApprovedRequest = null,
  isReserved = false,
  optionalProject = false,
  buyingEmployee = null,
  readonlyProjects = false,
  isTravelApprovalAndNoBooking = false,
  redirectToEmployee = () => {},
  onSetEmployeeInCartItem = () => {},
  onChangeProjectSelect = () => {},
  onSelectedEmployee = () => {},
  onSelectedOptionEmployee = () => {},
  onSelectDocument = () => {},
  onSelectCompany = () => {},
  onSelectProject = () => {},
  onRemoveEmployee = () => {},
  onRemoveOptionalEmployee = () => {},
  handleChangeLastAddedEmployee = () => {},
  onAddProject = () => {},
  onValidRequest = () => {},
  onResetNonFilledItem = () => {},
  onHandlesMetrics = DEFAULT_ON_HANDLES_METRICS,
  highlightNonFilledEmployee = false,
  lastAddedEmployee = null,
  withNumber = false,
  cartEmployeeBirthday = false,
  qaAttrPaidSeats = DEFAULT_QA_ATTR_PAID_SEATS,
  qaAttr = null,
  isSpecificCart = false,
  approvedList = [],
}: EmployeeListProps) => {
  const [addedNewEmployee, setAddedNewEmployee] = useState<{ [key: number]: boolean }>({});
  const handleSetAddedNewEmployee = (employeeId: number, addedNow: boolean) =>
    setAddedNewEmployee({ [employeeId]: addedNow });

  const getChosenApprovedEmployees = (value: any) => {
    const res = value && chosenApprovedRequest
    // @ts-ignore
      ? chosenApprovedRequest.Employees.filter(({ Name, Surname }) =>
        Name.toLowerCase().includes(value.toLowerCase()) || Surname.toLowerCase().includes(value.toLowerCase()))
      : chosenApprovedRequest.Employees.slice(0, 5);

    return Promise.resolve(res);
  };

  const maxEmployees = isOptionalEmployee && items.length < max ? items.length + 1 : max;
  const isOptionalTitle = isOptionalEmployee && items.length > 0;
  const selectGetEmployees = chosenApprovedRequest && isTravelApprovalAndNoBooking
    ? getChosenApprovedEmployees
    : employeeService?.autoCompleteEmployeeOnly;
  const isApprovalHotel = isSpecificCart && serviceType === SERVICETYPE.HOTEL;

  return (
    <div className={ styles['list-wrapper'] }>
      { Array(maxEmployees).fill(0).map((item, index) => {
        const employeeHasId = items[index] && items[index].Employee && items[index].Employee.Id;

        const itemKey = employeeHasId
          ? `employee_${items[index].Employee.Id}_${index}`
          : `employee-${index}`;

        const isLastAddedEmployee =
            employeeHasId && lastAddedEmployee && lastAddedEmployee.Id === items[index].Employee.Id;

        const buyingEmp = !!buyingEmployee && buyingEmployee.Id && index === 0 && !item;
        const finderUserEmployee = buyingEmp
        // @ts-ignore
          ? chosenApprovedRequest?.Employees.find(({ Id }) => Id === buyingEmployee.Id)
          : null;
        const setChosenApprovedRequest = finderUserEmployee ?
          { ...chosenApprovedRequest, Employees: [finderUserEmployee] } : chosenApprovedRequest;

        return (
          <EmployeeItem
            withNumber={ withNumber }
            readonlyProjects={ readonlyProjects }
            key={ itemKey }
            index={ index }
            items={ items }
            routes={ routes }
            getEmployee={ employeeService?.getEmployeeById }
            onSetEmployeeInCartItem={ onSetEmployeeInCartItem }
            chosenApprovedRequest={ setChosenApprovedRequest }
            isTravelApprovalAndNoBooking={ isTravelApprovalAndNoBooking }
            projects={ projects }
            isLastAddedEmployee={ isLastAddedEmployee }
            workspaceService={ workspaceService }
            settingsService={ settingsService }
            buyingEmployee={ buyingEmployee }
            highlightNonFilledEmployee={ highlightNonFilledEmployee }
            onResetNonFilledItem={ onResetNonFilledItem }
            employeesWithCompanies={ employeesWithCompanies }
            item={ items[index] }
            number={ index + 1 }
            isMulti={ isMulti }
            isCart={ isCart }
            isPersonalBonusCards={ isPersonalBonusCards }
            isChoiceForEachEmployee={ isChoiceForEachEmployee }
            showDocument={ showDocument }
            readOnly={ readOnly }
            departmentOnly={ departmentOnly }
            mandatoryProject={ mandatoryProject }
            optionalProject={ optionalProject }
            isOptionalTitle={ isOptionalTitle }
            isTitle={ items.length === index }
            enumOfDocuments={ enumOfDocuments }
            getEmployees={ selectGetEmployees }
            onAddEmployee={ handleAddEmployee }
            handleRemoveEmployee={ handleRemoveEmployee }
            onHandlesMetrics={ onHandlesMetrics }
            handleAddDocument={ handleAddDocument }
            onAddCompany={ handleAddCompany }
            handleChangeLastAddedEmployee={ handleChangeLastAddedEmployee }
            redirectToEmployee={ redirectToEmployee }
            redirectToProject={ redirectToProject }
            onChangeProjectSelect={ onChangeProjectSelect }
            onSelectedEmployee={ onSelectedEmployee }
            onSelectedOptionEmployee={ onSelectedOptionEmployee }
            onSelectDocument={ onSelectDocument }
            onSelectCompany={ onSelectCompany }
            onSelectProject={ onSelectProject }
            onRemoveEmployee={ onRemoveEmployee }
            onValidRequest={ onValidRequest }
            onRemoveOptionalEmployee={ onRemoveOptionalEmployee }
            onAddProject={ onAddProject }
            isReserved={ isReserved }
            serviceType={ serviceType }
            manyEmployees={ maxEmployees > 1 }
            cartEmployeeBirthday={ cartEmployeeBirthday }
            qaAttrPaidSeats={ qaAttrPaidSeats }
            qaAttr={ qaAttr }
            isApprovalHotel={ isApprovalHotel }
            setAddedNewEmployee={ handleSetAddedNewEmployee }
            addedNewEmployee={ addedNewEmployee }
            approvedList={ approvedList }
            accountSettingsService={ accountSettingsService }
          />
        );
      }) }
    </div>
  );
};

export default EmployeeList;
