import { VALID_SYMBLOL } from '../../../../bi/constants/cart';
import { PRICE_FLOAT_REG_EXP, PRICE_FLOAT_REG_EXP_NO_OGR } from '../../../../bi/constants/regExp';

const additionalFeeValidation = (value: string, isRestrictions: boolean = true) => {
  const validFirstSybmol = value ? value[0] !== VALID_SYMBLOL.DOT && value[0] !== VALID_SYMBLOL.ZERO && value[0] !== VALID_SYMBLOL.COMMA : value;

  const fiftyCent = value
    ? (value[0] === VALID_SYMBLOL.ZERO && value[1] === VALID_SYMBLOL.DOT) || (value[0] === VALID_SYMBLOL.ZERO && value[1] === VALID_SYMBLOL.COMMA)
    : !value;

  const regular = isRestrictions ? PRICE_FLOAT_REG_EXP : PRICE_FLOAT_REG_EXP_NO_OGR;

  return !!(regular.test(value) && (fiftyCent || validFirstSybmol));
};

export {
  additionalFeeValidation,
};
