// @ts-nocheck
import PropTypes from 'prop-types';
import React from 'react';
import { Text, Tooltip, PROPS } from 'new-ui';

import styles from './styles/index.module.css';

const {
  TEXT: { TYPES: { NORMAL_14_130, NORMAL_12 }, COLORS: { WHITE, GRAY } },
} = PROPS;

const RoundIconWithTooltip = ({ className, label, text }) => {
  const tooltip = () => (
    <Text
      color={ WHITE }
      type={ NORMAL_14_130 }
      className={ styles.tooltip_box }
    >
      { text }
    </Text>
  );

  const wrappedStyles = [styles.wrapper];

  if (className) {
    wrappedStyles.push(className);
  }

  return (
    <Tooltip
      className={ wrappedStyles.join(' ') }
      show={ !!text.length }
      renderContent={ tooltip }
    >
      <Text type={ NORMAL_12 } color={ GRAY }>
        { label }
      </Text>
    </Tooltip>
  );
};

RoundIconWithTooltip.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  text: PropTypes.string,
};

RoundIconWithTooltip.defaultProps = {
  text: '',
  className: '',
};

export { RoundIconWithTooltip };
