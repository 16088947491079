export interface IFavorite {
  Hash: string,
  Id: string,
  Item: IAirItem | ITrainItem | IHotelItem | ITransferItem,
  Type: string,
}

interface IArrivalAndDeparture {
  Code: string,
  Name: string,
}

export interface IAirCommonType {
  ID: string;
  Name: string;
}

export interface IAirSegment {
  AircraftName: string;
  ArrivalAirport: IAirCommonType;
  ArrivalCity: string;
  ArrivalCountry: string;
  ArrivalTerminal: string;
  ArrivalTime: number;
  AvailableSeats: number;
  ChangeDuration: number;
  DepartureAirport: IAirCommonType;
  DepartureCity: string;
  DepartureCountry: string;
  DepartureTerminal: string;
  DepartureTime: number;
  FlightNumber: string;
  MarketingAirline: IAirCommonType;
  OperatingAirline: IAirCommonType;
}

export interface IAirRoute {
  Arrival: IArrivalAndDeparture,
  Date: string,
  Departure: IArrivalAndDeparture,
  Duration: number,
  Segments: IAirSegment[],
}

export interface IAirItem {
  Class: string,
  Duration: number,
  Id: number,
  IsDirect: boolean,
  LastTripDate: string,
  Routes: IAirRoute[],
  Type: string,
}

interface IDate {
  Date: string,
  Time: string,
}

export interface ITrainItem {
  ArrivalDate: IDate,
  ArrivalDateLocal: IDate,
  CodeStationFrom: number,
  CodeStationTo: number,
  DepartureDate: IDate,
  DepartureDateLocal: IDate,
  ProviderName: string,
  StationFrom: string,
  StationTo: string,
  TrainId: number,
  TrainName: string,
  TrainNumber: string,
  TrainNumberLocal: string,
  TravelTime: string,
  Type: string,
}

interface IAmenities {
  Internet: boolean,
  Meal: boolean,
  Pool: boolean,
  Fitness: boolean,
  Parking: boolean,
  Spa: boolean,
  Bath?: boolean,
  Breakfast: boolean,
}

export interface IHotelItem {
  Address: string,
  Amenities: IAmenities,
  ClassificatorId: number,
  DistanceFromCenter: number,
  IsSmartHotel: boolean,
  Latitude: number,
  Longitude: number,
  MainImageUrl?: string,
  Name: string,
  RegionId: number,
  RegionName: string,
  Stars: number,
  Type: string,
}

interface IPlace {
  Address: string,
  Coordinates: {
    Latitude: number,
    Longitude: number,
  },
  Type: string,
}

interface IPlaces {
  FinishPlace: IPlace,
  StartPlace: IPlace,
}

export interface ITransferItem {
  Capacity: number,
  Class: string,
  Models: string[],
  Places: IPlaces,
  Type: string,
}

export enum TAB_TYPES {
  All = 'All',
}

export interface ITab {
  label: string,
  value: string,
}
