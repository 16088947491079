type PaymentMethodTypes = 'CA' | 'CP' | 'PA';

const PAYMENT_METHODS: Record<string, PaymentMethodTypes> = {
  ORGANIZATION_ACCOUNT: 'CA',
  EMPLOYEE_CARD: 'CP',
  EMPLOYEE_PERSONAL_ACCOUNT: 'PA',
};

export { PAYMENT_METHODS };
export type { PaymentMethodTypes };
