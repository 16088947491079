import React from 'react';
import { Moment } from 'moment';
import { Text, TextColor } from 'new-ui';

import { diffMinutesAndHours } from '../../../../bi/utils/time';
import { hoursAndMinutesPattern, momentObjectUTC } from '../../../../bi/utils/formatDate';

import { TECHNICAL_LANDINGS } from '../../../../bi/constants/airline';

import { ITechnicalStop } from '../../../../bi/types/airline';

const LABELS = {
  technicalStop: (text: string) => `Техническая посадка: ${text}`,
};

type TDetails = ITechnicalStop['Details'];

interface ITechnicalStopTextProps {
  technicalStop: ITechnicalStop[];
  className: string;
  textColor?: TextColor;
}
const getText = (details: TDetails) => {
  let result = '';
  let dateAA: Moment | undefined;
  let dateAD: Moment | undefined;

  details.forEach(({ DateQualifier, Date, LocationId }) => {
    if (DateQualifier === TECHNICAL_LANDINGS.FIRST) {
      dateAA = momentObjectUTC(Date);
      result += `${LocationId.split(',')[0]}, ${momentObjectUTC(dateAA).format(hoursAndMinutesPattern)} – `;
    }

    if (DateQualifier === TECHNICAL_LANDINGS.LAST) {
      dateAD = momentObjectUTC(Date);
      result += `${momentObjectUTC(dateAD).format(hoursAndMinutesPattern)} `;
    }
  });

  if (dateAA && dateAD) {
    const diffMinutesAndHoursText = diffMinutesAndHours(dateAA, dateAD);
    result += `(${diffMinutesAndHoursText})`;
  }

  return result;
};

const TechnicalStopText = ({ technicalStop, className, textColor = 'gray' }: ITechnicalStopTextProps) => (
  technicalStop.map(({ Details }, index) => {
    const text = getText(Details);

    return (
      <div key={ Details[index].Date } className={ className }>
        <Text
          type='NORMAL_14'
          color={ textColor }
        >
          { LABELS.technicalStop(text) }
        </Text>
      </div>
    );
  })
);

export { TechnicalStopText };
