// @ts-nocheck
import PropTypes from 'prop-types';
import React from 'react';
import { getText } from '../../../i18n';

import { InformationIcon } from '../InformationIcon';

const LABELS = {
  TOOLTIP: (timeType, moscowDate) => getText('components:localTime.tooltip', { timeType, moscowDate }),
};

const LocalTime = ({ dateLocal, dateMoscow, dayMoscow, timeMoscow, timeType, className, iconClassName }) => {
  const moscowDate = dateLocal.isSame(dateMoscow, 'day') ? timeMoscow : dayMoscow;
  const tooltipText = LABELS.TOOLTIP(timeType, moscowDate);

  return !dateLocal.isSame(dateMoscow) &&
    <InformationIcon className={ className } text={ tooltipText } iconClassName={ iconClassName } />;
};

LocalTime.propTypes = {
  timeType: PropTypes.string.isRequired,
  dateMoscow: PropTypes.object.isRequired,
  dateLocal: PropTypes.object.isRequired,
  dayMoscow: PropTypes.string.isRequired,
  timeMoscow: PropTypes.string.isRequired,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
};

LocalTime.defaultProps = {
  iconClassName: '',
  className: '',
};

export { LocalTime };
