import React from 'react';
import { Text, Checkbox } from 'new-ui';
import { getText } from '../../../../../i18n';

import { formatDate } from '../../../../bi/utils/formatDate';

import { SERVICETYPE } from '../../../../bi/constants/serviceType';
import { PATTERN } from '../../../../bi/constants/dateFormats';
import { EnumTripVersionStatus } from '../../../../bi/constants/trips';

import { IVoucherTravellers } from '../../../../bi/services/hotels/types';
import { ITripItem } from '../../../../bi/types/trips';

import styles from './styles/index.module.css';

const LABELS = {
  CONTAINS_ORDERS: {
    ONE: (hotelName: string) => getText('components:tripDetailsDialog.editDialog.containsOrders.one', { hotelName }),
    TWO: getText('components:tripDetailsDialog.editDialog.containsOrders.two'),
  },
  SELECT_ORDERS: getText('components:tripDetailsDialog.editDialog.selectOrders'),
};

const MAX_LENGTH_TRAVELLERS = 2;

interface IContainsHotelOrders {
  serviceType: string,
  orderItems: ITripItem[],
  selectedHotelOrders: ITripItem[],
  currentOrder: ITripItem,
  onChangeCheckbox: (value: boolean, containsOrder: ITripItem) => void,
}

type IHotelOrderParams = [string, string, number];

const getHotelOrderParams = (order: ITripItem): IHotelOrderParams => {
  const {
    Hotel: {
      Name,
      ClassificatorId,
    },
  } = JSON.parse(order.ActualVersion.JsonData);

  return [Name, ClassificatorId, order.Id];
};

const getTravellersFullName = (travellers: IVoucherTravellers[]): string => {
  const travellersFullName: string[] = [];

  travellers.forEach(({ Name, Surname, Patronymic }) => {
    const initialsOfPatronymic = Patronymic && `${Patronymic[0]}.`;
    travellersFullName.push(`${Surname} ${Name[0]}.${initialsOfPatronymic}`);
  });

  if (travellersFullName.length === MAX_LENGTH_TRAVELLERS) {
    return `${travellersFullName.join(', ')} - `;
  }

  return `${travellersFullName[0]} - `;
};

const ContainsHotelOrders = ({
  serviceType,
  orderItems,
  currentOrder,
  onChangeCheckbox,
  selectedHotelOrders,
}: IContainsHotelOrders) => {
  if (serviceType !== SERVICETYPE.HOTEL) return null;

  const renderCheckboxInfo = (travellers: IVoucherTravellers[], checkIn: string, checkOut: string) => {
    const date = `${formatDate(checkIn, PATTERN.SHORT_FULL_YEAR)} - ${formatDate(checkOut, PATTERN.SHORT_FULL_YEAR)}`;
    const checkboxInfo = getTravellersFullName(travellers) + date;

    return <Text type='SEMIBOLD_16'>{ checkboxInfo }</Text>;
  };

  const renderCheckboxes = (order: ITripItem) => {
    const {
      CheckinDate,
      CheckoutDate,
    } = order.ActualVersion;
    const { VoucherTravellers } = JSON.parse(order.ActualVersion.JsonData);

    const isSelected = !!selectedHotelOrders.find(({ Id }) => Id === order.Id);

    return (
      <Checkbox
        key={ order.Id }
        value={ isSelected }
        onChange={ (value: boolean) => onChangeCheckbox(value, order) }
        className={ styles.checkbox }
      >
        { renderCheckboxInfo(VoucherTravellers, CheckinDate, CheckoutDate) }
      </Checkbox>
    );
  };

  const [currentHotelName, currentHotelId, currentOrderId] = getHotelOrderParams(currentOrder);

  const containsOrderItems = orderItems.filter((orderItem) => {
    const { ServiceType, Status } = orderItem.ActualVersion;
    const correctStatuses = [EnumTripVersionStatus.Created, EnumTripVersionStatus.Changed];
    const isCorrectStatuses = correctStatuses.some(status => status === Status);

    if (ServiceType !== SERVICETYPE.HOTEL || !isCorrectStatuses) {
      return false;
    }

    const [name, hotelId, orderId] = getHotelOrderParams(orderItem);
    const hasHotelId = hotelId && currentHotelId && hotelId === currentHotelId;

    return (hasHotelId || name === currentHotelName) && currentOrderId !== orderId;
  });

  if (!containsOrderItems || !containsOrderItems.length) return null;

  const checkboxes = containsOrderItems.map(order => renderCheckboxes(order));

  return (
    <div className={ styles['contains-orders'] }>
      <Text type={ 'SEMIBOLD_16' }>{ LABELS.CONTAINS_ORDERS.ONE(currentHotelName) }</Text>
      <Text type={ 'SEMIBOLD_16' }>{ LABELS.CONTAINS_ORDERS.TWO }</Text>
      <Text type='SEMIBOLD_16' className={ styles['select-orders'] }>{ LABELS.SELECT_ORDERS }</Text>
      { checkboxes }
    </div>
  );
};
export { ContainsHotelOrders };
