// @ts-nocheck
import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { Datepicker, IconButton, PROPS, Text } from 'new-ui';

import { AirlineSearchMenuSuggestItem } from '../SuggestItem';

import splitWithoutRemovingSeparator from '../../../../../bi/utils/splitWithoutRemovingSeparator';

import { getText } from '../../../../../../i18n';

import DIRECTIONS from '../../../../../bi/constants/directions';
import { isSameDate } from '../../../../../bi/utils/formatDate';
import { isSmartAgent } from '../../../../../bi/utils/env';
import { MainAnalytic } from '../../../../../bi/utils/analytics';

import { SelectedRoutes } from '../../../../SelectedRoutes';

import stylesRow from './styles.module.css';

const LABELS = {
  DATE_TO: getText('components:menu.air.row.back'),
  CITY_FROM: getText('components:menu.air.row.cityFrom'),
  CITY_TO: getText('components:menu.air.row.cityTo'),
  DATE_FROM: getText('components:menu.air.row.to'),
};

const FIELDS = {
  FLIP: 'flip',
  DATE: 'date',
  DATE_BACK: 'date.back',
  SUGGEST_SELECT: 'suggest.select',
};

const { ICON: { TYPES: { CLOSE_BUTTONS } } } = PROPS;

class AirlineSearchRoute extends Component {
  static propTypes = {
    ind: PropTypes.number.isRequired,
    route: PropTypes.object.isRequired,
    isComplex: PropTypes.bool.isRequired,
    airlineService: PropTypes.object.isRequired,
    theme: PropTypes.string,
    isChangeAirTrip: PropTypes.bool,
    numberOfRoutes: PropTypes.number,
    qaAttrs: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.object.isRequired,
    ]),
  };

  static defaultProps = {
    theme: PROPS.SUGGEST.THEME.LIGHT,
    isChangeAirTrip: false,
    numberOfRoutes: 0,
    qaAttrs: null,
  };

  suggestFrom = createRef();
  suggestTo = createRef();

  state = {
    dateOpen: false,
    dateBackOpen: false,
  };

  handleFlipFromTo = key => this.props.airlineService.setSearchValue(FIELDS.FLIP, key);

  handleChangeBackDate = (key, value) => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.AIRLINE.SEARCH_AIR_DATE_CHOSEN, {
      date: value,
    });

    return this.props.airlineService.setSearchValue(FIELDS.DATE_BACK, { key, value });
  };

  handleSelectSuggest = (field, key, value) => this.props.airlineService.setSearchValue(FIELDS.SUGGEST_SELECT, { field, key, value });

  handleRemoveRoute = routeIndex => this.props.airlineService.removeSearchRoute(routeIndex);

  getSuggest = (field, key, query) => this.props.airlineService.autocomplete(field, key, query);

  clearSuggests = (field, ind) => this.props.airlineService.clearSuggests(field, ind);

  handleOpenDate = value => this.setState({ dateOpen: value });

  handleOpenDateBack = value => this.setState({ dateBackOpen: value });

  handleSuggestSelected = (field, suggest, ind) => {
    const { route } = this.props;

    if (suggest !== null) {
      const previousSelected = route[field].label;
      const newSelected = `${suggest.City} (${suggest.Code})`;

      this.handleSelectSuggest(field, ind, suggest);

      if (field === DIRECTIONS.FROM && newSelected !== previousSelected) this.suggestTo.current.focus();

      if (field === DIRECTIONS.TO && newSelected !== previousSelected) {
        this.handleOpenDate(true);
      }

      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.AIRLINE.SEARCH_CITY_CHOSEN, {
        city: newSelected,
      });
    }
  };

  handleDatePickerBlur = () => {
    const { route } = this.props;

    if (!route || !route.from.selected || !route.to.selected) {
      this.suggestForm.focus();
    }
  };

  handleChangeFromDate = (ind, value) => {
    const { route: { date }, airlineService: { setSearchValue } } = this.props;

    setSearchValue(FIELDS.DATE, { key: ind, value });

    if (!isSameDate(value, date)) {
      this.handleOpenDateBack(true);
    }

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.AIRLINE.SEARCH_AIR_DATE_CHOSEN, {
      date: value,
    });
  };

  renderSuggestion = ({ City, Name, Code }, query) => {
    if (!query) {
      return <AirlineSearchMenuSuggestItem city={ City } name={ Name } code={ Code } />;
    }

    const querySplit = splitWithoutRemovingSeparator(query);
    const city = this.getWordSuggest(City, querySplit);
    const name = this.getWordSuggest(Name, querySplit, PROPS.TEXT.TYPES.SEMIBOLD_12);
    const code = this.getWordSuggest(Code, querySplit);

    return <AirlineSearchMenuSuggestItem city={ city } name={ name } code={ code } />;
  };

  getWordSuggest = (name, query, typeText) => splitWithoutRemovingSeparator(name)
    .reduce((acc, word) => {
      const foundQueryWord = query.find((queryWord) => word.toLowerCase().startsWith(queryWord.toLowerCase()));

      if (foundQueryWord) {
        return [...acc, ...this.highlightWordStart(word, foundQueryWord, typeText)];
      }

      return [...acc, word];
    }, []);

  highlightWordStart = (string, query, typeText = PROPS.TEXT.TYPES.SEMIBOLD_16) => {
    const parts = splitWithoutRemovingSeparator(string, query, true);

    return [
      <Text
        type={ typeText }
        className={ stylesRow.highlight }
        key={ Math.random() * Math.random() }
      >
        { parts[0] }
      </Text>,
      ...parts.slice(1),
    ];
  };

  renderDateBack = () => {
    const {
      ind,
      route: {
        dateBack,
        date,
        minDate,
      },
      theme,
      qaAttrs,
    } = this.props;

    return (
      <div className={ stylesRow.date }>
        <Datepicker
          closeOnTabOut
          withLabel
          isCleansing
          theme={ theme }
          open={ this.state.dateBackOpen }
          placeholder={ LABELS.DATE_TO }
          inputTheme={ PROPS.DATEPICKER.INPUT_THEMES.OPEN }
          value={ dateBack }
          min={ date || minDate }
          onChange={ value => this.handleChangeBackDate(ind, value) }
          onBlur={ this.handleDatePickerBlur }
          wrapperClassName={ stylesRow.wrapper }
          inputClassName={ stylesRow.input }
          isDuration
          durationDates={ [date, dateBack] }
          alternativeDesign={ isSmartAgent }
          qaAttr={ qaAttrs?.dates?.to || '' }
        />
      </div>
    );
  };

  renderRemoveRote = () => {
    const { ind, qaAttrs } = this.props;

    const qaAttr = qaAttrs?.deleteRoute ? `${qaAttrs.deleteRoute}-${ind}` : '';

    const iconHtml = ind !== 0 && (
      <IconButton
        iconType={ PROPS.ICON.TYPES.CLOSE_BUTTONS.DEFAULT }
        onClick={ () => this.handleRemoveRoute(ind) }
        qaAttr={ qaAttr }
        alternativeDesign={ isSmartAgent }
      />
    );

    return (
      <div className={ stylesRow.clear }>
        {iconHtml}
      </div>
    );
  };

  renderRemoveRoteChangeAirTrip = () => {
    const { ind, numberOfRoutes, qaAttrs } = this.props;

    const qaAttr = qaAttrs?.deleteRoute ? `${qaAttrs.deleteRoute}-${ind}` : '';

    const iconHtml = (
      <IconButton
        iconType={ CLOSE_BUTTONS.DEFAULT }
        onClick={ () => this.handleRemoveRoute(ind) }
        qaAttr={ qaAttr }
      />
    );

    if (numberOfRoutes !== 1) {
      return (
        <div className={ stylesRow.clear_change }>
          {iconHtml}
        </div>
      );
    }

    return null;
  };

  render() {
    const {
      ind,
      route,
      isComplex,
      theme,
      isChangeAirTrip,
      qaAttrs,
    } = this.props;

    const { from, to, date, dateBack, minDate } = route;

    const rowStyle = [stylesRow.row];
    const inputStyles = [stylesRow.input];

    const removeRoteHtml = () => {
      if (isComplex && !isChangeAirTrip) {
        return this.renderRemoveRote();
      }

      if (isComplex && isChangeAirTrip) {
        return this.renderRemoveRoteChangeAirTrip();
      }

      return null;
    };

    const dateBackHtml = !isComplex && this.renderDateBack();
    let qaAttrDateFrom = qaAttrs?.dates?.from || '';

    if (theme) {
      rowStyle.push(stylesRow[theme]);
    }

    if (ind !== 0) {
      rowStyle.push(stylesRow.border);
      qaAttrDateFrom = qaAttrs?.dates?.from ? `${qaAttrs.dates.from}-${ind}` : '';
    }

    if (isChangeAirTrip) {
      inputStyles.push(stylesRow.input_change_air_trip);
    }

    const opts = {
      from: {
        value: from.label,
        items: from.suggestions,
        placeholder: LABELS.CITY_FROM,
        ref: this.suggestFrom,
        loading: from.loading,
        onSelect: suggest => this.handleSuggestSelected(DIRECTIONS.FROM, suggest, ind),
        onChange: v => this.getSuggest(DIRECTIONS.FROM, ind, v),
        onClear: () => this.clearSuggests(DIRECTIONS.FROM, ind),
      },
      to: {
        value: to.label,
        items: to.suggestions,
        placeholder: LABELS.CITY_TO,
        ref: this.suggestTo,
        loading: to.loading,
        onSelect: suggest => this.handleSuggestSelected(DIRECTIONS.TO, suggest, ind),
        onChange: v => this.getSuggest(DIRECTIONS.TO, ind, v),
        onClear: () => this.clearSuggests(DIRECTIONS.TO, ind),
      },
    };

    return (
      <div
        className={ rowStyle.join(' ') }
        ref={ (suggestForm) => { this.suggestForm = suggestForm; } }
      >
        <SelectedRoutes
          opts={ opts }
          theme={ theme }
          keyExtractor={ ({ Code }) => Code }
          nestedType={ PROPS.SUGGEST.NESTED_TYPE.COLUMN }
          renderItem={ this.renderSuggestion }
          onRevert={ () => this.handleFlipFromTo(ind) }
          ind={ ind }
          qaAttrs={ qaAttrs?.cities }
        />
        <div className={ stylesRow.date }>
          <Datepicker
            closeOnTabOut
            withLabel
            theme={ theme }
            open={ this.state.dateOpen }
            placeholder={ LABELS.DATE_FROM }
            inputTheme={ PROPS.DATEPICKER.INPUT_THEMES.OPEN }
            value={ date }
            min={ minDate }
            onChange={ value => this.handleChangeFromDate(ind, value) }
            wrapperClassName={ stylesRow.wrapper }
            inputClassName={ inputStyles.join(' ') }
            isDuration
            durationDates={ [date, dateBack] }
            qaAttr={ qaAttrDateFrom }
            alternativeDesign={ isSmartAgent }
          />
        </div>
        { dateBackHtml }
        { removeRoteHtml() }
      </div>
    );
  }
}

export { AirlineSearchRoute };
