import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  FC,
} from 'react';
import {
  IconButton,
  Dialog,
  Button,
  Textarea,
  Text,
} from 'new-ui';

import { getText } from '../../../i18n';

import { Notification } from './components/Notification';

import { isSmartAgent, svgColor } from '../../bi/utils/env';

import { TYPES } from '../../bi/constants/contentTypes';

import styles from './styles/index.module.css';

const LABELS = {
  MAIN: {
    COPY: getText('components:linkAction.copy'),
  },
  DEFAULT_LABEL: getText('components:linkAction.getLink'),
};

const EXEC_COMMAND = {
  COPY: 'Copy',
};

const copyTextToClipboard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text.replace(/\n+/gm, '\n'));
  } catch (err) {
    console.error('Error in copying text: ', err);
  }
};

const copyLayoutToClipboard = async (layout: ClipboardItem) => {
  try {
    await navigator.clipboard.write([layout]);
  } catch (err) {
    console.error('Error in copying text: ', err);
  }
};

interface LinkActionProps {
  label?: string,
  dialogLabel?: string,
  item: any,
  className?: string,
  link?: string,
  applicationLink?: string,
  isAllText?: boolean,
  withLinks?: boolean,
  additionalText?: string,
  generateLink?: (item: any) => string,
  onCopyToClipboard?(link: any): void,
  onClick?(): void,
}

const LinkAction: FC<LinkActionProps> = ({
  label = LABELS.DEFAULT_LABEL,
  dialogLabel = '',
  item,
  className = '',
  generateLink = () => '',
  link = '',
  isAllText = false,
  applicationLink = '',
  additionalText = '',
  withLinks = false,
  onCopyToClipboard = () => {},
  onClick = () => {},
}) => {
  const [valueToCopy, setValueToCopy] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [timeoutId, setTimeoutId] = useState(0);

  useEffect(() => () => window.clearTimeout(timeoutId), [timeoutId]);

  const clipboardValueRef = useRef<any>(null);

  const copyTextByType = (linkInput: HTMLInputElement) => {
    const text = isAllText ? linkInput?.innerText : linkInput?.value;
    const textWithDefaultPhrase = additionalText
      ? `${text}\n\n${additionalText}`
      : text;

    if (withLinks) {
      const htmlWithDefaultPhrase = additionalText
        ? `${linkInput.outerHTML} ${additionalText}`
        : linkInput.outerHTML;
      const blobHtml = new Blob([htmlWithDefaultPhrase], { type: TYPES.TEXT_HTML });
      const blobText = new Blob([textWithDefaultPhrase], { type: TYPES.TEXT_PLAIN });
      const clipboardItem = new ClipboardItem({ [TYPES.TEXT_PLAIN]: blobText, [TYPES.TEXT_HTML]: blobHtml });

      return copyLayoutToClipboard(clipboardItem);
    }

    return copyTextToClipboard(textWithDefaultPhrase);
  };

  const copyToClipboard = useCallback(() => {
    const linkInput = clipboardValueRef?.current;

    if (navigator.clipboard) {
      copyTextByType(linkInput);
    } else {
      if (isAllText) {
        const range = document.createRange();
        range.selectNodeContents(linkInput as Node);
        const selection = window.getSelection();
        selection?.removeAllRanges();
        selection?.addRange(range);
      } else {
        linkInput.focus();
        linkInput.select();
      }

      document.execCommand(EXEC_COMMAND.COPY);
    }

    setShowNotification(true);

    setTimeoutId(
      window.setTimeout(() => {
        setShowNotification(false);
        setShowDialog(false);
      }, 2000),
    );

    onCopyToClipboard(linkInput);
  }, [isAllText, onCopyToClipboard]);

  const generateUri = useCallback(
    (cb = () => {}) => {
      if (typeof generateLink === 'function') {
        setValueToCopy(generateLink(item));
      } else {
        setValueToCopy(link as string);
      }

      cb();
    },
    [generateLink, link, item],
  );

  const setShowDialogByValue = (value: boolean) => {
    setShowDialog(value);
  };

  const handleGetLinkClick = useCallback(() => {
    generateUri(() => setShowDialogByValue(true));
    onClick();
  }, [generateUri, onClick]);

  const content = (
    <IconButton
      className={ styles.label }
      iconType='link'
      iconColor={ svgColor }
      onClick={ handleGetLinkClick }
      alternativeDesign={ isSmartAgent }
    >
      <Text type='NORMAL_14'>{label}</Text>
    </IconButton>
  );

  const preparedValue = valueToCopy
    .split('/n')
    .filter(i => i !== '')
    .map((i, key) => (
      <>
        <span key={ key } className={ styles.copy }>
          <br />
          {i}
        </span>
      </>
    ));

  const textArea = (
    <div className={ styles.dialog }>
      <Textarea
        readOnly
        className={ styles.textarea }
        value={ valueToCopy }
        onChange={ () => {} }
        ref={ clipboardValueRef }
      />
    </div>
  );

  const renderDialogLabel = () => {
    if (!dialogLabel) return null;

    return (
      <>
        <span>{dialogLabel}</span>
        <br />
        <br />
      </>
    );
  };

  const dialogContent = () => (isAllText ? (
    <div className={ styles.dialog } ref={ clipboardValueRef } >
      {renderDialogLabel()}
      <a href={ applicationLink }>{item.Name}</a>
      {preparedValue}
    </div>
  ) : (
    textArea
  ));

  const dialog = (
    <Dialog
      showClosing
      show={ showDialog }
      onChange={ () => setShowDialogByValue(false) }
    >
      {dialogContent()}
      <div className={ styles.actions_block }>
        <Button
          className={ styles.action }
          type='primary'
          onClick={ () => generateUri(() => copyToClipboard()) }
        >
          {LABELS.MAIN.COPY}
        </Button>
        <Notification show={ showNotification } />
      </div>
    </Dialog>
  );

  return (
    <div className={ className }>
      {content}
      {dialog}
    </div>
  );
};

export { LinkAction };
