// @ts-nocheck
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, IconButton, PROPS, Text } from 'new-ui';
import { getText } from '../../../i18n';

import ProjectSelect from '../EmployeeList/ProjectSelect';

import styles from './styles.module.css';

const {
  TEXT: { COLORS: { GRAY } },
  ICON: { TYPES: { CLOSE_BUTTONS: { DEFAULT } } },
  BUTTON: { TYPES: { TEXTUAL } },
} = PROPS;

const LABEL = {
  PROJECT: getText('components:projectsInEmployeePage.project'),
  SHOW: getText('components:projectsInEmployeePage.show'),
};

const finderProjectName = (projects, projectId) => {
  const finderProject = projectId && projects?.find(({ Id }) => Id === projectId);

  return finderProject?.Name || '';
};

class ProjectsInEmployeePage extends Component {
  static propTypes = {
    projects: PropTypes.array.isRequired,
    projectId: PropTypes.number,
    isUserEmployee: PropTypes.bool.isRequired,
    onSelect: PropTypes.func.isRequired,
    qaAttr: PropTypes.string,
    qaAttrSearch: PropTypes.string,
    qaAttrFirstEl: PropTypes.string,
  };

  static defaultProps = {
    projectId: null,
    qaAttr: '',
    qaAttrSearch: '',
    qaAttrFirstEl: '',
  };

  constructor(props) {
    super();

    const { projects, projectId } = props;

    this.state = {
      isShowSelect: false,
      projectName: finderProjectName(projects, projectId),
    };
  }

  toggleShowSelect = isShowSelect => this.setState({
    isShowSelect,
  });

  handleSelectProjectName = id => this.setState({
    projectName: finderProjectName(this.props.projects, id),
  });

  handleSelectSuggestion = (id) => {
    this.props.onSelect(id);
    this.handleSelectProjectName(id);
    this.toggleShowSelect(false);
  };

  renderPlaceholder = () => <Text color={ GRAY }>{ LABEL.PROJECT }</Text>;

  renderProjectName = () => {
    const { qaAttr } = this.props;
    const { projectName } = this.state;

    if (projectName) {
      const deleteProjectButton = !this.props.isUserEmployee && (
        <IconButton
          iconType={ DEFAULT }
          onClick={ () => this.handleSelectSuggestion(0) }
          className={ styles.remove }
        />
      );

      return (
        <div className={ styles.content }>
          <Text className={ styles.project_name }>{ projectName }</Text>
          { deleteProjectButton }
        </div>
      );
    }

    return (
      <div className={ styles.costCenters }>
        <Button
          tabIndex={ -1 }
          type={ TEXTUAL }
          onClick={ () => this.toggleShowSelect(true) }
          qaAttr={ qaAttr }
        >
          &nbsp;
          { LABEL.SHOW }
        </Button>
      </div>
    );
  };

  render() {
    const { qaAttrSearch, qaAttrFirstEl } = this.props;

    if (this.state.isShowSelect) {
      return (
        <ProjectSelect
          projects={ this.props.projects }
          onSelect={ this.handleSelectSuggestion }
          onClose={ () => this.toggleShowSelect(false) }
          qaAttr={ qaAttrSearch }
          qaAttrFirstEl={ qaAttrFirstEl }
        />
      );
    }

    return (
      <div className={ styles.project }>
        { this.renderPlaceholder() } { this.renderProjectName() }
      </div>
    );
  }
}

export default ProjectsInEmployeePage;
