import React from 'react';
import { List, NoResults, Skeleton, StyledWrapper } from 'new-ui';

import { getText } from '../../../i18n';

import { TripAgent } from './components/Trips';
import TitleTrips from './components/Title';

import { isSmartAgent } from '../../bi/utils/env';

import { ITripPanel } from './types';

import styles from './styles/index.module.css';

const LABELS = {
  NO_RESULT: getText('smartdesk:tripsPanel.noResult'),
};

const TripsPanel = ({
  isTrips,
  trips,
  history,
}: ITripPanel) => {
  const handleGoToTrip = (tripId: number) => {
    history.push(`/trip/${tripId}`);
  };

  if (!isTrips) {
    return (
      <StyledWrapper className={ styles.skeleton }>
        <Skeleton className={ styles.skeleton }/>
      </StyledWrapper>
    );
  }

  if (!trips.length) {
    return (
      <StyledWrapper className={ styles.wrapper }>
        <div className={ styles.wrapper_title }>
          <TitleTrips
            isShow
            isShowGoTo={ false }
          />
        </div>
        <div className={ styles.wrapper_result }>
          <NoResults
            alternativeDesign={ isSmartAgent }
            label={ LABELS.NO_RESULT }
          />
        </div>
      </StyledWrapper>
    );
  }

  return (
    <div>
      <StyledWrapper className={ styles.wrapper }>
        <List
          className={ styles.list }
          renderItem={ (trip: any, index: number) => (
            <TripAgent
              key={ trip.Id }
              value={ trip }
              index={ index }
              handleGoToTrip={ handleGoToTrip }
            />
          ) }
          items={ trips }
          onClickItem={ ({ Id }) => handleGoToTrip(Id) }
          isLittle
        />
      </StyledWrapper>
    </div>
  );
};

export default TripsPanel;
