import React from 'react';
import { Button, MultiSelect, Select, Text } from 'new-ui';

import { getText } from '../../../i18n';

import { MainAnalytic } from '../../bi/utils/analytics';

import {
  AllFiltersProps,
  ItemEasy,
} from '../../bi/types/filters';

import styles from './styles/index.module.css';

const LABELS = {
  PLACEHOLDER: getText('settings:employees.allFilters.modal.placeholder.null'),
  ALL_FILTERS: getText('settings:employees.allFilters.modal.allFilters'),
  APPLY_FILTER: getText('settings:employees.allFilters.modal.applyFilter'),
  RESET_FILTER: getText('settings:employees.allFilters.modal.resetFilter'),
  MODAL: {
    ACESS_SERVICE: getText('settings:employees.allFilters.modal.select.acessService'),
    COMPANIES: getText('settings:employees.allFilters.modal.select.companies'),
    SEX: getText('settings:employees.allFilters.modal.select.sex'),
    PASSPORT: getText('settings:employees.allFilters.modal.select.passport'),
    TAGS: getText('settings:employees.allFilters.modal.select.tags'),
    SEARCH_TAGS: getText('settings:employees.allFilters.modal.select.searchTags'),
    COST_CENTER: getText('settings:employees.allFilters.modal.select.costCenter'),
    SEARCH_COST_CENTER: getText('settings:employees.allFilters.modal.select.searchCostCenter'),
    BONUS_CARD: getText('settings:employees.allFilters.modal.select.bonusCard'),
    SEARCH_USER_ANALYTICS: getText('settings:employees.allFilters.modal.select.userAnalytics'),
    USER_ANALYTICS: getText('settings:employees.allFilters.modal.select.searchUserAnalytics'),
    DEPARTMENTS: getText('settings:employees.allFilters.modal.select.departments'),
    TRAVEL_POLICY: getText('settings:employees.allFilters.modal.select.travelPolicy'),
    SEARCH_TRAVEL_POLICY: getText('settings:employees.allFilters.modal.select.searchTravelPolicy'),
    CODE: getText('settings:employees.allFilters.modal.select.code'),
    SEARCH_CODE: getText('settings:employees.allFilters.modal.select.searchCode'),
    SEARCH_DEPARTMENTS: getText('settings:employees.allFilters.modal.select.searchDepartments'),
  },
};

const AllFilters = ({
  selectedSex,
  selectedDocument,
  selectedBonusCards,
  selectedStatus,
  selectedCompany,
  selectedDepartments,
  selectedTags,
  selectedProjects,
  selectedTravelPolicies,
  selectedCodes,
  filtersStore: {
    response: {
      Sex,
      Document,
      BonusCards,
      Status,
      Company,
      Tags,
      Projects,
      Departments,
      TravelPolicies,
      Codes,
    },
    filterSelector: {
      Sex: selectorSex,
      Document: selectorDocunent,
      BonusCards: selectorBonusCards,
      Status: selectorStatus,
      Company: selectorCompany,
      Tags: selectorTags,
      Projects: selectorProjects,
      Departments: selectorDepartments,
      TravelPolicies: selectorTravelPolicies,
      Codes: selectorCodes,
    },
  },
  setFilters,
  resetSelectedState,
  calculateFilterCounter,
  showFilters,
  applyTags,
}: AllFiltersProps) => {
  const applyFilters = (close: boolean) => {
    calculateFilterCounter();
    applyTags();

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.FILTERS_APPLY);

    showFilters(close);
  };

  const setFilter = (el: any, defaultType: string, analyticType: string) => {
    setFilters(el, defaultType);
    MainAnalytic.sendAmplitude(analyticType);
  };

  const resetFilters = () => {
    resetSelectedState();
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.FILTERS_DELETE_PRESSED);
  };

  const renderFilterSingleSelect = (
    item: ItemEasy[],
    itemValue: number,
    type: string,
    labelFilter: string,
    analyticsType: string,
  ) => {
    const renderSelectItem = (renderItem: { label: string }) => renderItem.label;

    const isDisabled = item.length === 1;

    const isSearch = type !== selectorDocunent;

    return (
      <div
        className={ styles.filter_wrapper }
      >
        <div
          className={ styles.name_filter }
        >
          <Text
            type='NORMAL_16'
            color='gray'
          >
            { labelFilter }
          </Text>
        </div>
        <Select
          key={ type }
          search={ isSearch }
          disabled={ isDisabled }
          excludeValue
          className={ styles.width }
          items={ item }
          value={ itemValue }
          renderItem={ renderSelectItem }
          theme='default-large'
          onChange={ (value: any) => setFilter(value, type, analyticsType) }
        />
      </div>
    );
  };

  const renderFilterMultiSelect = (
    item: ItemEasy[],
    itemValue: number[] | string[],
    type: string,
    labelFilter: string,
    searchLabel: string,
    analyticsType: string,
  ) => {
    const isDisabled = !item.length;

    return (
      <div
        className={ styles.filter_wrapper }
      >
        <div
          className={ styles.name_filter }
        >
          <Text
            type='NORMAL_16'
            color='gray'
          >
            { labelFilter }
          </Text>
        </div>
        <MultiSelect
          key={ type }
          isPlaceholderAsSelect
          disabled={ isDisabled }
          className={ styles.width }
          search
          overflow
          theme='large'
          withArrow
          list={ item }
            // @ts-ignore
          values={ itemValue }
          onChange={ (el: any) => setFilter(el, type, analyticsType) }
          placeholder={ LABELS.PLACEHOLDER }
          searchPlaceholder={ searchLabel }
        />
      </div>
    );
  };

  const renderAllFilters = () => (
    <div
      className={ styles.filters_wrapper }
    >
      { renderFilterSingleSelect(
        Company,
        selectedCompany,
        selectorCompany,
        LABELS.MODAL.COMPANIES,
        MainAnalytic.ACTIONS.SETTINGS.FILTERS_COMPANY,
      ) }
      { renderFilterMultiSelect(
        Departments,
        selectedDepartments,
        selectorDepartments,
        LABELS.MODAL.DEPARTMENTS,
        LABELS.MODAL.SEARCH_DEPARTMENTS,
        MainAnalytic.ACTIONS.SETTINGS.FILTERS_DEPARTMENTS,
      ) }
      { renderFilterSingleSelect(
        Status,
        selectedStatus,
        selectorStatus,
        LABELS.MODAL.ACESS_SERVICE,
        MainAnalytic.ACTIONS.SETTINGS.FILTERS_ACCESS,
      ) }
      { renderFilterSingleSelect(
        Sex,
        selectedSex,
        selectorSex,
        LABELS.MODAL.SEX,
        MainAnalytic.ACTIONS.SETTINGS.FILTERS_GENDER,
      ) }
      { renderFilterMultiSelect(
        Codes,
        selectedCodes,
        selectorCodes,
        LABELS.MODAL.CODE,
        LABELS.MODAL.SEARCH_CODE,
        MainAnalytic.ACTIONS.SETTINGS.FILTERS_CITIZENSHIP,
      ) }
      { renderFilterSingleSelect(
        Document,
        selectedDocument,
        selectorDocunent,
        LABELS.MODAL.PASSPORT,
        MainAnalytic.ACTIONS.SETTINGS.FILTERS_DOCUMENTS,
      ) }
      { renderFilterMultiSelect(
        TravelPolicies,
        selectedTravelPolicies,
        selectorTravelPolicies,
        LABELS.MODAL.TRAVEL_POLICY,
        LABELS.MODAL.SEARCH_TRAVEL_POLICY,
        MainAnalytic.ACTIONS.SETTINGS.FILTERS_TRAVEL_POLICY,
      ) }
      { renderFilterMultiSelect(
        Projects,
        selectedProjects,
        selectorProjects,
        LABELS.MODAL.COST_CENTER,
        LABELS.MODAL.SEARCH_COST_CENTER,
        MainAnalytic.ACTIONS.SETTINGS.FILTERS_PROJECTS,
      ) }
      { renderFilterMultiSelect(
        Tags,
        selectedTags,
        selectorTags,
        LABELS.MODAL.TAGS,
        LABELS.MODAL.SEARCH_TAGS,
        MainAnalytic.ACTIONS.SETTINGS.FILTERS_TAGS,
      ) }
      { renderFilterSingleSelect(
        BonusCards,
        selectedBonusCards,
        selectorBonusCards,
        LABELS.MODAL.BONUS_CARD,
        MainAnalytic.ACTIONS.SETTINGS.FILTERS_BONUS_CARDS,
      ) }
    </div>
  );

  return (
    <div
      className={ styles.wrapper }
    >
      <div
        className={ styles.header_wrapper }
      >
        <Text
          type='bold_32'
        >
          { LABELS.ALL_FILTERS }
        </Text>
        <div
          className={ styles.buttons_wrapper }
        >
          <Button
            type='textual'
            className={ styles.button_reset }
            onClick={ resetFilters }
          >
            { LABELS.RESET_FILTER }
          </Button>
          <Button
            onClick={ () => applyFilters(false) }
          >
            { LABELS.APPLY_FILTER }
          </Button>
        </div>
      </div>
      { renderAllFilters() }
    </div>
  );
};

export { AllFilters };
