// @ts-nocheck
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Tooltip, Text, Button, PROPS, Dropdown } from 'new-ui';
import { getText } from '../../i18n';
import { languages } from '../../i18n/locales/consts';

import { VERSIONSTATUS } from '../../App/bi/constants/trips';
import parseJsonString from '../../App/bi/utils/parseJsonString';
import { isSmartAgent } from '../../App/bi/utils/env';
import { MainAnalytic } from '../../App/bi/utils/analytics';
import {
  SERVICETYPE,
  FLIGHT_CERTIFICATE_TYPES,
  ANALYTIC_SERVICE_TYPE_MATCH,
} from '../../App/bi/constants/serviceType';

import styles from './styles/tripDocuments.module.css';

const {
  TEXT: {
    TYPES: {
      NORMAL_14_130,
    },
    COLORS: {
      GRAY,
      WHITE,
    },
  },
  BUTTON: {
    TYPES: {
      TEXTUAL,
    },
  },
} = PROPS;

const LABELS = {
  SEND_TO_EMAIL: getText('trip:documents.sendToEmail'),
  DOWNLOAD: getText('trip:documents.download'),
  TICKET_REFERENCE: getText('trip:documents.ticketRef'),
  E_TICKET_REFERENCE: getText('trip:documents.eTicketRef'),
  TICKET_REFERENCE_CAN_BE_DOWNLOAD: getText('trip:documents.downloadAfterEndTrip'),
  TICKET: getText('trip:documents.ticket'),
  E_TICKET: getText('trip:documents.eTicket'),
  SERVICE_FORM: getText('trip:documents.blankService'),
  APPROVE_BOOKING: getText('trip:documents.bookingApprove'),
  ADDITIONAL_SERVICE_PNR: (EmdNumber = null) => getText('trip:documents.additionalServicePnr', { EmdNumber }),
  SEND_TO_EMAIL_NOT_AVAILABLE: getText('trip:documents.sendToEmailNotAvailable'),
  FORMAT_PDF_RU: getText('trip:documents.formatPdfRu'),
  FORMAT_PDF_EN: getText('trip:documents.formatPdfEn'),
};

export default class TripDocuments extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    item: PropTypes.object.isRequired,
    isProhibitionVoucher: PropTypes.bool.isRequired,
    flightCertificateStatus: PropTypes.string,
    onPrintDocument: PropTypes.func,
    onSendDocument: PropTypes.func,
    onDownloadVoucher: PropTypes.func,
    additionalServiceId: PropTypes.number,
    downloadServiceForm: PropTypes.func,
    sendServiceForm: PropTypes.func,
    onDownloadVoucherLocale: PropTypes.func,
    tripId: PropTypes.number,
  };

  static defaultProps = {
    onPrintDocument: null,
    downloadServiceForm: null,
    onDownloadVoucher: () => {},
    onSendDocument: () => {},
    sendServiceForm: () => {},
    onDownloadVoucherLocale: () => {},
    additionalServiceId: null,
    isBookItem: false,
    flightCertificateStatus: '',
    tripId: 0,
  };

  constructor(props) {
    super(props);

    const firstEmployeeId = props.item.Employees.length ? props.item.Employees[0].Id : null;

    this.mapServiceTypesToRenderDocuments = {
      [SERVICETYPE.AIR]: this.renderAirDocuments,
      [SERVICETYPE.HOTEL]: () => this.renderDocumentContent(LABELS.APPROVE_BOOKING, firstEmployeeId),
      [SERVICETYPE.TRANSFER]: () => this.renderDocumentContent(LABELS.APPROVE_BOOKING, firstEmployeeId),
      [SERVICETYPE.VIP_HALL]: () => this.renderDocumentContent(LABELS.APPROVE_BOOKING, firstEmployeeId),
      [SERVICETYPE.TRAIN]: () => this.renderDocumentContent(this.renderTrainTicketNumber(), firstEmployeeId),
      [SERVICETYPE.AEROEXPRESS]: () => this.renderDocumentContent(this.renderAeroexspressTicketNumber(), firstEmployeeId),
      [SERVICETYPE.FLIGHT_CERTIFICATE]: this.renderFlightCertificate,
      [SERVICETYPE.CUSTOM]: () => this.renderDocumentContent(LABELS.APPROVE_BOOKING, firstEmployeeId),
      [SERVICETYPE.AIR_ADDITIONAL_SERVICE]: () => this.renderDocumentContent(this.renderAdditionalServiceText(), firstEmployeeId),
    };

    this.state = {
      loadingFlightCertificate: false,
      loadingButton: false,
    };
  }

  isServiceForm = () => this.props.additionalServiceId !== null;

  toggleLoadingFlightCertificate = value => this.setState({
    loadingFlightCertificate: value,
  });

  toggleLoadingButton = value => this.setState({
    loadingButton: value,
  });

  handleDownload = (employeeId) => {
    const {
      id,
      item: { ServiceType },
      onPrintDocument,
      onDownloadVoucher,
      downloadServiceForm,
      additionalServiceId,
    } = this.props;

    if (this.isServiceForm() && downloadServiceForm) {
      downloadServiceForm(id, additionalServiceId).finally(this.stopLoading);

      return;
    }

    if (onPrintDocument) onPrintDocument(id, employeeId, ServiceType).finally(this.stopLoading);

    onDownloadVoucher(ServiceType);
  };

  handleDownloadVoucherLocale = (locale) => {
    const { id, onDownloadVoucherLocale, item: { ServiceType }, tripId } = this.props;

    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.TRIPS.TRIP_VOUCHER_PRESSED(tripId, ANALYTIC_SERVICE_TYPE_MATCH[ServiceType], locale),
    );

    return onDownloadVoucherLocale(id, locale).finally(this.stopLoading);
  };

  handleDownloadCertificate = (employeeId) => {
    this.toggleLoadingFlightCertificate(true);
    this.handleDownload(employeeId);
  };

  handleDownloadButton = (employeeId) => {
    this.toggleLoadingButton(true);
    this.handleDownload(employeeId);
  };

  handleDownloadVoucherLocaleButton = (locale) => {
    this.toggleLoadingButton(true);

    this.handleDownloadVoucherLocale(locale);
  };

  stopLoading = () => {
    this.toggleLoadingFlightCertificate(false);
    this.toggleLoadingButton(false);
  };

  renderFlightCertificate = () => {
    const { item: { JsonData }, flightCertificateStatus } = this.props;
    const canDownload = flightCertificateStatus === FLIGHT_CERTIFICATE_TYPES.CAN_DOWNLOAD || flightCertificateStatus === FLIGHT_CERTIFICATE_TYPES.FAILED;

    const itemData = parseJsonString(JsonData);

    const ticket = itemData ? `${LABELS.TICKET_REFERENCE} #${itemData.FlightData.Tickets}` : LABELS.E_TICKET_REFERENCE;

    const downLoadBtnHtml = (
      <Button
        loading={ this.state.loadingFlightCertificate }
        type={ TEXTUAL }
        disabled={ !canDownload }
        onClick={ () => this.handleDownloadCertificate(itemData.AirTripItemId) }
      >
        { LABELS.DOWNLOAD }
      </Button>
    );

    const downloadBtnWithTooltipHtml =
      <Tooltip
        className={ styles.tooltip_wrap }
        renderContent={ () => (
          <Text type={ NORMAL_14_130 } color={ WHITE } className={ styles.tooltip_content }>
            { LABELS.TICKET_REFERENCE_CAN_BE_DOWNLOAD }
          </Text>
        ) }
      >
        {downLoadBtnHtml}
      </Tooltip>;

    const buttonHtml = canDownload ? downLoadBtnHtml : downloadBtnWithTooltipHtml;

    return (
      <div className={ styles.flight_certificate_wrap }>
        <Text color={ GRAY }>{ ticket }: </Text>
        { buttonHtml }
      </div>
    );
  };

  renderAdditionalServiceText = () => {
    const { item: { ServiceType, JsonData } } = this.props;
    const { EmdNumber } = JSON.parse(JsonData);

    if (ServiceType !== SERVICETYPE.AIR_ADDITIONAL_SERVICE) return null;

    return LABELS.ADDITIONAL_SERVICE_PNR(EmdNumber);
  };

  renderTicketNumber = () => {
    const { item } = this.props;

    if (this.isServiceForm()) {
      return LABELS.SERVICE_FORM;
    }

    const itemData = parseJsonString(item.JsonData);

    if (itemData && itemData.TicketsExtended && itemData.TicketsExtended[0] && itemData.TicketsExtended[0].Num) {
      return `${LABELS.TICKET} #${itemData.TicketsExtended[0].Num}`;
    }

    return LABELS.E_TICKET;
  };

  renderAeroexspressTicketNumber = () => {
    const { item: { JsonData } } = this.props;

    const itemData = parseJsonString(JsonData);
    const { Ticket: { Id } } = itemData;

    return itemData && Id ? `${LABELS.TICKET} #${Id}` : LABELS.E_TICKET;
  };

  renderTrainTicketNumber = () => {
    const { item: { JsonData } } = this.props;
    const itemData = parseJsonString(JsonData);

    const { TicketNumber } = itemData;

    return itemData && TicketNumber ? `${LABELS.TICKET} #${TicketNumber}` : LABELS.E_TICKET;
  };

  renderButtonDownload = (employeeId) => (
    <Button
      loading={ this.state.loadingButton }
      type='textual'
      className={ styles.btn }
      onClick={ () => this.handleDownloadButton(employeeId) }
    >
      { LABELS.DOWNLOAD }
    </Button>
  );

  renderDropdownHtml = (employeeId) => (
    <div className={ styles.label }>
      <Dropdown
        isOpenMenu
        hoverOpenMenu
        renderLabel={ () => this.renderButtonDownload(employeeId) }
      >
        <div className={ styles.content }>
          <Button
            type='textual'
            onClick={ () => this.handleDownloadVoucherLocaleButton(languages.ru) }
          >
            <Text color='default' type='NORMAL_16'>{ LABELS.FORMAT_PDF_RU }</Text>
          </Button>
        </div>
        <div className={ styles.content }>
          <Button
            type='textual'
            onClick={ () => this.handleDownloadVoucherLocaleButton(languages.en) }
          >
            <Text color='default' type='NORMAL_16'>{ LABELS.FORMAT_PDF_EN }</Text>
          </Button>
        </div>
      </Dropdown>
    </div>
  );

  renderDownloadButton = (employeeId) => {
    const { onPrintDocument, onDownloadVoucher, item: { ServiceType } } = this.props;

    if (!onPrintDocument && !onDownloadVoucher) return null;

    const avalibleServiceType =
      ServiceType === SERVICETYPE.AIR ||
      ServiceType === SERVICETYPE.HOTEL ||
      ServiceType === SERVICETYPE.TRANSFER ||
      ServiceType === SERVICETYPE.VIP_HALL;

    return avalibleServiceType && isSmartAgent ? this.renderDropdownHtml(employeeId) : this.renderButtonDownload(employeeId);
  };

  renderTooltip = () => (
    <Text
      color={ WHITE }
      type={ NORMAL_14_130 }
      className={ styles.tooltip_box }
    >
      {LABELS.SEND_TO_EMAIL_NOT_AVAILABLE}
    </Text>
  );

  renderSendButton = (employeeId) => {
    const {
      id,
      onSendDocument,
      sendServiceForm,
      additionalServiceId,
      isProhibitionVoucher,
      item: {
        ServiceType,
      },
    } = this.props;

    if (!onSendDocument) {
      return null;
    }

    const handleSendDocument = this.isServiceForm() ?
      () => sendServiceForm(id, employeeId, additionalServiceId) :
      () => onSendDocument(ServiceType, id, employeeId);

    return (
      <Tooltip
        show={ isProhibitionVoucher }
        renderContent={ this.renderTooltip }
      >
        <Button
          type={ TEXTUAL }
          className={ styles.btn }
          disabled={ isProhibitionVoucher }
          onClick={ handleSendDocument }
        >
          { LABELS.SEND_TO_EMAIL }
        </Button>
      </Tooltip>
    );
  };

  renderDocumentContent = (title, employeeId) => {
    const { item: { JsonData, ServiceType } } = this.props;

    if (ServiceType === SERVICETYPE.CUSTOM && !JSON.parse(JsonData).VoucherFileName) return null;

    // Ah shit, here we go again
    const colon = ServiceType !== SERVICETYPE.AIR_ADDITIONAL_SERVICE ? ':' : null;

    return (
      <div className={ styles.document }>
        <Text color={ GRAY }>{ title }{ colon } </Text>
        { this.renderSendButton(employeeId) }
        { this.renderDownloadButton(employeeId) }
      </div>
    );
  };

  renderAirDocuments = () =>
    this.props.item.Employees.map(({ Id }) => (
      <div key={ Id }>{ this.renderDocumentContent(this.renderTicketNumber(), Id) }</div>
    ));

  render() {
    const { item: { Status, ServiceType }, onPrintDocument, onSendDocument, onDownloadVoucher } = this.props;

    const haveAnyFn = onSendDocument || onPrintDocument || onDownloadVoucher;

    if (Status === VERSIONSTATUS.CANCELLED || !haveAnyFn) return null;

    const block = !!this.mapServiceTypesToRenderDocuments[ServiceType]
      && this.mapServiceTypesToRenderDocuments[ServiceType]();

    return (
      <div className={ styles.wrapper }>
        { block }
      </div>
    );
  }
}
