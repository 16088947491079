// @ts-nocheck
import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import styles from './styles/link.module.css';

const prepareLabel = (label, renderLabel) => (renderLabel ? renderLabel() : label);

const Link = ({
  name,
  onClick,
  className,
  solidLine,
  emptyLine,
  disabled,
  reactRouterLink,
  multilineBorder,
  to,
  children,
  classLink,
  renderLabel,
}) => {
  if (reactRouterLink) {
    return (
      <RouterLink to={ to } onClick={ onClick } className={ className }>
        { children }
      </RouterLink>
    );
  }

  const onClickFn = disabled ? () => {} : onClick;
  const label = prepareLabel(name, renderLabel);

  const link = multilineBorder ? (
    <p>
      <span onClick={ onClickFn } className={ classLink }>{ label }</span>
    </p>
  ) : (
    <a onClick={ onClickFn } className={ classLink }>{ label }</a>
  );

  const solidClass = solidLine ? styles.solid : '';
  const emptyClass = emptyLine ? styles.empty : '';
  const disabledClass = disabled ? styles.disabled : '';
  const classNames = `${styles.cancel} ${className} ${solidClass} ${emptyClass} ${disabledClass}`;

  return (
    <div className={ classNames }>
      { link }
    </div>
  );
};

Link.propTypes = {
  name: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  classLink: PropTypes.string,
  solidLine: PropTypes.bool,
  emptyLine: PropTypes.bool,
  disabled: PropTypes.bool,
  reactRouterLink: PropTypes.bool,
  multilineBorder: PropTypes.bool,
  to: PropTypes.string,
  children: PropTypes.node,
  renderLabel: PropTypes.func,
};

Link.defaultProps = {
  name: null,
  onClick: null,
  className: '',
  classLink: '',
  solidLine: false,
  emptyLine: false,
  disabled: false,
  reactRouterLink: false,
  multilineBorder: false,
  to: null,
  children: null,
  renderLabel: null,
};

export default Link;
