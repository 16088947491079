import { action, computed, makeObservable, observable } from 'mobx';
import { UUID } from 'node:crypto';

import { EmployeeType } from '../employee/consts';
import {
  EnhancedSignatureModalTypes,
  GetDocumentsQuerySchema,
  GetDocumentsQueryType,
  IGetDocumentsResponse,
} from './types';

export class EnhancedSignatureStore {
  constructor() {
    makeObservable(this);
  }

  @observable signListData: IGetDocumentsResponse = {
    documents: [],
    count: 0,
  };
  @observable autocompleteEmployeeList: EmployeeType[] = [];
  @observable listInputParams: GetDocumentsQueryType = GetDocumentsQuerySchema.getDefault();
  @observable selectedIds: UUID[] = [];
  @observable sendDocId: UUID | null = null;
  @observable downloadDocIds: UUID[] = [];
  @observable previewDoc: string[] = [];
  @observable integrationEnabled: boolean = false;
  @observable activeModal: EnhancedSignatureModalTypes | null = null;

  @computed
  get selectedEmployee() {
    const id = this.listInputParams.employeeId;

    return id ? this.autocompleteEmployeeList.find((emp) => emp.Id === id) : null;
  }

  setInputParameter = <
    K extends keyof GetDocumentsQueryType,
    V extends GetDocumentsQueryType[K],
  >(key: K) => action((value: V) => {
    this.listInputParams[key] = value;
  });

  @action.bound
  setSignListData = (value: IGetDocumentsResponse) => {
    this.signListData = value;
  };

  @action.bound
  setSelectedIdsList = (value: UUID[]) => {
    this.selectedIds = value;
  };

  @action.bound
  setAutocompleteEmployeeList = (value: EmployeeType[] | null) => {
    if (value?.length) this.autocompleteEmployeeList = value;

    return this.autocompleteEmployeeList;
  };

  @action.bound
  setSendDocId = (value: UUID | null) => {
    this.sendDocId = value;
  };

  @action.bound
  switchDownloadDocID = (value: UUID) => {
    const id = this.downloadDocIds.indexOf(value);

    if (id === -1) {
      this.downloadDocIds.push(value);
    } else {
      this.downloadDocIds.splice(id, 1);
    }
  };

  @action.bound
  setIntegrationEnabled = (value: boolean) => {
    this.integrationEnabled = value;
  };

  @action.bound
  setPreviewDocs = (value: string[]) => {
    this.previewDoc = value;
  };

  @action.bound
  openModal = (value: EnhancedSignatureModalTypes) => {
    this.activeModal = value;
  };

  @action.bound
  closeModal = () => {
    this.activeModal = null;
  };
}

export const enhancedSignatureStore = new EnhancedSignatureStore();
