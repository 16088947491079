import React, { useRef, useState, useEffect, ReactNode } from 'react';
import { Slider, Text } from 'new-ui';

import { getText } from '../../../i18n';

import { secondsToLabel } from '../../bi/utils/time';

import styles from './styles/index.module.css';

interface ITimeSliderProps {
  children: ReactNode | null,
  min: number,
  max: number,
  to: number,
  from: number,
  onSlide: (values: ITimeSliderValues) => number,
}

interface ITimeSliderValues {
  min: number,
  max: number,
}

const LABELS = {
  from: getText('components:timeSlider.from'),
  to: getText('components:timeSlider.to'),
};

const TimeSlider = ({
  children,
  min,
  max,
  to,
  from,
  onSlide,
}: ITimeSliderProps) => {
  const debounceTimer = useRef<number | ReturnType<typeof setTimeout> | null>(null);

  const [localFrom, setLocalFrom] = useState<number>(from);
  const [localTo, setLocalTo] = useState<number>(to);

  useEffect(() => {
    setLocalFrom(from);
    setLocalTo(to);
  }, [to, from]);

  const handleSlide = (values: ITimeSliderValues) => {
    const { min: cMin, max: cMax } = values;

    setLocalFrom(cMin);
    setLocalTo(cMax);

    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    debounceTimer.current = setTimeout(() => onSlide(values), 300);
  };

  const step = max - min >= 1800 ? 300 : 60;
  const minTime = secondsToLabel(localFrom);
  const maxTime = secondsToLabel(localTo);

  return (
    <div className={ styles['time-slide'] }>
      <div className={ styles.label }>
        { children }
      </div>
      <div className={ styles.wrap }>
        <Slider
          min={ min }
          max={ max }
          value={ { min: localFrom, max: localTo } }
          step={ step }
          debounceMs={ 0 }
          onChange={ handleSlide }
        />
        <Text type='NORMAL_12' color='gray' className={ styles.time }>
          { LABELS.from } { minTime } { LABELS.to } { maxTime }
        </Text>
      </div>
    </div>
  );
};

export { TimeSlider };
