// @ts-nocheck
import PropTypes from 'prop-types';
import React from 'react';
import { LinkButton, PROPS, Text, Tooltip } from 'new-ui';

import { getText } from '../../../i18n';

import { formatRangeDateWithSimplicity } from '../../bi/utils/formatDate';
import { getEmployeeFullNameWithSimplicity } from '../../bi/utils/employees';

import { ApprovalWarning } from '../../bi/constants/travelApproval';

import styles from './styles/index.module.css';

const {
  TOOLTIP: { POSITION: { BOTTOM } },
  TEXT: { TYPES: { NORMAL_14_130 }, COLORS: { WHITE } },
  LINK_BUTTON: { THEMES: { RED } },
} = PROPS;

const LABELS = {
  WARNING: getText('components:travelApproval.taWarning'),
  AVAILABLE_DATES: getText('components:travelApproval.availableDates'),
  AVAILABLE_DIRECTIONS: getText('components:travelApproval.availableDirections'),
  SELECTED_EMPLOYEES: getText('components:travelApproval.selectedEmployees'),
};

const NameErrorsTravelApproval = ({
  item,
  validateApprovedRequest,
  chosenApprovedRequest,
  className,
}) => {
  const foundErrors = validateApprovedRequest.CartItemValidationStatuses ? validateApprovedRequest.CartItemValidationStatuses[item.Id] : [];

  if (!foundErrors?.length || foundErrors[0] === ApprovalWarning.valid) {
    return null;
  }

  const tooltipClassNames = [styles.tooltip];

  if (className) {
    tooltipClassNames.push(className);
  }

  const errorMessage = () => {
    const dates = formatRangeDateWithSimplicity(chosenApprovedRequest.StartDate, chosenApprovedRequest.EndDate);
    const destinations = chosenApprovedRequest.Destinations.map(({ Name }) => Name);
    const employees = chosenApprovedRequest.Employees.map(emp => getEmployeeFullNameWithSimplicity(emp, true)).join(', ');

    return foundErrors.map((error, ind) => {
      const text = () => {
        if (error === ApprovalWarning.wrongDates) {
          return `${LABELS.AVAILABLE_DATES} ${dates}`;
        }

        if (error === ApprovalWarning.wrongPlace) {
          return `${LABELS.AVAILABLE_DIRECTIONS} ${destinations}`;
        }

        return `${LABELS.SELECTED_EMPLOYEES} ${employees}`;
      };

      return (
        <li key={ `error_message_${error}_${ind}` }>
          { text() }
        </li>
      );
    });
  };

  return (
    <Tooltip
      position={ BOTTOM }
      className={ tooltipClassNames.join(' ') }
      renderContent={ () => (
        <div className={ styles['tooltip-wrapper'] }>
          <Text
            color={ WHITE }
            type={ NORMAL_14_130 }
          >
            { LABELS.WARNING } &nbsp; { errorMessage() }
          </Text>
        </div>
      ) }
    >
      <LinkButton
        className={ styles.link }
        theme={ RED }
      >
        { LABELS.WARNING }
      </LinkButton>
    </Tooltip>
  );
};

NameErrorsTravelApproval.propTypes = {
  item: PropTypes.object.isRequired,
  validateApprovedRequest: PropTypes.object.isRequired,
  chosenApprovedRequest: PropTypes.object.isRequired,
  className: PropTypes.string,
};

NameErrorsTravelApproval.defaultProps = {
  className: '',
};

export { NameErrorsTravelApproval };
