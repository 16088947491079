// @ts-nocheck
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Dialog, Text, PROPS } from 'new-ui';
import { getText } from '../../../i18n';

import RulesItem from './components/RulesItem';

import styles from './styles/index.module.css';

const LABELS = {
  RULES: getText('components:rulesDialog.rules'),
  OK: getText('common:ok'),
};

class DialogRules extends Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
    onGetRules: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
  };

  handleGetRule = itemId => this.props.onGetRules(itemId);

  render() {
    const { items, onClose, show } = this.props;

    const listHtml = items.map((item, index) => (
      <div key={ index } className={ styles['list-item'] }>
        <RulesItem
          item={ item }
          onGetRule={ () => this.handleGetRule(item.Id) }
        />
      </div>
    ));

    return (
      <Dialog
        showClosing
        show={ show }
        className={ styles.dialog }
        onChange={ onClose }
        outsideClickClosing={ false }
      >
        <div className={ styles.wrapper }>
          <Text type={ PROPS.TEXT.TYPES.BOLD_22 }>{LABELS.RULES}</Text>
          { listHtml }
          <div className={ styles.action }>
            <Button
              type={ PROPS.BUTTON.TYPES.SECONDARY }
              onClick={ onClose }
            >
              {LABELS.OK.toUpperCase()}
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default DialogRules;
