// @ts-nocheck
class MixPanel {
  static track(action, model) {
    if (window.mixpanel && !window.mixpanel.isDemo) {
      window.mixpanel.track(action, model);
    }
  }

  static reset() {
    if (window.mixpanel) {
      window.mixpanel.reset();
    }
  }
}

export default MixPanel;
