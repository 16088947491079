// @ts-nocheck
import PropTypes from 'prop-types';
import React from 'react';
import { Text, Tooltip, PROPS, Price } from 'new-ui';

import { getText } from '../../../../i18n';

import Config from '../../../../config';

import toDecline from '../../../bi/utils/toDecline';

import { TICKETS } from '../../../bi/constants/airline';

import TooltipText from './tooltipText';

import styles from '../styles/travellersAmount.module.css';

const LABELS = {
  by: getText('components:airlineSearchItem.travellersAmount.by'),
};

const TravellersAmount = ({ travellers, currentFareId, fares, qaAttr }) => {
  const price = fares.find(({ Id }) => currentFareId === Id).Price;

  if (!price) return null;

  const pricePerPerson = price.TotalPrice / travellers;

  const tooltip = () => (
    <TooltipText
      price={ price }
      travellers={ travellers }
    />
  );

  return (
    <Tooltip
      position={ PROPS.TOOLTIP.POSITION.LEFT }
      show={ Config.SHOW_PRICE_DETAILS }
      renderContent={ tooltip }
      className={ styles.wrapper }
    >
      <div className={ styles.wrap_price }>
        <Text
          type={ PROPS.TEXT.TYPES.NORMAL_12 }
          color={ PROPS.TEXT.COLORS.GRAY }
          className={ styles.text }
          qaAttr={ qaAttr }
        >
          { travellers } { toDecline(travellers, TICKETS) } { LABELS.by }
        </Text>
        <Price
          marginSmall
          type={ PROPS.TEXT.TYPES.NORMAL_12 }
          typeCurrency={ PROPS.TEXT.TYPES.NORMAL_12 }
          color={ PROPS.TEXT.COLORS.GRAY }
          value={ pricePerPerson }
          className={ styles.price }
        />
      </div>
    </Tooltip>
  );
};

TravellersAmount.propTypes = {
  travellers: PropTypes.number,
  currentFareId: PropTypes.string,
  fares: PropTypes.array,
  qaAttr: PropTypes.string,
};

TravellersAmount.defaultProps = {
  travellers: 0,
  currentFareId: '',
  fares: [],
  qaAttr: '',
};

export { TravellersAmount };
